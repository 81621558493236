import React from "react";

import PageContainer from "components/common/PageContainer/PageContainer";

import { withAuth } from "../../../../hoc/withAuth";
import { Content } from "./content";
import { DetailedWheelProvider } from "./context";
import { ProgressChart } from "./progressChart";
import { SubmitEvidence } from "./submitEvidence";
import { IDetailedWheelProps } from "./types";

export const DetailedWheelPage = (props: IDetailedWheelProps) => (
  <DetailedWheelProvider {...props}>
    <PageContainer>
      <Content />
      <SubmitEvidence />
      <ProgressChart />
    </PageContainer>
  </DetailedWheelProvider>
);

export default withAuth(DetailedWheelPage, []);
