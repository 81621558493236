import { axiosInstance } from "helpers/axios-instance";
import { ITeacher } from "typings/models";

export const getTeacher = async (teacherId: string) => {
  return axiosInstance.get(`/teachers/?id=${teacherId}`);
};

export const updateTeacher = async (
  id: string,
  mmSchoolId: string,
  mmGroupIds: string[]
) => {
  return axiosInstance.put(`/teachers`, {
    id,
    mmSchoolId,
    mmGroupIds,
  });
};

export const getTeachers = async (
  name?: string,
  groupId?: string,
  schoolId?: string
) => {
  let query = "";
  query += name ? `&name=${name}` : "";
  query += groupId ? `&groupId=${groupId}` : "";
  query += schoolId ? `&schoolId=${schoolId}` : "";
  return axiosInstance.get(`/teachers/all?limit=1000000${query}`);
};

export const getTeachersPage = async (query = "") => {
  return axiosInstance.get(`/teachers/all${query}`);
};

export const hasAccess = (teacher: ITeacher, application: string) => {
  return teacher?.user?.id?.applicationsAccess?.includes(application);
};
