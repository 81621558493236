import React, { useEffect, useState } from "react";
import {
  addNewGroup,
  addStudentsToGroup,
  findAllGroups,
  findGroups,
  lockGroupForStudent,
  removeStudentsFromGroup,
} from "lib/group";
import { GroupsNavigation } from "components/teacher/GroupsNavigation";
import { IGroup, IStudent, ITeacher } from "@rose-arch-holdings-ltd/common";
import { Button, Typography } from "@material-ui/core";
import { S } from "./styles";
import { CreateGroupModel } from "components/teacher/CreateGroupModel";
import { EditGroupPage } from "pages/teacher/sections/EditGroupPage";
import { getTeachers } from "lib/teacher";
import { useAuth } from "../../../../hooks/useAuth";
import { getStudents } from "lib/student";
import { AddStudentToGroupModel } from "components/teacher/AddStudentToGroupModel";
import { RemoveStudentFromGroupModel } from "components/teacher/RemoveStudentFromGroupModel";
import { LockStudentGroupModel } from "components/teacher/LockStudentGroupModel";
import { axiosError } from "helpers/axios-instance";

export const GroupsPage = () => {
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [selectedGroup, setSelectedGroup] = useState<IGroup>();
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [allGroups, setAllGroups] = useState<IGroup[]>([]);
  const [groupsLoading, setGroupsLoading] = useState(true);
  const [groupTeachers, setGroupTeachers] = useState<ITeacher[]>([]);
  const [schoolTeachers, setSchoolTeachers] = useState<ITeacher[]>([]);
  const [schoolStudents, setSchoolStudents] = useState<IStudent[]>([]);
  const [isLoadingStudents, setIsLoadingStudents] = useState<boolean>(true);
  const [modelOpen, setModelOpen] = useState(false);
  const [newGroupName, setNewGroupName] = useState("");
  const [newGroupAdded, setNewGroupAdded] = useState(false);
  const [newGroupErrorMsg, setNewGroupErrorMsg] = useState("");

  const [selectedStudents, setSelectedStudents] = useState<string[]>([]);
  const [studToGroupModelOpen, setStudToGroupModelOpen] = useState(false);
  const [addStudentSubmitted, setAddStudentSubmitted] = useState(false);
  const [addStudentError, setAddStudentError] = useState(false);

  const [studFromGroupModelOpen, setStudFromGroupModelOpen] = useState(false);
  const [removeStudentSubmitted, setRemoveStudentSubmitted] = useState(false);
  const [removeStudentError, setRemoveStudentError] = useState(false);

  const [lockGroupModelOpen, setLockGroupModelOpen] = useState(false);
  const [lockGroupSubmitted, setLockGroupSubmitted] = useState(false);
  const [lockGroupError, setLockGroupError] = useState(false);

  const [studentSearch, setStudentSearch] = useState("");
  const [studentGroupSearch, setStudentGroupSearch] = useState("");
  const [studentYearSearch, setStudentYearSearch] = useState("");
  const [otherSearch, setOtherSearch] = useState("");

  const { user } = useAuth([]);

  const getGroupList = async (): Promise<void> => {
    try {
      const { data } = await findGroups();
      setGroups(data || []);
      const { data: data1 } = await findAllGroups();
      setAllGroups(data1 || []);
      setGroupsLoading(false);
    } catch {}
  };

  const getStudentsList = async (): Promise<void> => {
    try {
      if (selectedGroup?.schoolId) {
        const { data } = await getStudents(
          `?mmSchoolId=${selectedGroup?.schoolId}&name=${studentSearch}&yearGroup=${studentYearSearch}&other=${otherSearch}&groupName=${studentGroupSearch}`
        );
        setSchoolStudents(data?.students || []);
      }
    } catch {}
  };

  useEffect(() => {
    setIsLoadingData(true);
    (async () => {
      await getGroupList();
      setIsLoadingData(false);
    })();
  }, []);

  useEffect(() => {
    setIsLoadingStudents(true);
    (async () => {
      await getStudentsList();
      setIsLoadingStudents(false);
    })();
  }, [
    selectedGroup,
    studentSearch,
    studentYearSearch,
    otherSearch,
    studentGroupSearch,
  ]);

  const selectGroup = async (group: IGroup) => {
    setIsLoadingData(true);
    setSelectedGroup(group);
    try {
      const result1 = await getTeachers(undefined, group._id);
      setGroupTeachers(result1.data.teachers);
      const result2 = await getTeachers(
        undefined,
        undefined,
        user?.roles?.teacher?.id?.mm_school_id.toString()
      );
      setSchoolTeachers(result2.data.teachers);
    } catch {
    } finally {
      setIsLoadingData(false);
    }
  };

  const updateGroupList = async (): Promise<void> => {
    setGroupsLoading(true);
    await getGroupList();
  };

  const addStudents = async () => {
    try {
      await addStudentsToGroup(selectedStudents, [selectedGroup?._id ?? ""]);
      setAddStudentSubmitted(true);
      await getStudentsList();
      setSelectedStudents([]);
    } catch {
      setAddStudentError(true);
      setAddStudentSubmitted(false);
    }
  };

  const removeStudents = async () => {
    try {
      await removeStudentsFromGroup(selectedStudents, [
        selectedGroup?._id ?? "",
      ]);
      setRemoveStudentSubmitted(true);
      await getStudentsList();
      setSelectedStudents([]);
    } catch {
      setRemoveStudentError(true);
      setRemoveStudentSubmitted(false);
    }
  };

  const lockGroup = async () => {
    try {
      await lockGroupForStudent(selectedGroup?._id ?? "", selectedStudents);
      setLockGroupSubmitted(true);
      await getStudentsList();
      setSelectedStudents([]);
    } catch {
      setLockGroupSubmitted(false);
      setLockGroupError(true);
    }
  };

  const handleOpenModal = () => {
    setModelOpen(true);
  };

  const addGroup = async () => {
    try {
      await addNewGroup(newGroupName);
      setNewGroupAdded(true);
      await updateGroupList();
      setModelOpen(false);
      setNewGroupName("");
      setNewGroupAdded(false);
    } catch (err) {
      axiosError(err, (msg) => {
        setNewGroupErrorMsg(msg);
      });
      setNewGroupAdded(false);
      setGroupsLoading(false);
    }
  };

  const canAddStudents = () => {
    const students = selectedStudents.map((s) =>
      schoolStudents.find((i) => i._id.toString() === s)
    );
    const alreadyHasGroup = students.some((s) =>
      s?.mm_group_ids.includes(selectedGroup?._id || "")
    );
    return !alreadyHasGroup;
  };

  const canRemoveStudents = () => {
    const students = selectedStudents.map((s) =>
      schoolStudents.find((i) => i._id.toString() === s)
    );
    return students.every((s) =>
      s?.mm_group_ids.includes(selectedGroup?._id || "")
    );
  };

  const canLockStudents = () => {
    const students = selectedStudents.map((s) =>
      schoolStudents.find((i) => i._id.toString() === s)
    );
    const alreadyLocked = students.some((s) =>
      s?.mm_locked_group_ids.includes(selectedGroup?._id || "")
    );
    return canRemoveStudents() && !alreadyLocked;
  };

  const openAddUserModel = async () => {
    if (selectedStudents.length > 0 && canAddStudents()) {
      setStudToGroupModelOpen(true);
    }
  };

  const openRemoveUserModel = async () => {
    if (selectedStudents.length > 0 && canRemoveStudents()) {
      setStudFromGroupModelOpen(true);
    }
  };

  const openLockGroupModel = async () => {
    if (selectedStudents.length > 0 && canLockStudents()) {
      setLockGroupModelOpen(true);
    }
  };

  const renderNoGroupSelected = () => (
    <S.NoSelectedGroupContainer>
      <Typography variant="body2">
        Select a group from the list <br />
        on the left to edit it
      </Typography>
      <S.Caption variant="caption">or</S.Caption>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpenModal()}
      >
        add a new group
      </Button>
    </S.NoSelectedGroupContainer>
  );

  const getPageContent = () => {
    if (selectedGroup) {
      return (
        <EditGroupPage
          group={selectedGroup}
          refreshGroups={updateGroupList}
          groupTeachers={groupTeachers}
          schoolTeachers={schoolTeachers}
          isLoadingData={isLoadingData && isLoadingStudents}
          schoolStudents={schoolStudents}
          groups={groups}
          allGroups={allGroups}
          studentSearch={studentSearch}
          setStudentSearch={setStudentSearch}
          studentGroupSearch={studentGroupSearch}
          setStudentGroupSearch={setStudentGroupSearch}
          otherSearch={otherSearch}
          setOtherSearch={setOtherSearch}
          studentYearSearch={studentYearSearch}
          setStudentYearSearch={setStudentYearSearch}
          selectedStudents={selectedStudents}
          setSelectedStudents={setSelectedStudents}
          openAddUserModel={() => openAddUserModel()}
          openRemoveUserModel={() => openRemoveUserModel()}
          openLockGroupModel={() => openLockGroupModel()}
        />
      );
    }
    return renderNoGroupSelected();
  };

  return (
    <S.PageContainer>
      <S.GroupListContainer>
        <GroupsNavigation
          groups={groups}
          page="manage"
          selectedGroup={selectedGroup}
          setSelectedGroup={selectGroup}
          isLoading={groupsLoading}
          openModal={handleOpenModal}
        />
      </S.GroupListContainer>
      <S.EditContainer>{getPageContent()}</S.EditContainer>
      <CreateGroupModel
        isOpen={modelOpen}
        handleCloseModal={() => {
          setModelOpen(false);
          setNewGroupName("");
          setNewGroupAdded(false);
        }}
        newGroupName={newGroupName}
        setNewGroupName={setNewGroupName}
        newGroupAdded={newGroupAdded}
        addNewGroup={addGroup}
        newGroupErrorMsg={newGroupErrorMsg}
      />
      <AddStudentToGroupModel
        isOpen={studToGroupModelOpen}
        handleCloseModal={() => {
          setStudToGroupModelOpen(false);
          setAddStudentError(false);
          setAddStudentSubmitted(false);
        }}
        groupName={selectedGroup?.name ?? ""}
        addToGroup={() => addStudents()}
        error={addStudentError}
        studentNames={selectedStudents}
        submitted={addStudentSubmitted}
        schoolStudents={schoolStudents}
      />
      <RemoveStudentFromGroupModel
        isOpen={studFromGroupModelOpen}
        handleCloseModal={() => {
          setStudFromGroupModelOpen(false);
          setRemoveStudentError(false);
          setRemoveStudentSubmitted(false);
        }}
        group={selectedGroup}
        removeFromGroup={() => removeStudents()}
        error={removeStudentError}
        studentNames={selectedStudents}
        submitted={removeStudentSubmitted}
        schoolStudents={schoolStudents}
      />
      <LockStudentGroupModel
        isOpen={lockGroupModelOpen}
        handleCloseModal={() => {
          setLockGroupModelOpen(false);
          setLockGroupError(false);
          setLockGroupSubmitted(false);
        }}
        group={selectedGroup}
        lockGroup={() => lockGroup()}
        error={lockGroupError}
        studentNames={selectedStudents}
        submitted={lockGroupSubmitted}
        schoolStudents={schoolStudents}
      />
    </S.PageContainer>
  );
};
