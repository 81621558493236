import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import React from "react";

import { hasAccess } from "lib/student";

import { SL } from "./styles";
import { StudentListProps } from "./types";

export const StudentList = ({
  type = "students",
  ...props
}: StudentListProps) => (
  <SL>
    {props.loading ? (
      <SL.Info>
        <CircularProgress />
        <Typography variant="body2" className="mt-3">
          Loading...
        </Typography>
      </SL.Info>
    ) : (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Access</TableCell>
            <TableCell align="center">Name</TableCell>
            <TableCell align="center">ID</TableCell>
            <TableCell align="center">School</TableCell>
            <TableCell align="center">Email</TableCell>
            <TableCell align="center">Last Login</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!props.data.length ? (
            <TableRow>
              <TableCell colSpan={6}>
                <SL.Info>No data found</SL.Info>
              </TableCell>
            </TableRow>
          ) : (
            <>
              {props.data.map((student, index) => (
                <TableRow key={`${student.name}-${index}`}>
                  <TableCell align="center">
                    {hasAccess(student, "meta-mirror") ? (
                      <SL.Checked className="uis uis-check-circle" />
                    ) : (
                      <SL.Times className="uis uis-times-circle" />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <SL.Link to={`/admin/${type}/${student._id}`}>
                      {student.name}
                    </SL.Link>
                  </TableCell>
                  <TableCell align="center">{student._id.toString()}</TableCell>
                  <TableCell align="center">
                    {student?.school_id?.name ?? student?.mm_school_id?.name}
                  </TableCell>
                  <TableCell align="center">
                    {student?.user?.id?.email}
                  </TableCell>
                  <TableCell align="center">
                    {student.lastLogin === ""
                      ? ""
                      : moment(student.lastLogin).format("DD/MM/YYYY")}
                  </TableCell>
                </TableRow>
              ))}
            </>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={props.total}
              rowsPerPage={props.limit}
              page={props.page}
              onPageChange={(_, p) => props.setPage(p)}
              onRowsPerPageChange={({ target: { value } }) => {
                props.setLimit(Number(value));
                props.setPage(0);
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    )}
  </SL>
);
