import React from "react";

import { GradeIcons } from "components/common/feedbackModal/types";

import { GB } from "./styles";
import { GradeButtonProps } from "./types";

export const GradeButton = ({ text, ...rest }: GradeButtonProps) => (
  <GB {...rest}>
    <GB.Icon grade={rest.grade} className={GradeIcons[rest.grade!]} />
    <GB.Text variant="body1" grade={rest.grade} selected={rest.selected}>
      {text}
    </GB.Text>
  </GB>
);
