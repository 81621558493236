import { CircularProgress, Grid } from "@material-ui/core";
import React from "react";

import { useDetailedWheel } from "./context";
import { Evidence } from "./evidence";
import { Header } from "./header";
import { Overview } from "./overview";
import { DWP } from "./styles";
import { FeedbackModal } from "components/common/feedbackModal";
import { IArea } from "typings/meta-mirror";

export const Content = () => {
  const {
    areaId,
    error,
    evidence,
    evidenceId,
    handleRefreshData,
    loading,
    mirrorType,
    setAreaId,
    setEvidenceId,
    wheelData,
  } = useDetailedWheel();

  const area = React.useMemo(
    (): IArea | undefined => wheelData?.find(({ _id }) => _id === areaId),
    [areaId, wheelData]
  );

  const curEvidenceObj = React.useMemo(
    () => evidence?.find((e) => e._id === evidenceId),
    [evidence, evidenceId]
  );
  const handleCloseModal = () => {
    setAreaId(undefined);
    setEvidenceId(undefined);
    handleRefreshData?.();
  };

  return (
    <DWP>
      <Grid
        alignContent="center"
        alignItems="center"
        className={!!error || loading ? "flex-grow-1" : ""}
        container
        direction="column"
        justifyContent="center"
      >
        {loading ? (
          <Grid item>
            <CircularProgress />
          </Grid>
        ) : error ? (
          <Grid item>
            <DWP.Error variant="body2">{error}</DWP.Error>
          </Grid>
        ) : (
          <>
            <Header />
            <DWP.Divider />
            <Grid container direction="row">
              <Overview />
              <Evidence />
            </Grid>
          </>
        )}
        {!!evidenceId && (
          <FeedbackModal
            areaLevels={area?.levels ?? []}
            areaName={area?.areaName ?? ""}
            evidenceIds={[evidenceId]}
            // @ts-ignore
            evidenceObject={curEvidenceObj}
            handleCloseModal={handleCloseModal}
            isFromProfile
            open
            // @ts-ignore
            wheelLevelType={mirrorType}
          />
        )}
      </Grid>
    </DWP>
  );
};
