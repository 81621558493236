import { Button, IconButton, Typography } from "@material-ui/core";
import styled from "styled-components";

import palette from "MuiTheme/palette";

export const FilterHeader = Object.assign(
  styled.div({
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 15,
    padding: "0 15px",
    width: 310,
  }),
  {
    Title: styled(Typography)({
      fontSize: 16,
      fontWeight: "bold",
      marginLeft: 3,
    }),
  }
);

export const FilterIcon = styled(IconButton)({
  backgroundColor: "transparent !important",
  border: `solid 2px ${palette.primary} !important`,
  borderRadius: "7px !important",
  color: `${palette.primary} !important`,
  fontSize: "16px !important",
  marginRight: "10px !important",
  maxHeight: "30px !important",
  maxWidth: "30px !important",
  minHeight: "30px !important",
  minWidth: "30px !important",
  padding: "0 !important",
  position: "relative",
  "::hover": {
    borderColor: `${palette.primaryDark} !important`,
    color: `${palette.primaryDark} !important`,
  },
});

export const FilterSelect = styled.div({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  height: 150,
  justifyContent: "space-between",
  padding: 15,
  textAlign: "left",
  width: 310,
});

export const Indicator = styled.div({
  backgroundColor: palette.secondary,
  borderRadius: "100%",
  height: 10,
  position: "absolute",
  right: -4,
  top: -4,
  width: 10,
});

export const List = Object.assign(
  styled.div({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    minHeight: 350,
    paddingTop: 15,
    width: "100%",
  }),
  {
    Scroller: styled.div({
      maxHeight: "calc(100vh - 250px)",
      minHeight: "clamp(350px, calc(100vh - 275px), 100vh)",
      overflowX: "hidden",
      overflowY: "auto",
      paddingRight: "5px",
      scrollBehavior: "smooth",
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar": {
        width: 7,
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: 7,
        backgroundColor: palette.font.caption,
      },
      "@media (max-width: 1199px)": {
        maxHeight: "unset",
      },
    }),
  }
);

export const ListHeader = Object.assign(
  styled.div({
    alignItems: "flex-end",
    borderBottom: "solid 2px #e1e8f0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 15,
    paddingBottom: 10,
    width: "100%",
  }),
  {
    Button: styled(Button)({
      fontSize: "16px !important",
      height: "30px !important",
      padding: "0 25px !important",
      "@media (max-width: 475px)": {
        padding: "0 10px !important",
      },
    }),

    Title: styled(Typography)({
      fontSize: "20px !important",
      fontWeight: "700 !important",
      lineHeight: "1 !important",
      margin: "0 !important",
    }),
  }
);
