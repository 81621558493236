import React from "react";

import palette from "MuiTheme/palette";

import { useEvidenceList } from "../context";
import { ActionButton } from "./actionButton";
import { useEvidence } from "./context";
import { ItemActions } from "./styles";
import { useRouteMatch } from "react-router-dom";

const view = {
  comment: { icon: "uil-eye", text: "View" },
  link: { icon: "uil-external-link-alt", text: "Open link" },
};

export const Actions = () => {
  const {
    areaId,
    id,
    loading,
    messages,
    name,
    type,
    viewEvidenceItem,
    viewed,
  } = useEvidence();
  const { handleReviewEvidence, openDeleteModal, isTeacher } =
    useEvidenceList();

  const { params } = useRouteMatch<any>();

  const ViewButton = () => {
    const button = (view as any)[type];
    return (
      <ActionButton
        onClick={viewEvidenceItem}
        icon={button?.icon ?? "uil-import"}
        loading={!button ? loading : undefined}
      >
        {button?.text ?? "Download"}
      </ActionButton>
    );
  };

  return (
    <ItemActions>
      <ActionButton
        onClick={() => handleReviewEvidence?.(id, areaId)}
        icon="uil-comment-alt-check"
        notification={!viewed}
      >
        {params.token ? "View" : isTeacher ? "Review" : "Feedback"}
      </ActionButton>
      {!params.token && <ViewButton />}
      {!isTeacher && !params.token && (
        <ActionButton
          onClick={() => openDeleteModal(name, id)}
          icon="uil-trash-alt"
          hoverColor={palette.error}
          backgroundHoverColor={`${palette.error}44`}
          disabled={messages?.length}
          disabledTooltipText={
            "You can't delete evidence that has been reviewed."
          }
        >
          Delete
        </ActionButton>
      )}
    </ItemActions>
  );
};
