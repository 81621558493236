import { Button, Typography } from "@material-ui/core";
import styled from "styled-components";

import palette from "MuiTheme/palette";

export const P = Object.assign(
  styled.div<{ border?: boolean }>(({ border }) => ({
    alignContent: "center",
    alignItems: "center",
    border: border ? `solid 2px ${palette.font.caption}88` : "none",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    justifyItems: "center",
    width: "100%",
  })),
  {
    Comment: styled(Typography)({
      fontStyle: "italic !important",
      margin: "10px !important",
      maxWidth: "100%",
      overflowX: "scroll",
      padding: "0 25px !important",
      textAlign: "center",
    }),

    Container: styled.div({
      alignItems: "center",
      borderRadius: "10px",
      display: "flex",
      height: "100%",
      overflow: "hidden",
      width: "100%",
    }),

    Description: styled(Typography)({
      margin: "10px 0 !important",
      maxWidth: "100%",
      position: "relative",
      textAlign: "center",
    }),

    Embed: styled.embed({
      border: "none",
      borderRadius: "10px",
      height: "100%",
      width: "100%",
    }),

    Image: styled.img({
      borderRadius: "5px",
      boxShadow:
        "0 8px 22px -6px rgba(24, 39, 75, 0.12), 0 14px 64px -4px rgba(24, 39, 75, 0.12)",
      height: "100%",
      width: "100%",
    }),

    Link: styled(Typography)({
      border: "1px solid #c8c7c7",
      borderRadius: "3px",
      fontstyle: "italic !important",
      margin: "10px 10px !important",
      maxWidth: "100%",
      overflowX: "scroll",
      padding: "5px 25px !important",
      textAlign: "left",
    }),

    LinkButton: styled(Button)({ margin: "15px 0 !important" }),

    LinkIcon: styled.i({
      color: palette.secondary,
      fontSize: "10vh",
      lineHeight: "10vh",
    }),

    Section: styled.div({
      backgroundColor: "#F4F4F7",
      borderRadius: "10px",
      height: "100%",
      padding: "20px",
      width: "100%",
    }),
  }
);
