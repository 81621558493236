/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import { useRouteMatch } from "react-router-dom";

import { getAllMirrorsForStudent } from "lib/mirror";
import { getProfileFromToken } from "lib/token";

import { WheelsPageContext, WheelsPageProviderProps } from "./types";
import {
  deleteAchievement,
  deleteOtherAchievement,
  patchAchievement,
  putAchievement,
  putContact,
  putOtherAchievement,
} from "lib/profile";
import { ContactType } from "./profile/contact/types";

const Context = React.createContext<WheelsPageContext>({
  addAchievement: () => {},
  addContact: () => Promise.resolve(),
  addOther: () => Promise.resolve(),
  error: "",
  handlePageChange: () => {},
  isUser: false,
  loading: true,
  page: 0,
  pageSize: 10,
  removeAchievement: () => {},
  removeOtherAchievement: () => {},
  updateAchievement: () => {},
  wheels: [],
});

export const WheelsPageProvider = ({
  children,
  isToken,
  isUser,
  userId,
}: WheelsPageProviderProps) => {
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [profile, setProfile] = React.useState<any>();
  const [wheels, setWheels] = React.useState([]);

  const mockUser = React.useMemo(
    () =>
      ["65dc82fdf8d02d4410f242a0", "6203a149e06849007804a615"].includes(userId),
    [userId]
  );

  const { params } = useRouteMatch<any>();

  const getProfile = () =>
    getProfileFromToken(params.token)
      .then(({ data }: any) => setProfile(data))
      .catch(() => setError("Could not find profile"))
      .finally(() => setLoading(false));

  const getWheels = () =>
    getAllMirrorsForStudent(userId)
      .then(({ data }) => {
        setProfile(data?.profile);
        !isToken && setWheels(data?.wheels);
      })
      .catch(() => setError("Could not find wheels"))
      .finally(() => setLoading(false));

  const addAchievement = (subject: string, grade: string) =>
    putAchievement({ studentId: userId, grade, subject }).then(({ data }) =>
      setProfile(data)
    );

  const addContact = (contact: ContactType) =>
    putContact({ studentId: userId, ...contact }).then(({ data }) =>
      setProfile(data)
    );

  const addOther = async (achievement: string, image: string, name: string) =>
    putOtherAchievement({ achievement, image, name, studentId: userId }).then(
      ({ data }) => setProfile(data)
    );

  const removeAchievement = (subject: string, grade: string) =>
    deleteAchievement({ studentId: userId, grade, subject }).then(({ data }) =>
      setProfile(data)
    );

  const removeOtherAchievement = (image: string) =>
    deleteOtherAchievement({ studentId: userId, image }).then(({ data }) =>
      setProfile(data)
    );

  const updateAchievement = (
    grade: string,
    previousGrade: string,
    previousSubject: string,
    subject: string
  ) =>
    patchAchievement({
      grade,
      previousGrade,
      previousSubject,
      studentId: userId,
      subject,
    }).then(({ data }) => setProfile(data));

  React.useEffect(() => {
    isToken ? getProfile() : getWheels();
  }, []);

  return (
    <Context.Provider
      value={{
        addAchievement,
        addContact,
        addOther,
        error,
        handlePageChange: setPage,
        isToken,
        isUser: !!isUser,
        loading,
        mockUser,
        page,
        pageSize,
        profile,
        removeAchievement,
        removeOtherAchievement,
        updateAchievement,
        wheels,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useWheelsPage = () => React.useContext(Context);
