import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Select from "components/common/Select/Select";
import TextInput from "components/common/TextInput/TextInput";
import { getBlankWheel, updateMirror, updateMirrorAreas } from "lib/mirror";
import palette from "palette";
import { IArea, IGroup, TWheelData } from "typings/meta-mirror";

import AreaSettings from "./AreaSettings";
const StyledViewContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

const StyledLoaderContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  position: absolute;
`;

const StyledErrorMessage = styled(Typography)`
  color: ${palette.error} !important;
  height: 30px;
  margin: 0 0 20px !important;
`;

const StyledPaper = styled(Paper)`
  width: 100%;
  height: 100%;
  padding: 20px;
`;

const StyledDivider = styled.div`
  width: 100%;
  height: 3px;
  border-radius: 15px;
  background-color: ${palette.disabled};
  margin-top: 15px;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledInfoMessage = styled(Typography)<{ textColor: string }>`
  color: ${(props) => props.textColor} !important;
  padding-right: 15px !important;
  font-weight: bold !important;
  text-align: right !important;
`;

const StyledAreaSettingsGridContainer = styled(Grid)`
  height: 73% !important;
`;

const StyledEditWrapper = styled.div`
  width: 100%;
  height: calc(100% - 140px);
  overflow-x: hidden;
  overflow-y: auto;
`;

interface IProps {
  wheelId: string;
  selectWheel(wheelId: string): void;
  refreshWheels(): Promise<void>;
  groups?: IGroup[];
}

const EditWheel: React.FC<IProps> = ({
  wheelId,
  selectWheel,
  refreshWheels,
  groups = [],
}: IProps) => {
  const [localWheelId, setLocalWheelId] = useState("");
  const [groupIds, setGroupIds] = useState<string[]>([]);
  const [originalGroupIds, setOriginalGroupIds] = useState<string[]>([]);
  const [originalName, setOriginalName] = useState("");
  const [originalWheelData, setOriginalWheelData] = useState<TWheelData>();
  const [mirrorLevelType, setMirrorLevelType] = useState("");
  const [wheelData, setWheelData] = useState<TWheelData>();
  const [wheelName, setWheelName] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const getWheelData = async (): Promise<void> => {
    setLoading(true);
    setWheelData(undefined);
    try {
      const response = await getBlankWheel(wheelId);
      setLoading(false);
      const originalWheelData = _.cloneDeep(response.data[0].wheelData);
      const { name, wheelData } = response.data[0];
      setMirrorLevelType(response.data[0]?.levelType ?? "sequential");
      setOriginalWheelData(originalWheelData);
      setWheelData(wheelData);
      setOriginalName(name);
      setOriginalGroupIds(response.data[0].groupIds);
      setGroupIds(response.data[0].groupIds);
      setWheelName(name);
      setTimeout(() => setLoading(false), 500);
    } catch {
      setLoadingError(
        "Something went wrong whilst trying to grab the data for this wheel."
      );
      setLoading(false);
    }
  };

  const handleWheelChange = () => {
    setSuccess("");
    setError("");
    getWheelData();
    setLocalWheelId(wheelId);
  };

  useEffect(() => {
    if (wheelId && wheelId !== localWheelId) {
      if (settingsHaveChanged()) {
        if (
          !confirm(
            "You have unsaved changes, are you sure you want to continue?"
          )
        ) {
          selectWheel(localWheelId);
        } else {
          handleWheelChange();
        }
      } else {
        handleWheelChange();
      }
    }
  }, [wheelId]);

  const handleUpdateAreaSettings = (
    area: Partial<IArea>,
    areaIndex: number
  ): void => {
    const areaSettings: any = wheelData;

    let areaSettingToUpdate = { ...areaSettings[areaIndex] };
    areaSettingToUpdate = { ...areaSettingToUpdate, ...area };
    areaSettings[areaIndex] = areaSettingToUpdate;

    setWheelData(areaSettings);
  };

  const updateWheelDisabled = (): boolean => {
    if (updateLoading) return true;

    if (!settingsHaveChanged()) {
      setError(
        "Please make a change to a wheel setting before trying to update."
      );
      return true;
    }

    if (!wheelName) {
      setError("Please input a name for this wheel.");
      return true;
    }
    if (wheelData) {
      for (const [index, area] of wheelData.entries()) {
        if (area.areaName === "") {
          setError(`Please input a name for Area ${index + 1}.`);
          return true;
        }
        for (const level of area.levels) {
          if (!level.description || level.description === "<p></p>") {
            setError(
              `Please input a rubric for Area ${index + 1}: Level ${
                level.level
              }.`
            );
            return true;
          }
        }
      }
    }

    setError("");
    return false;
  };

  const areaSettingsHaveChanged = (): boolean =>
    !_.isEqual(originalWheelData, wheelData);

  const groupsHaveChanged = (): boolean =>
    !_.isEqual(originalGroupIds, groupIds);

  const nameHasChanged = (): boolean => wheelName !== originalName;

  const settingsHaveChanged = (): boolean =>
    areaSettingsHaveChanged() || groupsHaveChanged() || nameHasChanged();

  const handleUpdateWheelClick = async (): Promise<void> => {
    if (updateWheelDisabled()) return;
    setUpdateLoading(true);
    if (groupsHaveChanged() || nameHasChanged()) {
      try {
        await updateMirror({
          id: wheelId,
          disabled: false,
          name: wheelName,
          groupIds,
          levelType: mirrorLevelType,
        });
      } catch ({ message }: any) {
        setError(message);
        setUpdateLoading(false);
        return;
      }
    }
    if (areaSettingsHaveChanged() && wheelData) {
      try {
        await updateMirrorAreas(wheelData);
      } catch ({ message }: any) {
        setError(message);
        setUpdateLoading(false);
        return;
      }
    }
    setLoading(true);
    await refreshWheels();
    await getWheelData();
    setUpdateLoading(false);
    setSuccess("Your changes have been saved successfully.");
  };

  const renderErrorMessage = (): JSX.Element => (
    <StyledErrorMessage variant="body2">{loadingError}</StyledErrorMessage>
  );

  const renderLoading = (): JSX.Element => (
    <StyledLoaderContainer>
      <CircularProgress size={48} />
    </StyledLoaderContainer>
  );

  const renderEditWheelUI = (): JSX.Element => (
    <StyledPaper>
      <StyledHeader>
        <Typography variant="h6">{originalName}</Typography>
      </StyledHeader>
      <StyledDivider />
      <StyledEditWrapper>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="stretch"
          className="mt-1 mt-lg-3"
        >
          <Grid item xs={12}>
            <TextInput
              value={wheelName}
              label="Wheel Name"
              onChange={(event) => setWheelName(event.target.value)}
              inputProps={{
                maxLength: 50,
              }}
              fullWidth
            />
          </Grid>
          <Grid className="mt-2" item xs={12}>
            <Select
              label="Groups"
              multiple
              onChange={({ target: { value } }) =>
                setGroupIds(value as string[])
              }
              options={groups.map(({ _id, name }) => ({
                label: name,
                value: _id,
              }))}
              value={groupIds}
            />
          </Grid>
        </Grid>

        <StyledDivider />

        <StyledAreaSettingsGridContainer
          container
          direction="column"
          justifyContent="space-evenly"
          alignItems="stretch"
          className="mt-3 d-flex flex-column flex-grow-1 justify-content-start h-100"
        >
          <Grid item xs={12} className="d-flex flex-grow-1">
            <AreaSettings
              areaSettings={wheelData!}
              flushRTEs={false}
              updateAreaSettings={handleUpdateAreaSettings}
              mirrorLevelType={mirrorLevelType}
            />
          </Grid>
        </StyledAreaSettingsGridContainer>
      </StyledEditWrapper>
      <StyledDivider />
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        className="mt-3"
      >
        <Grid item>
          <StyledInfoMessage
            variant="body1"
            textColor={error ? palette.error : palette.complete}
          >
            {error ? error : success}
          </StyledInfoMessage>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => selectWheel("")}
            className="mr-2"
          >
            cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleUpdateWheelClick()}
          >
            update wheel
          </Button>
        </Grid>
      </Grid>
    </StyledPaper>
  );

  const renderContent = (): JSX.Element => {
    if (loadingError) {
      return renderErrorMessage();
    }
    if (loading) {
      return renderLoading();
    }
    return renderEditWheelUI();
  };

  return <StyledViewContainer>{renderContent()}</StyledViewContainer>;
};

export default EditWheel;
