import { Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { TWheelData } from "typings/meta-mirror";
import ProgressChart, {
  ISeriesData,
} from "components/common/ProgressChart/ProgressChart";
import Wheel from "components/common/Wheel/Wheel";
import palette from "palette";
import ReportHeaderSection from "./ReportHeaderSection";

const StyledContainer = styled.div`
  background: transparent;
  text-align: center;
  height: 1650px;
  width: 1250px;
  padding: 5%;
  transform: scale(0.652);
  position: absolute;
  top: -27%;
  left: -27%;
  @media print {
    transform: none;
    top: unset;
    left: unset;
    position: relative;
  }
`;

const StyledHeaderSection = styled.div`
  margin: 0 0 30px;
`;

const StyledLineSpacing = styled.hr`
  margin-bottom: 30px;
  border-top: 2px solid ${palette.disabled};
`;

const StyledWheelsSection = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledBottomSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledChartSection = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  text-align: left;
`;

const StyledChartContainer = styled.div`
  width: 100%;
  height: 400px;
`;

// const StyledCommentSection = styled.div`
//   width: 33%;
//   flex-shrink: 0;
//   text-align: center;
//   display: flex;
//   flex-direction: column;
//   height: 100%;
//   justify-content: center;
//   padding: 0 0 0 20px;
//   border-left: 2px solid ${palette.disabled};
// `;

// const StyledCommentLine = styled.hr`
//   border-top: 2px dashed ${palette.disabled};
//   margin-bottom: 20px;
// `;

interface IProps {
  wheelName: string;
  studentName: string;
  reportComment: string;
  groupName: string;
  actualWheelResults: TWheelData;
  groupWheelResults: TWheelData;
  progressChartResults: ISeriesData[];
  groupProgressChartResult: ISeriesData[];
}

const GroupReport: React.FC<IProps> = ({
  wheelName,
  studentName,
  reportComment,
  groupName,
  actualWheelResults,
  groupWheelResults,
  progressChartResults,
  groupProgressChartResult,
}: IProps) => {
  return (
    <StyledContainer>
      <StyledHeaderSection>
        <ReportHeaderSection
          wheelName={wheelName}
          subtitle={`${studentName} x ${groupName}`}
          comment={reportComment}
        />
      </StyledHeaderSection>
      <StyledWheelsSection>
        {!!actualWheelResults && !!actualWheelResults.length && (
          <div>
            <Typography variant="body2">{studentName}</Typography>
            <Wheel
              data={actualWheelResults}
              backgroundColor={palette.surface}
              width={550}
              height={350}
              labels
            />
          </div>
        )}
        {!!groupWheelResults && !!groupWheelResults.length && (
          <div>
            <Typography variant="body2">{groupName}</Typography>
            <Wheel
              data={groupWheelResults}
              backgroundColor={palette.surface}
              width={550}
              height={350}
              labels
            />
          </div>
        )}
      </StyledWheelsSection>
      <StyledLineSpacing />
      <StyledBottomSection>
        <StyledChartSection>
          <div>
            <Typography variant="body2">Student Progress</Typography>
            <StyledChartContainer>
              <ProgressChart chartData={progressChartResults} reportChart />
            </StyledChartContainer>
          </div>
          <div>
            <Typography variant="body2">Group Progress</Typography>
            <StyledChartContainer>
              <ProgressChart chartData={groupProgressChartResult} reportChart />
            </StyledChartContainer>
          </div>
        </StyledChartSection>
        {/* <StyledCommentSection>
          <Typography
            variant="body2"
            align="center"
          >
            Comments
          </Typography>
          <div>
            {Array(20).fill('').map((value: string, index: number) => (
              <StyledCommentLine key={`comment-line-${index}`} />
            ))}
          </div>
        </StyledCommentSection> */}
      </StyledBottomSection>
    </StyledContainer>
  );
};

export default GroupReport;
