import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Select, { TOption } from "components/common/Select/Select";
import { WheelsPageContext } from "../../states/WheelsPageContext";
import Wheel from "components/common/Wheel/Wheel";
import { TWheelData } from "typings/meta-mirror";
import palette from "palette";
import { CircularProgress, Tooltip } from "@material-ui/core";
import { getBlankWheel } from "lib/mirror";
import MmWheelAverageService from "lib/wheelAverage";
import LockedGroupFilter from "components/teacher/LockedGroupCheckbox";

const WHEEL_INITIAL_SIZE = 500;

const StyledViewContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px 0 0;
`;

const StyledWheelSection = styled.div`
  width: 100%;
  min-height: 70vh;
  max-width: 900px;
  position: relative;
`;

const StyledLoaderContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  position: absolute;
`;

const WheelView: React.FC<{ wheelId: string; levelType: string }> = ({
  wheelId,
  levelType,
}) => {
  const wheelBoxRef = useRef<HTMLDivElement>(null);
  const {
    groups,
    selectedGroup,
    handleSelectGroup,
    wheelResults,
    isLoading,
    handleGetSelectedWheelData,
  } = useContext(WheelsPageContext);
  const [wheelSize, setWheelSize] = useState(WHEEL_INITIAL_SIZE);
  const [wheelHeight, setWheelHeight] = useState(WHEEL_INITIAL_SIZE);
  const [selectOptions, setSelectOptions] = useState<TOption[]>();
  const [wheelData, setWheelData] = useState<TWheelData>();
  const [loading, setLoading] = useState(false);
  // const [levelType, setLevelType] = useState("");
  const [includeLocked, setIncludeLocked] = useState(false);
  const preparedGroupOptions = [
    { value: "all-students", label: "All Students" },
  ];

  const getWheelData = async (): Promise<void> => {
    let studentsResults = wheelResults.studentsResults;
    // setLevelType(wheelResults?.levelType ?? "");
    if (selectedGroup !== "all-students") {
      studentsResults = studentsResults.filter((result) => {
        const locked = result.lockedGroupIds?.includes(selectedGroup);
        const inGroup = result.groupIds.includes(selectedGroup);
        return (includeLocked && locked && inGroup) || (inGroup && !locked);
      });
    }

    if (!studentsResults.length) {
      setLoading(true);
      const response = await getBlankWheel(wheelId);
      setWheelData(response.data[0].wheelData);
      setLoading(false);
      return;
    }
    const response =
      MmWheelAverageService.getAverageWheelScoreBasedOnStudentsResults(
        studentsResults,
        levelType
      );
    setWheelData(response);
  };

  const getSelectOptions = (): void => {
    const filteredGroups = groups?.filter((group) =>
      wheelResults.groupIds.includes(group._id)
    );
    const options = filteredGroups.map((group) => ({
      value: group._id,
      label: group.name,
    }));

    if (options.length === 1) {
      setSelectOptions(options);
      if (selectedGroup !== options[0].value) {
        handleSelectGroup(options[0].value);
      }
      return;
    }

    options.unshift(...preparedGroupOptions);
    setSelectOptions(options);
  };

  useEffect(() => {
    const handleResize = () => {
      if (!!wheelBoxRef) {
        setWheelSize(wheelBoxRef?.current?.offsetWidth || WHEEL_INITIAL_SIZE);
        setWheelHeight(
          wheelBoxRef?.current?.offsetHeight * 0.5 || WHEEL_INITIAL_SIZE
        );
      }
    };
    if (wheelSize === WHEEL_INITIAL_SIZE) {
      handleResize();
    }
    window.addEventListener("resize", handleResize);

    if (!!loading || !!isLoading) {
      return;
    }

    if (!wheelResults) {
      handleGetSelectedWheelData(wheelId);
      return;
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [wheelResults, selectOptions, wheelData, loading, isLoading]);

  useEffect(() => {
    getWheelData();
    getSelectOptions();
  }, [wheelId, wheelResults, selectedGroup, includeLocked]);

  useEffect(() => {
    getSelectOptions();
  }, [groups]);

  return (
    <StyledViewContainer>
      <StyledWheelSection ref={wheelBoxRef}>
        {isLoading || !selectOptions ? (
          <StyledLoaderContainer>
            <CircularProgress size={48} />
          </StyledLoaderContainer>
        ) : (
          <>
            {selectOptions.length <= 1 ? (
              <>
                <Tooltip
                  title="Only one group is available for this wheel."
                  placement="top"
                >
                  <div>
                    <Select
                      value={selectedGroup}
                      label="Student Group"
                      options={selectOptions}
                      disabled
                    />
                  </div>
                </Tooltip>
                <LockedGroupFilter
                  disabled={false}
                  onUpdate={(value: boolean) => setIncludeLocked(value)}
                  selected={includeLocked}
                />
              </>
            ) : (
              <Select
                onChange={(event) =>
                  handleSelectGroup(event.target.value as string)
                }
                value={selectedGroup}
                label="Student Group"
                options={selectOptions}
                disabled={selectOptions.length <= 1}
              />
            )}
            {!!wheelData && (
              <>
                {/*<p>sadasd</p>*/}
                {/*{console.log(wheelData)}*/}
                <Wheel
                  data={wheelData}
                  backgroundColor={palette.surface}
                  width={wheelSize}
                  height={wheelHeight}
                  labels
                  interactive
                  descriptions="bottom"
                  mirrorLevelType={levelType}
                />
              </>
            )}
          </>
        )}
      </StyledWheelSection>
    </StyledViewContainer>
  );
};

export default WheelView;
