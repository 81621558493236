import { axiosInstance } from "helpers/axios-instance";
import { IQuickEditUpdate, TWheelData } from "../typings/meta-mirror";

type UpdateParams = {
  id: string;
  disabled: boolean;
  name?: string;
  groupIds?: string[];
  levelType?: string;
};

export const updateMirror = async ({
  levelType = "",
  ...rest
}: UpdateParams) => {
  return axiosInstance.put("/mirrors", {
    levelType,
    ...rest,
  });
};

export const getMirrorList = async (groupIds: string[]) => {
  return axiosInstance.post("/mirrors/list", {
    groupIds,
  });
};

export const getMirrorResultList = async (
  mirrorId: string,
  teacherId: string
) => {
  return axiosInstance.post("/mirrors/result/list", {
    mirrorId,
    teacherId,
  });
};

export const getBlankWheel = async (mirrorId: string) => {
  return axiosInstance.get(`/mirrors/blank/${mirrorId}`);
};

export const getSingleWheelDetailsForStudentId = async (
  mirrorId: string,
  studentId?: string,
  teacherId?: string,
  date?: string,
  evidenceAreaId?: string,
  evidenceLevelId?: string
) => {
  let queries = ``;
  queries += studentId ? `&studentId=${studentId}` : ``;
  queries += teacherId ? `&teacherId=${teacherId}` : ``;
  queries += date ? `&date=${date}` : ``;
  queries += evidenceAreaId ? `&evidenceAreaId=${evidenceAreaId}` : ``;
  queries += evidenceLevelId ? `&evidenceLevelId=${evidenceLevelId}` : ``;
  return axiosInstance.get(`/mirrors/student?mirrorId=${mirrorId}${queries}`);
};

export const getWheelStudentProgressData = async (
  mirrorId: string,
  studentId?: string
) => {
  return axiosInstance.get(
    `/mirrors/progress?mirrorId=${mirrorId}&studentId=${studentId}`
  );
};

export const getWheelGroupProgressData = async (
  mirrorId: string,
  groupId?: string
) => {
  return axiosInstance.get(
    `/mirrors/progress?mirrorId=${mirrorId}&groupId=${groupId}`
  );
};

export const getWheelComparisonData = async (
  mirrorId: string,
  groupId: string,
  date: string
) => {
  return axiosInstance.get(
    `/mirrors/compare?mirrorId=${mirrorId}&date=${date}&groupId=${groupId}`
  );
};

export const getSingleWheelForStudentWithPersonalGrades = async (
  mirrorId: string,
  studentId: string
) => {
  return axiosInstance.get(
    `/mirrors/student/personal?mirrorId=${mirrorId}&studentId=${studentId}`
  );
};

export const getMirrorsUsed = async (teacherId: string) => {
  return axiosInstance.get(`/mirrors/mirrors-used?teacherId=${teacherId}`);
};

export const addMirror = async (
  teacherId: string,
  mirrorName: string,
  groupIds: string[],
  areaSettings: TWheelData,
  levelType: string
) => {
  return axiosInstance.post(`/mirrors`, {
    teacherId,
    mirrorName,
    groupIds,
    areaSettings,
    levelType,
  });
};

export const updateMirrorAreas = async (areaSettings: TWheelData) => {
  return axiosInstance.post(`/mirrors/update/area-settings`, {
    areaSettings,
  });
};

export const getAllMirrorsForStudent = async (studentId: string) => {
  return axiosInstance.get(`/mirrors/student/all?studentId=${studentId}`);
};

export const quickEditMirror = async (
  mirrorId: string,
  areaId: string,
  quickEditUpdates: IQuickEditUpdate[]
) => {
  return axiosInstance.put(`/mirrors/quick-edit`, {
    mirrorId,
    areaId,
    quickEditUpdates,
  });
};

type ValidateParams = {
  areaId?: string;
  evidenceId?: string;
  mirrorId: string;
  studentId: string;
};

export const validateEvidence = async (params: ValidateParams) =>
  axiosInstance.post(`mirrors/validate`, params);
