import { TypographyProps, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { ILevel } from "typings/meta-mirror";
import palette from "palette";
import { GRADE_COLORS } from "./constants";

export const StyledCurrentGrade = styled.span<{
  grade: ILevel["grade"];
  small?: boolean;
}>`
  background-color: ${(props) =>
    props.grade === null
      ? palette.surface
      : palette[GRADE_COLORS[props.grade]]};
  color: ${(props) =>
    props.grade === null ? palette.font.body : palette.font.contrast};
  border: ${(props) =>
    props.grade === null ? `dashed 2px ${palette.font.body}` : "none"};
  height: ${(props) => (props.small ? "20px" : "27.5px")};
  border-radius: ${(props) => (props.small ? "20px" : "27.5px")};
  padding: 0 10px 0 ${(props) => (props.small ? "20px" : "27.5px")};
  display: inline-flex;
  align-items: center;
  position: relative;
`;

interface IStyledCurrentGradeTextProps extends TypographyProps {
  grade: ILevel["grade"];
  small?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledCurrentGradeText = styled(
  ({ grade, small, ...props }: IStyledCurrentGradeTextProps) => {
    return <Typography {...props} />;
  }
)<{ grade: ILevel["grade"] }>`
  color: ${(props) =>
    props.grade === null
      ? palette.font.body
      : palette.font.contrast} !important;
  font-weight: 600 !important;
  font-size: ${(props) => (props.small ? "16px" : "18px")} !important;
  margin-left: ${(props) => (props.small ? "2px" : "5px")} !important;
`;

export const StyledCurrentGradeIcon = styled.i<{
  grade: ILevel["grade"];
  small?: boolean;
}>`
  position: absolute;
  left: ${(props) =>
    props.small ? (props.grade === null ? "-2px" : "0") : "-3px"};
  top: ${(props) =>
    props.small
      ? props.grade === null
        ? "-2px"
        : "0"
      : props.grade === null
      ? "-2px"
      : "0"};
  min-width: ${(props) => (props.small ? "20px" : "27.5px")};
  min-height: ${(props) => (props.small ? "20px" : "27.5px")};
  max-width: ${(props) => (props.small ? "20px" : "27.5px")};
  max-height: ${(props) => (props.small ? "20px" : "27.5px")};
  font-size: ${(props) => (props.small ? "17px" : "25px")};
  line-height: ${(props) => (props.small ? "21px" : "30px")};
  padding-left: ${(props) =>
    props.grade === null ? 0 : props.small ? "4px" : "4px"};
  background-color: ${(props) =>
    props.grade === null
      ? palette.surface
      : palette[GRADE_COLORS[props.grade]]};
  text-align: center;
  border-radius: ${(props) => (props.small ? "20px" : "27.5px")};
  border: ${(props) =>
    props.grade === null ? `dashed 2px ${palette.font.body}` : "none"};
`;
