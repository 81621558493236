import { Theme } from "@material-ui/core";
import Palette from "palette";

type ThemeInterface = Exclude<Theme["overrides"], undefined>;

const MuiMenuItem: ThemeInterface["MuiMenuItem"] = {
  root: {
    borderBottomColor: Palette.disabled,
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    "&$selected": {
      "&:hover": {
        backgroundColor: Palette.primaryLight,
      },
      backgroundColor: Palette.primaryLight,
    },
    "&:hover": {
      backgroundColor: Palette.primaryLight,
    },
    "&:last-child": {
      borderBottomStyle: "none",
    },
  },
};

export default MuiMenuItem;
