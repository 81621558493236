import React, { useContext, useEffect, useState } from "react";
import AnimatedModal from "components/common/AnimatedModal";
import { CircularProgress } from "@material-ui/core";
import ProgressChart, {
  ISeriesData,
} from "components/common/ProgressChart/ProgressChart";
import { TOption } from "components/common/Select/Select";
import { WheelsPageContext } from "../../states/WheelsPageContext";
import styled from "styled-components";
import {
  getWheelGroupProgressData,
  getWheelStudentProgressData,
} from "lib/mirror";
import MmProgressChartService from "lib/progressChart";

const StyledLoader = styled(CircularProgress)`
  position: absolute !important;
  left: calc(50% - 20px) !important;
  top: calc(50% - 20px) !important;
`;

interface IProps {
  open: boolean;
  wheelId: string;
  wheelName: string;
  handleClose(): void;
  studentId?: string;
  wheelLevelType?: string;
}

const preparedGroupOptions = [{ value: "all-students", label: "All Students" }];

const ProgressChartModal: React.FC<IProps> = ({
  open,
  wheelName,
  wheelId,
  handleClose,
  studentId,
  wheelLevelType = "sequential",
}: IProps) => {
  const { groups, wheelResults } = useContext(WheelsPageContext);

  const [selectedGroup, setSelectedGroup] = useState("");
  const [chartData, setChartData] = useState<ISeriesData[]>();
  const [selectOptions, setSelectOptions] = useState<TOption[]>([]);

  const getData = async (groupId: string): Promise<void> => {
    if (!!studentId) {
      const responseData = await getWheelStudentProgressData(
        wheelId,
        studentId
      );

      setChartData(
        MmProgressChartService.getProgressChartData(
          responseData.data,
          wheelLevelType
        )
      );
      return;
    }

    const responseData = await getWheelGroupProgressData(wheelId, groupId);
    setChartData(
      MmProgressChartService.getProgressChartData(
        responseData.data,
        wheelLevelType
      )
    );
  };

  const getSelectOptions = (): TOption[] => {
    if (wheelResults) {
      const filteredGroups = groups.filter((group) =>
        wheelResults.groupIds.includes(group._id)
      );
      const options = filteredGroups.map((group) => ({
        value: group._id,
        label: group.name,
      }));
      if (options.length === 1) {
        if (selectedGroup !== options[0].value) {
          setSelectedGroup(options[0].value);
        }
        return options;
      }
      options.unshift(...preparedGroupOptions);
      setSelectedGroup(options[0].value);
      return options;
    }
  };

  const handleCloseModal = (): void => {
    setChartData([]);
    handleClose();
  };

  const handleSelectGroup = (group: string): void => {
    setSelectedGroup(group);
  };

  useEffect(() => {
    if (open && (!!selectedGroup || !!studentId) && !!wheelLevelType) {
      getData(selectedGroup);
    }
    if (!studentId && open && !selectOptions.length) {
      setSelectOptions(getSelectOptions());
    }
    if (!open) {
      setSelectedGroup("");
      setSelectOptions([]);
    }
  }, [open, selectedGroup, wheelLevelType]);

  return (
    <AnimatedModal
      open={open}
      handleClose={handleCloseModal}
      onClose={handleCloseModal}
      title={`${wheelName} Progress`}
      width="80%"
      minWidth="850px"
      height="80%"
      minHeight="500px"
    >
      {!chartData || !chartData.length ? (
        <StyledLoader />
      ) : !!studentId ? (
        <ProgressChart chartData={chartData} />
      ) : (
        <ProgressChart
          chartData={chartData}
          handleSelectGroup={handleSelectGroup}
          selectedGroup={selectedGroup}
          selectGroups={selectOptions}
        />
      )}
    </AnimatedModal>
  );
};

export default ProgressChartModal;
