/* eslint-disable @typescript-eslint/indent */
import { Box } from "@material-ui/core";
import React from "react";

import { useWheelsPage } from "../../context";
import { Sum } from "../styles";
import { useOther } from "./context";
import { Item } from "./item";

export const List = () => {
  const { items } = useOther();
  const { isUser } = useWheelsPage();

  return (
    <Sum.Content>
      {items.length ? (
        <Box
          component="ul"
          sx={{
            margin: 0,
            padding: "0 20px",
            //  @ts-ignore
            listStyleType: "circle",
          }}
        >
          {items.map((item: any) => (
            <Item key={item.image} {...item} />
          ))}
        </Box>
      ) : isUser ? (
        "Add your other achievements here when you're ready."
      ) : (
        "This user has not added any other achievements yet."
      )}
    </Sum.Content>
  );
};
