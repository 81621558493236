import { axiosInstance } from "helpers/axios-instance";
import { Contact } from "pages/student/sections/wheelsPage/profile/types";

type DeleteAchievementParams = {
  studentId: string;
  subject: string;
  grade: string;
};
export const deleteAchievement = async (params: DeleteAchievementParams) =>
  axiosInstance.delete(`/students/achievement`, { data: params });

type DeleteOtherAchievementParams = {
  studentId: string;
  image: string;
};
export const deleteOtherAchievement = async (
  params: DeleteOtherAchievementParams
) => axiosInstance.delete("/students/achievement/other", { data: params });

type PatchAchievementParams = {
  studentId: string;
  grade: string;
  previousGrade: string;
  previousSubject: string;
  subject: string;
};
export const patchAchievement = async (params: PatchAchievementParams) =>
  axiosInstance.patch(`/students/achievement`, params);

type PutAchievementParams = {
  studentId: string;
  subject: string;
  grade: string;
};
export const putAchievement = async (params: PutAchievementParams) =>
  axiosInstance.put(`/students/achievement`, params);

type PatchProfileSummaryProps = { summary: string; id: string };
export const patchProfileSummary = async (params: PatchProfileSummaryProps) =>
  axiosInstance.patch(`/students/summary`, params);

type PutOtherAchievementParams = {
  achievement: string;
  image: string;
  name: string;
  studentId: string;
};

type PutContact = Contact & { studentId: string };
export const putContact = async (params: PutContact) =>
  axiosInstance.put(`/students/contact`, params);

export const putOtherAchievement = async (params: PutOtherAchievementParams) =>
  axiosInstance.put(`/students/achievement/other`, params);

type UploadProfilePictureProps = {
  file: string | ArrayBuffer;
  name: string;
  userId: string;
};
export const uploadProfilePicture = async (body: UploadProfilePictureProps) =>
  axiosInstance.put("/students/image", body);
