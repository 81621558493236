import React from "react";

import { OtherProvider, useOther } from "./context";
import { Sum } from "../styles";
import { CloseRounded, EditRounded } from "@material-ui/icons";
import { useWheelsPage } from "../../context";
import { Editor } from "./editor";
import { List } from "./list";
import { Modal } from "./modal";

export const OtherAcheivements = () => (
  <OtherProvider>
    <Other />
  </OtherProvider>
);

const Other = () => {
  const { editing, handleEditClick, items } = useOther();
  const { isUser, profile } = useWheelsPage();

  if (!items.length && profile?.name?.toLowerCase().includes("exeter")) {
    return <React.Fragment />;
  }

  return (
    <>
      <Sum>
        <Sum.Title>
          <i
            className="uil uil-medal"
            style={{ color: "#007bff", marginRight: ".25em" }}
          />
          Other Achievements
        </Sum.Title>
        {isUser && (
          <Sum.EditButton onClick={handleEditClick}>
            {editing ? <CloseRounded /> : <EditRounded />}
          </Sum.EditButton>
        )}
        {editing ? <Editor /> : <List />}
      </Sum>
      <Modal />
    </>
  );
};
