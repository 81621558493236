import React, { Component } from "react";
import PageContainer from "components/common/PageContainer/PageContainer";
import WheelsNavigation from "components/teacher/WheelsNavigation";
import styled from "styled-components";
import { IWheelsListItem } from "typings/wheel";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { Typography } from "@material-ui/core";
import SelectedWheelWrapper from "components/teacher/SelectedWheelWrapper";
import { IGroupData } from "typings/group";
import { WheelsPageContext } from "../../../../states/WheelsPageContext";
import {
  IStudentTableViewFilters,
  IStudentWheelResultsData,
  IWheelResultList,
} from "typings/student";
import SelectedWheelStudentWrapper from "components/teacher/SelectedWheelStudentWrapper";
import SelectedWheelCompareWrapper from "components/teacher/SelectedWheelCompareWrapper";
import { mediaRules } from "MuiTheme/Breakpoints";
import CSVTool from "helpers/csvTool";
import WheelDataFilteringTool from "./tools/wheelDataFilteringTool";
import { getMirrorList, getMirrorResultList } from "lib/mirror";
import { IWithAuth, withAuth } from "../../../../hoc/withAuth";
import { getGroups } from "lib/group";

const INITIAL_FILTERS_STATE: IStudentTableViewFilters = {
  area: "",
  other: [],
  yearGroups: [],
  studentGroups: [],
  sex: [],
  lastLogin: 0,
  lockedGroupsViewable: false,
};

const StyledPageContainer = styled(PageContainer)`
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 992px) {
    flex-direction: column;
  }
`;

const StyledWheelListContainer = styled.div`
  width: 300px;
  flex-shrink: 0;
  margin: 0 30px 0 0;
  position: fixed;
  height: calc(100% - 60px);

  @media only screen and (max-width: 992px) {
    position: relative;
    height: auto;
    width: 100%;
    margin: 0 0 30px 0;
  }
`;

const StyledRouteContainer = styled.div`
  width: 100%;
  padding-left: 330px;
  @media ${mediaRules.lg} {
    padding-left: 0;
  }
`;

const StyledNoSelectedWheelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
`;

interface IState {
  isLoading: boolean;
  isWheelListLoading: boolean;
  wheels: IWheelsListItem[];
  groups: IGroupData[];
  selectedGroup: string;
  wheelResults: IWheelResultList | null;
  tableViewSelectedFilters: IStudentTableViewFilters;
}

interface IProps extends RouteComponentProps, IWithAuth {}

class WheelsPage extends Component<IProps, IState> {
  state = {
    isLoading: true,
    isWheelListLoading: true,
    wheels: [],
    groups: [],
    selectedGroup: "",
    wheelResults: null,
    tableViewSelectedFilters: INITIAL_FILTERS_STATE,
  };

  async componentDidMount(): Promise<void> {
    await this.getWheelList();
    await this.getStudentGroups();
  }

  protected getWheelList = async (): Promise<void> => {
    try {
      const result = await getMirrorList(
        this.props.user.roles?.teacher.id?.mm_group_ids ?? []
      );
      this.setState({
        wheels: result?.data || [],
        isWheelListLoading: false,
      });
    } catch {}
  };

  protected getStudentGroups = async (): Promise<void> => {
    try {
      const result = await getGroups(this.props.user.roles?.teacher.id?._id);
      this.setState({ groups: result?.data[0].groups || [] });
    } catch {}
  };

  protected getSelectedWheelResults = async (
    wheelId: string
  ): Promise<void> => {
    this.setState({
      isLoading: true,
    });
    try {
      const result = await getMirrorResultList(
        wheelId,
        this.props.user.roles.teacher.id?._id
      );
      this.setState(
        {
          selectedGroup: "all-students",
          wheelResults: result.data,
          tableViewSelectedFilters: INITIAL_FILTERS_STATE,
        },
        () => {
          this.setState({ isLoading: false });
        }
      );
    } catch {}
  };

  protected handleSelectGroup = (group: string): void => {
    this.setState({
      selectedGroup: group,
    });
  };

  protected getWheelAreasLabels = (): string[] => {
    const { studentsResults } = this.state.wheelResults;
    const allAreas: Array<{ areaName: string; areaId: string }> = [];

    if (!!studentsResults) {
      studentsResults.forEach((studentResult: IStudentWheelResultsData) => {
        studentResult.areas.forEach(({ _id: areaId, areaName }) => {
          allAreas.push({
            areaId,
            areaName,
          });
        });
      });

      return [...new Set(allAreas.map((area) => JSON.stringify(area)))]
        .map((area) => JSON.parse(area))
        .map((area) => area.areaName);
    }

    return [];
  };

  protected getNumberOfLevels = (): number => {
    const { studentsResults } = this.state.wheelResults;

    if (!!studentsResults) {
      const arrOfAllLevelsInArea: number[] = [];
      studentsResults.forEach((studentResult: IStudentWheelResultsData) => {
        studentResult.areas.forEach(({ levels }) => {
          arrOfAllLevelsInArea.push(levels.length);
        });
      });

      return arrOfAllLevelsInArea.sort((a, b) => a - b)[0];
    }

    return 0;
  };

  protected handleGenerateCSVFile = (
    viewMode: "wheel-view" | "table-view"
  ): void => {
    const levelsCount = this.getNumberOfLevels();
    const headerArr: string[] = ["Name"];
    const levelsArr: string[] = [""];
    const body: Array<Array<string | number>> = [];

    this.getWheelAreasLabels().forEach((area: string) => {
      headerArr.push(area);

      for (let i = levelsCount; i > 1; --i) {
        headerArr.push("");
      }
      for (let i = 1; i <= levelsCount; ++i) {
        levelsArr.push(`Level ${i}`);
      }
    });

    const { wheelResults, tableViewSelectedFilters } = this.state;

    let filteredData = wheelResults.studentsResults;

    if (viewMode === "table-view") {
      filteredData = WheelDataFilteringTool.getFilteredTableViewStudentListData(
        wheelResults.studentsResults,
        tableViewSelectedFilters
      );
    }

    filteredData.forEach((studentResult: IStudentWheelResultsData) => {
      const studentData: Array<string | number> = [];
      studentData.push(studentResult.name);

      studentResult.areas.forEach(({ levels }) => {
        levels.forEach(({ grade }) => {
          studentData.push(grade === null ? "Not Attempted" : grade);
        });
      });

      body.push(studentData);
    });

    CSVTool.downloadCSVFileFromArray(
      [headerArr, levelsArr, ...body],
      `${wheelResults.name} wheel results`
    );
  };

  protected handleTableViewFilterSelect = (
    selectedFilter: Partial<IStudentTableViewFilters>
  ) => {
    const { tableViewSelectedFilters } = this.state;

    this.setState({
      tableViewSelectedFilters: {
        ...tableViewSelectedFilters,
        ...selectedFilter,
      },
    });
  };

  render() {
    const {
      wheels,
      groups,
      selectedGroup,
      isLoading,
      isWheelListLoading,
      wheelResults,
      tableViewSelectedFilters,
    } = this.state;

    return (
      <WheelsPageContext.Provider
        value={{
          groups,
          wheels,
          isLoading,
          selectedGroup,
          wheelResults,
          tableViewSelectedFilters,
          handleSelectGroup: this.handleSelectGroup,
          handleGetSelectedWheelData: this.getSelectedWheelResults,
          handleGenerateCSVFile: this.handleGenerateCSVFile,
          handleTableViewFilterSelect: this.handleTableViewFilterSelect,
        }}
      >
        <StyledPageContainer>
          <StyledWheelListContainer>
            <WheelsNavigation
              wheels={wheels}
              isLoading={isWheelListLoading}
              page="wheels"
            />
          </StyledWheelListContainer>
          <StyledRouteContainer>
            <Switch>
              <Route path="/teacher/my-wheels" exact>
                <StyledNoSelectedWheelContainer>
                  <Typography variant="body2">
                    Select a wheel from the list <br />
                    on the left to get started.
                  </Typography>
                </StyledNoSelectedWheelContainer>
              </Route>
              <Route
                path="/teacher/my-wheels/:wheelId"
                component={SelectedWheelWrapper}
                exact
              />
              <Route
                path="/teacher/my-wheels/:wheelId/compare"
                component={SelectedWheelCompareWrapper}
                exact
              />
              <Route
                path="/teacher/my-wheels/:wheelId/:studentId"
                component={SelectedWheelStudentWrapper}
                exact
              />
            </Switch>
          </StyledRouteContainer>
        </StyledPageContainer>
      </WheelsPageContext.Provider>
    );
  }
}

export default withAuth(WheelsPage, ["teacher", "admin"]);
