import React from "react";

import { getEvidence } from "lib/evidence";
import { getBlobFromUrl, getFileById } from "lib/file";

import { useEvidenceList } from "../context";
import { EvidenceContext, EvidenceProviderProps } from "./types";

const Context = React.createContext<EvidenceContext>({
  area: "",
  areaId: "",
  date: "",
  id: "",
  loading: false,
  name: "",
  type: "",
  viewEvidenceItem: () => Promise.resolve(),
});

const ALPHA = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

export const EvidenceProvider = ({
  children,
  evidence,
}: EvidenceProviderProps) => {
  const { isTeacher, mirrorLevelType, setComment, studentName } =
    useEvidenceList();
  const [loading, setLoading] = React.useState(false);

  //  Potentially flawed logic here as there is no option
  //  for no messages when teacher and evidence has actually been opened.
  const viewed = React.useMemo(() => {
    if (!evidence.feedback && !evidence.messages?.length) return !isTeacher;
    const filtered =
      evidence.messages?.filter(({ teacherId }) => isTeacher === !teacherId) ??
      [];
    if (!evidence.feedback && evidence.messages?.length) {
      return filtered.every(({ viewed }) => viewed);
    }
    if (evidence.feedback && evidence.messages?.length) {
      return evidence.feedback.viewed;
    }
    return evidence.feedback?.viewed && filtered.every(({ viewed }) => viewed);
  }, [evidence.feedback, evidence.messages]);

  const viewEvidenceItem = async () => {
    setLoading(true);
    const { data } = await getEvidence(evidence._id);
    let url =
      typeof data.evidence === "string"
        ? data.evidence
        : data.evidence?.evidence;
    if (data.evidence.isCloudStorage) {
      const { data: file } = await getFileById(data.evidence.evidence);
      url = file;
    }

    if (evidence.fileType === "comment") {
      return setComment({
        name: evidence.evidenceName,
        date: evidence.createdAt,
        url,
      });
    }

    if (evidence.fileType === "link" && !url.startsWith("http")) {
      url = `https://${url}`;
    }

    const link = document.createElement("a");
    link.rel = "noopener noreferrer";
    link.target = "_blank";
    if (evidence.fileType !== "link") {
      const read = await getBlobFromUrl(url);
      const blob = new Blob([read]);
      url = URL.createObjectURL(blob);
    }

    if (evidence.fileType !== "link") link.download = evidence.evidenceName;
    link.href = url;
    let filename = evidence.evidenceName;
    if (studentName) {
      const name = studentName.split(" ");
      const student = `${name[name.length - 1]}${name[0].charAt(0)}`;
      const areaLevel = `${evidence.areaName?.replace(" ", "-")}-${
        mirrorLevelType === "sequential"
          ? evidence.levelName
          : ALPHA[Number(evidence.levelName) - 1]
      }`;
      filename = `${student}-${areaLevel}-${evidence.evidenceName}`;
    }
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    evidence.fileType !== "link" && URL.revokeObjectURL(url);
    setLoading(false);
  };

  return (
    <Context.Provider
      value={{
        area: evidence.areaName,
        areaId: evidence.areaId,
        date: evidence.createdAt,
        id: evidence._id,
        loading,
        messages: evidence.messages,
        name: evidence.evidenceName,
        studentGrade: evidence.studentGrade,
        teacherGrade: evidence.teacherGrade,
        type: evidence.fileType,
        viewEvidenceItem,
        viewed,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useEvidence = () => React.useContext(Context);
