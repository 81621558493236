import { Theme } from '@material-ui/core';

export const breakpoints: Partial<Theme['breakpoints']> = {
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
};

export const mediaRules = {
  sm: `(max-width: ${breakpoints.values.sm}px)`,
  md: `(max-width: ${breakpoints.values.md}px)`,
  lg: `(max-width: ${breakpoints.values.lg}px)`,
  xl: `(max-width: ${breakpoints.values.xl}px)`,
};

