import { Typography } from "@material-ui/core";
import { CloudUploadRounded } from "@material-ui/icons";
import React from "react";
import { ImageUploadProps } from "./types";
import { UploadButton } from "./styles";

export const ImageUpload = ({
  image,
  name,
  onChange,
  placeholder,
}: ImageUploadProps) => (
  //  @ts-ignore
  <UploadButton
    component="label"
    image={image}
    placeholder={placeholder}
    variant="outlined"
  >
    <Typography id="upload" variant="caption" style={{ textAlign: "center" }}>
      <CloudUploadRounded style={{ fontSize: "3rem" }} />
      <div>Upload Image</div>
    </Typography>
    {image && (
      <img
        src={image as string}
        alt="profile"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "50%",
        }}
      />
    )}
    <input
      accept="image/*"
      onChange={(e) => onChange?.(e.target.files?.[0])}
      name={name}
      style={{ display: "none" }}
      type="file"
    />
  </UploadButton>
);
