import { axiosInstance } from "helpers/axios-instance";

export const createToken = async (studentId: string) =>
  axiosInstance.post(`/token`, { studentId });

type WheelTokenParams = {
  areaId?: string;
  levelId?: string;
  token: string;
  wheelId: string;
};

export const getWheelFromToken = async (params: WheelTokenParams) =>
  axiosInstance.post(`/token/wheel`, params);

export const getProfileFromToken = async (token: string) =>
  axiosInstance.get(`/token/${token}`);
