import React, { ChangeEvent, Component } from "react";
import AnimatedModal from "components/common/AnimatedModal";
import styled from "styled-components";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import palette from "palette";
import { uploadStudents } from "lib/student";
import { IWithAuth, withAuth } from "../../hoc/withAuth";

const StyledModalContainer = styled.div`
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin: 40px 0 0;
`;

const StyledInput = styled.input`
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const StyledLabel = styled.label<{ uploaded: boolean }>`
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 270px;
  max-width: 100%;
  height: 50px;
  color: ${(props) => (props.uploaded ? "#fff" : palette.secondary)};
  background-color: ${(props) => (props.uploaded ? "#FF6F91" : "transparent")};
  border: 1px solid ${palette.secondary};
  border-color: ${palette.secondary};
  border-width: 3px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: #ffe5f6;
  }
`;

const StyledIcon = styled.i`
  font-size: 22px;
  margin: 0 5px 0 0;
`;

const StyledActionButton = styled(Button)`
  width: 150px;
  height: 35px !important;

  &:nth-child(2) {
    margin: 0 0 0 12px;
  }
`;

const StyledTableContainer = styled.div`
  width: 100%;
  margin: 20px 0 20px;
  border-radius: 10px !important;
  border: 3px solid ${palette.font.caption};
  overflow: auto;
`;

const StyledTableCell = styled(TableCell)`
  font-size: 14px !important;
`;

const StyledMessagesContainer = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${(props) => (props.open ? "150px" : "0")};
  max-height: 150px;
  overflow: auto;
  transition: 0.3s;
`;

const StyledMessage = styled.div<{ success?: boolean }>`
  width: 100%;
  padding: 8px 15px;
  border: 3px solid
    ${(props) => (props.success ? palette.complete : palette.secondary)};
  color: ${(props) => (props.success ? palette.complete : palette.secondary)};
  border-radius: 10px;
  font-weight: 700;

  &:not(:first-child) {
    margin: 10px 0 0;
  }
`;

const SimpleMessage = styled.div`
  width: 100%;
  padding: 8px 15px;
  color: ${palette.font.body};
  font-weight: 400;
  text-align: center;
  font-size: 18px;
`;

const StyledUploadContainer = styled.div`
  margin: 30px 0 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const EXAMPLE_DATA: IExampleTableData[] = [
  {
    name: "John Kowalksy",
    email: "example@email.com",
    password: "securePassword",
    sex: "m",
    group: "",
    other: "SEN",
    yearGroup: "Year 5",
  },
  {
    name: "Alice Kowalksy",
    email: "example@email.com",
    password: "securePassword",
    sex: "f",
    group: "",
    other: "EAL",
    yearGroup: "Year 6",
  },
];

interface IExampleTableData {
  name: string;
  email: string;
  password: string;
  sex: string;
  group: string;
  other: string;
  yearGroup: string;
}

interface IProps extends IWithAuth {
  isOpen: boolean;
  onClose: () => void;
  refreshStudents: () => Promise<void>;
}

interface IState {
  file: string | ArrayBuffer | null;
  fileName: string;
  error: string;
  requesting: boolean;
  success: boolean;
  validationError: string[];
  uploadingResults: { inFile: number; uploaded: number };
  isFileTypeValid: boolean;
}

class UploadStudents extends Component<IProps, IState> {
  state = {
    file: null,
    fileName: "",
    error: "",
    requesting: false,
    success: false,
    validationError: [],
    uploadingResults: { inFile: 0, uploaded: 0 },
    isFileTypeValid: false,
  };

  errorMsg =
    "Something went wrong. Please verify the file format (.csv required) and try again";

  protected handleUploadFile = (event: ChangeEvent<HTMLInputElement>): void => {
    const { files } = event.target;

    if (files) {
      const reader = new FileReader();
      const file = files[0];

      reader.readAsDataURL(file);

      reader.onload = () => {
        const { name: fileName } = file;
        const isFileTypeValid = fileName.split(".").pop() === "csv";

        this.setState({
          fileName,
          isFileTypeValid,
          file: reader.result,
          error: isFileTypeValid ? "" : this.errorMsg,
        });
      };

      reader.onerror = () =>
        this.setState({
          file: null,
          error: this.errorMsg,
          fileName: "",
        });
    }
  };

  protected handleSubmitFile = async (): Promise<void> => {
    this.setState({
      requesting: true,
      error: "",
    });

    const { file } = this.state;

    if (file) {
      const teacherId = this.props.user.roles?.teacher.id._id;
      const schoolId = this.props.user.roles?.teacher.id.mm_school_id;
      const { data } = await uploadStudents(file || "", teacherId, schoolId);

      if (data.error) {
        this.setState({
          requesting: false,
          error: this.errorMsg,
        });
        return;
      }

      if (data.validationErrors) {
        this.setState({
          requesting: false,
          validationError: data.validationErrors,
          success: false,
        });
      }

      if (data.uploadingResults) {
        await this.props.refreshStudents();
        this.setState({
          requesting: false,
          uploadingResults: data.uploadingResults,
          validationError: [],
          success: true,
          file: null,
        });
      }
    }
  };

  protected handleCloseModal = (): void => {
    this.props.onClose();

    setTimeout(() => {
      this.setState({
        file: null,
        fileName: "",
        error: "",
        requesting: false,
        success: false,
        validationError: [],
        uploadingResults: { inFile: 0, uploaded: 0 },
      });
    }, 1000);
  };

  render() {
    const { isOpen } = this.props;
    const {
      file,
      fileName,
      requesting,
      success,
      validationError,
      uploadingResults,
      isFileTypeValid,
      error,
    } = this.state;

    return (
      <AnimatedModal
        open={isOpen}
        title="Upload Students"
        handleClose={this.handleCloseModal}
        minHeight="0px"
        height="auto"
        width="100%"
        maxWidth="800px"
      >
        <StyledModalContainer>
          <Typography variant="body1">
            To add new students, upload csv in the format displayed below.
          </Typography>
          <StyledTableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Email</StyledTableCell>
                  <StyledTableCell>Password</StyledTableCell>
                  <StyledTableCell>Sex</StyledTableCell>
                  <StyledTableCell>Group</StyledTableCell>
                  <StyledTableCell>Year group</StyledTableCell>
                  <StyledTableCell>Other</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {EXAMPLE_DATA.map(
                  (exampleData: IExampleTableData, rowIndex: number) => (
                    <TableRow key={`example-data-${rowIndex}`}>
                      <StyledTableCell>{exampleData.name}</StyledTableCell>
                      <StyledTableCell>{exampleData.email}</StyledTableCell>
                      <StyledTableCell>{exampleData.password}</StyledTableCell>
                      <StyledTableCell>{exampleData.sex}</StyledTableCell>
                      <StyledTableCell>{exampleData.group}</StyledTableCell>
                      <StyledTableCell>{exampleData.yearGroup}</StyledTableCell>
                      <StyledTableCell>{exampleData.other}</StyledTableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </StyledTableContainer>
          {!!error && <StyledMessage>{error}</StyledMessage>}
          <StyledMessagesContainer open={!!validationError.length}>
            {validationError.map((message: string, messageIndex: number) => (
              <StyledMessage key={`validation-error-${messageIndex}`}>
                {message}
              </StyledMessage>
            ))}
          </StyledMessagesContainer>
          {uploadingResults.inFile > 1 ? (
            <SimpleMessage>
              {uploadingResults.uploaded} out of {uploadingResults.inFile}{" "}
              student accounts created.
            </SimpleMessage>
          ) : uploadingResults.inFile === 1 &&
            uploadingResults.uploaded === 1 ? (
            <SimpleMessage>Student account created.</SimpleMessage>
          ) : uploadingResults.inFile === 1 &&
            uploadingResults.uploaded === 0 ? (
            <SimpleMessage>Couldn&apos;t create student account.</SimpleMessage>
          ) : (
            ""
          )}
          <StyledUploadContainer>
            <>
              <StyledInput
                id="file-input"
                type="file"
                accept=".csv"
                onChange={(event) => this.handleUploadFile(event)}
              />
              <StyledLabel htmlFor="file-input" uploaded={!!file}>
                {file ? (
                  <>
                    <StyledIcon className="uil uil-file-check" />
                    {fileName}
                  </>
                ) : (
                  <>
                    <StyledIcon className="uil uil-file-upload" />
                    upload csv
                  </>
                )}
              </StyledLabel>
            </>
          </StyledUploadContainer>
          <StyledActionsContainer>
            <StyledActionButton
              variant="outlined"
              color="primary"
              onClick={this.handleCloseModal}
            >
              cancel
            </StyledActionButton>
            {!success || file ? (
              <StyledActionButton
                variant="contained"
                color="primary"
                onClick={this.handleSubmitFile}
                disabled={requesting || !isFileTypeValid || !file}
              >
                add students
              </StyledActionButton>
            ) : (
              <StyledActionButton
                variant="contained"
                color="primary"
                onClick={this.handleCloseModal}
              >
                continue
              </StyledActionButton>
            )}
          </StyledActionsContainer>
        </StyledModalContainer>
      </AnimatedModal>
    );
  }
}

export default withAuth(UploadStudents, []);
