import React, { useEffect, useState } from "react";
import AnimatedModal from "components/common/AnimatedModal";
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  TypographyProps,
} from "@material-ui/core";
import styled from "styled-components";
import palette from "palette";
import { TWheelData } from "typings/meta-mirror";
import { IResponseBody } from "typings/global";
import Wheel from "components/common/Wheel/Wheel";

const StyledLoader = styled(CircularProgress)`
  height: 20px !important;
  width: 20px !important;
  color: ${palette.font.caption} !important;
  margin-right: 10px;
`;

const StyledWheelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0 75px;
  max-height: 70vh;
  min-height: 590px;
`;

interface IStyledInfoMessageProps extends TypographyProps {
  textColor?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledInfoMessage = styled(
  ({ textColor, ...props }: IStyledInfoMessageProps) => (
    <Typography {...props} />
  )
)<{ textColor: string }>`
  color: ${(props) => props.textColor || palette.font.caption} !important;
  min-height: 50px;
  max-height: 50px;
`;

const StyledWheelTitle = styled(Typography)`
  font-size: 18px !important;
  font-weight: 700 !important;
  margin-top: 20px !important;
  margin-bottom: -15px !important;
  z-index: 100 !important;
`;

interface IProps {
  open: boolean;
  wheelName: string;
  wheelData: TWheelData;
  handleAddWheel: () => Promise<IResponseBody>;
  handleClose(): void;
  mirrorLevelType: string;
}

const AddWheelModal: React.FC<IProps> = ({
  open,
  wheelName,
  wheelData,
  handleAddWheel,
  handleClose,
  mirrorLevelType,
}: IProps) => {
  const [requestPending, setRequestPending] = useState(false);
  const [wheelAdded, setWheelAdded] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [localWheelData, setLocalWheelData] = useState(wheelData);
  const [localWheelName, setLocalWheelName] = useState(wheelName);

  useEffect(() => {
    if (open) {
      setLocalWheelData(wheelData);
      setLocalWheelName(wheelName);
    }
  }, [open]);

  const handleCloseModal = (): void => {
    handleClose();
    setTimeout(() => {
      setRequestPending(false);
      setWheelAdded(false);
      setLocalWheelData([]);
      setLocalWheelName("");
      setErrorMessage("");
    }, 1000);
  };

  const addWheel = async (): Promise<void> => {
    setRequestPending(true);
    const result = await handleAddWheel();
    setRequestPending(false);
    if (result.error) {
      setErrorMessage(result.message);
      return;
    }
    setErrorMessage("");
    setWheelAdded(true);
  };

  return (
    <AnimatedModal
      open={open}
      handleClose={handleCloseModal}
      onClose={handleCloseModal}
      disableClose={requestPending}
      disableBackdropClick={requestPending}
      title={
        wheelAdded
          ? "Wheel Added"
          : "Are you sure you want to save this new wheel?"
      }
      hideClose
      width="50%"
      minWidth="900px"
    >
      <>
        <StyledWheelContainer>
          <StyledWheelTitle variant="caption" className="mx-auto">
            {localWheelName}
          </StyledWheelTitle>
          <Wheel
            data={localWheelData}
            height={400}
            width={900}
            backgroundColor={palette.surface}
            labels
            interactive
            descriptions="bottom"
            mirrorLevelType={mirrorLevelType}
          />
        </StyledWheelContainer>
        {errorMessage ? (
          <StyledInfoMessage
            textColor={palette.error}
            variant="body2"
            className="w-100 text-center mb-5 mt-2"
          >
            {errorMessage}
          </StyledInfoMessage>
        ) : wheelAdded ? (
          <StyledInfoMessage
            textColor={palette.complete}
            variant="body2"
            className="w-100 text-center mt-2 mb-5"
          >
            Your new wheel has been saved successfully.
          </StyledInfoMessage>
        ) : (
          <StyledInfoMessage
            textColor={palette.font.body}
            variant="body2"
            className="w-100 text-center mt-2 mb-5"
          >
            Saving this wheel will make it visible to all students and teachers
            from all selected groups. <br />
            <b>
              <em>
                Only a wheel's name, area names, and level descriptions can be
                edited after creation. Please ensure that the groups, number of
                areas, and number of levels are correct.
              </em>
            </b>
          </StyledInfoMessage>
        )}
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignContent="center"
        >
          {!wheelAdded && (
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={handleCloseModal}
                disabled={requestPending}
                className="mr-3"
              >
                go back
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={wheelAdded ? handleCloseModal : addWheel}
              disabled={requestPending}
              className={wheelAdded ? "" : "ml-3"}
            >
              {requestPending && <StyledLoader />}
              {wheelAdded ? "done" : "continue"}
            </Button>
          </Grid>
        </Grid>
      </>
    </AnimatedModal>
  );
};

export default AddWheelModal;
