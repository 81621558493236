import { Button, Typography } from "@material-ui/core";
import styled from "styled-components";

import palette from "MuiTheme/palette";

export const Delete = Object.assign(
  styled.div({
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 30,
    textAlign: "center",
  }),
  {
    Button: styled(Button)({
      backgroundColor: `${palette.error}dd !important`,
      color: "white !important",
      "&:hover": {
        backgroundColor: `${palette.error} !important`,
      },
    }),

    ButtonContainer: styled.div({
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      marginTop: 30,
      textAlign: "center",
      width: 230,
    }),
  }
);

export const View = {
  Area: styled(Typography)({
    marginBottom: 25,
    marginTop: 5,
    textAlign: "center",
  }),

  Comment: styled(Typography)({
    backgroundColor: "#eaeaea",
    borderRadius: 10,
    fontStyle: "italic",
    padding: 15,
    textAlign: "center",
  }),

  Container: styled.div({
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 15,
    paddingLeft: 10,
  }),

  Info: styled.div({
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  }),

  Text: styled(Typography)({ fontStyle: "italic" }),
};
