import React from "react";
import styled from "styled-components";
import { Slider, Typography } from "@material-ui/core";
import Select from "components/common/Select/Select";
import TextInput from "components/common/TextInput/TextInput";
import { IGroupData } from "typings/group";
import palette from "palette";
import { IWheelSettings } from "pages/teacher/sections/AddWheelPage";
import { MIRROR_LEVEL_TYPES } from "constants/index";

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  flex: 1;
`;

const StyledInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
`;

const StyledSliderHeading = styled(Typography)`
  margin-left: -5px !important;
  font-size: 16px !important;
  color: ${palette.font.body} !important;
  font-weight: 700 !important;
`;

const StyledWheelTypeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin-top: 15px;
`;

const marks = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
  { value: 13, label: "13" },
  { value: 14, label: "14" },
  { value: 15, label: "15" },
  { value: 16, label: "16" },
  { value: 17, label: "17" },
  { value: 18, label: "18" },
  { value: 19, label: "19" },
  { value: 20, label: "20" },
];

interface IProps {
  wheelName: string;
  selectedGroups: string[];
  studentGroups: IGroupData[];
  areas: number;
  levels: number;
  levelsType: string;
  updateWheelSetting(settings: Partial<IWheelSettings>): void;
}

const WheelSettings: React.FC<IProps> = ({
  wheelName,
  selectedGroups,
  studentGroups,
  areas,
  levels,
  levelsType,
  updateWheelSetting,
}: IProps) => {
  return (
    <StyledContainer>
      <StyledInputContainer className="my-2 my-md-0">
        <TextInput
          value={wheelName}
          label="Wheel Name"
          onChange={(event) =>
            updateWheelSetting({ wheelName: event.target.value })
          }
          inputProps={{
            maxLength: 50,
          }}
          fullWidth
        />
      </StyledInputContainer>
      <StyledInputContainer className="my-2 my-lg-0">
        <Select
          value={selectedGroups}
          options={studentGroups.map((group) => ({
            label: group.name,
            value: group._id,
          }))}
          label="Student Groups"
          multiple
          onChange={(event) =>
            updateWheelSetting({
              selectedGroups: event.target.value as string[],
            })
          }
        />
      </StyledInputContainer>
      <StyledInputContainer className="px-2">
        <br />
        <StyledSliderHeading variant="body1">Areas</StyledSliderHeading>
        <Slider
          value={areas}
          onChangeCommitted={(event, value) =>
            updateWheelSetting({ areas: value as number })
          }
          min={2}
          max={20}
          marks={marks.slice(1, marks.length)}
        />
      </StyledInputContainer>
      <br />
      <StyledInputContainer className="px-2">
        <StyledSliderHeading variant="body1">
          {levelsType === "non-sequential" ? "Parts" : "Levels"}
        </StyledSliderHeading>
        <Slider
          value={levels}
          onChangeCommitted={(event, value) =>
            updateWheelSetting({ levels: value as number })
          }
          min={1}
          max={10}
          marks={marks.slice(0, 10)}
        />
      </StyledInputContainer>
      <StyledWheelTypeContainer>
        <Select
          value={levelsType}
          options={MIRROR_LEVEL_TYPES.map((v) => ({ label: v, value: v }))}
          label="Wheel Level Type"
          onChange={(event) => {
            updateWheelSetting({
              levelsType: event.target.value as string,
            });
          }}
        />
      </StyledWheelTypeContainer>
    </StyledContainer>
  );
};

export default WheelSettings;
