import Cookies from "js-cookie";
import { SESSION_COOKIE, WHITELABEL_COOKIE } from "constants/index";
import { axiosInstance } from "helpers/axios-instance";

const cookieOptions = { domain: process.env.COOKIE_URL };

export const GetAuthToken = () => Cookies.get(SESSION_COOKIE);

export const LoginAsOther = async (email: string, uid: string) => {
  try {
    const { data } = await axiosInstance.post(`/auth/login/impersonate`, {
      email,
      uid,
    });
    Cookies.set(SESSION_COOKIE, data.accessToken, cookieOptions);
    return true;
  } catch (err) {
    console.log("Issue logging in");
    return false;
  }
};

export const logoutUser = async () => {
  Cookies.remove(SESSION_COOKIE, cookieOptions);
};

export const setWhitelabel = (whitelabel: string) => {
  if (!whitelabel) {
    Cookies.remove(WHITELABEL_COOKIE, cookieOptions);
  } else {
    Cookies.set(WHITELABEL_COOKIE, whitelabel, cookieOptions);
  }
};

export const getWhitelabel = () => Cookies.get(WHITELABEL_COOKIE);

export const getSchoolConfig = async () => {
  let id = "";
  if (getWhitelabel()) {
    id = `?urlId=${getWhitelabel()}`;
  }

  const url = `/schools/config${id}`;

  const { data: schoolConfig } = await axiosInstance.get(url);

  return {
    schoolConfig,
  };
};
