/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { TOption } from "components/common/Select/Select";
import { LEVEL_PARTS } from "constants/index";
import { IEvidenceForReview } from "typings/evidence";
import { ILevel } from "typings/meta-mirror";

export const getLabel = (level: ILevel, type: string) =>
  level.name
    ? level.name
    : type === "non-sequential"
    ? `Part ${LEVEL_PARTS[level.level - 1]}`
    : `Level ${level.level}`;

type GetLevelsProps = {
  evidence?: IEvidenceForReview;
  levels: ILevel[];
  type: string;
};

export const getLevels = ({
  evidence,
  levels,
  type,
}: GetLevelsProps): TOption[] => {
  if (!evidence) return [];
  const current = levels.find(({ _id }) => _id === evidence.currentLevelId);
  let disabled = !!current && type !== "non-sequential";

  return levels
    .sort((a, b) => a.level - b.level)
    .map((level, index) => {
      const curGrade = current?.grade ?? 0;
      const curLevel = current?.level ?? 0;
      const logic =
        level.level === curLevel && curGrade && index !== levels.length - 1;
      if (disabled && level.level >= curLevel && logic) disabled = false;
      return {
        disabled,
        label: getLabel(level, type),
        value: level._id!,
        level: level.level,
      };
    });
};
