import { useEffect, useState } from "react";
import { axiosInstance } from "helpers/axios-instance";
import { IUserFull } from "@rose-arch-holdings-ltd/common";

export const useAuth = (requiredRoles: string[]) => {
  const [user, setUser] = useState<Partial<IUserFull>>({});
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    (async () => {
      const { data } = await axiosInstance.post("/auth/validate", {
        ...(requiredRoles.length ? { requiredRoles } : {}),
      });
      setUser(data.user);
      setIsAuth(!!data?.tokenValid);
    })();
  }, []);

  return {
    user,
    isAuth,
  };
};
