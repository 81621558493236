export const VALID_FILETYPES = [
  ".doc",
  ".docx",
  ".pdf",
  ".png",
  ".jpeg",
  ".jpg",
  ".pptx",
] as const;

export const MAX_FILE_SIZE = 10 * (1024 * 1024);

export const VALID_DOMAINS = [
  "drive.google.com",
  "docs.google.com",
  "youtube.com",
];

const regExpPrefix = "^(https?://)?(www.)?(";
const regExpSuffix =
  ")/([A-Za-z0-9-._~:?#[\\]@!$&'()*+,;=])([A-Za-z0-9-._~:/?#[\\]@!$&'()*+,;=]+)$";

export const EvidenceValidator = {
  validateEvidenceLink(link: string): boolean {
    const linkValid = VALID_DOMAINS.some((validDomain) => {
      const regExp =
        regExpPrefix + validDomain.replace(".", "\\.") + regExpSuffix;
      return !!link.match(regExp);
    });
    return linkValid;
  },
};
