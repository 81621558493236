import React, { useState } from "react";
import styled from "styled-components";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { giveUserAccess } from "lib/user";

const StyledContainer = styled(Paper)`
  width: 100%;
  min-height: 120px;
  padding: 10px 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledButton = styled(Button)`
  min-width: 140px !important;
  width: 100%;
  max-width: 160px;
`;

const StyledTypography = styled(Typography)`
  font-size: 16px !important;
`;

interface IProps {
  userName: string;
  userId: string;
  onFinish: () => void;
}

const GiveAccess: React.FC<IProps> = ({
  userName,
  userId,
  onFinish,
}: IProps) => {
  const [isRequesting, setRequestingState] = useState(false);

  const handleGiveAccess = async (): Promise<void> => {
    if (confirm("Are you sure?")) {
      setRequestingState(true);
      try {
        await giveUserAccess(userId);
        setRequestingState(false);
        onFinish();
      } catch {}
    }
  };

  return (
    <StyledContainer>
      <Grid container direction="row" alignContent="center">
        <Grid
          item
          xs={12}
          sm={8}
          className="d-flex flex-column justify-content-center align-content-start mt-2 mt-sm-0"
        >
          <StyledTypography variant="body1">
            <b>{userName}</b> does not have access to Meta Mirror.
          </StyledTypography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          className="mt-3 mt-sm-0 d-flex flex-row justify-content-end"
        >
          <StyledButton
            color="secondary"
            variant="contained"
            onClick={handleGiveAccess}
            disabled={isRequesting}
          >
            give access
          </StyledButton>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default GiveAccess;
