import { Box, CircularProgress, Typography } from "@material-ui/core";
import React from "react";

export const Loading = () => (
  <Box
    sx={{
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      //  @ts-ignore
      gap: "1rem",
      height: "100vh",
      justifyContent: "center",
      width: "100vw",
    }}
  >
    <CircularProgress size={60} />
    <Typography variant="h2">Loading</Typography>
  </Box>
);
