import React from "react";
import {
  FormControl,
  FormControlProps,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@material-ui/core";
import styled from "styled-components";
import palette from "palette";

const StyledLabel = styled(InputLabel)`
  background-color: ${palette.surface};
`;

const StyledDisabledLabel = styled(InputLabel)`
  background-color: ${palette.surface} !important;
  font-size: 14px !important;
  margin-top: 2px !important;
  color: ${palette.font.body} !important;
`;

const StyledCheckIcon = styled.i`
  font-size: 22px;
  color: ${palette.primary};
  position: absolute;
  right: 8px;
  top: calc(50% - 15px);
`;

export type TOption = {
  /**
   * Value for the option, used in onChange.
   */
  value: number | string;
  /**
   * Label for the option, displayed in the select menu.
   */
  label: string;
  /**
   * If the option is disabled;
   */
  disabled?: boolean;
};

interface IProps extends Omit<SelectProps, "variant" | "MenuProps"> {
  FormControlProps?: FormControlProps;
  /**
   * The options to be shown in the menu of the select.
   * {label: string, value: string | number}
   */
  options: TOption[];
  /**
   * The menu item shown if no options are supplied.
   */
  emptyMessage?: string;
  /**
   * Disable auto margin on component.
   */
  disableMargin?: boolean;
  /**
   * DefaultOption which should add possibility to unselect non-multiple select.
   */
  defaultOption?: string;
}

/**
 * A Select component based on the MUI Select.
 * Has been pre-styled, supports multiple select and single select.
 */
const CustomSelect: React.FC<IProps> = ({
  value,
  options,
  label,
  emptyMessage,
  disableMargin,
  defaultOption,
  FormControlProps,
  ...rest
}: IProps) => {
  const renderOptions = (): JSX.Element[] => {
    const preparedElements: JSX.Element[] = [];

    if (defaultOption) {
      preparedElements.push(
        <MenuItem value="default" key={defaultOption}>
          <i>{defaultOption}</i>
        </MenuItem>
      );
    }

    options.forEach((option) => {
      preparedElements.push(
        <MenuItem
          value={option.value}
          key={option.value}
          disabled={!!option.disabled}
        >
          {option.label}
          {!!Array.isArray(value) && !!value.includes(option.value) && (
            <StyledCheckIcon className="uil uil-check" />
          )}
        </MenuItem>
      );
    });
    return preparedElements;
  };

  return (
    <FormControl
      className={`w-100 position-relative ${!disableMargin && "m-auto"}`}
      {...FormControlProps}
    >
      {rest.disabled ? (
        <StyledDisabledLabel id="multi-select-label">
          {label}
        </StyledDisabledLabel>
      ) : (
        <StyledLabel id="multi-select-label">{label}</StyledLabel>
      )}
      <Select
        value={value}
        variant="outlined"
        MenuProps={{
          variant: "menu",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          getContentAnchorEl: null,
          MenuListProps: {
            style: {
              border: "0px",
              maxHeight: "300px",
            },
          },
        }}
        label={label}
        margin="dense"
        renderValue={(renderValue) => {
          if (Array.isArray(renderValue)) {
            const selectedValues = options
              .filter((option) => renderValue.includes(option.value))
              .map((option) => option.label);
            return selectedValues.join(", ");
          }
          const returnValue = options.find(
            (option) => option.value === renderValue
          );
          if (!!returnValue) {
            return returnValue.label;
          }
          return "";
        }}
        {...rest}
      >
        {options?.length ? (
          renderOptions()
        ) : (
          <MenuItem key="none">
            <i>{!!emptyMessage ? emptyMessage : "None"}</i>
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
