import React, { useEffect, useRef, useState } from "react";
import { TOption } from "../Select/Select";
import {
  XYPlot,
  XAxis,
  YAxis,
  ChartLabel,
  HorizontalGridLines,
  VerticalGridLines,
  LineMarkSeries as LineSeries,
} from "react-vis";
import styled from "styled-components";
import palette from "palette";
import ProgressChartSideBar, { IKeyItemData } from "./ProgressChartSideBar";

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const StyledChartContainer = styled.div`
  width: 85%;
  height: 100%;
`;

interface IChartDataPoint {
  x: Date;
  y: number;
}

export interface ISeriesData {
  areaName: string;
  areaId: string;
  data: IChartDataPoint[];
}

interface IProps {
  chartData: ISeriesData[];
  selectGroups?: TOption[];
  selectedGroup?: string;
  /**
   * Indicates if the chart is being used for a report.
   * If it is, different styling will be applied to the component.
   */
  reportChart?: boolean;
  handleSelectGroup?(selectedGroup: string): void;
}

const lineColors = [
  "#3493e7",
  "#ff6ea1",
  "#f9c54e",
  "#62aa38",
  "#33c297",
  "#f94144",
  "#a2db76",
  "#ed8607",
  "#954b71",
  "#577590",
];
const CHART_INITIAL_SIZE = 250;

const ProgressChart: React.FC<IProps> = ({
  chartData,
  selectGroups,
  selectedGroup,
  reportChart,
  handleSelectGroup,
}: IProps) => {
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const [chartWidth, setChartWidth] = useState(CHART_INITIAL_SIZE);
  const [chartHeight, setChartHeight] = useState(CHART_INITIAL_SIZE);
  const [selectedSeries, setSelectedSeries] = useState("");

  useEffect(() => {
    const handleResize = () => {
      const newWidth =
        chartContainerRef?.current.offsetWidth || CHART_INITIAL_SIZE;
      let newHeight =
        chartContainerRef?.current.offsetHeight || CHART_INITIAL_SIZE;
      if (newWidth < CHART_INITIAL_SIZE * 2) {
        newWidth === CHART_INITIAL_SIZE * 2;
      }
      if (newHeight < CHART_INITIAL_SIZE) {
        newHeight = CHART_INITIAL_SIZE;
      }
      setChartWidth(newWidth);
      setChartHeight(newHeight);
    };
    if (chartWidth === CHART_INITIAL_SIZE) {
      handleResize();
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [chartContainerRef]);

  const handleSeriesSelect = (seriesId: string): void => {
    if (selectedSeries === seriesId) {
      setSelectedSeries("");
      return;
    }
    setSelectedSeries(seriesId);
  };

  const getLineColor = (index: number, seriesId: string): string => {
    const lineColor = lineColors[index];
    if (!selectedSeries.length || selectedSeries === seriesId) {
      return lineColor;
    }
    return `${lineColor}77`;
  };

  const getLineSeries = (): JSX.Element[] => {
    const lineSeries = [];
    let selectedSeriesIndex = -1;
    chartData.forEach((series, index) => {
      if (selectedSeries === series.areaId) {
        selectedSeriesIndex = index;
      }
      lineSeries.push(
        <LineSeries
          lineStyle={{
            fill: "none",
            strokeWidth: series.areaId === selectedSeries ? 4 : 2.5,
            cursor: "pointer",
            zIndex: series.areaId === selectedSeries ? 100 : 1,
          }}
          size={series.areaId === selectedSeries ? 3.5 : 0.1}
          key={series.areaId}
          curve={"curveMonotoneX"}
          color={getLineColor(index, series.areaId)}
          data={series.data}
          onSeriesClick={() => handleSeriesSelect(series.areaId)}
        />
      );
    });
    if (selectedSeriesIndex >= 0) {
      [lineSeries[selectedSeriesIndex], lineSeries[lineSeries.length - 1]] = [
        lineSeries[lineSeries.length - 1],
        lineSeries[selectedSeriesIndex],
      ];
    }
    return lineSeries;
  };

  const getKeyData = (): IKeyItemData[] => {
    return chartData.map(({ areaId, areaName }, index) => ({
      areaId,
      areaName,
      handleSelect: () => handleSeriesSelect(areaId),
      lineColor: getLineColor(index, areaId),
      selected: areaId === selectedSeries,
    }));
  };

  const getTickLabels = (): number[] => {
    const ticks = chartData[0].data.map((dataPoint) =>
      new Date(dataPoint.x).getTime()
    );
    return ticks;
  };

  return (
    <StyledContainer>
      <StyledChartContainer ref={chartContainerRef}>
        <XYPlot
          xType="time"
          width={chartWidth}
          height={chartHeight}
          animation={true}
          yDomain={[0, 100]}
          margin={{ bottom: 80, left: 60, right: 45, top: 20 }}
          onDoubleClick={() => setSelectedSeries("")}
        >
          <HorizontalGridLines />
          <VerticalGridLines />
          <XAxis
            position="middle"
            height={200}
            tickLabelAngle={chartWidth < 850 ? -25 : 0}
            tickTotal={chartData[0].data.length}
            tickFormat={(d) => {
              const date = new Date(d);
              return date.toLocaleString("default", {
                month: "short",
                year: "numeric",
              });
            }}
            tickValues={getTickLabels()}
            tickSizeOuter={1}
            tickPadding={20}
            style={{
              line: {
                stroke: palette.font.body,
                strokeWidth: 2,
              },
              ticks: {
                stroke: palette.font.body,
                strokeWidth: 2,
              },
              text: {
                stroke: "none",
                fill: palette.font.caption,
                fontWeight: 600,
              },
            }}
          />
          <YAxis
            position="middle"
            tickTotal={10}
            tickSizeOuter={0}
            tickSizeInner={chartWidth - 105}
            tickPadding={10}
            tickValues={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            style={{
              line: {
                stroke: palette.disabled,
                strokeWidth: 2,
              },
              ticks: {
                strokeWidth: 2,
                strokeDasharray: 10,
              },
              text: {
                stroke: "none",
                fill: palette.font.caption,
                fontWeight: 600,
              },
            }}
          />
          <ChartLabel
            text="Month"
            includeMargin={false}
            xPercent={0.55}
            yPercent={1}
            style={{
              textAnchor: "middle",
              transform: "translate(0, 80)",
              fontSize: 16,
              fontWeight: 700,
              fill: palette.font.body,
            }}
          />

          <ChartLabel
            text="Total Completion (%)"
            includeMargin={false}
            xPercent={0}
            yPercent={0.525}
            style={{
              textAnchor: "middle",
              transform: "translate(-45, 0) rotate(-90)",
              fontSize: 16,
              fontWeight: 700,
              fill: palette.font.body,
            }}
          />
          {getLineSeries()}
        </XYPlot>
      </StyledChartContainer>
      <ProgressChartSideBar
        keyData={getKeyData()}
        {...{ selectGroups, selectedGroup, handleSelectGroup, reportChart }}
      />
    </StyledContainer>
  );
};

export default ProgressChart;
