import { axiosInstance } from "helpers/axios-instance";

export const getConversation = (params: { evidenceId: string }) =>
  axiosInstance.get("/meta-mirror/conversation", { params });

export type SendMessageParams = {
  evidenceId: string;
  grade?: string;
  message: string;
  studentId: string;
  teacherId?: string;
  wheelId: string;
};

export const sendMessage = (params: SendMessageParams) =>
  axiosInstance.put("/meta-mirror/conversation", params);

export type EditMessageParams = {
  _id: string;
  isLegacyMessage?: boolean;
  message?: string;
  viewed?: boolean;
};

export const editMessage = ({
  _id,
  isLegacyMessage,
  message,
  viewed,
}: EditMessageParams) => {
  const params: any = { viewed };
  params[isLegacyMessage ? "feedbackId" : "conversationId"] = _id;
  params[isLegacyMessage ? "comment" : "message"] = message;
  return axiosInstance.patch(
    `/meta-mirror/${isLegacyMessage ? "feedback" : "conversation"}`,
    params
  );
};
