import React, { Component } from "react";
import PageContainer from "components/common/PageContainer/PageContainer";
import PageHeading from "components/common/PageHeading/PageHeading";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import UserAssign from "components/admin/UserAssign";
import GiveAccess from "components/admin/GiveAccess";
import LoginAs from "components/admin/LoginAs";
import styled from "styled-components";
import { ISchoolWithGroupListData } from "typings/school";
import { RouteComponentProps } from "react-router";
import { IBreadCrumb } from "typings/main";
import {
  IDetailedStudentData,
  IWheelDataWithFeedbackEvidence,
} from "typings/student";
import StudentWheelItem from "components/admin/StudentWheelItem";
import { IResponseBody } from "typings/global";
import { getDetailedStudent, getStudent, updateStudent } from "lib/student";
import { getSchoolsWithClasses } from "lib/school";
import { LoginAsOther } from "lib/auth";
import { withAuth } from "../../../hoc/withAuth";

const StyledLoaderContainer = styled.div`
  width: 100%;
  padding: 100px 0;
  text-align: center;
`;

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 30px 0;
`;

const StyledWheelsSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

interface IState {
  isLoading: boolean;
  student: IDetailedStudentData;
  schools: ISchoolWithGroupListData[];
  wheels: IWheelDataWithFeedbackEvidence[];
}

class DetailedStudentPage extends Component<
  RouteComponentProps<{ studentId: string }>,
  IState
> {
  state = {
    isLoading: true,
    student: {
      _id: "",
      userId: "",
      name: "",
      schoolId: "",
      groupIds: [""],
      lockedGroupIds: [""],
      haveAccess: false,
      yearGroup: "",
    },
    wheels: [],
    schools: [],
  };

  componentDidMount(): void {
    this.getData();
  }

  protected getData = async (): Promise<void> => {
    this.setState({ isLoading: true });
    await this.getDetailedStudentsData();
    await this.getSchoolsWithClasses();
    this.setState({ isLoading: false });
  };

  protected getDetailedStudentsData = async (): Promise<void> => {
    const { studentId } = this.props.match.params;
    try {
      const result = await getDetailedStudent(studentId);
      const { wheels, ...student } = result.data;
      this.setState({
        student,
        wheels,
      });
    } catch {}
  };

  protected getSchoolsWithClasses = async (): Promise<void> => {
    const result = await getSchoolsWithClasses();
    this.setState({ schools: result?.data || [] });
  };

  protected handleLoginAs = async (): Promise<void> => {
    if (confirm("Are you sure?")) {
      try {
        const { data } = await getStudent(this.state.student._id);
        await LoginAsOther(data.user.id.email, data.user.id._id);
        window.location.href = "/student";
      } catch {}
    }
  };

  protected handleUpdateDetails = async (
    schoolId: string,
    groupIds: string[],
    mmLockedGroupIds: string[]
  ): Promise<IResponseBody> => {
    const { student } = this.state;

    try {
      const { data } = await updateStudent(
        student._id,
        schoolId,
        groupIds,
        mmLockedGroupIds
      );
      student.schoolId = schoolId;
      student.groupIds = groupIds;
      this.setState({ student });
      await this.getDetailedStudentsData();
      return data;
    } catch {
      return {};
    }
  };

  render() {
    const { student, isLoading, schools, wheels } = this.state;
    const { name, haveAccess, userId, schoolId, groupIds, lockedGroupIds } =
      student;

    const breadCrumbs: IBreadCrumb[] = [
      {
        url: "/admin/students",
        name: "Students",
      },
      {
        url: "",
        name,
      },
    ];

    return (
      <PageContainer>
        <PageHeading title={name} breadCrumbs={breadCrumbs} />
        {isLoading ? (
          <StyledLoaderContainer>
            <CircularProgress />
            <Typography variant="body2" className="mt-3">
              Loading...
            </Typography>
          </StyledLoaderContainer>
        ) : (
          <>
            <StyledContentContainer>
              <Grid container direction="row" spacing={4}>
                <Grid item xs={12} lg={6}>
                  {haveAccess ? (
                    <UserAssign
                      handleUpdateDetails={this.handleUpdateDetails}
                      schoolId={schoolId}
                      groupsIds={groupIds}
                      lockedGroupIds={lockedGroupIds}
                      schoolList={schools}
                    />
                  ) : (
                    <GiveAccess
                      userName={name}
                      userId={userId}
                      onFinish={this.getDetailedStudentsData}
                    />
                  )}
                </Grid>
                <Grid item xs={12} lg={6}>
                  <LoginAs
                    userName={name}
                    handleLogin={this.handleLoginAs}
                    disabled={!haveAccess}
                  />
                </Grid>
              </Grid>
            </StyledContentContainer>
            <StyledWheelsSection>
              {wheels.map((wheel: IWheelDataWithFeedbackEvidence) => (
                <StudentWheelItem key={wheel._id} {...wheel} />
              ))}
            </StyledWheelsSection>
          </>
        )}
      </PageContainer>
    );
  }
}

export default withAuth(DetailedStudentPage, ["admin"]);
