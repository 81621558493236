import React, { useEffect, useState } from "react";
import AnimatedModal from "components/common/AnimatedModal";
import { TOption } from "components/common/Select/Select";
import GenerateReportFirstStep from "./GenerateReportFirstStep";
import GenerateReportSecondsStep from "./GenerateReportSecondStep";
import { getStudentListBasedOnGroup } from "lib/group";

const FIRST_STEP_MODAL_WIDTH = 800;
const SECOND_STEP_MODAL_WIDTH = 1250;

const FIRST_STEP_MODAL_HEIGHT = "auto";
const SECOND_STEP_MODAL_HEIGHT = "950px";

const INITIAL_SELECTED_REPORT_TYPE = "historical";

const INITIAL_MODAL_SELECTED_OPTIONS: IGenerateReportSelectedOptions = {
  reportType: INITIAL_SELECTED_REPORT_TYPE,
  studentGroups: [],
  startDate: new Date(),
  comparisonGroup: "",
};

export type TReportType = "historical" | "group";

export interface IGenerateReportSelectedOptions {
  reportType: TReportType;
  studentGroups: string[] | string;
  startDate: Date | null;
  comparisonGroup: string;
}

interface IProps {
  isOpen: boolean;
  wheelId: string;
  wheelGroups: TOption[];
  wheelCreateDate: string;
  studentId?: string;
  isBulk?: boolean;
  onClose: () => void;
  wheelLevelType?: string;
}

const GenerateReportModal: React.FC<IProps> = ({
  isOpen,
  wheelId,
  wheelGroups = [],
  wheelCreateDate,
  studentId,
  isBulk,
  onClose,
  wheelLevelType,
}: IProps) => {
  const [studentIds, setStudentIds] = useState<string[]>([]);

  const [modalStep, setModalStep] = useState<1 | 2>(1);

  const [selectedOptions, setSelectedOptions] =
    useState<IGenerateReportSelectedOptions>(INITIAL_MODAL_SELECTED_OPTIONS);

  useEffect(() => {
    if (isOpen && wheelGroups.length >= 1) {
      setSelectedOptions({
        ...selectedOptions,
        studentGroups:
          wheelGroups.length === 1
            ? [wheelGroups[0]?.value as string]
            : isBulk
            ? []
            : "",
        comparisonGroup:
          wheelGroups.length === 1 ? (wheelGroups[0]?.value as string) : "",
      });
    }
  }, [wheelGroups]);

  const resetModalState = (): void => {
    setSelectedOptions(INITIAL_MODAL_SELECTED_OPTIONS);
    setModalStep(1);
  };

  const handleClose = (): void => {
    onClose();

    setTimeout(() => {
      resetModalState();
    }, 700);
  };

  const handleSelectOption = (
    selectedOption: Partial<IGenerateReportSelectedOptions>
  ): void => {
    if (
      selectedOptions.reportType === "historical" &&
      selectedOption.reportType === "group"
    ) {
      setSelectedOptions({
        ...selectedOptions,
        studentGroups: "",
      });
    }

    setSelectedOptions({ ...selectedOptions, ...selectedOption });
  };

  const handleGenerateReport = async (): Promise<void> => {
    if (isBulk) {
      try {
        const result = await getStudentListBasedOnGroup(
          selectedOptions.studentGroups as string[]
        );
        setStudentIds(result.data);
        setModalStep(2);
      } catch {}
      return;
    }

    setModalStep(2);
  };

  return (
    <AnimatedModal
      open={isOpen}
      handleClose={() => handleClose()}
      title="Generate Report"
      maxWidth={`${
        modalStep === 1 ? FIRST_STEP_MODAL_WIDTH : SECOND_STEP_MODAL_WIDTH
      }px`}
      maxHeight={
        modalStep === 1 ? FIRST_STEP_MODAL_HEIGHT : SECOND_STEP_MODAL_HEIGHT
      }
      width="100%"
      height={modalStep === 1 ? "auto" : "100%"}
    >
      <>
        {modalStep === 1 && (
          <GenerateReportFirstStep
            selectedOptions={selectedOptions}
            wheelGroups={wheelGroups}
            wheelCreateDate={wheelCreateDate}
            handleClose={handleClose}
            handleGenerate={handleGenerateReport}
            handleSelectOption={handleSelectOption}
            isBulk={isBulk}
          />
        )}
        {modalStep === 2 && (
          <GenerateReportSecondsStep
            wheelLevelType={wheelLevelType}
            wheelId={wheelId}
            selectedOptions={selectedOptions}
            studentId={studentId}
            studentIds={studentIds}
            isBulk={isBulk}
          />
        )}
      </>
    </AnimatedModal>
  );
};

export default GenerateReportModal;
