import React from "react";

import { useFeedback } from "./context";
import { Message as MessageType } from "./types";
import { Msg } from "./styles";
import { ClearRounded, EditRounded } from "@material-ui/icons";

export const Message = ({
  _id,
  createdAt,
  message,
  student,
  teacher,
}: MessageType) => {
  const { editing, handleEdit, isTeacher } = useFeedback();

  const isSender = React.useMemo(
    () => (isTeacher ? !!teacher : !teacher && !!student),
    [isTeacher, student, teacher]
  );

  return (
    <Msg isSender={isSender}>
      <Msg.Container isSender={isSender} style={{ position: "relative" }}>
        {isSender && (
          <Msg.Edit
            onClick={() => handleEdit(_id)}
            size="small"
            style={{ position: "absolute" }}
          >
            {editing?._id === _id ? (
              <ClearRounded style={{ width: 17, height: 17, color: "white" }} />
            ) : (
              <EditRounded style={{ width: 17, height: 17, color: "white" }} />
            )}
          </Msg.Edit>
        )}
        <Msg.Name isSender={isSender}>{teacher ?? student}</Msg.Name>
        <Msg.Content>{message}</Msg.Content>
        <Msg.Timestamp>{new Date(createdAt).toLocaleString()}</Msg.Timestamp>
      </Msg.Container>
    </Msg>
  );
};
