import React from "react";

import { EvidenceListProvider } from "./context";
import { EvidenceListHeader } from "./header";
import { Modals } from "./modals";
import { List } from "./styles";
import { EvidenceListProps } from "./types";
import { Scroller } from "./scroller";

export const EvidenceList = (props: EvidenceListProps) => (
  <EvidenceListProvider {...props}>
    <List>
      <EvidenceListHeader />
      <Scroller />
      <Modals />
    </List>
  </EvidenceListProvider>
);
