/* eslint-disable @typescript-eslint/indent */
import { Snackbar, Typography } from "@material-ui/core";
import { CloseRounded, EditRounded } from "@material-ui/icons";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Alert } from "reactstrap";

import { validateEvidence } from "lib/mirror";

import { useWheelsPage } from "../../context";
import { useProfile } from "../context";
import { decodeParsedUrl, formatSummary } from "../helper";
import { Sum } from "../styles";
import { Editor } from "./editor";

export const Summary = () => {
  const [error, setError] = React.useState<string>();
  const { isToken, isUser } = useWheelsPage();
  const { editing, setEditing, summary } = useProfile();

  const { params } = useRouteMatch<any>();
  const text = React.useMemo(
    () =>
      formatSummary({
        type: isToken ? "token" : isUser ? "student" : "teacher",
        summary,
        token: params.token,
      }),
    [summary]
  );

  const handleClick = async (e: any) => {
    const href = e.target.getAttribute("href");
    const type = e.target.innerText;
    if (href && ["area", "evidence", "mirror"].includes(type)) {
      e.preventDefault();
      //  Verifiy evidence/area/mirror exists.
      const { data } = await validateEvidence(
        decodeParsedUrl({
          href,
          isToken,
          isUser,
          studentId: e.target.getAttribute("data-studentId"),
          type,
        })
      );
      if (!data?.valid) {
        return setError("Error: This evidence seems to have been removed.");
      }
      window.location = href;
    }
  };

  return (
    <>
      <Sum>
        <Sum.Title>
          <i
            className="uil uil-book-open"
            style={{ color: "#007bff", marginRight: ".25em" }}
          />
          My Summary
        </Sum.Title>
        {isUser && (
          <Sum.EditButton onClick={() => setEditing(!editing)}>
            {editing ? <CloseRounded /> : <EditRounded />}
          </Sum.EditButton>
        )}
        {editing ? (
          <>
            <Typography component="div" variant="caption">
              To link your evidence enter @ followed by the mirror, area, or
              evidence file name.
            </Typography>
            <Typography component="div" variant="caption">
              To link an area enter # followed by the mirror or area name.
            </Typography>
            <Typography
              component="div"
              style={{ marginBottom: 10 }}
              variant="caption"
            >
              To link a mirror enter / followed by the mirror name.
            </Typography>
            <Editor />
          </>
        ) : (
          <Sum.Content
            dangerouslySetInnerHTML={{
              __html:
                text.length > 0
                  ? text
                  : isUser
                  ? "Add a personal summary here when you’re ready"
                  : "This user has not added a summary yet.",
            }}
            onClick={handleClick}
          />
        )}
      </Sum>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={() => setError(undefined)}
        open={!!error}
      >
        {alert && (
          <Alert
            color="danger"
            isOpen={!!alert}
            closeClassName="uil uil-times"
            toggle={() => setError(undefined)}
          >
            {error}
          </Alert>
        )}
      </Snackbar>
    </>
  );
};
