import React, { Component } from "react";
import PageContainer from "components/common/PageContainer/PageContainer";
import styled from "styled-components";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import PageHeading from "components/common/PageHeading/PageHeading";
import { RouteComponentProps } from "react-router";
import { IDetailedTeacherData } from "typings/teacher";
import LoginAs from "components/admin/LoginAs";
import GiveAccess from "components/admin/GiveAccess";
import { IBreadCrumb } from "typings/main";
import UserAssign from "components/admin/UserAssign";
import { ISchoolWithGroupListData } from "typings/school";
import { IResponseBody } from "typings/global";
import { getTeacher, hasAccess, updateTeacher } from "lib/teacher";
import { getSchoolsWithClasses } from "lib/school";
import { LoginAsOther } from "lib/auth";
import { withAuth } from "../../../hoc/withAuth";

const StyledLoaderContainer = styled.div`
  width: 100%;
  padding: 100px 0;
  text-align: center;
`;

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 30px 0;
`;

interface IState {
  isLoading: boolean;
  teacher: IDetailedTeacherData;
  schools: ISchoolWithGroupListData[];
}

class DetailedTeacherPage extends Component<
  RouteComponentProps<{ teacherId: string }>,
  IState
> {
  state = {
    isLoading: true,
    schools: [],
    teacher: {
      _id: "",
      name: "",
      schoolId: "",
      groupIds: [],
      haveAccess: false,
      userId: "",
    },
  };

  componentDidMount(): void {
    this.getDetailedTeacherData();
    this.getSchoolsWithClasses();
  }

  protected getDetailedTeacherData = async (): Promise<void> => {
    this.setState({
      isLoading: true,
    });
    const { teacherId } = this.props.match.params;

    const { data } = await getTeacher(teacherId);
    const teacherData = {
      ...data,
      haveAccess: hasAccess(data, "meta-mirror"),
      schoolId: data?.mm_school_id?._id,
      groupIds: data?.mm_group_ids,
    };

    this.setState({
      isLoading: false,
      teacher: teacherData,
    });
  };

  protected getSchoolsWithClasses = async (): Promise<void> => {
    this.setState({
      isLoading: true,
    });

    const result = await getSchoolsWithClasses();

    this.setState({
      isLoading: false,
      schools: result?.data || [],
    });
  };

  protected handleLoginAs = async (): Promise<void> => {
    if (confirm("Are you sure?")) {
      const { data } = await getTeacher(this.state.teacher._id);
      const valid = await LoginAsOther(data.user.id.email, data.user.id._id);
      if (valid) {
        window.location.href = "/teacher";
      }
    }
  };

  protected handleUpdateDetails = async (
    schoolId: string,
    groupIds: string[]
  ): Promise<IResponseBody> => {
    const { teacher } = this.state;

    try {
      const { data } = await updateTeacher(teacher._id, schoolId, groupIds);
      teacher.schoolId = schoolId;
      teacher.groupIds = groupIds;
      this.setState({ teacher });
      return data;
    } catch {
      return {};
    }
  };

  render() {
    const { teacher, isLoading, schools } = this.state;
    const { name, haveAccess, userId, schoolId, groupIds } = teacher;

    const breadCrumbs: IBreadCrumb[] = [
      {
        url: "/admin/teachers",
        name: "Teachers",
      },
      {
        url: "",
        name,
      },
    ];

    return (
      <PageContainer>
        <PageHeading title={name} breadCrumbs={breadCrumbs} />
        {isLoading ? (
          <StyledLoaderContainer>
            <CircularProgress />
            <Typography variant="body2" className="mt-3">
              Loading...
            </Typography>
          </StyledLoaderContainer>
        ) : (
          <StyledContentContainer>
            <Grid container direction="row" spacing={4}>
              <Grid item xs={12} lg={6}>
                {haveAccess ? (
                  <UserAssign
                    handleUpdateDetails={this.handleUpdateDetails}
                    schoolId={schoolId}
                    groupsIds={groupIds}
                    schoolList={schools}
                  />
                ) : (
                  <GiveAccess
                    userName={name}
                    userId={userId}
                    onFinish={this.getDetailedTeacherData}
                  />
                )}
              </Grid>
              <Grid item xs={12} lg={6}>
                <LoginAs
                  userName={name}
                  handleLogin={this.handleLoginAs}
                  disabled={!haveAccess}
                />
              </Grid>
            </Grid>
          </StyledContentContainer>
        )}
      </PageContainer>
    );
  }
}

export default withAuth(DetailedTeacherPage, ["admin"]);
