import { Typography } from "@material-ui/core";
import React from "react";

import { LEVEL_PARTS } from "constants/index";
import { IEvidenceWithFeedback } from "typings/student";

import GradeIcon from "../../GradeIcon";
import { useEvidenceList } from "../context";
import { useEvidence } from "./context";
import { ItemInfo } from "./styles";

type GradePillProps = {
  grade: IEvidenceWithFeedback["studentGrade" | "teacherGrade"];
};
export const Info = () => {
  const { area, studentGrade, teacherGrade, type } = useEvidence();
  const { mirrorLevelType } = useEvidenceList();

  const GradePill = ({ grade }: GradePillProps) => {
    if (grade?.level == undefined || grade?.result == undefined)
      return <Typography variant="caption">No grade available.</Typography>;
    const { level, result } = grade;
    const grading =
      mirrorLevelType === "sequential"
        ? `Level ${level}`
        : `Part ${LEVEL_PARTS[Number(level) - 1]}`;
    return <GradeIcon text={grading} grade={result} small />;
  };

  return (
    <ItemInfo>
      <ItemInfo.Row>
        <ItemInfo.Text>Area:</ItemInfo.Text>
        <Typography variant="body1">{area}</Typography>
      </ItemInfo.Row>
      <ItemInfo.Row>
        <ItemInfo.Text>Student Grade:</ItemInfo.Text>
        <GradePill grade={studentGrade} />
      </ItemInfo.Row>
      <ItemInfo.Row>
        <ItemInfo.Text>Teacher Grade:</ItemInfo.Text>
        <GradePill grade={teacherGrade} />
      </ItemInfo.Row>
    </ItemInfo>
  );
};
