import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Wheel from "components/common/Wheel/Wheel";
import Select, { TOption } from "components/common/Select/Select";
import palette from "palette";
import {
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { WheelsPageContext } from "../../states/WheelsPageContext";
import DatePicker from "components/common/DatePicker/DatePicker";
import { TWheelData } from "typings/meta-mirror";
import { getWheelComparisonData } from "lib/mirror";
import MmWheelAverageService from "lib/wheelAverage";

const StyledViewContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

const StyledWheelSection = styled.div`
  width: 100%;
  height: 41.5vh;
`;

const StyledCenterContainer = styled.div`
  width: 100%;
  height: 41.5vh;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
`;

const StyledWheelLoadingContainer = styled(StyledCenterContainer)`
  height: calc(41.5vh - 52px);
`;

const StyledLoaderContainer = styled.div`
  height: 90px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledSelectContainer = styled.div`
  width: 50%;
  padding: 0 5px 0 clamp(10px, 5%, 20px);
  margin-top: 10px;
  &:not(:first-child) {
    padding: 0 clamp(10px, 5%, 20px) 0 5px;
  }
`;

const StyledAddIcon = styled.i`
  font-size: 40pt;
  width: 61px;
  height: 61px;
  line-height: 64px;
`;

const StyledAddCompareWheelText = styled(Typography)`
  font-weight: 700 !important;
  color: ${palette.secondary} !important;
  margin: 0 0 5px !important;
`;

const WHEEL_INITIAL_SIZE = 300;

const preparedGroupOptions = [{ value: "all-students", label: "All Students" }];

interface IProps {
  wheelId: string;
  active: boolean;
  disabled?: boolean;
  levelType?: string;
  handleActivate?(): void;
  handleSetPrintData(
    wheelData: TWheelData,
    selectedGroup: string,
    selectedData: string
  );
}

const ComparisonWheel: React.FC<IProps> = ({
  wheelId,
  active,
  disabled,
  levelType,
  handleActivate,
  handleSetPrintData,
}: IProps) => {
  const { groups, wheelResults, handleGetSelectedWheelData, isLoading } =
    useContext(WheelsPageContext);

  const wheelBoxRef = useRef<HTMLDivElement>(null);
  const [wheelWidth, setWheelWidth] = useState(WHEEL_INITIAL_SIZE);
  const [wheelHeight, setWheelHeight] = useState(WHEEL_INITIAL_SIZE);

  const [loading, setLoading] = useState(false);
  const [selectOptions, setSelectOptions] = useState<TOption[]>();
  const [selectedGroup, setSelectedGroup] = useState("all-students");
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const [wheelData, setWheelData] = useState<TWheelData>();

  const setPrintData = (
    printWheelData: TWheelData,
    group: string,
    date: Date
  ): void => {
    let groupName = "All Students";
    if (group !== "all-students") {
      groupName = groups.find((findGroup) => findGroup._id === group).name;
    }
    handleSetPrintData(
      printWheelData,
      groupName,
      date.toLocaleString("default", { month: "long", year: "numeric" })
    );
  };

  const getData = async (group: string, date: Date): Promise<void> => {
    setLoading(true);
    const response = await getWheelComparisonData(
      wheelId,
      group,
      date.getTime().toString()
    );
    const avgWheelData =
      await MmWheelAverageService.getAverageWheelScoreBasedOnStudentsResults(
        response.data.studentsResults,
        levelType ?? "sequential"
      );
    setWheelData(avgWheelData);
    setPrintData(avgWheelData, group, date);
    setLoading(false);
  };

  const getSelectOptions = (): TOption[] => {
    const filteredGroups = groups.filter((group) =>
      wheelResults.groupIds.includes(group._id)
    );
    const options = filteredGroups.map((group) => ({
      value: group._id,
      label: group.name,
    }));
    if (options.length <= 1) {
      if (selectedGroup !== options[0].value) {
        setSelectedGroup(options[0].value);
      }
      return options;
    }
    options.unshift(...preparedGroupOptions);
    return options;
  };

  const handleGroupChange = (group: string): void => {
    setSelectedGroup(group);
    getData(group, selectedDate);
  };

  const handleDateChange = (date: Date): void => {
    setSelectedDate(date);
    getData(selectedGroup, date);
  };

  useEffect(() => {
    if (!wheelResults || !groups || !groups.length) {
      handleGetSelectedWheelData(wheelId);
      return;
    }
    if (!selectOptions) {
      setSelectOptions(getSelectOptions());
      return;
    }
    if (!wheelData && !loading) {
      getData(selectedGroup, selectedDate);
    }

    const handleResize = () => {
      const newWidth = wheelBoxRef?.current.offsetWidth || WHEEL_INITIAL_SIZE;
      let newHeight =
        wheelBoxRef?.current.offsetHeight - 52 || WHEEL_INITIAL_SIZE;
      if (newHeight > window.innerHeight * 0.5) {
        newHeight = window.innerHeight * 0.5;
      }
      if (newHeight < 250) {
        newHeight = 250;
      }
      setWheelWidth(newWidth);
      setWheelHeight(newHeight);
    };
    if (wheelWidth === WHEEL_INITIAL_SIZE) {
      handleResize();
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [groups, wheelResults, selectOptions, isLoading]);

  const getWheelVerticalPadding = (): number => {
    if (wheelWidth < 350) {
      return wheelHeight * 0.2;
    }
    if (wheelWidth < 450) {
      return wheelHeight * 0.15;
    }
    if (wheelWidth < 550) {
      return wheelHeight * 0.1;
    }
    if (wheelWidth < 650) {
      return wheelHeight * 0.05;
    }
    return 0;
  };

  return (
    <StyledViewContainer>
      <StyledWheelSection ref={wheelBoxRef}>
        {!disabled && (
          <>
            {active ? (
              isLoading || !wheelData ? (
                <StyledCenterContainer>
                  <StyledLoaderContainer>
                    <CircularProgress size={32} />
                  </StyledLoaderContainer>
                </StyledCenterContainer>
              ) : (
                <>
                  {loading || !wheelData ? (
                    <StyledWheelLoadingContainer>
                      <CircularProgress size={32} />
                    </StyledWheelLoadingContainer>
                  ) : (
                    <Wheel
                      data={wheelData}
                      backgroundColor={palette.surface}
                      width={wheelWidth}
                      height={wheelHeight}
                      labels
                      interactive={false}
                      verticalPadding={getWheelVerticalPadding()}
                      mirrorLevelType={levelType}
                    />
                  )}
                  <StyledOptionsContainer>
                    <StyledSelectContainer>
                      {selectOptions.length <= 1 ? (
                        <Tooltip
                          title="Only one group is available for this wheel."
                          placement="top"
                        >
                          <div className="w-100">
                            <Select
                              value={selectedGroup}
                              label="Student Group"
                              options={selectOptions}
                              className="mr-2"
                              disabled
                              fullWidth
                            />
                          </div>
                        </Tooltip>
                      ) : (
                        <Select
                          onChange={(event) =>
                            handleGroupChange(event.target.value as string)
                          }
                          value={selectedGroup}
                          label="Student Group"
                          options={selectOptions}
                          fullWidth
                        />
                      )}
                    </StyledSelectContainer>
                    <StyledSelectContainer>
                      <DatePicker
                        label="Date"
                        minDate={new Date(wheelResults.createdAt)}
                        maxDate={new Date()}
                        handleSetValue={handleDateChange}
                      />
                    </StyledSelectContainer>
                  </StyledOptionsContainer>
                </>
              )
            ) : (
              <StyledCenterContainer>
                <StyledAddCompareWheelText variant="body2">
                  Compare another wheel
                </StyledAddCompareWheelText>
                <IconButton color="secondary" onClick={handleActivate}>
                  <StyledAddIcon className="uil uil-plus-circle" />
                </IconButton>
              </StyledCenterContainer>
            )}
          </>
        )}
      </StyledWheelSection>
    </StyledViewContainer>
  );
};

export default ComparisonWheel;
