import { Button, CircularProgress } from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { TWheelData } from "typings/meta-mirror";
import { ISeriesData } from "components/common/ProgressChart/ProgressChart";
import palette from "palette";
import { IGroupData } from "typings/group";
import { WheelsPageContext } from "../../../states/WheelsPageContext";
import GenerateReportAction from "./GenerateReportAction";
import { IGenerateReportSelectedOptions } from "./GenerateReportModal";
import GroupReport from "./GroupReport";
import HistoricalReport from "./HistoricalReport";
import {
  getBlankWheel,
  getSingleWheelDetailsForStudentId,
  getWheelComparisonData,
  getWheelGroupProgressData,
  getWheelStudentProgressData,
} from "lib/mirror";
import { IWithAuth, withAuth } from "../../../hoc/withAuth";
import MmProgressChartService from "lib/progressChart";
import MmWheelAverageService from "lib/wheelAverage";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledContentSection = styled.div<{ isBulk?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: ${(props) => (props.isBulk ? "calc(100% - 60px)" : "100%")};
`;

const StyledButtonSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin: 20px 0 0;
`;

const StyledActionSection = styled.div`
  width: 300px;
  flex-shrink: 0;
  height: 100%;
  margin: 0 25px 0 0;
`;

const StyledReportSection = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: ${palette.background};
  padding: 25px;
  overflow: auto;
`;

const StyledPrintPageContainer = styled.div`
  background-color: #ffffff;
  position: relative;
  width: 815px;
  height: 1075.8px;
  @media print {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`;

interface IProps extends IWithAuth {
  wheelId: string;
  selectedOptions: IGenerateReportSelectedOptions;
  studentId?: string;
  studentIds?: string[];
  isBulk?: boolean;
  wheelLevelType?: string;
}

const GenerateReportSecondStep: React.FC<IProps> = ({
  wheelId,
  selectedOptions,
  studentId,
  studentIds,
  isBulk,
  user,
  wheelLevelType,
}: IProps) => {
  const { groups } = useContext(WheelsPageContext);

  const [isLoading, setIsLoading] = useState(true);

  const [actualWheelResult, setActualWheelResult] = useState<TWheelData>([]);
  const [pastWheelResult, setPastWheelResult] = useState<TWheelData>([]);
  const [groupWheelResults, setGroupWheelResults] = useState<TWheelData>([]);

  const [wheelProgress, setWheelProgress] = useState<ISeriesData[]>([]);
  const [groupWheelProgress, setGroupWheelProgress] = useState<ISeriesData[]>(
    []
  );

  const [wheelName, setWheelName] = useState("");
  const [studentName, setStudentName] = useState("");
  const [groupName, setGroupName] = useState("");
  const [comment, setComment] = useState("");
  const [viewingStudent, setViewingStudent] = useState(0);

  const printRef = useRef<HTMLDivElement>(null);

  const getStudentId = (): string => {
    if (isBulk) {
      return studentIds[viewingStudent] || "";
    }

    return studentId;
  };

  const getGroupId = (): string => {
    if (isBulk) {
      return selectedOptions.comparisonGroup;
    }

    return selectedOptions.studentGroups as string;
  };

  const getHistoricalReportData = async (): Promise<void> => {
    setIsLoading(true);
    const studentId = getStudentId()._id || getStudentId();
    const teacherId = user.roles?.teacher.id._id;
    try {
      const actualWheelResponseData = await getSingleWheelDetailsForStudentId(
        wheelId,
        studentId,
        teacherId
      );
      setActualWheelResult(actualWheelResponseData.data.data.wheelData);
      setWheelName(actualWheelResponseData.data.data.name);
      setStudentName(actualWheelResponseData.data.data.studentName);
    } catch {}

    try {
      const pastWheelResponseData = await getSingleWheelDetailsForStudentId(
        wheelId,
        studentId,
        teacherId,
        selectedOptions.startDate.getTime().toString()
      );
      setPastWheelResult(pastWheelResponseData.data.data.wheelData);
    } catch {}

    try {
      const progressChartResponseData = await getWheelStudentProgressData(
        wheelId,
        studentId
      );

      const preparedChartData = MmProgressChartService.getProgressChartData(
        progressChartResponseData.data,
        wheelLevelType ?? "sequential"
      );

      setWheelProgress(preparedChartData);
    } catch {}

    setIsLoading(false);
  };

  const getGroupReportData = async (): Promise<void> => {
    setIsLoading(true);

    const studentId = getStudentId()._id || getStudentId();

    try {
      const actualWheelResponseData = await getSingleWheelDetailsForStudentId(
        wheelId,
        studentId
      );

      setActualWheelResult(actualWheelResponseData.data.data.wheelData);
      setWheelName(actualWheelResponseData.data.data.name);
      setStudentName(actualWheelResponseData.data.data.studentName);
    } catch {}

    try {
      const actualGroupResponseData = await getWheelComparisonData(
        wheelId,
        getGroupId(),
        new Date().getTime().toString()
      );

      let preparedStudentsResults =
        actualGroupResponseData.data.studentsResults;
      if (!preparedStudentsResults.length) {
        const blankWheelResults = await getBlankWheel(wheelId);

        preparedStudentsResults = [
          {
            lastLogin: "",
            name: "",
            sex: "",
            _id: "",
            areas: blankWheelResults.data.wheelData,
            groupIds: [""],
          },
        ];
      }

      const preparedAverageData =
        MmWheelAverageService.getAverageWheelScoreBasedOnStudentsResults(
          preparedStudentsResults,
          wheelLevelType ?? "sequential"
        );
      setGroupWheelResults(preparedAverageData);
    } catch {}

    try {
      const progressChartResponseData = await getWheelStudentProgressData(
        wheelId,
        studentId
      );
      const preparedChartData = MmProgressChartService.getProgressChartData(
        progressChartResponseData.data,
        wheelLevelType ?? "sequential"
      );
      setWheelProgress(preparedChartData);
    } catch {}

    try {
      const groupProgressChartResponseData = await getWheelGroupProgressData(
        wheelId,
        getGroupId()
      );

      const preparedGroupChartData =
        MmProgressChartService.getProgressChartData(
          groupProgressChartResponseData.data,
          wheelLevelType ?? "sequential"
        );
      setGroupWheelProgress(preparedGroupChartData);
    } catch {}

    setIsLoading(false);
  };

  const resetData = (): void => {
    setActualWheelResult([]);
    setPastWheelResult([]);
    setGroupWheelResults([]);
    setWheelProgress([]);
    setGroupWheelProgress([]);
  };

  const getReportData = (): void => {
    const { reportType, comparisonGroup, studentGroups } = selectedOptions;

    if (selectedOptions) {
      resetData();
      if (reportType === "historical") {
        getHistoricalReportData();
      }

      if (reportType === "group") {
        getGroupReportData();

        const [selectedGroup] = groups.filter(
          ({ _id }: IGroupData) => _id === (comparisonGroup || studentGroups)
        );

        if (selectedGroup) {
          setGroupName(selectedGroup.name);
        }
      }
    }
  };

  useEffect(() => {
    getReportData();
  }, [viewingStudent]);

  return (
    <StyledContainer>
      <StyledContentSection isBulk={isBulk}>
        <StyledActionSection>
          <GenerateReportAction
            printRef={printRef}
            comment={comment}
            handleUpdateComment={(newComment: string) => setComment(newComment)}
          />
        </StyledActionSection>
        <StyledReportSection>
          {isLoading ? (
            <div className="d-flex w-100 h-100 align-items-center justify-content-center">
              <CircularProgress />
            </div>
          ) : (
            <StyledPrintPageContainer ref={printRef}>
              {selectedOptions.reportType === "historical" && (
                <HistoricalReport
                  actualWheelResult={actualWheelResult}
                  selectedDateWheelResult={pastWheelResult}
                  progressChartResults={wheelProgress}
                  startDate={selectedOptions.startDate}
                  studentName={studentName}
                  wheelName={wheelName}
                  reportComment={comment}
                  wheelLevelType={wheelLevelType}
                />
              )}
              {selectedOptions.reportType === "group" && (
                <GroupReport
                  wheelName={wheelName}
                  studentName={studentName}
                  reportComment={comment}
                  groupName={groupName}
                  actualWheelResults={actualWheelResult}
                  groupWheelResults={groupWheelResults}
                  progressChartResults={wheelProgress}
                  groupProgressChartResult={groupWheelProgress}
                />
              )}
            </StyledPrintPageContainer>
          )}
        </StyledReportSection>
      </StyledContentSection>
      {isBulk && (
        <StyledButtonSection>
          <Button
            variant="outlined"
            color="primary"
            disabled={viewingStudent <= 0}
            className="mr-3"
            onClick={() => {
              setViewingStudent(viewingStudent - 1);
            }}
          >
            previous
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={viewingStudent >= studentIds.length - 1}
            onClick={() => {
              setViewingStudent(viewingStudent + 1);
            }}
          >
            next
          </Button>
        </StyledButtonSection>
      )}
    </StyledContainer>
  );
};

export default withAuth(GenerateReportSecondStep, []);
