import React, {
  PropsWithChildren,
  useRef,
  useEffect,
  useState,
  useMemo,
} from "react";
import styled from "styled-components";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import palette from "palette";
import { RouteComponentProps } from "react-router";
import { EvidenceList } from "components/common/evidenceList";
import Wheel from "components/common/Wheel/Wheel";
import { IFeedbackWithDetails } from "typings/feedback";
import { IArea, ILevel, TWheelData } from "typings/meta-mirror";
import FeedbackModal from "../common/feedbackModal";
import { TEvidencePartialData, TGroupPartialData } from "typings/student";
import ProgressChartModal from "./ProgressChartModal";
import GenerateReportModal from "./GenerateReport/GenerateReportModal";
import { mediaRules } from "MuiTheme/Breakpoints";
import { getSortedEvidenceForList } from "lib/evidence";
import {
  getSingleWheelDetailsForStudentId,
  getSingleWheelForStudentWithPersonalGrades,
} from "lib/mirror";

const StyledPaper = styled(Paper)`
  height: auto;
  width: 100%;
  padding: 20px;
  @media ${mediaRules.lg} {
    height: 100%;
  }
`;

const StyledDivider = styled.div`
  width: 100%;
  height: 3px;
  border-radius: 15px;
  background-color: ${palette.disabled};
  margin-top: 15px;
`;

const StyledIcon = styled.i`
  font-size: 20pt;
`;

const StyledStudentName = styled(Typography)`
  text-align: center !important;
`;

const WHEEL_INITIAL_SIZE = 500;

const SelectedWheelStudentWrapper: React.FC<
  RouteComponentProps<{ wheelId: string; studentId: string }>
> = ({
  match,
  history,
}: PropsWithChildren<
  RouteComponentProps<{ wheelId: string; studentId: string }>
>) => {
  const [storedWheelId, setStoredWheelId] = useState("");
  const [storedStudentId, setStoredStudentId] = useState("");
  const [evidence, setEvidence] = useState<TEvidencePartialData>();
  const [feedback, setFeedback] = useState<IFeedbackWithDetails[]>();
  const [hasSetFromUrl, setHasSetFromUrl] = useState(false);
  const [wheelData, setWheelData] = useState<TWheelData>();
  const [personalWheelData, setPersonalWheelData] = useState<TWheelData>();
  const [studentName, setStudentName] = useState("");
  const [wheelName, setWheelName] = useState("");
  const [wheelGroups, setWheelGroups] = useState<TGroupPartialData>([]);
  const [wheelCreateDate, setWheelCreateData] = useState("");
  const [loading, setLoading] = useState(true);
  const [reviewEvidenceId, setReviewEvidenceId] = useState<string[]>([]);
  const [reviewEvidenceAreaId, setReviewEvidenceAreaId] = useState("");
  const [progressChartModalOpen, setProgressChartModalOpen] = useState(false);
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [isGenerateReportModalOpen, setIsGenerateReportModalOpen] =
    useState(false);
  const [wheelLevelType, setWheelLevelType] = useState("");

  const wheelBoxRef = useRef<HTMLDivElement>(null);
  const [wheelWidth, setWheelSize] = useState(WHEEL_INITIAL_SIZE);
  const [wheelHeight, setWheelHeight] = useState(WHEEL_INITIAL_SIZE);
  const curEvidenceObj = useMemo(() => {
    const i = evidence?.find((e) => e._id === reviewEvidenceId[0]);
    if (i) setReviewEvidenceAreaId(i.areaId);
    return i;
  }, [evidence, reviewEvidenceId]);

  const getData = async (wheelId: string, studentId: string): Promise<void> => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const seId = params.get("seId");
    if (seId && !hasSetFromUrl) {
      setReviewEvidenceId([seId]);
      setReviewModalOpen(true);
      setHasSetFromUrl(true);
    }
    const area = params.get("area");
    const level = params.get("level");
    try {
      const response = await getSingleWheelDetailsForStudentId(
        wheelId,
        studentId,
        undefined,
        undefined,
        area!,
        level!
      );
      const { data } = response.data;

      const personalResponse = await getSingleWheelForStudentWithPersonalGrades(
        wheelId,
        studentId
      );

      setWheelData(data.wheelData);
      setPersonalWheelData(personalResponse.data.wheelData);
      setWheelName(data.name);
      setEvidence(data.evidence);
      setWheelLevelType(personalResponse.data.levelType ?? "sequential");
      setFeedback(data.feedback);
      setStudentName(data.studentName);
      setWheelGroups(data.wheelGroups);
      setWheelCreateData(data.wheelCreateDate);
    } catch {}
  };

  const getDataWithLoading = async (
    wheelId: string,
    studentId: string
  ): Promise<void> => {
    setLoading(true);
    await getData(wheelId, studentId);
    setLoading(false);
  };

  useEffect(() => {
    const { wheelId, studentId } = match.params;
    if (wheelId !== storedWheelId || studentId !== storedStudentId) {
      setStoredWheelId(wheelId);
      setStoredStudentId(studentId);
      getDataWithLoading(wheelId, studentId);
    }

    const handleResize = () => {
      let newWidth = wheelBoxRef?.current?.offsetWidth || WHEEL_INITIAL_SIZE;
      let newHeight =
        (wheelBoxRef?.current?.offsetHeight ?? 0) * 0.5 || WHEEL_INITIAL_SIZE;
      if (newWidth < 350) {
        newWidth = 350;
      }
      if (newHeight < 350) {
        newHeight = 350;
      }
      setWheelSize(newWidth);
      setWheelHeight(newHeight);
    };

    if (
      (wheelWidth === WHEEL_INITIAL_SIZE ||
        wheelHeight === WHEEL_INITIAL_SIZE) &&
      !!wheelBoxRef.current
    ) {
      handleResize();
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [match, loading]);

  const getAreaForReviewModal = (): IArea =>
    wheelData?.find((singleArea) => singleArea._id === reviewEvidenceAreaId)!;

  const getAreaNameForReviewModal = (): string => {
    const area = getAreaForReviewModal();
    if (area) {
      return area.areaName;
    }
    return "";
  };

  const getAreaLevelsForReviewModal = (): ILevel[] => {
    const area = getAreaForReviewModal();
    if (area) {
      return area.levels;
    }
    return [];
  };

  const handleReloadData = (): void => {
    setReviewEvidenceId([]);
    const { wheelId, studentId } = match.params;
    getData(wheelId, studentId);
  };

  const handleReviewEvidence = (
    evidenceId: string,
    evidenceAreaId: string
  ): void => {
    setReviewEvidenceId([evidenceId]);
    setReviewEvidenceAreaId(evidenceAreaId);
    setReviewModalOpen(true);
  };

  const handleCloseReviewModal = (): void => {
    setReviewModalOpen(false);
    handleReloadData();
    setTimeout(() => {
      setReviewEvidenceAreaId("");
      setReviewEvidenceId([]);
    }, 1000);
  };

  const getWheelVerticalPadding = (): number => {
    if (wheelWidth < 400) {
      return wheelHeight * 0.2;
    }
    if (wheelWidth < 450) {
      return wheelHeight * 0.15;
    }
    if (wheelWidth < 550) {
      return wheelHeight * 0.1;
    }
    if (wheelWidth < 650) {
      return wheelHeight * 0.05;
    }
    return 0;
  };

  return (
    <StyledPaper>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        className={loading ? "h-100" : ""}
      >
        {loading ? (
          <Grid item>
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={4}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => history.goBack()}
                >
                  Back
                </Button>
              </Grid>
              <Grid item xs={4}>
                <StyledStudentName variant="h6">
                  {studentName}
                </StyledStudentName>
              </Grid>
              <Grid item xs={4} className="d-flex flex-row justify-content-end">
                {/*{wheelLevelType !== "non-sequential" && (*/}
                <>
                  <Tooltip title="Generate Report">
                    <IconButton
                      color="primary"
                      onClick={() => setIsGenerateReportModalOpen(true)}
                    >
                      <StyledIcon className="uil uil-file-graph" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="View Progress">
                    <IconButton
                      color="primary"
                      className="ml-3"
                      onClick={() => setProgressChartModalOpen(true)}
                    >
                      <StyledIcon className="uil uil-chart-line" />
                    </IconButton>
                  </Tooltip>
                </>
                {/*)}*/}
              </Grid>
            </Grid>
            <StyledDivider />
            <Grid container direction="row">
              <Grid
                item
                xl={6}
                xs={12}
                className="d-flex flex-column align-content-center align-items-center pr-xl-4 pr-0 pt-4"
                ref={wheelBoxRef}
              >
                <Wheel
                  backgroundColor={palette.surface}
                  data={wheelData!}
                  height={wheelHeight}
                  width={wheelWidth}
                  labels
                  interactive
                  descriptions="bottom"
                  verticalPadding={getWheelVerticalPadding()}
                  studentData={personalWheelData}
                  enableStudentData
                  mirrorLevelType={wheelLevelType}
                />
              </Grid>
              <Grid
                item
                xl={6}
                xs={12}
                className="d-flex flex-column flex-grow-1"
              >
                <EvidenceList
                  evidence={getSortedEvidenceForList(evidence!)}
                  variant="teacher"
                  handleReviewEvidence={handleReviewEvidence}
                  handleRefreshData={handleReloadData}
                  wheelData={wheelData!}
                  mirrorLevelType={wheelLevelType}
                  studentName={studentName}
                  wheelLocked={false}
                  selectEvidenceId=""
                />
              </Grid>
            </Grid>
            <FeedbackModal
              areaLevels={getAreaLevelsForReviewModal()}
              areaName={getAreaNameForReviewModal()}
              handleCloseModal={handleCloseReviewModal}
              evidenceIds={reviewEvidenceId}
              open={reviewModalOpen}
              evidenceObject={curEvidenceObj}
              wheelData={wheelData}
              wheelLevelType={wheelLevelType}
            />
            <ProgressChartModal
              open={progressChartModalOpen}
              wheelId={match.params.wheelId}
              wheelName={wheelName}
              studentId={match.params.studentId}
              handleClose={() => setProgressChartModalOpen(false)}
              wheelLevelType={wheelLevelType}
            />
            <GenerateReportModal
              wheelLevelType={wheelLevelType}
              isOpen={isGenerateReportModalOpen}
              wheelId={storedWheelId}
              wheelCreateDate={wheelCreateDate}
              wheelGroups={wheelGroups?.map(({ _id, name }) => ({
                label: name,
                value: _id,
              }))}
              studentId={match.params.studentId}
              onClose={() => setIsGenerateReportModalOpen(false)}
            />
          </>
        )}
      </Grid>
    </StyledPaper>
  );
};

export default SelectedWheelStudentWrapper;
