import React from "react";

import { useDetailedWheel } from "pages/student/sections/detailedWheelPage/context";

import { useEvidenceList } from "./context";
import { EvidenceListFilters } from "./filters";
import { ListHeader } from "./styles";

export const EvidenceListHeader = () => {
  const { isToken } = useDetailedWheel();
  const { handleSubmit, isTeacher, wheelLocked } = useEvidenceList();

  return (
    <ListHeader>
      <ListHeader.Title variant="h5">Evidence</ListHeader.Title>
      {!isToken && (
        <div>
          <EvidenceListFilters />
          {!isTeacher && !wheelLocked && (
            <ListHeader.Button
              color="primary"
              variant="contained"
              onClick={handleSubmit}
            >
              Add evidence
            </ListHeader.Button>
          )}
        </div>
      )}
    </ListHeader>
  );
};
