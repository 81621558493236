/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { SelectInputProps } from "@material-ui/core/Select/SelectInput";

import Select from "components/common/Select/Select";

import { useFeedback } from "../context";
import { useUpdateEvidence } from "./context";

type Change = SelectInputProps["onChange"];

export const AreaSelect = () => {
  const { evidence } = useFeedback();
  const { area, areas, loading, setArea } = useUpdateEvidence();

  const handleChange: Change = ({ target }) => setArea(target.value as string);

  return (
    <Select
      className="text-left"
      disabled={loading}
      disableMargin
      label="Area"
      onChange={handleChange}
      options={areas.map(({ levels, ...rest }) => rest)}
      value={area || evidence.evidence.areaId}
    />
  );
};
