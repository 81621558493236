/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

import { P } from "./styles";
import { PreviewComponentProps } from "./types";

export const Office = ({ fileType, src }: PreviewComponentProps) => (
  <P border>
    {src && (
      <DocViewer
        config={{ header: { disableHeader: true } }}
        documents={[{ uri: src, fileType }]}
        pluginRenderers={DocViewerRenderers}
        prefetchMethod="GET"
      />
    )}
  </P>
);
