import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Grid, Paper, Typography } from "@material-ui/core";
import palette from "palette";
import Wheel from "components/common/Wheel/Wheel";
import FeedbackList from "components/common/OLD_FeedbackList/FeedbackList";
import EvidenceList from "components/common/OLD_EvidenceList/EvidenceList";
import { IWheelDataWithFeedbackEvidence } from "typings/student";
import { mediaRules } from "MuiTheme/Breakpoints";
import {
  getSortedEvidenceForList,
  getSortedFeedbackForList,
} from "lib/evidence";

const StyledWheelContainer = styled(Paper)`
  width: 100%;
  padding: 30px;
  &:not(:last-child) {
    margin: 0 0 30px 0;
  }
`;

const StyledWheelContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const StyledWheelBox = styled.div`
  width: 100%;
  height: 350px;
  margin-bottom: 30px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${mediaRules.xl} {
    width: 100%;
  }
`;

const StyledEvidenceBox = styled.div`
  height: 100%;
  min-height: 100px;
  max-height: 350px;
  &:nth-child(even) {
    margin: 0 30px;
  }
  @media ${mediaRules.xl} {
    width: 100%;
    &:nth-child(even) {
      margin: 30px 0px;
    }
  }
`;

const WHEEL_INITIAL_SIZE = 350;

const StudentWheelItem: React.FC<IWheelDataWithFeedbackEvidence> = ({
  name,
  feedback,
  evidence,
  wheelData,
}: IWheelDataWithFeedbackEvidence) => {
  const wheelBoxRef = useRef<HTMLDivElement>(null);
  const [wheelWidth, setWheelWidth] = useState(WHEEL_INITIAL_SIZE);
  const [wheelHeight, setWheelHeight] = useState(WHEEL_INITIAL_SIZE);

  useEffect(() => {
    const handleResize = () => {
      let newWidth = wheelBoxRef?.current?.offsetWidth || WHEEL_INITIAL_SIZE;
      if (newWidth > 700) {
        newWidth = 700;
      }
      setWheelWidth(newWidth);
      setWheelHeight(wheelBoxRef?.current?.offsetHeight || WHEEL_INITIAL_SIZE);
    };

    if (wheelWidth === WHEEL_INITIAL_SIZE) {
      handleResize();
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <>
      <StyledWheelContainer>
        <Typography variant="h5">{name}</Typography>
        <StyledWheelContent>
          <StyledWheelBox ref={wheelBoxRef}>
            <Wheel
              data={wheelData}
              backgroundColor={palette.surface}
              width={wheelWidth}
              height={wheelHeight}
              labels
              interactive={false}
            />
          </StyledWheelBox>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} xl={6}>
              <StyledEvidenceBox>
                <EvidenceList
                  evidence={getSortedEvidenceForList(evidence)}
                  variant="admin"
                />
              </StyledEvidenceBox>
            </Grid>
            <Grid item xs={12} xl={6}>
              <StyledEvidenceBox>
                <FeedbackList
                  feedback={getSortedFeedbackForList(feedback)}
                  variant="admin"
                />
              </StyledEvidenceBox>
            </Grid>
          </Grid>
        </StyledWheelContent>
      </StyledWheelContainer>
    </>
  );
};

export default StudentWheelItem;
