/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import { AchievementsContext, AchievementsProviderProps } from "./types";
import { useProfile } from "../context";

const Context = React.createContext<AchievementsContext>({
  achievements: [],
  grade: "",
  setEditing: () => {},
  setGrade: () => {},
  setSubject: () => {},
  subject: "",
});

export const AchievementsProvider = ({
  children,
}: AchievementsProviderProps) => {
  const { achievements } = useProfile();
  const [editing, setEditing] = React.useState<boolean>(false);
  const [grade, setGrade] = React.useState<string>("");
  const [subject, setSubject] = React.useState<string>("");

  const handleEditClick = () => setEditing((prev) => !prev);

  return (
    <Context.Provider
      value={{
        achievements,
        editing,
        grade,
        handleEditClick,
        setEditing,
        setGrade,
        setSubject,
        subject,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useAchievements = () => React.useContext(Context);
