import { Box, Button, CircularProgress } from "@material-ui/core";
import {
  CloseRounded,
  LanguageRounded,
  SaveRounded,
  SchoolRounded,
} from "@material-ui/icons";
import React from "react";

import TextInput from "components/common/TextInput/TextInput";

import { useContact } from "./context";

// type Change = SelectInputProps["onChange"];

// const socials = {
//   facebook: <Facebook />,
//   instagram: <Instagram />,
//   linkedin: <LinkedIn />,
//   twitter: <Twitter />,
//   whatsapp: <WhatsApp />,
//   youtube: <YouTube />,
// };

export const Editor = () => {
  const {
    disabled,
    handleAdd,
    school,
    setSchool,
    setWebsite,
    website,
    setEditing,
  } = useContact();
  const [loading, setLoading] = React.useState(false);

  const handleCancel = () => setEditing(false);

  const handleSave = () => {
    setLoading(true);
    handleAdd()
      .then(() => setEditing(false))
      .finally(() => setLoading(false));
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
        <TextInput
          InputProps={{ startAdornment: <SchoolRounded /> }}
          label="School"
          onChange={({ target }) => setSchool(target.value)}
          value={school}
        />
        <TextInput
          InputProps={{ startAdornment: <LanguageRounded /> }}
          label="Website"
          onChange={({ target }) => setWebsite(target.value)}
          value={website}
        />
        {/* @ts-ignore */}
        {/* <Box sx={{ display: "flex", gap: ".5rem" }}>
          <Select
            FormControlProps={{
              style: { marginTop: "8px", marginBottom: "4px", flex: 1 },
            }}
            disabled={loading}
            disableMargin
            label="Platform"
            onChange={handleChange}
            options={[
              { value: "facebook", label: "Facebook" },
              { value: "instagram", label: "Instagram" },
              { value: "linkedin", label: "LinkedIn" },
              { value: "twitter", label: "Twitter" },
              { value: "whatsapp", label: "WhatsApp" },
              { value: "youtube", label: "YouTube" },
            ]}
            value={platform}
          />
          <TextInput
            InputProps={{ startAdornment: (socials as any)[platform] }}
            label="Social"
            value={social}
            onChange={({ target }) => setSocial(target.value)}
            style={{ flex: 1 }}
          />
        </Box> */}
      </Box>
      <Box
        sx={{
          alignItems: "flex-end",
          display: "flex",
          // @ts-ignore
          gap: ".5rem",
          paddingLeft: "2rem",
        }}
      >
        <Button
          color="secondary"
          onClick={handleCancel}
          startIcon={<CloseRounded />}
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={disabled || loading}
          onClick={handleSave}
          startIcon={loading ? <CircularProgress size={20} /> : <SaveRounded />}
          variant="contained"
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};
