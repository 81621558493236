import React from "react";

import { useEvidenceList } from "../context";
import { Actions } from "./actions";
import { EvidenceProvider } from "./context";
import { Header } from "./header";
import { Info } from "./info";
import { Item } from "./styles";
import { EvidenceProps } from "./types";

export const Evidence = ({ evidence }: EvidenceProps) => {
  const { selectEvidenceId } = useEvidenceList();

  return (
    <EvidenceProvider evidence={evidence}>
      <Item selected={evidence._id === selectEvidenceId}>
        <Header />
        <Info />
        <Actions />
      </Item>
    </EvidenceProvider>
  );
};
