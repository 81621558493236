import { Theme } from "@material-ui/core";
import Palette from "palette";

type ThemeInterface = Exclude<Theme["overrides"], undefined>;

const MuiIconButton: ThemeInterface["MuiIconButton"] = {
  root: {
    padding: 0,
    minWidth: 40,
    minHeight: 40,
    outlineWidth: "0px !important",
    transition: "350ms",
    "&$disabled": {
      "&$colorPrimary": {
        cursor: "pointer",
        color: Palette.font.contrast,
        backgroundColor: Palette.primaryLight,
      },
    },
  },
  colorPrimary: {
    backgroundColor: Palette.primary,
    color: Palette.font.contrast,
    "&:hover": {
      backgroundColor: Palette.primaryDark,
    },
  },
  colorSecondary: {
    backgroundColor: Palette.secondary,
    color: Palette.font.contrast,
    "&:hover": {
      backgroundColor: Palette.secondaryDark,
    },
  },
};

export default MuiIconButton;
