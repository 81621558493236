import { Button } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

export const UploadButton = styled(({ image, placeholder, ...rest }: any) => (
  <Button {...rest} />
))(({ image, placeholder = "No image selected." }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "250px !important",
  height: "250px !important",
  borderRadius: "50% !important",
  border: "0 !important",
  mt: 2,
  overflow: "hidden",
  background: "rgba(0, 0, 0, .5) !important",
  ...(!image && {
    "&:before": {
      content: `"${placeholder}"`,
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      transition: "500ms cubic-bezier(0.4, 0, 0.2, 1)",
      textAlign: "center",
      color: "white",
    },
  }),
  "& #upload": {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    filter: "opacity(0)",
    opacity: 0,
    transition: "500ms cubic-bezier(0.4, 0, 0.2, 1)",
    zIndex: 1,
    background: "rgba(0, 0, 0, .5)",
    color: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  "&:hover": {
    "&:before": {
      filter: "opacity(0)",
      opacity: 0,
    },
    "& #upload": {
      filter: "opacity(1)",
      opacity: 1,
      zIndex: 1,
    },
  },
}));
