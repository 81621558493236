import { Evidence, FeedItem, IdName } from "./types";

type ParsedUrl = {
  href: string;
  isToken?: boolean;
  isUser: boolean;
  studentId: string;
  type: "area" | "evidence" | "mirror";
};

export const decodeParsedUrl = ({
  href,
  isToken,
  isUser,
  studentId,
  type,
}: ParsedUrl) => {
  const [part, search] = href.split("?");
  const params = new URLSearchParams(search);
  const parts = part.split("/");

  if (isToken) {
    let ids = ["evidenceId", "areaId", "mirrorId", "studentId"];
    if (type !== "evidence") {
      ids = ids.slice(1);
      if (type !== "area") ids = ids.slice(1);
    }
    return ids.reduce((acc: any, id, index) => {
      acc[id] = parts[parts.length - index - 1];
      return acc;
    }, {});
  }

  return {
    areaId: params.get("area"),
    evidenceId: params.get("seId"),
    studentId,
    mirrorId: isUser ? parts[parts.length - 1] : parts[parts.length - 2],
  };
};

const getParts = (link: string) => {
  const [studentId, wheelId, rest] = link.split("/");
  const [areaId, evidenceId] = rest?.split("?seId=") ?? [undefined, undefined];
  return { areaId, evidenceId, studentId, wheelId };
};

type GetUrlParams = {
  parts: ReturnType<typeof getParts>;
  token?: string;
  type: "student" | "teacher" | "token";
};
const getUrl = ({ parts, token, type }: GetUrlParams) => {
  const url: string[] = [];
  const searchParams = new URLSearchParams({ profile: "true" });
  const { areaId, evidenceId, studentId, wheelId } = parts;
  if (type === "token" && token) {
    url.push("token", token, studentId, wheelId);
    areaId && url.push(areaId);
    evidenceId && url.push(evidenceId);
    return `${url.join("/")}?${searchParams.toString()}`;
  }
  url.push(type);
  if (type === "teacher") url.push("my-wheels");
  url.push(wheelId);
  if (type === "teacher") url.push(studentId);
  areaId && searchParams.set("area", areaId);
  evidenceId && searchParams.set("seId", evidenceId);

  return `${url.join("/")}?${searchParams.toString()}`;
};

type FormatParams = {
  summary?: string;
  token?: string;
  type: "student" | "teacher" | "token";
};

export const formatSummary = ({ type, summary, token }: FormatParams) => {
  let output = summary ?? "";
  if (!summary) return output;

  const matches = summary.match(/href=\"(.*?)\</g);
  matches?.forEach((match) => {
    const innerText = match.split(">")[1].split("<")[0];
    if (["area", "evidence", "mirror"].includes(innerText)) {
      const parts = getParts(match.split('href="')[1].split('"')[0]);
      const url = getUrl({ parts, token, type });
      const replaced = match.replace(
        /href=\"(.*?)\"/,
        `href="/${url}" data-studentId="${parts.studentId}"`
      );
      output = output.replace(match, replaced);
    } else {
      const part = match.split('href="')[1].split('"')[0];
      if (part.startsWith("http")) return;
      const replaced = match.replace(
        /href=\"(.*?)\"/,
        `href="https://${part}"`
      );
      output = output.replace(match, replaced);
    }
  });

  return output;
};

export const itemRenderer = ({ area, evidence, wheel }: Partial<FeedItem>) => {
  const createDiv = (key: string, text?: string) => {
    const div = document.createElement("div");
    div.classList.add(`evidence-${key}`);
    text && (div.textContent = text);
    return div;
  };
  const item = createDiv("item");
  if (evidence) item.appendChild(createDiv("evidence", evidence));
  if (wheel) {
    const container = document.createElement("div");
    container.classList.add("evidence-wheel-container");
    container.appendChild(createDiv("wheel", wheel));
    if (area) container.appendChild(createDiv("area", area));
    item.appendChild(container);
  }
  return item;
};

const filter = (input: any[], key: string) =>
  input.filter(
    (value, index, self) =>
      self.findIndex((t) => t[key] === value[key]) === index
  );

export const getFeeds = (input: Evidence[]) => {
  const feeds: any[] = [];
  const keys: (keyof Evidence)[] = [];

  const list = [
    { key: "wheel", marker: "/" },
    { key: "area", marker: "#" },
    { key: "evidence", marker: "@" },
  ];

  for (const { key, marker } of list as any[]) {
    keys.push(key);
    const feed = input.map((item) => {
      let id = `${marker}${key === "wheel" ? "mirror" : key}`;
      let name = "";
      const out = {} as FeedItem;
      let link = item.studentId;

      keys.forEach((k) => {
        const feedItem = item[k] as IdName;
        id += feedItem.name;
        link += k === "evidence" ? `?seId=${feedItem.id}` : `/${feedItem.id}`;
        name += feedItem.id;
        (out as any)[k] = feedItem.name;
      });

      return { ...out, id, link, name, text: key === "wheel" ? "mirror" : key };
    });

    feeds.push({ feed: filter(feed, key), marker, itemRenderer });
  }
  return feeds;
};
