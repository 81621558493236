import { Theme } from '@material-ui/core';

type ThemeInterface = Exclude<Theme['overrides'], undefined>;

const MuiBackdrop: ThemeInterface['MuiBackdrop'] = {
  root: {
    transition: '1s',
  },
  invisible: {
    transition: '1s',
  },
};

export default MuiBackdrop;
