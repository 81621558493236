import AnimatedModal from "components/common/AnimatedModal";
import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import { S } from "./styles";
import palette from "palette";
import { updateManyStudents } from "lib/student";
import Select from "components/common/Select/Select";
import { AVAILABLE_YEAR_GROUP_FILTER } from "components/teacher/StudentListFilters";

interface IProps {
  isOpen: boolean;
  handleCloseModal: () => void;
  selectedStudents: string[];
  refreshStudents: () => Promise<void>;
}

export const UpdateYearGroupModel: React.FC<IProps> = ({
  isOpen,
  handleCloseModal,
  selectedStudents,
  refreshStudents,
}) => {
  const [yearName, setYearName] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleUpdateYear = async () => {
    try {
      await updateManyStudents(selectedStudents, yearName);
      await refreshStudents();
      setSuccess(true);
    } catch {
      setError(true);
    }
  };

  return (
    <AnimatedModal
      open={isOpen}
      title="Update Year Group"
      handleClose={() => {
        setSuccess(false);
        setError(false);
        handleCloseModal();
      }}
      maxWidth="625px"
      minHeight="200px"
      width="100%"
    >
      <>
        <Typography align={"center"} variant="body2" style={{ margin: "10px" }}>
          Select a year group from the dropdown below to update the selected
          students.
        </Typography>
        <Select
          value={yearName}
          fullWidth
          disabled={success}
          onChange={(event) => {
            setYearName(event.target.value as string);
          }}
          options={AVAILABLE_YEAR_GROUP_FILTER.map((yearGroup) => ({
            label: `Year ${yearGroup}`,
            value: `Year ${yearGroup}`,
          }))}
          label="Year Group"
        />
        <Typography
          style={{
            color: !error ? palette.complete : palette.error,
            marginBottom: "20px",
            textAlign: "center",
          }}
          variant="body2"
        >
          {error && "Error updating year group"}
          {success && !error && "Year group updated"}
        </Typography>
        <S.ActionContainer>
          <S.Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setSuccess(false);
              setError(false);
              handleCloseModal();
            }}
          >
            cancel
          </S.Button>
          <S.Button
            variant="contained"
            color="primary"
            disabled={yearName.length < 2 || success}
            onClick={() => handleUpdateYear()}
          >
            update
          </S.Button>
        </S.ActionContainer>
      </>
    </AnimatedModal>
  );
};
