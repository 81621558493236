import { Paper } from "@material-ui/core";
import styled from "styled-components";

import palette from "MuiTheme/palette";

export const TP = Object.assign(
  styled(Paper)({
    minHeight: "100%",
    padding: "25px",
    width: "100%",
  }),
  {
    Divider: styled.div({
      backgroundColor: palette.disabled,
      borderRadius: "15px",
      height: "3px",
      margin: "15px 0 0",
      width: "100%",
    }),
  }
);
