/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";

import PageContainer from "components/common/PageContainer/PageContainer";

import { withAuth } from "../../../../hoc/withAuth";
import { WheelsPageProvider } from "./context";
import { Profile } from "./profile";
import { ProfileProvider } from "./profile/context";
import { IWheelsPageProps, StudentPageProps } from "./types";
import { Wheels } from "./wheels";

export const StudentPage = (props: StudentPageProps) => (
  <WheelsPageProvider {...props}>
    <ProfileProvider>
      <Profile />
    </ProfileProvider>
    {!props.isToken && <Wheels />}
  </WheelsPageProvider>
);

export const WheelsPage = withAuth(
  ({ user }: IWheelsPageProps) => (
    <PageContainer>
      <StudentPage isUser userId={user.roles?.student.id._id as any} />
    </PageContainer>
  ),
  []
);
