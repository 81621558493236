import { Theme } from '@material-ui/core';

type ThemeInterface = Exclude<Theme['overrides'], undefined>;

const MuiFormControlLabel: ThemeInterface['MuiFormControlLabel'] = {
  root: {
    '& .MuiTypography-body1': {
      fontSize: '14px !important',
    },
  },
};

export default MuiFormControlLabel;
