import React, { useRef, useState } from "react";
import {
  Button,
  ButtonProps,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import palette from "palette";

const StyledLabel = styled(InputLabel)`
  background-color: ${palette.surface};
`;

const StyledTopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  outline: none;
  padding: 0 5px;
`;

const StyledIconButton = styled(IconButton)`
  transition: 0.5s !important;
  min-width: 20px !important;
  min-height: 20px !important;
  height: 20px !important;
  width: 20px !important;
  color: ${palette.font.caption} !important;
  :hover {
    color: ${palette.primary} !important;
  }
  &.Mui-disabled {
    color: ${palette.disabled} !important;
  }
`;

interface IStyledMonthButtonProps extends ButtonProps {
  isActive: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledMonthButton = styled(
  ({ isActive, ...props }: IStyledMonthButtonProps) => <Button {...props} />
)<{ isActive: boolean }>`
  transition: 0.5s !important;
  height: 100% !important;
  width: 100% !important;
  text-align: center !important;
  font-weight: ${(props) => (props.isActive ? 700 : 500)} !important;
  color: ${(props) =>
    props.isActive ? palette.primary : palette.font.caption} !important;
  :hover {
    background-color: ${palette.primaryLight}aa !important;
  }
  &.Mui-disabled {
    color: ${palette.disabled} !important;
  }
`;

interface IProps {
  label: string;
  minDate: Date;
  maxDate: Date;
  handleSetValue(selectedDate: Date): void;
}

/**
 * A Date Picker component based on the MUI Select, restyled to support Year/Month picking.
 */
const DatePicker: React.FC<IProps> = ({
  label,
  minDate,
  maxDate,
  handleSetValue,
}: IProps) => {
  const selectRef = useRef<HTMLElement>();

  const [menuOpen, toggleMenuOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [maxPage] = useState(maxDate.getFullYear() - minDate.getFullYear());
  const [year, setYear] = useState<number>(0);
  const [month, setMonth] = useState<number>(maxDate.getMonth());

  const isMonthDisabled = (monthKey: number): boolean => {
    if (page === 0 && monthKey > maxDate.getMonth()) {
      return true;
    }
    if (page === maxPage && monthKey < minDate.getMonth()) {
      return true;
    }
    return false;
  };

  const isMonthSelected = (monthKey: number): boolean => {
    return page === year && monthKey === month;
  };

  const monthOnClick = (monthKey: number): void => {
    setMonth(monthKey);
    setYear(page);
    toggleMenuOpen(false);
    handleSetValue(new Date(maxDate.getFullYear() - page, monthKey));
  };

  const getMonths = (): JSX.Element[] => {
    const monthButtons = [];
    for (let i = 0; i < 12; ++i) {
      monthButtons.push(
        <Grid item xs={4} key={i}>
          <StyledMonthButton
            onClick={() => monthOnClick(i)}
            color="primary"
            isActive={isMonthSelected(i)}
            disabled={isMonthDisabled(i)}
          >
            {new Date(0, i).toLocaleDateString("default", { month: "short" })}
          </StyledMonthButton>
        </Grid>
      );
    }
    return monthButtons;
  };

  const handleNextYear = (): void => {
    setPage(page - 1);
  };

  const handlePrevYear = (): void => {
    setPage(page + 1);
  };

  return (
    <FormControl className={"w-100 position-relative"}>
      <StyledLabel id="multi-select-label">{label}</StyledLabel>
      <Select
        value={`${month}-${year}`}
        variant="outlined"
        MenuProps={{ variant: "menu", style: { visibility: "hidden" } }}
        label={label}
        margin="dense"
        ref={selectRef}
        onClick={() => toggleMenuOpen(!menuOpen)}
        onAbort={() => toggleMenuOpen(false)}
        onClose={() => toggleMenuOpen(false)}
        open={menuOpen}
      >
        <MenuItem value={`${month}-${year}`}>{`${new Date(
          0,
          month
        ).toLocaleString("default", { month: "long" })} ${
          maxDate.getFullYear() - year
        }`}</MenuItem>
      </Select>
      <Menu
        open={menuOpen}
        anchorEl={selectRef.current}
        onAbort={() => toggleMenuOpen(false)}
        onClose={() => toggleMenuOpen(false)}
        keepMounted={false}
        PaperProps={{
          style: {
            width: selectRef.current?.offsetWidth || 250,
            outlineColor: "transparent",
          },
        }}
      >
        <StyledTopContainer>
          <StyledIconButton
            onClick={handlePrevYear}
            disabled={page === maxPage}
          >
            <i className="uil uil-angle-left" />
          </StyledIconButton>
          <Typography>{maxDate.getFullYear() - page}</Typography>
          <StyledIconButton onClick={handleNextYear} disabled={page === 0}>
            <i className="uil uil-angle-right" />
          </StyledIconButton>
        </StyledTopContainer>
        <Divider className="m-2" />
        <Grid container direction="row" className="px-2">
          {getMonths()}
        </Grid>
      </Menu>
    </FormControl>
  );
};

export default DatePicker;
