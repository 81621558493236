import styled from "styled-components";
import { Grid, IconButton, Modal, Paper, Typography } from "@material-ui/core";
import palette from "palette";

export const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledCloseButton = styled(IconButton)`
  position: absolute !important;
  right: -5px !important;
  top: -6px !important;
`;

export const StyledIcon = styled.i`
  font-size: 20pt;
`;

interface IStyledPaperProps {
  width: string;
  height: string;
  minWidth: string;
  maxWidth: string;
  minHeight: string;
  maxHeight: string;
}

export const StyledPaper = styled(Paper)<IStyledPaperProps>`
  padding: 30px 30px 45px;
  margin: auto;
  padding: 30px;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  min-width: ${(props) => props.minWidth};
  max-width: ${(props) => props.maxWidth};
  min-height: ${(props) => props.minHeight};
  max-height: ${(props) => props.maxHeight};
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 7px;
    background-color: ${palette.primary};
  }
`;

export const StyledModalTitle = styled(Typography)`
  font-size: clamp(16px, 5vw, 26px) !important;
  line-height: 30px !important;
  max-width: 90% !important;
  overflow-wrap: break-word;
  margin: 0 auto !important;
`;

export const StyledGridItemHeader = styled(Grid)`
  max-height: 40px !important;
  position: relative !important;
  margin-bottom: 10px !important;
`;

export const StyledContentContainer = styled(Grid)`
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 7px;
    background-color: ${palette.primary};
  }
  
  overflow-x: hidden;
  max-height: 820px;
  position: relative !important;
  @media only screen and (max-height: 800px) {
    overflow-y: scroll;
    max-height: 500px;
  }
`;
