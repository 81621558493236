import { Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import palette from "palette";
import { IFeedbackWithDetails } from "typings/feedback";
import FeedbackComment from "./FeedbackComment";

const StyledItemBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledBoxTitle = styled(Typography)`
  font-weight: 700 !important;
  margin: 0 0 10px 0 !important;
`;

const StyledItemsContainer = styled.div`
  padding-right: 5px;
  overflow: auto;
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 7px;
    background-color: ${palette.font.caption};
  }
`;

enum EmptyMessages {
  "admin" = "No feedback has been submitted for this student on this wheel.",
  "student" = "You haven't received any feedback for this wheel yet.",
  "teacher" = "No feedback has been submitted yet.",
}

interface IProps {
  feedback: IFeedbackWithDetails[];
  variant: "admin" | "student" | "teacher";
  disableTitle?: boolean;
}

const FeedbackList: React.FC<IProps> = ({
  feedback,
  variant,
  disableTitle = false,
}: IProps) => (
  <StyledItemBox>
    {!disableTitle && <StyledBoxTitle variant="body1">Feedback</StyledBoxTitle>}
    <StyledItemsContainer>
      {!!feedback.length ? (
        feedback.map((singleFeedback) => (
          <FeedbackComment
            key={singleFeedback._id}
            date={new Date(singleFeedback.createdAt).toLocaleDateString()}
            comment={singleFeedback.comment}
            evidenceName={singleFeedback.evidenceName}
            teacherName={singleFeedback.teacherName}
            viewed={singleFeedback.viewed}
            id={singleFeedback._id}
            variant={variant}
          />
        ))
      ) : (
        <Typography variant="caption">{EmptyMessages[variant]}</Typography>
      )}
    </StyledItemsContainer>
  </StyledItemBox>
);

export default FeedbackList;
