import React, { Component } from "react";
import AnimatedModal from "components/common/AnimatedModal";
import styled from "styled-components";
import { Button, Grid, Typography } from "@material-ui/core";
import palette from "palette";
import SubmitLink from "./SubmitLink";
import SubmitFile from "./SubmitFile";
import SubmitGrade from "./SubmitGrade";
import {
  ILevel,
  TWheelData,
  TWheelValidEvidenceFileType,
} from "typings/meta-mirror";
import { IEvidence } from "typings/evidence";
import ChooseEvidenceType from "./ChooseEvidenceType";
import SubmitComment from "./SubmitComment";
import { submitEvidence } from "lib/evidence";
import { IWithAuth, withAuth } from "../../../hoc/withAuth";

const StyledModalContainer = styled.div`
  max-width: 800px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const StyledSuccessMessage = styled(Typography)`
  font-weight: 700;
  text-align: center !important;
`;

const StyledSuccessIcon = styled.i`
  display: block;
  text-align: center;
  font-size: 55px;
  color: ${palette.complete};
`;

const StyledActionButton = styled(Button)`
  width: 140px;
  height: 35px !important;
`;

type TValidEvidenceType =
  | TWheelValidEvidenceFileType
  | "link"
  | "file"
  | "comment"
  | "";

interface IProps extends IWithAuth {
  isOpen: boolean;
  onClose: () => void;
  handleEvidenceAdded: (evidenceAdded: IEvidence) => void;
  wheelId: string;
  wheelAreas: { _id: string; name: string; disabled: boolean }[];
  wheelData: TWheelData;
  mirrorLevelType: string;
}

interface IState {
  requesting: boolean;
  error: string;
  success: boolean;
  selectedAreaId: string;
  selectedLevelId: string;
  selectedGrade: ILevel["grade"];
  evidenceType: TValidEvidenceType;
  evidence: string;
  evidenceName: string;
  stepNumber: number;
}

class SubmitEvidenceModal extends Component<IProps, IState> {
  state = {
    requesting: false,
    error: "",
    success: false,
    selectedAreaId: "",
    selectedLevelId: "",
    selectedGrade: null,
    evidenceType: "" as TValidEvidenceType,
    evidence: "",
    evidenceName: "",
    stepNumber: 0,
  };

  protected handleSubmitEvidence = async (): Promise<void> => {
    this.setState({
      requesting: true,
    });

    const { wheelId, handleEvidenceAdded } = this.props;
    const {
      selectedAreaId,
      evidenceType,
      evidence,
      evidenceName,
      selectedLevelId,
      selectedGrade,
      stepNumber,
    } = this.state;

    const studentId = this.props.user?.roles?.student?.id._id;

    try {
      const response = await submitEvidence(
        studentId,
        wheelId,
        selectedAreaId,
        evidence,
        evidenceType,
        evidenceName,
        selectedLevelId,
        selectedGrade
      );
      this.setState({
        requesting: false,
        success: true,
        error: "",
        stepNumber: stepNumber + 1,
      });
      await handleEvidenceAdded(response.data);
    } catch (err) {
      this.setState({
        error: err.message,
        success: false,
        requesting: false,
        stepNumber: 1,
      });
      return;
    }
  };

  protected handleSetEvidenceData = (
    evidence: string,
    evidenceName: string,
    evidenceType?: TValidEvidenceType
  ): void => {
    if (!!evidenceType) {
      this.setState({ evidence, evidenceName, evidenceType });
      return;
    }
    this.setState({ evidence, evidenceName });
  };

  protected handleSetError = (error: string): void => {
    this.setState({ error });
  };

  protected handleSelectArea = (selectedAreaId: string): void => {
    this.setState({ selectedAreaId });
  };

  protected handleSelectEvidenceType = (
    evidenceType: TValidEvidenceType
  ): void => {
    this.setState({
      evidenceType,
      stepNumber: 1,
    });
  };

  protected handleResetModal = (): void => {
    this.setState({
      success: false,
      error: "",
      requesting: false,
      evidence: "",
      evidenceType: "",
      selectedAreaId: "",
      selectedLevelId: "",
      selectedGrade: null,
      evidenceName: "",
      stepNumber: 0,
    });
  };

  protected handleCloseModal = (): void => {
    this.props.onClose();
    setTimeout(this.handleResetModal, 1000);
  };

  protected isSubmitDisabled = (): boolean => {
    const {
      evidenceType,
      evidence,
      selectedAreaId,
      requesting,
      stepNumber,
      selectedGrade,
    } = this.state;
    if (stepNumber === 2 && ![0, 0.5, 1].includes(selectedGrade)) {
      return true;
    }
    return !evidence || !evidenceType || !selectedAreaId || requesting;
  };

  protected handleForwardButton = (): void => {
    const { stepNumber } = this.state;
    if (stepNumber === 3) {
      this.handleCloseModal();
      return;
    }
    if (stepNumber === 2) {
      this.handleSubmitEvidence();
      return;
    }
    if (stepNumber === 1) {
      this.setState({ error: "" });
    }
    this.setState({ stepNumber: this.state.stepNumber + 1 });
  };

  protected getForwardButtonText = (): string => {
    const { stepNumber, success } = this.state;
    if (success) {
      return "done";
    }
    if (stepNumber === 3) {
      return "submit";
    }
    return "next";
  };

  protected handleBackButton = (): void => {
    const { stepNumber } = this.state;
    if (stepNumber === 0 || stepNumber === 3) {
      this.handleCloseModal();
      return;
    }
    this.setState({ stepNumber: this.state.stepNumber - 1 });
  };

  protected getBackButtonText = (): string => {
    const { stepNumber } = this.state;
    if (stepNumber === 3) {
      return "close";
    }
    if (stepNumber === 0) {
      return "cancel";
    }
    return "back";
  };

  protected handleSetStudentGrade = (grade: ILevel["grade"]): void => {
    this.setState({ selectedGrade: grade });
  };

  protected handleSetStudentLevelId = (levelId: string): void => {
    this.setState({ selectedLevelId: levelId });
  };

  protected renderEvidenceStep = () => {
    const { wheelAreas, wheelData, mirrorLevelType } = this.props;
    const {
      requesting,
      error,
      selectedAreaId,
      selectedGrade,
      evidence,
      evidenceType,
      stepNumber,
      selectedLevelId,
      evidenceName,
    } = this.state;
    const {
      handleSetEvidenceData,
      handleSetStudentLevelId,
      handleSelectArea,
      handleSetError,
    } = this;

    const submitComponentProps = {
      requesting,
      error,
      selectedAreaId,
      wheelAreas,
      wheelData,
      handleSetEvidenceData,
      handleSelectLevel: handleSetStudentLevelId,
      handleSelectArea,
      selectedLevelId,
    };

    if (stepNumber === 0) {
      return (
        <ChooseEvidenceType
          handleSubmitLink={() => this.handleSelectEvidenceType("link")}
          handleSubmitComment={() => this.handleSelectEvidenceType("comment")}
          handleUploadFile={() => this.handleSelectEvidenceType("file")}
        />
      );
    }

    if (stepNumber === 1) {
      if (evidenceType === "link") {
        return (
          <SubmitLink
            link={evidence}
            evidenceName={evidenceName}
            {...submitComponentProps}
            mirrorLevelType={mirrorLevelType}
          />
        );
      }
      if (evidenceType === "comment") {
        return (
          <SubmitComment
            comment={evidence}
            {...submitComponentProps}
            mirrorLevelType={mirrorLevelType}
          />
        );
      }
      return (
        <SubmitFile
          file={evidence}
          handleSetError={handleSetError}
          mirrorLevelType={mirrorLevelType}
          {...submitComponentProps}
        />
      );
    }

    if (stepNumber === 2) {
      return (
        <SubmitGrade
          error={error}
          handleSelectLevel={this.handleSetStudentLevelId}
          handleSetStudentGrade={this.handleSetStudentGrade}
          requesting={requesting}
          selectedAreaId={selectedAreaId}
          selectedGrade={selectedGrade}
          selectedLevelId={selectedLevelId}
          mirrorLevelType={mirrorLevelType}
          wheelAreaLevels={
            wheelData.find(({ _id }) => _id === selectedAreaId).levels
          }
        />
      );
    }

    if (stepNumber === 3) {
      return (
        <div>
          <StyledSuccessIcon className="uil uil-check-circle" />
          <StyledSuccessMessage variant="body1">
            Your evidence has been submitted, and your personal grade has been
            updated if you made any changes.
          </StyledSuccessMessage>
          <br />
        </div>
      );
    }
  };

  render() {
    const { isOpen } = this.props;

    return (
      <AnimatedModal
        open={isOpen}
        title="Submit Evidence"
        handleClose={this.handleCloseModal}
        onClose={this.handleCloseModal}
        width="90%"
        maxWidth="800px"
        minWidth="320px"
        maxHeight="90vh"
      >
        <StyledModalContainer>
          <div>{this.renderEvidenceStep()}</div>
          <Grid container direction="row" className="bg-white">
            <Grid item xs={6} className="d-flex justify-content-end pr-2">
              <StyledActionButton
                variant="outlined"
                color="primary"
                onClick={this.handleBackButton}
              >
                {this.getBackButtonText()}
              </StyledActionButton>
            </Grid>
            <Grid item xs={6} className={"d-flex justify-content-start pl-2"}>
              <StyledActionButton
                variant="contained"
                color="primary"
                disabled={this.isSubmitDisabled()}
                onClick={this.handleForwardButton}
              >
                {this.getForwardButtonText()}
              </StyledActionButton>
            </Grid>
          </Grid>
        </StyledModalContainer>
      </AnimatedModal>
    );
  }
}

export default withAuth(SubmitEvidenceModal, []);
