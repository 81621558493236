import {
  Button as MuiButton,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import palette from "MuiTheme/palette";
import styled from "styled-components";

export const Button = styled(MuiButton)({
  // @ts-ignore
  position: "absolute !important",
  top: 20,
  right: 20,
});

export const ErrorMessage = styled(Typography)({
  color: palette.attempting,
  fontSize: 16,
  fontWeight: 700,
  marginLeft: -5,
});

export const PaginationContainer = styled.div({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  margin: "30px 0 0",
});

export const Row = styled.div({
  display: "flex",
  justifyContent: "center",
  margin: "10px 0 0 0",
  "& button": {
    border: "0",
    borderRadius: "5px",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    padding: "10px 20px",
    marginBottom: "10px",
  },
});

export const TextArea = styled(TextareaAutosize)({
  background: palette.background,
  border: `1px solid ${palette.disabled}`,
  borderRadius: 8,
  marginBottom: 10,
  outline: "none",
  padding: 10,
  resize: "none",
  width: "100%",
  maxWidth: "350px",
  position: "absolute",
  top: 20,
  right: 20,
  fontSize: ".8rem",
});
