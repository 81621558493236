import React from "react";

import { useFeedback } from "./context";
import { Message } from "./message";

export const Conversation = () => {
  const { messages } = useFeedback();
  const scrollRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (scrollRef.current)
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
  }, [messages]);

  return (
    <div
      ref={scrollRef}
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        gap: ".5rem",
        maxHeight: "calc(100% - 90px)",
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      {messages.map((message) => (
        <Message key={message._id} {...message} />
      ))}
    </div>
  );
};
