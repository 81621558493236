import React, { useContext, useState } from "react";
import styled from "styled-components";
import palette from "palette";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableFooter,
  Tooltip,
  TableCellProps,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { WheelsPageContext } from "../../states/WheelsPageContext";
import { IGroupData } from "../../typings/group";
import { getStudentGroupStatus } from "lib/group";
import { IStudent } from "../../typings/models";
import { S } from "components/teacher/GroupStudentList/styles";

const StyledContainer = styled.div`
  border-radius: 10px !important;
  border: 3px solid ${palette.font.caption};
  overflow: auto;
`;

const StyledNoDataContainer = styled.div`
  width: 100%;
  padding: 50px 0;
  text-align: center;
`;

const StyledIcon = styled.i<{ color?: string }>`
  font-size: 24px;
  color: ${(props) => props.color || palette.disabled};
`;

const StyledLink = styled(Link)`
  color: inherit;

  &:hover {
    color: inherit;
  }
`;

const StyledIconLink = styled(StyledLink)`
  border-radius: 50%;
  width: 35px;
  display: block;
  height: 35px;
  margin: 0 auto;
  transition: 0.3s;

  &:hover {
    background-color: ${palette.disabled}66;
  }
`;

interface IStyledTableCellProps extends TableCellProps {
  customAlign: string;
  customWidth: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledTableCell = styled(
  ({ customAlign, customWidth, ...props }: IStyledTableCellProps) => (
    <TableCell {...props} />
  )
)`
  width: ${(props) => (props.customWidth ? props.customWidth : "auto")};
  min-width: ${(props) => (props.customWidth ? props.customWidth : "auto")};
  text-align: ${(props) =>
    props.customAlign ? props.customAlign : "left"} !important;
`;

export interface IArea {
  name: string;
  level: number;
}

export interface IStudentData {
  _id: string;
  name: string;
  areas: IArea[];
  isNewEvidence: boolean;
  mm_locked_group_ids: string[];
}

export interface ISelectedWheelData {
  students: IStudentData[];
  areas: Array<{ label: string; value: string }>;
}

interface IProps {
  data: ISelectedWheelData;
  currentGroup: string[];
  groups: IGroupData[];
}

const SelectedWheelStudentList: React.FC<IProps> = ({
  data,
  currentGroup,
  groups,
}: IProps) => {
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const { wheelResults } = useContext(WheelsPageContext);

  const getAreaLevel = (areaName: string, areas: IArea[]): number => {
    const lookingArea = areas.filter((area) => area.name === areaName)[0];

    if (lookingArea) {
      return lookingArea.level;
    }

    return 1;
  };

  return (
    <StyledContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell customAlign="center" customWidth="200px">
              Name
            </StyledTableCell>
            <StyledTableCell customAlign="center" customWidth="150px">
              Updated Evidence
            </StyledTableCell>
            {data.areas.map((area, areaIndex) => (
              <StyledTableCell
                customAlign="center"
                key={`area-${areaIndex}`}
                customWidth="200px"
              >
                {area.label}
              </StyledTableCell>
            ))}
            <StyledTableCell customAlign="center" customWidth="150px">
              Group Status
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!!data.students && data.students.length ? (
            <>
              {data.students
                .slice(
                  currentPage * pageSize,
                  currentPage * pageSize + pageSize
                )
                .map((student, studentIndex) => {
                  const groupStatus = getStudentGroupStatus(
                    student,
                    currentGroup[0] || groups[0]._id
                  );
                  return (
                    <TableRow key={`student-${studentIndex}`}>
                      <TableCell align="center">
                        <StyledLink
                          to={`/teacher/my-wheels/${wheelResults._id}/${student._id}`}
                        >
                          {student.name}
                        </StyledLink>
                      </TableCell>
                      <TableCell align="center">
                        {student.isNewEvidence ? (
                          <Tooltip title="View details">
                            <StyledIconLink
                              to={`/teacher/my-wheels/${wheelResults._id}/${student._id}`}
                            >
                              <StyledIcon
                                className="uil uil-document-info"
                                color={palette.secondary}
                              />
                            </StyledIconLink>
                          </Tooltip>
                        ) : (
                          <StyledIcon className="uil uil-file-times" />
                        )}
                      </TableCell>
                      {data.areas.map((area, areaIndex) => (
                        <TableCell
                          align="center"
                          key={`student-area-value-${areaIndex}`}
                        >
                          {getAreaLevel(area.label, student.areas)}
                        </TableCell>
                      ))}
                      <TableCell align="center">
                        {groupStatus === "added" ? (
                          <S.Icon
                            className="uil uil-user-check"
                            style={{ color: "green" }}
                          />
                        ) : groupStatus === "locked" ? (
                          <S.Icon className="uil uil-padlock" />
                        ) : (
                          <S.Icon className="uil uil-times" />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </>
          ) : (
            <>
              <TableRow>
                <TableCell colSpan={5}>
                  <StyledNoDataContainer>No data found</StyledNoDataContainer>
                </TableCell>
              </TableRow>
            </>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={data.students.length}
              rowsPerPage={pageSize}
              page={currentPage}
              onChangePage={(event, page) => setCurrentPage(page)}
              onChangeRowsPerPage={(event) => {
                setPageSize(parseInt(event.target.value, 10));
                setCurrentPage(0);
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </StyledContainer>
  );
};

export default SelectedWheelStudentList;
