import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-v4-rtl/dist/css/bootstrap.css";
import { BrowserRouter, Route } from "react-router-dom";
import styled from "styled-components";
import MuiTheme from "./MuiTheme";
import { MuiThemeProvider } from "@material-ui/core";
import AdminPage from "./pages/admin/AdminPage";
import TeacherPage from "./pages/teacher/TeacherPage";
import StudentPage from "./pages/student/StudentPage";
import { FeatureBanner } from "components/featureBanner";
import { TokenPage } from "pages/token";

const StyledAppWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export function App() {
  return (
    <StyledAppWrapper>
      <MuiThemeProvider theme={MuiTheme}>
        <FeatureBanner />
        <BrowserRouter>
          <>
            <Route path="/admin" component={AdminPage} />
            <Route path="/student" component={StudentPage} />
            <Route path="/teacher" component={TeacherPage} />
            <Route path="/token" component={TokenPage} />
          </>
        </BrowserRouter>
      </MuiThemeProvider>
    </StyledAppWrapper>
  );
}
