import { Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { TWheelData } from "typings/meta-mirror";
import PrintComparisonWheel from "./PrintComparisonWheel";

const StyledHiddenPrintContainer = styled.div`
  display: none;
  height: 100%;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 5%;
  @media print {
    display: flex;
  }
`;

const StyledPrintHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10%;
  width: 100%;
`;

const StyledPrintWheelsContainer = styled.div`
  height: 90%;
  width: 100%;
  padding-top: 5%;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
`;

export interface IWheelPrintData {
  selectedGroup: string;
  selectedDate: string;
  wheelData: TWheelData;
}

interface IProps {
  wheelName: string;
  wheelsActive: boolean[];
  wheelsPrintData: IWheelPrintData[];
  printRef: React.MutableRefObject<HTMLDivElement>;
}

const PrintComparison: React.FC<IProps> = ({
  wheelName,
  wheelsActive,
  wheelsPrintData,
  printRef,
}: IProps) => {
  const getComparisonWheels = (): JSX.Element[] => {
    const wheels = [];
    wheelsPrintData.forEach((wheelPrintData, index) => {
      if (!!wheelPrintData) {
        wheels.push(
          <PrintComparisonWheel
            key={index}
            active={wheelsActive[index]}
            wheelData={wheelPrintData.wheelData}
            studentGroup={wheelPrintData.selectedGroup}
            date={wheelPrintData.selectedDate}
          />
        );
      }
    });
    return wheels;
  };

  return (
    <StyledHiddenPrintContainer ref={printRef}>
      <StyledPrintHeaderContainer>
        <Typography variant="h5">Compare: {wheelName}</Typography>
        <Typography variant="caption">
          Created on {new Date().toLocaleDateString()}
        </Typography>
      </StyledPrintHeaderContainer>
      <StyledPrintWheelsContainer>
        {getComparisonWheels()}
      </StyledPrintWheelsContainer>
    </StyledHiddenPrintContainer>
  );
};

export default PrintComparison;
