import { Theme } from '@material-ui/core';

type ThemeInterface = Exclude<Theme['overrides'], undefined>;

const MuiFormHelperText: ThemeInterface['MuiFormHelperText'] = {
  root: {
    textAlign: 'right',
  },
};

export default MuiFormHelperText;
