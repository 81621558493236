import { Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import Wheel from "components/common/Wheel/Wheel";
import palette from "palette";
import ReportHeaderSection from "./ReportHeaderSection";
import { TWheelData } from "typings/meta-mirror";
import ProgressChart, {
  ISeriesData,
} from "components/common/ProgressChart/ProgressChart";

const StyledContainer = styled.div`
  background: transparent;
  text-align: center;
  height: 1650px;
  width: 1250px;
  padding: 5%;
  transform: scale(0.652);
  position: absolute;
  top: -27%;
  left: -27%;
  @media print {
    transform: none;
    top: unset;
    left: unset;
    position: relative;
  }
`;

const StyledHeaderSection = styled.div`
  margin: 0 0 30px;
`;

const StyledReportSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const StyledChartSection = styled(StyledReportSection)`
  height: 500px;
`;

const StyledLineSpacing = styled.hr`
  margin-bottom: 30px;
  border-top: 2px solid ${palette.disabled};
`;

// const StyledCommentSection = styled.div`
//   width: 100%;
// `;

// const StyledCommentLine = styled.hr`
//   border-top: 2px dashed ${palette.disabled};
//   margin-bottom: 20px;
// `;

interface IProps {
  actualWheelResult: TWheelData;
  selectedDateWheelResult: TWheelData;
  progressChartResults: ISeriesData[];
  startDate: Date;
  studentName: string;
  wheelName: string;
  reportComment: string;
  wheelLevelType?: string;
}

const HistoricalReport: React.FC<IProps> = ({
  actualWheelResult,
  selectedDateWheelResult,
  progressChartResults,
  startDate,
  studentName,
  wheelName,
  reportComment,
  wheelLevelType,
}: IProps) => {
  return (
    <StyledContainer>
      <StyledHeaderSection>
        <ReportHeaderSection
          wheelName={wheelName}
          subtitle={studentName}
          comment={reportComment}
        />
      </StyledHeaderSection>
      <StyledReportSection>
        {!!selectedDateWheelResult && !!selectedDateWheelResult.length && (
          <div>
            <Typography variant="body2">
              {`${startDate.toLocaleString("default", {
                month: "long",
              })} ${startDate.getFullYear()}`}
            </Typography>
            <Wheel
              mirrorLevelType={wheelLevelType}
              data={selectedDateWheelResult}
              backgroundColor={palette.surface}
              width={550}
              height={400}
              labels
            />
          </div>
        )}
        {!!actualWheelResult && !!actualWheelResult.length && (
          <div>
            <Typography variant="body2">
              {`${new Date().toLocaleString("default", {
                month: "long",
              })} ${new Date().getFullYear()}`}
            </Typography>
            <Wheel
              mirrorLevelType={wheelLevelType}
              data={actualWheelResult}
              backgroundColor={palette.surface}
              width={550}
              height={400}
              labels
            />
          </div>
        )}
      </StyledReportSection>
      <StyledLineSpacing />
      {!!progressChartResults && !!progressChartResults.length && (
        <StyledChartSection>
          <div className="w-100">
            <Typography variant="body2">Student Progress</Typography>
            <ProgressChart chartData={progressChartResults} reportChart />
          </div>
        </StyledChartSection>
      )}
      {/* <StyledLineSpacing />
      <Typography variant="body2">
        Comments
      </Typography>
      <StyledCommentSection>
        {Array(5).fill('').map((value: string, index: number) => (
          <StyledCommentLine key={`comment-line-${index}`} />
        ))}
      </StyledCommentSection> */}
    </StyledContainer>
  );
};

export default HistoricalReport;
