import React, { Component } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import PageContainer from "components/common/PageContainer/PageContainer";
import Wheel from "components/common/Wheel/Wheel";
import styled from "styled-components";
import palette from "palette";
import { TWheelData, TLevel, ILevel, IArea } from "typings/meta-mirror";
import WheelSettings from "components/teacher/WheelSettings";
import AreaSettings from "components/teacher/AreaSettings";
import AddWheelModal from "components/teacher/AddWheelModal";
import { IGroupData } from "typings/group";
import { IResponseBody } from "typings/global";
import _ from "lodash";
import { RouteComponentProps } from "react-router";
import { addMirror, getMirrorsUsed } from "lib/mirror";
import { IWithAuth, withAuth } from "../../../hoc/withAuth";
import { getGroups } from "lib/group";

const INITIAL_AREAS_AND_LEVELS_SIZE = 2;
const MAX_LEVELS_AMOUNT = 10;

const StyledPaper = styled(Paper)`
  width: 100%;
  min-height: calc(100vh - 60px);
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  flex-grow: 1;
`;

const StyledLoader = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
`;

const StyledDivider = styled.div`
  width: 100%;
  height: 3px;
  border-radius: 15px;
  background-color: ${palette.disabled};
  margin: 15px 0 0;
`;

const StyledWheelCounter = styled(Typography)`
  margin-bottom: -7px !important;
  font-size: 18px !important;
`;

const StyledWheelTitle = styled(Typography)`
  padding-top: 8px !important;
  padding-right: 5px !important;
`;

const StyledWheelGridItem = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
`;

const StyledLoaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

const StyledLimitMessageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

const StyledLimitMessage = styled(Typography)`
  text-align: center;
`;

const StyledNoAccessText = styled(Typography)`
  text-align: center !important;
  top: 49% !important;
  width: 100% !important;
  position: absolute !important;
`;

const StyledValidationErrorMessage = styled(Typography)`
  text-align: right !important;
  color: ${palette.error} !important;
  padding-right: 15px !important;
  font-weight: bold !important;
`;

const StyledPageTitle = styled(Typography)`
  text-align: center !important;
`;

export interface IWheelSettings {
  wheelName: string;
  selectedGroups: string[];
  areas: number;
  levels: number;
  levelsType?: string;
}

interface IState {
  isLoading: boolean;
  isModalOpen: boolean;
  flushRTEs: boolean;
  areaSettingsVisible: boolean;
  wheelsUsed: number;
  wheelsLimit: number;
  studentGroups: IGroupData[];
  areaSettings: TWheelData;
  wheelSettings: IWheelSettings;
  validationError: string;
  mirrorLevelsType: string;
}

const generateEmptyLevels = (
  levelsLength?: number,
  startLevel?: number
): ILevel[] => {
  const preparedEmptyLevelsArr: ILevel[] = [];

  const length = startLevel
    ? levelsLength + startLevel
    : levelsLength || MAX_LEVELS_AMOUNT;
  const beginLevel = startLevel || 1;

  for (let i = beginLevel; i <= length && i <= MAX_LEVELS_AMOUNT; ++i) {
    preparedEmptyLevelsArr.push({
      level: i as TLevel,
      description: "",
      grade: null,
    });
  }

  return preparedEmptyLevelsArr;
};

const emptyArea = {
  _id: "",
  areaName: "",
  levels: generateEmptyLevels(),
};

interface IProps extends RouteComponentProps, IWithAuth {}

class AddWheelPage extends Component<IProps, IState> {
  timerId: number;
  state = {
    isLoading: true,
    isModalOpen: false,
    flushRTEs: false,
    areaSettingsVisible: false,
    wheelsUsed: 0,
    wheelsLimit: 0,
    studentGroups: [],
    validationError: "",
    areaSettings: [
      {
        _id: "0",
        areaName: "",
        levels: generateEmptyLevels(INITIAL_AREAS_AND_LEVELS_SIZE),
      },
      {
        _id: "1",
        areaName: "",
        levels: generateEmptyLevels(INITIAL_AREAS_AND_LEVELS_SIZE),
      },
    ],
    wheelSettings: {
      wheelName: "",
      selectedGroups: [],
      areas: INITIAL_AREAS_AND_LEVELS_SIZE,
      levels: INITIAL_AREAS_AND_LEVELS_SIZE,
      levelsType: "sequential",
    },
  };

  async componentDidMount(): Promise<void> {
    this.isAddWheelDisabled = _.debounce(this.isAddWheelDisabled, 500, {
      maxWait: 500,
      leading: true,
    });
    this.generateAreaSettings = _.debounce(this.generateAreaSettings, 500, {
      maxWait: 500,
      leading: false,
    });

    this.getWheelsUsed();
    const teacherId = this.props.user.roles?.teacher.id?._id;

    try {
      const groupsResult = await getGroups(teacherId);
      this.setState({ studentGroups: groupsResult.data[0].groups });
    } catch {}
    this.setState({ isLoading: false });

    // this timeout is for animation purposes
    this.timerId = setTimeout(() => {
      this.setState({ areaSettingsVisible: true });
    }, 750);
  }

  componentWillUnmount(): void {
    this.timerId && clearTimeout(this.timerId);
  }

  protected getWheelsUsed = async (): Promise<void> => {
    try {
      const teacherId = this.props.user.roles?.teacher.id?._id;
      const wheelsResult = await getMirrorsUsed(teacherId);
      const { wheelsLimit, wheelsUsed } = wheelsResult.data[0];
      this.setState({
        wheelsLimit,
        wheelsUsed,
      });

      if (wheelsResult.data[0]?.mirrorLevelsType) {
        const { wheelSettings } = this.state;
        this.setState({
          wheelSettings: {
            ...wheelSettings,
            levelsType: wheelsResult.data[0].mirrorLevelsType ?? "sequential",
          },
        });
      }
    } catch {}
  };

  /**
   * Function to generate the areaSettings object in state when the number of areas of levels changes.
   * @param newAreas - The new number of areas for the wheel being added.
   * @param newLevels - The new number of levels for the wheel being added.
   */
  protected generateAreaSettings = (
    newAreas: number,
    newLevels: number
  ): void => {
    const areaSettings = this.state.areaSettings;
    const oldAreas = areaSettings.length;
    const oldLevels = areaSettings[0].levels.length;

    // == Areas need to be added ==
    if (newAreas > oldAreas) {
      // Add new areas
      for (let i = oldAreas; i < newAreas; ++i) {
        const newArea = { ...emptyArea };
        newArea.levels = generateEmptyLevels(oldLevels);
        newArea._id = String(i);
        areaSettings.push(newArea);
      }
    }

    // == Areas need to be removed ==
    if (newAreas < oldAreas) {
      const areasToRemove = oldAreas - newAreas;
      // Remove old areas
      for (let i = 0; i < areasToRemove; ++i) {
        areaSettings.pop();
      }
    }

    if (newLevels > oldLevels) {
      areaSettings.forEach((area) => {
        for (let i = area.levels.length; i < newLevels; ++i) {
          area.levels.push(generateEmptyLevels(1, i + 1)[0]);
        }
      });
    }

    // Levels need to be decreased
    if (newLevels < oldLevels) {
      // Remove old levels to each area
      areaSettings.forEach((area) => {
        for (let i = area.levels.length; i > newLevels; --i) {
          area.levels.pop();
        }
      });
    }

    this.setState({ areaSettings });
  };

  protected handleChangeWheelSetting = (
    selectedFilter: Partial<IWheelSettings>
  ): void => {
    const { wheelSettings } = this.state;

    this.setState(
      {
        wheelSettings: {
          ...wheelSettings,
          ...selectedFilter,
        },
      },
      () => {
        if (["areas", "levels"].includes(Object.keys(selectedFilter)[0])) {
          const { areas, levels } = this.state.wheelSettings;
          this.generateAreaSettings(areas, levels);
        }
      }
    );
  };

  protected handleUpdateAreaSettings = (
    area: Partial<IArea>,
    areaIndex: number
  ): void => {
    const { areaSettings } = this.state;

    let areaSettingToUpdate = { ...areaSettings[areaIndex] };

    areaSettingToUpdate = { ...areaSettingToUpdate, ...area };

    areaSettings[areaIndex] = areaSettingToUpdate;

    this.setState({ areaSettings });
  };

  protected handleSetValidationErrorMessage = (
    validationError: string
  ): void => {
    this.setState({ validationError });
  };

  protected isAddWheelDisabled = (): boolean => {
    const { areaSettings, isLoading, areaSettingsVisible, wheelSettings } =
      this.state;

    if (isLoading || !areaSettingsVisible) {
      return true;
    }

    if (!wheelSettings.wheelName) {
      this.handleSetValidationErrorMessage(
        "Please input a name for this wheel."
      );
      return true;
    }

    if (!wheelSettings.selectedGroups.length) {
      this.handleSetValidationErrorMessage(
        "Please select at least one student group for this wheel."
      );
      return true;
    }

    for (const [index, area] of areaSettings.entries()) {
      if (area.areaName === "") {
        this.handleSetValidationErrorMessage(
          `Please input a name for Area ${index + 1}.`
        );
        return true;
      }
      for (const level of area.levels) {
        if (!level.description || level.description === "<p></p>") {
          this.handleSetValidationErrorMessage(
            `Please input a rubric for Area ${index + 1}: Level ${level.level}.`
          );
          return true;
        }
      }
    }

    this.handleSetValidationErrorMessage("");
    return false;
  };

  protected handleAddWheel = async (): Promise<IResponseBody> => {
    if (this.isAddWheelDisabled()) {
      return;
    }

    const { wheelSettings, areaSettings } = this.state;

    try {
      const response = await addMirror(
        this.props.user.roles?.teacher.id?._id,
        wheelSettings.wheelName,
        wheelSettings.selectedGroups,
        areaSettings,
        wheelSettings.levelsType
      );

      this.setState({ flushRTEs: true });
      this.getWheelsUsed();
      this.setState({
        flushRTEs: false,
        areaSettings: [
          {
            areaName: "",
            levels: generateEmptyLevels(INITIAL_AREAS_AND_LEVELS_SIZE),
          },
          {
            areaName: "",
            levels: generateEmptyLevels(INITIAL_AREAS_AND_LEVELS_SIZE),
          },
          {
            areaName: "",
            levels: generateEmptyLevels(INITIAL_AREAS_AND_LEVELS_SIZE),
          },
        ],
        wheelSettings: {
          wheelName: "",
          selectedGroups: [],
          areas: INITIAL_AREAS_AND_LEVELS_SIZE,
          levels: INITIAL_AREAS_AND_LEVELS_SIZE,
        },
      });

      return response;
    } catch (err) {
      return err;
    }
  };

  protected handleToggleModalState = (isOpen: boolean): void => {
    if (isOpen && this.isAddWheelDisabled()) {
      return;
    }
    this.setState({ isModalOpen: isOpen });
  };

  render() {
    const {
      isLoading,
      isModalOpen,
      wheelsUsed,
      wheelsLimit,
      wheelSettings,
      studentGroups,
      areaSettings,
      flushRTEs,
      areaSettingsVisible,
      validationError,
    } = this.state;

    const { history } = this.props;

    return (
      <PageContainer>
        {isLoading ? (
          <StyledLoader />
        ) : (
          <>
            {!!studentGroups.length ? (
              <>
                <AddWheelModal
                  open={isModalOpen}
                  handleClose={() => {
                    this.handleToggleModalState(false);
                    history.push("/teacher/manage-wheels");
                  }}
                  wheelName={wheelSettings.wheelName}
                  wheelData={areaSettings}
                  handleAddWheel={this.handleAddWheel}
                  mirrorLevelType={wheelSettings.levelsType}
                />
                <StyledPaper>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={4}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => history.push("/teacher/manage-wheels")}
                      >
                        back to manage
                      </Button>
                    </Grid>
                    <Grid item xs={4}>
                      <StyledPageTitle variant="h6">Add Wheel</StyledPageTitle>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-end"
                      >
                        <Grid item>
                          {isLoading ? (
                            <CircularProgress />
                          ) : (
                            <>
                              <StyledWheelCounter align="right" variant="h6">
                                {wheelsLimit - wheelsUsed} / {wheelsLimit}
                              </StyledWheelCounter>
                              <Typography variant="caption">
                                Wheels Remaining
                              </Typography>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <StyledDivider />

                  <Grid
                    container
                    direction="column"
                    justifyContent="space-evenly"
                    alignItems="stretch"
                    className="d-flex flex-grow-1 justify-content-start"
                  >
                    {wheelsUsed >= wheelsLimit ? (
                      <StyledLimitMessageContainer>
                        {wheelsLimit === 0 ? (
                          <StyledLimitMessage variant="body1">
                            Your organisation is unable to create wheels right
                            now.
                            <br />
                            Please contact an administrator for more
                            information.
                          </StyledLimitMessage>
                        ) : (
                          <StyledLimitMessage variant="body1">
                            Your organisation has used up all available wheels.
                            <br />
                            To add more wheels please increase your school wheel
                            limit, or request for a wheel to be deleted.
                          </StyledLimitMessage>
                        )}
                      </StyledLimitMessageContainer>
                    ) : (
                      <>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-evenly"
                          alignItems="stretch"
                          className="mt-1 mt-lg-3"
                        >
                          <Grid
                            item
                            xs={12}
                            lg={8}
                            className="pb-0 pb-md-4 order-1 order-lg-0"
                          >
                            <WheelSettings
                              wheelName={wheelSettings.wheelName}
                              selectedGroups={wheelSettings.selectedGroups}
                              studentGroups={studentGroups}
                              areas={wheelSettings.areas}
                              levels={wheelSettings.levels}
                              updateWheelSetting={this.handleChangeWheelSetting}
                              levelsType={wheelSettings.levelsType}
                            />
                          </Grid>
                          <StyledWheelGridItem
                            item
                            xs={12}
                            lg={4}
                            className="order-0 order-lg-1"
                          >
                            <StyledWheelTitle
                              variant="caption"
                              className="mx-auto"
                            >
                              Preview
                            </StyledWheelTitle>
                            <Wheel
                              data={areaSettings}
                              height={300}
                              width={300}
                              backgroundColor={palette.surface}
                              labels={false}
                              interactive={false}
                              preview
                            />
                          </StyledWheelGridItem>
                        </Grid>

                        <StyledDivider />

                        <Grid
                          container
                          direction="column"
                          justifyContent="space-evenly"
                          alignItems="stretch"
                          className="mt-3 d-flex flex-column flex-grow-1 justify-content-start"
                        >
                          <Grid item xs={12} className="d-flex flex-grow-1">
                            {areaSettingsVisible ? (
                              <AreaSettings
                                areaSettings={areaSettings}
                                flushRTEs={flushRTEs}
                                mirrorLevelType={wheelSettings.levelsType}
                                updateAreaSettings={
                                  this.handleUpdateAreaSettings
                                }
                              />
                            ) : (
                              <StyledLoaderContainer>
                                <CircularProgress />
                              </StyledLoaderContainer>
                            )}
                          </Grid>
                        </Grid>

                        <StyledDivider />
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                          className="mt-3"
                        >
                          <Grid item>
                            <StyledValidationErrorMessage variant="body1">
                              {validationError}
                            </StyledValidationErrorMessage>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => this.handleToggleModalState(true)}
                            >
                              add wheel
                            </Button>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </StyledPaper>
              </>
            ) : (
              <StyledNoAccessText variant="body2">
                You are currently not assigned to any groups, and are therefore
                unable to create new wheels.
              </StyledNoAccessText>
            )}
          </>
        )}
      </PageContainer>
    );
  }
}

export default withAuth(AddWheelPage, ["teacher", "admin"]);
