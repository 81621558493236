import React from "react";
import { SelectInputProps } from "@material-ui/core/Select/SelectInput";

import Select from "components/common/Select/Select";

import { useFeedback } from "../context";
import { useUpdateEvidence } from "./context";

type Change = SelectInputProps["onChange"];

export const LevelSelect = () => {
  const { wheelLevelType } = useFeedback();
  const { level, levels, loading, setLevel } = useUpdateEvidence();

  const handleChange: Change = ({ target }) => setLevel(target.value as string);

  return (
    <Select
      className="text-left"
      disabled={loading}
      disableMargin
      label={wheelLevelType === "non-sequential" ? "Part" : "Level"}
      onChange={handleChange}
      options={levels}
      value={level}
    />
  );
};
