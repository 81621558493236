import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import styled from "styled-components";
import palette from "palette";

interface IProps {
  selected: boolean;
  onUpdate: (state: boolean) => void;
  disabled: boolean;
}

const StyledOptionsContainer = styled(FormGroup)`
  display: flex;
  align-items: center;
  flex-direction: row !important;
`;

const StyledFormLabel = styled.div`
  font-size: 14px;
  color: ${palette.font.body};
  margin: 0 0 -8px;
`;

const LockedGroupFilter: React.FC<IProps> = ({
  selected,
  onUpdate,
  disabled,
}: IProps) => {
  return (
    <FormControl>
      <FormGroup>
        <StyledFormLabel>Locked Students</StyledFormLabel>
        <StyledOptionsContainer>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(event) => onUpdate(event.target.checked)}
                checked={selected}
                color="primary"
                disabled={disabled}
              />
            }
            label=""
          />
        </StyledOptionsContainer>
      </FormGroup>
    </FormControl>
  );
};

export default LockedGroupFilter;
