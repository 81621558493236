import { axiosInstance } from "helpers/axios-instance";

export const getReleases = async (userId: string) =>
  axiosInstance.get("/release/latest", {
    params: {
      application: 'meta-mirror',
      userId,
      application: "meta-mirrors",
    },
  });

export const putRelease = async (userId: string, releaseId: string) =>
  axiosInstance.put("/release/viewed", {
    userId,
    releaseId,
  });
