import { Button, Paper, Typography } from "@material-ui/core";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Wheel from "components/common/Wheel/Wheel";
import { useWheelsPage } from "pages/student/sections/wheelsPage/context";
import palette from "palette";
import { TWheelData } from "typings/meta-mirror";

const StyledPaper = styled(Paper)`
  padding: 10px 20px 10px 0px;
  @media (max-width: 767px) {
    padding: 0px 20px 20px;
  }
`;

const StyledWheelContainer = styled.div`
  @media (max-width: 767px) {
    justify-content: center;
    display: flex;
  }
`;

const StyledFullHeightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledRow = styled.div`
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const StyledTextRow = styled(StyledRow)`
  align-content: flex-end;
  align-items: flex-end;
`;

const StyledFeedbackRow = styled(StyledRow)`
  align-items: flex-start;
  align-content: flex-start;
`;

const StyledFeedbackIcon = styled.i`
  color: ${palette.secondary};
  font-size: 20pt;
`;

const StyledFeedbackText = styled(Typography)`
  margin-left: 5px !important;
  line-height: 28pt !important;
  font-weight: 800 !important;
  color: ${palette.secondary} !important;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  min-width: 100px;
  :hover {
    text-decoration: none;
  }
  width: 100%;
`;

interface IProps {
  wheelData: TWheelData;
  wheelName: string;
  wheelId: string;
  newFeedback?: boolean;
  isLocked?: boolean;
}

const WheelCard: React.FC<IProps> = ({
  wheelData,
  wheelName,
  wheelId,
  newFeedback,
  isLocked,
}: IProps) => {
  const { isUser, profile } = useWheelsPage();
  const [localWheelData, setLocalWheelData] = useState(wheelData);

  const link = React.useMemo(() => {
    if (isUser) return `/student/${wheelId}`;
    return `/teacher/my-wheels/${wheelId}/${profile._id}`;
  }, [isUser]);

  useEffect(() => {
    setLocalWheelData(wheelData);
  }, [wheelData]);

  return (
    <StyledPaper className="d-flex flex-column flex-md-row">
      <StyledWheelContainer>
        <Wheel
          backgroundColor={palette.surface}
          data={localWheelData}
          height={200}
          width={200}
          labels={false}
          interactive={false}
        />
      </StyledWheelContainer>
      {newFeedback ? (
        <StyledFullHeightContainer className="flex-grow-1">
          <StyledTextRow className="justify-content-md-start">
            <Typography variant="h5">{wheelName}</Typography>
          </StyledTextRow>
          <StyledFeedbackRow className="justify-content-md-start">
            <StyledFeedbackIcon className="uil uil-comment-alt-exclamation" />
            <StyledFeedbackText>New Feedback</StyledFeedbackText>
          </StyledFeedbackRow>
        </StyledFullHeightContainer>
      ) : (
        <StyledFullHeightContainer className="align-items-md-start flex-grow-1">
          <Typography variant="h5">
            {wheelName}
            {isLocked && <i className="uil uil-padlock" />}
          </Typography>
        </StyledFullHeightContainer>
      )}
      <StyledFullHeightContainer>
        <StyledLink to={link} className="pt-2 pt-md-0">
          <Button
            variant="contained"
            color={newFeedback ? "secondary" : "primary"}
            fullWidth
          >
            View
          </Button>
        </StyledLink>
      </StyledFullHeightContainer>
    </StyledPaper>
  );
};

export default WheelCard;
