import React, { useState } from "react";
import {
  CircularProgress,
  IconButton,
  TableCell,
  TableRow,
} from "@material-ui/core";
import TextInput from "components/common/TextInput/TextInput";
import styled from "styled-components";
import { IGroupData } from "typings/group";
import { updateGroup } from "lib/group";

const StyledTableCell = styled(TableCell)`
  display: flex;
  flex-direction: row;
  width: 33.333%;
`;

const StyledTextInput = styled(TextInput)`
  margin: 0 0 0 40px !important;
`;

const StyledIcon = styled.i`
  font-size: 14px;
`;

const StyledGroupName = styled.p`
  margin: 0 5px 0 40px;
`;

const StyledNameContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

interface IProps {
  groupData: IGroupData;
}

const GroupsListRow: React.FC<IProps> = ({ groupData }: IProps) => {
  const [editMode, setEditMode] = useState(false);
  const [groupName, setGroupName] = useState(groupData.name);
  const [loading, setLoading] = useState(false);

  const handleChangeGroupName = async (): Promise<void> => {
    setLoading(true);
    try {
      await updateGroup(groupData._id, groupName);
      setLoading(false);
      setEditMode(false);
    } catch {
      setLoading(false);
      setGroupName(groupData.name);
    }
  };

  return (
    <TableRow>
      <StyledTableCell align="center">{groupData._id}</StyledTableCell>
      <StyledTableCell align="center">
        {editMode ? (
          <>
            <StyledTextInput
              value={groupName}
              onChange={(event) => setGroupName(event.target.value)}
              inputProps={{
                style: {
                  textAlign: "center",
                },
              }}
            />
            <IconButton
              onClick={() => handleChangeGroupName()}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={12} />
              ) : (
                <StyledIcon className="uil uil-check" />
              )}
            </IconButton>
          </>
        ) : (
          <>
            <StyledNameContainer>
              <StyledGroupName>{groupName}</StyledGroupName>
              <IconButton onClick={() => setEditMode(true)}>
                <StyledIcon className="uil uil-pen" />
              </IconButton>
            </StyledNameContainer>
          </>
        )}
      </StyledTableCell>
      <StyledTableCell align="center">{groupData.createdAt}</StyledTableCell>
    </TableRow>
  );
};

export default GroupsListRow;
