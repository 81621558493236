const palette = {
  primary: '#2C99F8',
  primaryLight: '#DAEFFF',
  primaryDark: '#2086DF',
  secondary: '#FF6F91',
  secondaryLight: '#FFE5F6',
  secondaryDark: '#E35174',
  attempting: '#6CD9FF',
  target: '#FF8F50',
  partial: '#FAC545',
  complete: '#23D692',
  error: '#c23616' ,
  disabled: '#E1E8F0',
  surface: '#FFFFFF',
  background: '#F5F6FA',
  font: {
    heading: '#474D6C',
    body: '#687192',
    caption: '#707099',
    contrast: '#FEFEFE',
  },
};

export default palette;
