import React, { useState } from "react";
import styled from "styled-components";
import {
  IconButton,
  Tooltip,
  Typography,
  TypographyProps,
} from "@material-ui/core";
import palette from "palette";
import { updateFeedback } from "lib/feedback";

const StyledContainer = styled.div<{ viewed: boolean }>`
  width: 100%;
  border: 2px solid
    ${(props) => (props.viewed ? palette.font.body : palette.secondary)};
  border-radius: 10px;
  color: ${(props) => (props.viewed ? palette.font.body : palette.secondary)};
  padding: 12px;
  display: flex;
  flex-direction: column;
  &:not(:first-child) {
    margin: 10px 0 0;
  }
`;

const StyledHeaderContainer = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
`;

const StyledHeaderLeftContainer = styled.div<{ viewed: boolean }>`
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: ${(props) => (props.viewed ? "0" : "-5px !important")};
`;

interface IStyledHeaderTextProps extends TypographyProps {
  fontSize: number;
  fontWeight: number;
  viewed: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledHeaderText = styled(
  ({ fontSize, fontWeight, viewed, ...props }: IStyledHeaderTextProps) => (
    <Typography {...props} />
  )
)`
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : 400} !important;
  font-size: ${(props) => (props.fontSize ? props.fontSize : 16)}px !important;
  color: ${(props) =>
    props.viewed ? palette.font.body : palette.secondary} !important;
`;

const StyledHeaderLinkText = styled(StyledHeaderText)`
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  max-width: 65% !important;
`;

const StyledCommentSection = styled.div`
  margin: 5px 0 0;
`;

const StyledHeaderIcon = styled.i`
  color: ${palette.secondary};
  margin-right: 10px !important;
  font-size: 35px;
`;

const StyledHeaderButtonIcon = styled.i`
  color: ${palette.secondary};
  font-size: 25px;
`;

const StyledIconButton = styled(IconButton)`
  margin-right: 10px !important;
  margin-left: -5px !important;
`;

interface IProps {
  teacherName: string;
  date: string;
  evidenceName: string;
  id: string;
  comment: string;
  viewed: boolean;
  variant: "admin" | "student" | "teacher";
}

const FeedbackComment: React.FC<IProps> = ({
  teacherName,
  date,
  evidenceName,
  comment,
  viewed,
  variant,
  id,
}: IProps) => {
  const [isViewed, setIsViewed] = useState(viewed);

  const handleMarkAsViewed = async (): Promise<void> => {
    try {
      await updateFeedback({ feedbackId: id, viewed: true });
      setIsViewed(true);
    } catch {}
  };

  return (
    <StyledContainer viewed={isViewed}>
      <StyledHeaderContainer>
        <StyledHeaderLeftContainer viewed={isViewed}>
          {!isViewed && (
            <>
              {variant === "student" ? (
                <Tooltip title="Mark feedback as seen">
                  <StyledIconButton onClick={handleMarkAsViewed}>
                    <StyledHeaderButtonIcon className="uil uil-eye" />
                  </StyledIconButton>
                </Tooltip>
              ) : (
                <Tooltip
                  title="Student has not marked this as seen yet"
                  placement="right"
                >
                  <StyledHeaderIcon className="uil uil-comment-info-alt" />
                </Tooltip>
              )}
            </>
          )}
          <div>
            <StyledHeaderText
              viewed={isViewed}
              variant="body1"
              fontSize={16}
              fontWeight={700}
            >
              {teacherName}
            </StyledHeaderText>
            <StyledHeaderText
              viewed={isViewed}
              variant="body1"
              fontSize={12}
              fontWeight={500}
            >
              {date}
            </StyledHeaderText>
          </div>
        </StyledHeaderLeftContainer>
        <StyledHeaderLinkText
          viewed={isViewed}
          variant="body1"
          fontSize={14}
          fontWeight={700}
        >
          {evidenceName}
        </StyledHeaderLinkText>
      </StyledHeaderContainer>
      <StyledCommentSection>{comment}</StyledCommentSection>
    </StyledContainer>
  );
};

export default FeedbackComment;
