export const VALID_FILE_TYPES = [
  "image/gif",
  "image/png",
  "image/jpeg",
  "image/svg+xml",
  "image/webp",
  "image/bmp",
  "application/pdf",
];

export const ERROR_INVALID =
  "Invalid file format. Please upload a valid file format.";

export const ERROR_READER =
  "Something went wrong when uploading your file.\nPlease try again.";

export const ERROR_SIZE =
  "File size is too large. Please upload a file less than 10MB in size.";
