import React from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';

type IProps = Omit<TextFieldProps, 'variant' | 'margin'>;

/**
 * A TextInput component based on the MUI TextField.
 * Has been pre-styled, supports multiline and singleline.
 */
const TextInput: React.FC<IProps> = ({ ...props }: IProps) => {
  return (
    <TextField
      variant="outlined"
      margin="dense"
      {...props}
    />
  );
};

export default TextInput;
