import { Button } from "@material-ui/core";
import { Send } from "@material-ui/icons";
import _ from "lodash";
import React from "react";

import TextInput from "components/common/TextInput/TextInput";

import { useFeedback } from "./context";

export const FeedbackInput = () => {
  const {
    editing,
    feedback,
    loading,
    setFeedback,
    submitDisabled,
    submitMessage,
    updateMessage,
  } = useFeedback();

  const ref = React.createRef<HTMLTextAreaElement>();

  React.useEffect(() => {
    // @ts-ignore
    editing && ref.current?.firstChild?.focus();
  }, [editing]);

  return (
    <TextInput
      disabled={loading}
      label="Feedback Comment"
      maxRows={3}
      minRows={3}
      multiline
      onChange={({ target: { value } }) => setFeedback(value)}
      value={feedback}
      InputProps={{
        ref,
        endAdornment: (
          <Button
            color="primary"
            disabled={submitDisabled}
            onClick={editing ? updateMessage : submitMessage}
            variant="contained"
            style={{ height: 69 }}
          >
            <Send style={{ marginRight: ".5rem" }} />
            {editing ? "Update" : "Send"}
          </Button>
        ),
      }}
      style={{ width: "100%", marginBottom: 0 }}
    />
  );
};
