import AnimatedModal from "components/common/AnimatedModal";
import React, { useEffect, useMemo, useState } from "react";
import { Typography } from "@material-ui/core";
import { S } from "./styles";
import palette from "palette";
import { IGroup, IStudent } from "@rose-arch-holdings-ltd/common";
import { getMirrorList } from "lib/mirror";

interface IProps {
  isOpen: boolean;
  handleCloseModal: () => void;
  group: IGroup | undefined;
  studentNames: string[];
  lockGroup: () => void;
  submitted: boolean;
  error: boolean;
  schoolStudents: IStudent[];
}

export const LockStudentGroupModel: React.FC<IProps> = ({
  isOpen,
  handleCloseModal,
  error,
  lockGroup,
  submitted,
  studentNames,
  group,
  schoolStudents,
}) => {
  const [mirrorNames, setMirrorNames] = useState<string[]>([]);

  useEffect(() => {
    if (group?._id) {
      getMirrorList([group?._id]).then((res) => {
        const names = res.data.map((g: IGroup) => g.name);
        setMirrorNames(names);
      });
    }
  }, [group, studentNames]);

  return (
    <AnimatedModal
      open={isOpen}
      title="Lock Students in Group"
      handleClose={() => handleCloseModal()}
      maxWidth="625px"
      minHeight="200px"
      width="100%"
    >
      <>
        <Typography align={"center"} variant="body2">
          The following students will be locked for group{" "}
          <strong>{group?.name}</strong>:
          <br />
          {studentNames
            .map((s) => {
              const student = schoolStudents.find(
                (v) => v._id.toString() === s
              );
              return student?.name;
            })
            .join(", ")}
          <br />
          <br />
          The above students will lose the ability to submit new evidence for
          the following wheels:
          <br />
          <strong>{mirrorNames.join(", ")}</strong>
          <br />
          <br />
          <strong style={{ color: "red" }}>
            This action will prevent a student from submitting new evidence for
            the above wheels.
          </strong>
          <br />
          <br />
          Student will still be able to view grades, feedback, and submitted
          evidence.
        </Typography>
        <Typography
          style={{
            color: !error ? palette.complete : palette.error,
            marginBottom: "20px",
            textAlign: "center",
          }}
          variant="body2"
        >
          {error && "Error locking group"}
          {submitted && !error && "Successfully locked"}
        </Typography>
        <S.ActionContainer>
          <S.Button
            variant="outlined"
            color="primary"
            onClick={() => handleCloseModal()}
          >
            cancel
          </S.Button>
          <S.Button
            variant="contained"
            color="primary"
            disabled={submitted}
            onClick={() => lockGroup()}
          >
            lock
          </S.Button>
        </S.ActionContainer>
      </>
    </AnimatedModal>
  );
};
