import { Button, CircularProgress, Typography } from "@material-ui/core";
import styled from "styled-components";

import { mediaRules } from "MuiTheme/Breakpoints";
import palette from "MuiTheme/palette";
import AnimatedModal from "components/common/AnimatedModal";

export const ButtonLink = styled(Button)({
  "& .MuiButton-label": {
    color: "rgb(0, 123, 255)",
  },
  "& .MuiButton-endIcon": { marginLeft: "2px" },
});

export const ModalContainer = styled.div({
  alignIte: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

export const StyledCircularProgress = styled(CircularProgress)({
  height: "25px !important",
  width: "25px !important",
  margin: "0 10px 0 0 !important",
});

export const StyledIcon = styled.i({
  fontSize: "20px",
  margin: "0 5px 0 0",
});

export const StyledInput = styled.input({
  width: 0,
  height: 0,
  opacity: 0,
  overflow: "hidden",
  position: "absolute",
  zIndex: "-1",
});

export const StyledInputContainer = styled.div({
  marginBottom: 4,
  marginTop: 8,
  [`@media ${mediaRules.sm}`]: {
    padding: 0,
    margin: "15px 0",
  },
});

export const StyledLabel = styled.label<{ fileUploaded: boolean }>(
  ({ fileUploaded }) => ({
    padding: "0 15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "100%",
    height: "40px",
    color: !!fileUploaded ? palette.font.contrast : palette.secondary,
    backgroundColor: !!fileUploaded ? palette.secondary : palette.font.contrast,
    border: `1px solid ${palette.secondary}`,
    borderWidth: "3px",
    borderRadius: "10px",
    fontSize: "18px",
    fontWeight: "700",
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      backgroundColor: !!fileUploaded
        ? palette.secondaryDark
        : palette.secondaryLight,
    },
  })
);

export const StyledModal = styled(AnimatedModal)({
  "& .MuiGrid-container > .MuiGrid-root:nth-child(2)": {
    marginTop: "1rem",
  },
});

export const P = Object.assign(
  styled.div<{ border?: boolean }>(({ border }) => ({
    marginTop: ".5rem",
    alignContent: "center",
    alignItems: "center",
    border: border ? `solid 2px ${palette.font.caption}88` : "none",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    justifyItems: "center",
    width: "100%",
  })),
  {
    Comment: styled(Typography)({
      fontStyle: "italic !important",
      margin: "10px !important",
      maxWidth: "100%",
      overflowX: "scroll",
      padding: "0 25px !important",
      textAlign: "center",
    }),

    Container: styled.div({
      alignItems: "center",
      borderRadius: "10px",
      display: "flex",
      height: "100%",
      overflow: "hidden",
      width: "100%",
    }),

    Description: styled(Typography)({
      margin: "10px 0 !important",
      maxWidth: "100%",
      position: "relative",
      textAlign: "center",
    }),

    Embed: styled.embed({
      border: "none",
      borderRadius: "10px",
      height: "100%",
      width: "100%",
    }),

    Image: styled.img({
      borderRadius: "5px",
      boxShadow:
        "0 8px 22px -6px rgba(24, 39, 75, 0.12), 0 14px 64px -4px rgba(24, 39, 75, 0.12)",
      height: "100%",
      width: "100%",
    }),

    Link: styled(Typography)({
      border: "1px solid #c8c7c7",
      borderRadius: "3px",
      fontstyle: "italic !important",
      margin: "10px 10px !important",
      maxWidth: "100%",
      overflowX: "scroll",
      padding: "5px 25px !important",
      textAlign: "left",
    }),

    LinkButton: styled(Button)({ margin: "15px 0 !important" }),

    LinkIcon: styled.i({
      color: palette.secondary,
      fontSize: "10vh",
      lineHeight: "10vh",
    }),

    Section: styled.div({
      backgroundColor: "#F4F4F7",
      borderRadius: "10px",
      height: "100%",
      padding: "20px",
      width: "100%",
    }),
  }
);
