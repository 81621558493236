import React, { useEffect, useState } from "react";
import { TOption } from "components/common//Select/Select";
import {
  CircularProgress,
  Typography,
  TypographyProps,
} from "@material-ui/core";
import styled from "styled-components";
import palette from "palette";
import { mediaRules } from "MuiTheme/Breakpoints";
import { ILevel } from "typings/meta-mirror";
import GradeButton from "components/common/GradeButton/GradeButton";
import GradeIcon from "components/common/GradeIcon";
import { getStudentCurrentGradeByArea } from "lib/student";
import { IWithAuth, withAuth } from "../../../hoc/withAuth";
import { LEVEL_PARTS } from "constants/index";

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  width: 95%;
  padding: 20px 0 15px 0;
  text-align: center !important;
  @media ${mediaRules.sm} {
    width: 100%;
  }
`;

const StyledErrorMessage = styled(Typography)`
  color: ${palette.error} !important;
  min-height: 30px;
  @media (max-width: 959px) {
    min-height: 45px;
  }
  @media ${mediaRules.sm} {
    min-height: 60px;
  }
`;

const StyledInputContainer = styled.div`
  padding: 0 10%;
  margin: 5px 0;
  @media ${mediaRules.sm} {
    padding: 0;
    margin: 15px 0;
  }
`;

const StyledReviewActionsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  max-height: 100%;
  overflow-y: auto;
  padding-right: 5px;
  padding-top: 20px;
  margin-right: 5px;
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 7px;
    background-color: ${palette.font.caption};
  }
  @media ${mediaRules.lg} {
    max-height: unset;
  }
`;

const StyledNewStatusLabel = styled(Typography)`
  margin: 5px 0 10px 0 !important;
  text-align: left;
  padding-left: 10px;
  font-weight: bold !important;
`;

const StyledNewGradeButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const StyledLoadingContainer = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
`;

const StyledLoader = styled(CircularProgress)`
  position: absolute !important;
  left: calc(50% - 20px) !important;
  top: calc(50% - 20px) !important;
`;

interface IProps extends IWithAuth {
  error: string;
  requesting: boolean;
  selectedAreaId: string;
  wheelAreaLevels: ILevel[];
  selectedLevelId: string;
  selectedGrade: ILevel["grade"];
  handleSetStudentGrade(grade: ILevel["grade"]): void;
  handleSelectLevel(selectedAreaId: string): void;
  mirrorLevelType: string;
}

const SubmitGrade: React.FC<IProps> = ({
  error,
  selectedAreaId,
  wheelAreaLevels,
  selectedLevelId,
  selectedGrade,
  handleSetStudentGrade,
  handleSelectLevel,
  user,
  mirrorLevelType,
}: IProps) => {
  const [loading, setLoading] = useState(true);
  const [currentLevelId, setCurrentLevelId] = useState("");
  const [currentGrade, setCurrentGrade] = useState<ILevel["grade"]>(null);
  const [currentStudentGrade, setCurrentStudentGrade] =
    useState<ILevel["grade"]>(null);
  const [currentTeacherGrade, setCurrentTeacherGrade] =
    useState<ILevel["grade"]>(null);
  const [levelOptions, setLevelOptions] = useState<TOption[]>([]);
  const [studLevelId, setStudLevelId] = useState("");

  const getLevels = (levelIdIn: string): TOption[] => {
    const levels = wheelAreaLevels.sort((a, b) => a.level - b.level);
    const currentLevel = wheelAreaLevels.find(
      (level) => level._id === levelIdIn
    );
    let levelDisabled = !!levelIdIn;
    const levelOptionsArr: TOption[] = [];
    levels.forEach((level) => {
      if (levelDisabled && level.level >= currentLevel.level) {
        levelDisabled = false;
      }
      levelOptionsArr.push({
        label:
          mirrorLevelType !== "non-sequential"
            ? `Level ${level.level}`
            : `Part ${LEVEL_PARTS[level.level - 1]}`,
        value: level._id,
        disabled: levelDisabled,
      });
    });

    const enabledOptions = levelOptionsArr.filter(
      (option) => option.disabled === false
    );
    if (
      enabledOptions.length >= 1 &&
      mirrorLevelType !== "non-sequential" &&
      (selectedLevelId === "" ||
        !enabledOptions.find((option) => option.value === selectedLevelId))
    ) {
      handleSelectLevel(enabledOptions[0].value as string);
    }
    return levelOptionsArr;
  };

  const isGradeButtonDisabled = (grade: ILevel["grade"]): boolean => {
    const gradeToValue = {
      null: 0,
      "0": 1,
      "0.5": 2,
      "1": 3,
    };

    const teacherGrade: number = gradeToValue[String(currentGrade || null)];
    const studentGrade: number =
      gradeToValue[String(currentStudentGrade || null)];
    const gradeParam: number = gradeToValue[String(grade)];

    const sync =
      selectedLevelId === currentLevelId &&
      gradeParam < teacherGrade &&
      mirrorLevelType !== "non-sequential";

    let gradeToJudge = 0;
    if (currentTeacherGrade !== null) {
      gradeToJudge = teacherGrade;
    } else {
      gradeToJudge = studentGrade;
    }

    const nonSync =
      selectedLevelId === studLevelId &&
      gradeParam < gradeToJudge &&
      mirrorLevelType === "non-sequential";

    return sync || nonSync;
  };

  useEffect(() => {
    if (!levelOptions.length) {
      (async () => {
        let levelId = wheelAreaLevels.find((level) => level.level === 1)._id;

        const { data } = await getStudentCurrentGradeByArea(
          selectedAreaId,
          user?.roles?.student?.id._id,
          mirrorLevelType === "non-sequential" ? selectedLevelId : undefined
        );

        let result = null;
        if (data.data.teacherLevelId) {
          levelId = data.data.teacherLevelId;
          result = data.data.teacherResult;
          setCurrentTeacherGrade(result);
        }
        setCurrentLevelId(levelId);
        setCurrentGrade(result);
        setLevelOptions(getLevels(levelId));

        setCurrentStudentGrade(data.data.studentResult);
        setStudLevelId(data.data.studentLevelId);

        const currentLevel = wheelAreaLevels.find(
          (level) => level._id === levelId
        ).level;
        const selectedLevel = wheelAreaLevels.find(
          (level) => level._id === selectedLevelId
        ).level;

        if (currentLevel === selectedLevel) {
          if (mirrorLevelType !== "non-sequential") {
            handleSetStudentGrade(result);
          }
        }
      })();
    }

    if (levelOptions.length && loading) {
      setLoading(false);
    }
  }, [levelOptions]);

  const renderLoading = (): void | JSX.Element => {
    if (loading) {
      return (
        <StyledLoadingContainer>
          <StyledLoader />
        </StyledLoadingContainer>
      );
    }
    return;
  };

  const renderSelectGradeInput = (): void | JSX.Element => {
    if (loading) {
      return;
    }
    return (
      <>
        <Typography variant="body1">
          You&apos;re submitting evidence for{" "}
          <b>
            {levelOptions.find(({ value }) => value === selectedLevelId).label}.
          </b>{" "}
          Select the grade you think you have achieved for this level below.
          <br /> <br />
          {mirrorLevelType !== "non-sequential" ? (
            <>
              The last grade you achieved from a teacher for this area was{" "}
              <GradeIcon
                grade={currentGrade}
                text={
                  levelOptions.find(({ value }) => value === currentLevelId)
                    .label
                }
              />
            </>
          ) : null}
        </Typography>
        <StyledInputContainer>
          <StyledReviewActionsContainer>
            <StyledNewStatusLabel variant="caption">
              New Personal Grade
            </StyledNewStatusLabel>
            <StyledNewGradeButtonContainer>
              <GradeButton
                grade={0}
                selected={selectedGrade === 0}
                text="Attempting"
                onClick={() => handleSetStudentGrade(0)}
                disabled={isGradeButtonDisabled(0)}
              />
              <GradeButton
                grade={0.5}
                selected={selectedGrade === 0.5}
                text="Partial"
                onClick={() => handleSetStudentGrade(0.5)}
                disabled={isGradeButtonDisabled(0.5)}
              />
              <GradeButton
                grade={1}
                selected={selectedGrade === 1}
                text="Complete"
                onClick={() => handleSetStudentGrade(1)}
                disabled={isGradeButtonDisabled(1)}
              />
            </StyledNewGradeButtonContainer>
          </StyledReviewActionsContainer>
        </StyledInputContainer>
        <StyledErrorMessage>{error}</StyledErrorMessage>
      </>
    );
  };

  return (
    <StyledContainer>
      {renderLoading()}
      {renderSelectGradeInput()}
    </StyledContainer>
  );
};

export default withAuth(SubmitGrade, []);
