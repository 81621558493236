import { Theme } from '@material-ui/core';

type ThemeInterface = Exclude<Theme['overrides'], undefined>;

const MuiPaper: ThemeInterface['MuiPaper'] = {
  elevation1: {
    boxShadow: '0px 6px 12px -6px rgba(24, 39, 75, 0.5), 0px 8px 24px -4px rgba(24, 39, 75, 0.25)',
  },
};

export default MuiPaper;
