import { getLabel } from "components/common/feedbackModal/feedback/helper";
import { TWheelData } from "typings/meta-mirror";

const AreaLevelOptionsHelper = {
  getAreaOptionsFromWheelData: (wheelData: TWheelData) =>
    wheelData.map(({ _id, areaName }) => ({
      value: _id,
      label: areaName,
    })),

  getLevelsOptionsFromWheelDataByArea: (
    wheel: TWheelData,
    areaId: string,
    type = "sequential"
  ) =>
    areaId
      ? wheel
          ?.find(({ _id }) => _id === areaId)
          ?.levels.map((level) => ({
            value: level._id,
            label: getLabel(level, type),
          }))
      : [],
};

export default AreaLevelOptionsHelper;
