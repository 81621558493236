import { Theme } from "@material-ui/core";
import palette from "palette";

type ThemeInterface = Exclude<Theme["overrides"], undefined>;

const MuiSlider: ThemeInterface["MuiSlider"] = {
  track: {
    height: 8,
    borderRadius: 100,
  },
  rail: {
    height: 8,
    borderRadius: 100,
  },
  thumb: {
    height: 20,
    width: 20,
    marginTop: -6,
  },
  marked: {
    marginBottom: 25,
  },
  markLabel: {
    top: 35,
    marginLeft: 4,
    color: palette.font.caption,
    fontSize: 16,
    fontWeight: 600,
  },
  markLabelActive: {
    color: palette.font.body,
    fontSize: 16,
    fontWeight: 600,
  },
  mark: {
    visibility: "hidden",
  },
};

export default MuiSlider;
