/* eslint-disable @typescript-eslint/indent */
import { CloseRounded, EditRounded } from "@material-ui/icons";
import React from "react";

import { useWheelsPage } from "../../context";
import { Sum } from "../styles";
import { AchievementsProvider, useAchievements } from "./context";
import { Editor } from "./editor";
import { AchievementList } from "./list";

export const Achievements = () => (
  <AchievementsProvider>
    <Achieve />
  </AchievementsProvider>
);

const Achieve = () => {
  const { achievements, editing, handleEditClick } = useAchievements();
  const { isUser, profile } = useWheelsPage();

  if (!achievements.length && profile?.name?.toLowerCase().includes("exeter")) {
    return <React.Fragment />;
  }

  return (
    <Sum>
      <Sum.Title>
        <i
          className="uil uil-newspaper"
          style={{ color: "#007bff", marginRight: ".25em" }}
        />
        Curriculum Achievements
      </Sum.Title>
      {isUser && (
        <Sum.EditButton onClick={handleEditClick}>
          {editing ? <CloseRounded /> : <EditRounded />}
        </Sum.EditButton>
      )}
      {editing ? <Editor /> : <AchievementList />}
    </Sum>
  );
};
