import React from "react";

import { GradeButton } from "components/common/gradeButton";
import { GRADES } from "constants/index";
import { ILevel } from "typings/meta-mirror";

import { useFeedback } from "./context";
import { Actions } from "./styles";

export const Grades = () => {
  const {
    evidence,
    gradeError,
    selectedGrade,
    selectedLevel,
    setSelectedGrade,
    wheelLevelType,
  } = useFeedback();

  const isDisabled = (grade: ILevel["grade"]): boolean =>
    selectedLevel === evidence.currentLevelId &&
    (GRADES as any)[String(grade)] <
      (GRADES as any)[String(evidence.currentGrade)] &&
    wheelLevelType !== "non-sequential";

  return (
    <>
      <Actions.GradeButtonContainer>
        {[
          { grade: 0, text: "Attempting" },
          { grade: 0.5, text: "Partial" },
          { grade: 1, text: "Complete" },
        ].map(({ grade, text }) => (
          <div key={text} style={{ flex: 1 }}>
            <GradeButton
              grade={grade as any}
              selected={selectedGrade === grade}
              text={text}
              onClick={() => setSelectedGrade(grade as any)}
              disabled={isDisabled(grade as any)}
            />
          </div>
        ))}
      </Actions.GradeButtonContainer>
      {!!gradeError && (
        <Actions.ErrorMessage>{gradeError}</Actions.ErrorMessage>
      )}
    </>
  );
};
