import React from "react";
import { Capsule } from "../mock/styles";
import { Box, Button, CircularProgress, Tooltip } from "@material-ui/core";
import { CancelRounded, DeleteRounded, EditRounded } from "@material-ui/icons";
import { useAchievements } from "./context";
import TextInput from "components/common/TextInput/TextInput";
import { useWheelsPage } from "../../context";

export const Pill = ({
  color,
  children,
  grade: g,
  subject: s,
  ...rest
}: any) => {
  const { editing: e } = useAchievements();
  const { updateAchievement, removeAchievement } = useWheelsPage();
  const [editing, setEditing] = React.useState(false);
  const [grade, setGrade] = React.useState<string>("");
  const [loading, setLoading] = React.useState(false);
  const [subject, setSubject] = React.useState<string>("");

  const disabled = React.useMemo(
    () => (s === subject && g === grade) || loading,
    [s, g, subject, grade, loading]
  );

  const handleEditClick = () => setEditing(!editing);

  const handleRemoveClick = () => {
    setLoading(true);
    removeAchievement(s, g);
  };

  const handleSubmitClick = () => {
    setLoading(true);
    updateAchievement(grade, g, s, subject);
  };

  React.useEffect(() => {
    setEditing(false);
    setGrade(g);
    setLoading(false);
    setSubject(s);
  }, [s, g]);

  return editing ? (
    <Box>
      <Box
        style={{
          alignItems: "center",
          display: "flex",
          gap: ".5rem",
        }}
      >
        <TextInput
          value={subject}
          label="Subject"
          onChange={({ target }) => setSubject(target.value)}
        />
        <TextInput
          value={grade}
          label="Grade"
          onChange={({ target }) => setGrade(target.value)}
        />
        <Button
          color="secondary"
          onClick={handleEditClick}
          startIcon={<CancelRounded />}
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={disabled}
          onClick={handleSubmitClick}
          startIcon={loading ? <CircularProgress size={20} /> : <EditRounded />}
          variant="contained"
        >
          Edit
        </Button>
      </Box>
    </Box>
  ) : (
    <Capsule
      color={color}
      {...rest}
      style={{
        alignItems: "center",
        display: "inline-flex",
        gap: ".5rem",
        ...rest.style,
      }}
    >
      {children}
      {e && (
        <Box style={{ alignItems: "center", display: "inline-flex" }}>
          <Tooltip title="Edit">
            <Button
              color="inherit"
              disabled={loading}
              onClick={handleEditClick}
              size="small"
              style={{
                minWidth: "unset",
                width: 18,
                height: 18,
                padding: "0 .75rem",
              }}
            >
              {loading ? (
                <CircularProgress size={18} />
              ) : (
                <EditRounded style={{ width: 18, height: 18 }} />
              )}
            </Button>
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              color="inherit"
              disabled={loading}
              onClick={handleRemoveClick}
              style={{
                minWidth: "unset",
                width: 18,
                height: 18,
                padding: "0 .75rem",
              }}
            >
              {loading ? (
                <CircularProgress size={18} />
              ) : (
                <DeleteRounded style={{ width: 18, height: 18 }} />
              )}
            </Button>
          </Tooltip>
        </Box>
      )}
    </Capsule>
  );
};
