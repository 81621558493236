/* eslint-disable @typescript-eslint/indent */
import React from "react";

import { Sum } from "../styles";
import { useAchievements } from "./context";
import { Pill } from "./pill";
import { useWheelsPage } from "../../context";

const capsuleColors = ["#e35174", "#23d692", "#fac545", "#6cd9ff"];

export const AchievementList = () => {
  const { achievements, editing } = useAchievements();
  const { isUser } = useWheelsPage();

  return (
    <Sum.Content>
      {achievements?.length
        ? achievements.map(({ grade, subject }, index) => (
            // @ts-ignore
            <Pill
              color={capsuleColors[index % 4]}
              grade={grade}
              key={index}
              subject={subject}
            >
              {subject} {grade}
            </Pill>
          ))
        : isUser
        ? editing
          ? ""
          : "Add your achievements here when you're ready"
        : "This user has not added any achievements yet."}
    </Sum.Content>
  );
};
