import { Typography, Tooltip, TypographyProps } from "@material-ui/core";
import React from "react";
import styled, { css } from "styled-components";
import palette from "palette";
import Select, { TOption } from "../Select/Select";

const StyledSideContainer = styled.div<{ padding: string }>`
  width: 15%;
  height: 100%;
  padding: ${(props) => props.padding};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const StyledKeyItemContainer = styled.div<{
  hasHover?: boolean;
  small?: boolean;
}>`
  transition: 0.5s;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${(props) => (props.small ? "6px" : "10px")};
  border-radius: 10px;
  @media print {
    margin-left: 20px;
  }
  ${(props) =>
    props.hasHover &&
    css`
      :hover {
        background-color: #0000000d;
      }
      cursor: pointer;
      padding: 5px;
    `}
`;

const StyledKeyHeading = styled(Typography)`
  color: ${palette.font.body} !important;
  margin-top: 15px !important;
`;

const StyledHelpTooltipIcon = styled.i`
  font-size: 24px;
  color: ${palette.font.caption};
  margin-left: 10px;
  margin-top: auto;
`;

const StyledKeyScrollContainer = styled.div<{ scrollEnabled?: boolean }>`
  overflow-y: ${(props) => (!!props.scrollEnabled ? "auto" : "hidden")};
  overflow-x: hidden;
  max-height: 80%;
  padding-right: 5px;
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 7px;
    background-color: ${palette.font.caption};
  }
`;

const StyledKeyColor = styled.div<{ color: string; small?: boolean }>`
  background-color: ${(props) => props.color};
  border-radius: 100%;
  min-height: ${(props) => (props.small ? "12px" : "18px")};
  min-width: ${(props) => (props.small ? "12px" : "18px")};
  margin-right: 10px;
`;

interface IStyledKeyTextProps extends TypographyProps {
  bold?: boolean;
  small?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledKeyText = styled(
  ({ bold, small, ...props }: IStyledKeyTextProps) => <Typography {...props} />
)<{ bold?: boolean }>`
  transition: 0.5s;
  color: ${(props) =>
    props.bold ? palette.font.heading : palette.font.caption} !important;
  font-size: ${(props) => (props.small ? "8pt" : "14px")} !important;
  font-weight: ${(props) => (props.bold ? 600 : 400)} !important;
  word-break: break-all;
  line-height: ${(props) => (props.small ? "10px" : "normal")} !important;
  :hover {
    color: ${palette.font.heading} !important;
  }
`;

export interface IKeyItemData {
  areaId: string;
  areaName: string;
  lineColor: string;
  selected: boolean;
  handleSelect(): void;
}

interface IProps {
  keyData: IKeyItemData[];
  selectGroups?: TOption[];
  selectedGroup?: string;
  reportChart?: boolean;
  handleSelectGroup?(selectedGroup: string): void;
}

const ProgressChartSideBar: React.FC<IProps> = ({
  keyData,
  selectGroups,
  selectedGroup,
  handleSelectGroup,
  reportChart,
}: IProps) => {
  const getKey = (): JSX.Element[] => {
    const keyItems = [];
    keyData.forEach(
      ({ areaId, areaName, lineColor, handleSelect, selected }) => {
        keyItems.push(
          <StyledKeyItemContainer
            key={`${areaId}-key`}
            onClick={handleSelect}
            hasHover={!reportChart}
            small={reportChart}
          >
            <StyledKeyColor color={lineColor} />
            <StyledKeyText bold={selected} small={reportChart}>
              {areaName}
            </StyledKeyText>
          </StyledKeyItemContainer>
        );
      }
    );
    return keyItems;
  };

  return (
    <StyledSideContainer
      padding={
        selectGroups && selectedGroup && handleSelectGroup ? "20px 0 0 0" : "0"
      }
    >
      {selectGroups &&
        selectedGroup &&
        handleSelectGroup &&
        (selectGroups.length <= 1 ? (
          <Tooltip
            title="Only one group is available for this wheel."
            placement="top"
          >
            <div className="w-100">
              <Select
                value={selectedGroup}
                label="Student Group"
                options={selectGroups}
                className="mr-2"
                disabled
                fullWidth
                disableMargin
              />
            </div>
          </Tooltip>
        ) : (
          <Select
            onChange={(event) =>
              handleSelectGroup(event.target.value as string)
            }
            value={selectedGroup}
            label="Student Group"
            options={selectGroups}
            fullWidth
            disableMargin
          />
        ))}
      <StyledKeyItemContainer>
        {!reportChart && (
          <>
            <StyledKeyHeading variant="h6">Key</StyledKeyHeading>
            <Tooltip
              title={
                <>
                  Click on an area in the key or on the graph to highlight it.
                  <br />
                  Click an area again, or double click the chart to deselect.
                </>
              }
              PopperProps={{
                style: {
                  width: "auto",
                  textAlign: "center",
                },
              }}
            >
              <StyledHelpTooltipIcon className="il uil-question-circle" />
            </Tooltip>
          </>
        )}
      </StyledKeyItemContainer>
      <StyledKeyScrollContainer scrollEnabled={!reportChart}>
        {getKey()}
      </StyledKeyScrollContainer>
    </StyledSideContainer>
  );
};

export default ProgressChartSideBar;
