export enum EmptyMessages {
  "admin" = "No evidence has been submitted by this student on this wheel.",
  "student" = "You haven't submitted any evidence for this wheel yet.",
  "teacher" = "No evidence has been submitted by this student yet.",
}

export enum EmptyFilterMessages {
  "admin" = "No evidence has been submitted by this student on this wheel for the selected area/level.",
  "student" = "You haven't submitted any evidence for the selected area/level yet.",
  "teacher" = "No evidence has been submitted for the selected area/level by this student yet.",
}
