import React from "react";
import { Typography, Grid } from "@material-ui/core";
import styled from "styled-components";
import palette from "palette";

const StyledLabel = styled.label`
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  max-width: 100%;
  height: 50px;
  color: ${palette.secondary};
  border: 1px solid ${palette.secondary};
  border-color: ${palette.secondary};
  border-width: 3px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background-color: #ffe5f6;
  }
`;

const StyledModalText = styled(Typography)`
  margin-top: 20px !important;
  text-align: center !important;
`;

const StyledIcon = styled.i`
  font-size: 22px;
  margin: 0 5px 0 0;
`;

interface IProps {
  handleUploadFile(): void;
  handleSubmitLink(): void;
  handleSubmitComment(): void;
}

const ChooseEvidenceType: React.FC<IProps> = ({
  handleUploadFile,
  handleSubmitLink,
  handleSubmitComment,
}: IProps) => (
  <>
    <StyledModalText variant="body1">
      You can submit evidence as a file, as a comment, or as a link.
    </StyledModalText>
    <StyledModalText>
      Select the type of evidence you would like to submit:
    </StyledModalText>
    <Grid container direction="row" className="mb-5 mt-4">
      <Grid
        item
        xs={12}
        md={4}
        className="d-flex justify-content-center justify-content-md-end mb-2 mb-md-0 pr-md-3 pr-0"
      >
        <StyledLabel onClick={handleUploadFile}>
          <StyledIcon className="uil uil-file-upload" />
          upload file
        </StyledLabel>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        className="d-flex justify-content-center justify-content-md-start mb-2 mb-md-0  pr-md-3 pr-0"
      >
        <StyledLabel onClick={handleSubmitLink}>
          <StyledIcon className="uil uil-link-add" />
          submit link
        </StyledLabel>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        className="d-flex justify-content-center justify-content-md-start"
      >
        <StyledLabel onClick={handleSubmitComment}>
          <StyledIcon className="uil uil-comment" />
          add comment
        </StyledLabel>
      </Grid>
    </Grid>
  </>
);

export default ChooseEvidenceType;
