import React from "react";
import { Backdrop, Box, Grid, ModalProps, Slide } from "@material-ui/core";
import {
  StyledModal,
  StyledPaper,
  StyledGridItemHeader,
  StyledModalTitle,
  StyledCloseButton,
  StyledIcon,
  StyledContentContainer,
} from "./styles";
import { getCSSProp } from "./helpers";

export interface IAnimatedModalProps
  extends Omit<ModalProps, "closeAfterTransition"> {
  /**
   * If supplied will display a title on the modal.
   */
  title?: string;
  /**
   * Defines if the close button will be hidden or not.
   */
  hideClose?: boolean;
  handleClose(): void;
  width?: string;
  height?: string;
  minWidth?: string;
  maxWidth?: string;
  minHeight?: string;
  maxHeight?: string;
  /**
   * Defines if the close button is clickable.
   */
  disableClose?: boolean;
  startingContent?: React.ReactNode;
}

const AnimatedModal: React.FC<IAnimatedModalProps> = ({
  title,
  hideClose,
  open,
  children,
  handleClose,
  width,
  height,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
  disableClose,
  startingContent,
  ...rest
}: IAnimatedModalProps) => {
  return (
    <StyledModal
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 1000,
      }}
      {...rest}
    >
      <Slide direction="up" in={open} timeout={700}>
        <StyledPaper
          width={getCSSProp(width)}
          height={getCSSProp(height)}
          minWidth={getCSSProp(minWidth)}
          minHeight={getCSSProp(minHeight)}
          maxHeight={getCSSProp(maxHeight)}
          maxWidth={getCSSProp(maxWidth)}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignContent="stretch"
            className="h-100"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <StyledGridItemHeader item style={{ display: "flex" }} xs={12}>
              {startingContent && (
                <Box
                  sx={{
                    display: "inline-flex",
                  }}
                >
                  {startingContent}
                </Box>
              )}
              {title && (
                <StyledModalTitle
                  className="text-center"
                  id="modal-title"
                  variant="h5"
                >
                  {title}
                </StyledModalTitle>
              )}
              {!hideClose && (
                <StyledCloseButton
                  onClick={handleClose}
                  disabled={disableClose}
                >
                  <StyledIcon className="uil uil-times-circle" />
                </StyledCloseButton>
              )}
            </StyledGridItemHeader>
            <StyledContentContainer
              item
              xs={12}
              style={{ display: "flex", flex: 1, flexDirection: "column" }}
            >
              {children}
            </StyledContentContainer>
          </Grid>
        </StyledPaper>
      </Slide>
    </StyledModal>
  );
};

export default AnimatedModal;
