import React from "react";

import { FeedbackProvider } from "./context";
import { Error } from "./error";
import { Feedback as FeedbackComponent } from "./feedback";
import { Header } from "./header";
// import { LevelSelect } from "./levelSelect";
import { Actions } from "./styles";
import { FeedbackProps } from "./types";
import { Status } from "./status";

export const Feedback = (props: FeedbackProps) => (
  <FeedbackProvider {...props}>
    <Actions>
      <Header />
      <Status />
      {/* <Actions.Divider /> */}
      {/* <LevelSelect /> */}
      <FeedbackComponent />
      <Error />
    </Actions>
  </FeedbackProvider>
);
