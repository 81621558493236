import React from "react";

import SubmitEvidenceModal from "components/student/SubmitEvidenceModal";

import { useDetailedWheel } from "./context";

export const SubmitEvidence = () => {
  const {
    handleRefreshData,
    mirrorType,
    setSubmitEvidenceModalOpen,
    submitEvidenceModalOpen,
    wheelData,
    wheelId,
  } = useDetailedWheel();
  return (
    <SubmitEvidenceModal
      handleEvidenceAdded={handleRefreshData}
      isOpen={submitEvidenceModalOpen}
      mirrorLevelType={mirrorType}
      onClose={() => setSubmitEvidenceModalOpen(false)}
      wheelAreas={wheelData?.map(({ _id, areaName }) => ({
        _id,
        name: areaName,
        disabled: false,
      }))}
      wheelData={wheelData}
      wheelId={wheelId}
    />
  );
};
