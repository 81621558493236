import moment from "moment";
import {
  IStudentTableViewFilters,
  IStudentWheelResultsData,
} from "typings/student";

const WheelDataFilteringTool = {
  getFilteredTableViewStudentListData: (
    data: IStudentWheelResultsData[],
    filters: IStudentTableViewFilters,
    groupIds?: string[]
  ): IStudentWheelResultsData[] => {
    const {
      sex,
      lastLogin,
      studentGroups,
      yearGroups,
      other,
      lockedGroupsViewable,
    } = filters;
    const filteredData = data.filter((studentResult) => {
      let isSexMatch = true;
      let isLastLoginMatch = true;
      let isStudentGroupMatch = true;
      let isOtherMatch = true;
      let isYearGroupMatch = true;
      let isLockedGroupMatch = true;

      if (sex.length) {
        isSexMatch = sex.includes(studentResult.sex);
      }

      if (lastLogin === 1) {
        isLastLoginMatch =
          new Date(moment().subtract(7, "days").toDate()) <=
          new Date(studentResult.lastLogin);
      }

      if (lastLogin === 2) {
        isLastLoginMatch =
          new Date(moment().subtract(7, "days").toDate()) >=
          new Date(studentResult.lastLogin);
      }

      if (studentGroups.length) {
        isStudentGroupMatch = studentGroups.some((group) =>
          studentResult.groupIds.includes(group)
        );
      }

      if (yearGroups.length) {
        if (Array.isArray(studentResult.yearGroup)) {
          isYearGroupMatch = studentResult.yearGroup?.some((year) =>
            yearGroups.includes(year)
          );
        } else {
          isYearGroupMatch = !!yearGroups.includes(studentResult.yearGroup);
        }
      }

      if (other.length) {
        if (!other.some((selectedItem) => selectedItem === undefined)) {
          isOtherMatch = !!other.filter((otherItem: string) =>
            studentResult?.other.includes(otherItem)
          ).length;
        }
      }

      const studentLocked = groupIds?.some((g) => {
        return studentResult?.lockedGroupIds?.includes(g);
      });

      if (studentLocked && !lockedGroupsViewable) {
        isLockedGroupMatch = false;
      }

      return (
        isSexMatch &&
        isLastLoginMatch &&
        isStudentGroupMatch &&
        isOtherMatch &&
        isYearGroupMatch &&
        isLockedGroupMatch
      );
    });

    return filteredData;
  },
};

export default WheelDataFilteringTool;
