import React from "react";
import { IGroupData } from "typings/group";
import { IWheelsListItem } from "typings/wheel";
import { IStudentTableViewFilters, IWheelResultList } from "typings/student";

export interface IWheelsPageContextProps {
  groups: IGroupData[];
  wheels: IWheelsListItem[];
  selectedGroup: string;
  wheelResults: IWheelResultList | null;
  isLoading: boolean;
  tableViewSelectedFilters: IStudentTableViewFilters;
  handleSelectGroup: (group: string) => void;
  handleGetSelectedWheelData: (wheelId: string) => void;
  handleGenerateCSVFile: (viewMode: "wheel-view" | "table-view") => void;
  handleTableViewFilterSelect: (
    selectedFilter: Partial<IStudentTableViewFilters>
  ) => void;
}

export const WheelsPageContext = React.createContext<
  Partial<IWheelsPageContextProps>
>({
  groups: [] as IGroupData[],
  wheels: [] as IWheelsListItem[],
  isLoading: false,
  selectedGroup: "all-students",
  wheelResults: null,
});
