/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import { DetailedWheelContext, DetailedWheelProviderProps } from "./types";
import {
  getSingleWheelDetailsForStudentId,
  getSingleWheelForStudentWithPersonalGrades,
} from "lib/mirror";
import { TEvidencePartialData } from "typings/student";
import { TWheelData } from "typings/meta-mirror";
import { getWheelFromToken } from "lib/token";

const Context = React.createContext<DetailedWheelContext>({
  evidence: [],
  feedback: [],
  handleRefreshData: () => {},
  loading: true,
  locked: false,
  progressModalOpen: false,
  setAreaId: () => {},
  setEvidenceId: () => {},
  setProgressModalOpen: () => {},
  setSubmitEvidenceModalOpen: () => {},
  submitEvidenceModalOpen: false,
  user: {} as any,
  wheelData: [],
  wheelName: "",
});

export const DetailedWheelProvider = ({
  children,
  match,
  user,
}: DetailedWheelProviderProps) => {
  const isToken = match.params.token;

  const ref = React.createRef<HTMLDivElement>();
  const [areaId, setAreaId] = React.useState<string>();
  const [error, setError] = React.useState<string>();
  const [evidence, setEvidence] = React.useState<TEvidencePartialData>([]);
  const [evidenceId, setEvidenceId] = React.useState<string>();
  const [feedback, setFeedback] = React.useState<any>();
  const [loading, setLoading] = React.useState(true);
  const [locked, setLocked] = React.useState(false);
  const [mirrorType, setMirrorType] = React.useState<string>();
  const [personalWheelData, setPersonalWheelData] =
    React.useState<TWheelData>();
  const [progressModalOpen, setProgressModalOpen] = React.useState(false);
  const [submitEvidenceModalOpen, setSubmitEvidenceModalOpen] =
    React.useState(false);
  const [wheelData, setWheelData] = React.useState<TWheelData>();
  const [wheelId, setWheelId] = React.useState<string>();
  const [wheelName, setWheelName] = React.useState<string>();

  const filteredEvidence = React.useMemo(() => {
    const { areaId, evidenceId, token } = match.params;
    if (!token) return evidence;
    if (evidenceId) return evidence.filter((e) => e._id === evidenceId);
    if (areaId) return evidence.filter((e) => e.areaId === areaId);
    return evidence;
  }, [evidence]);

  const getStudentData = async () => {
    setLoading(true);
    const params = new URLSearchParams(window.location.search);
    setEvidenceId(params.get("seId") ?? undefined);
    const { wheelId } = match.params as any;
    const studentId = user?.roles?.student?.id?._id!;

    getSingleWheelDetailsForStudentId(wheelId, studentId)
      .then(async ({ data: { data } }) => {
        const lockedIds = user?.roles?.student?.id?.mm_locked_group_ids ?? [];
        setEvidence(data.evidence);
        setLocked(lockedIds?.some((id) => data.groupIds?.includes(id)));
        setWheelData(data.wheelData);
        setWheelName(data.name);
        return await getSingleWheelForStudentWithPersonalGrades(
          wheelId,
          studentId
        ).then(({ data: personal }) => {
          setMirrorType(personal.levelType ?? "sequential");
          setPersonalWheelData(personal.wheelData);
          return;
        });
      })
      .catch(({ message }) => setError(message))
      .finally(() => setLoading(false));
  };

  const getTokenData = async () => {
    setLoading(true);
    setEvidenceId(match.params.evidenceId ?? undefined);
    getWheelFromToken(match.params)
      .then(async ({ data }) => {
        setEvidence(data.evidence);
        setLocked(true);
        setWheelData(data.wheelData);
        setWheelName(data.name);
        return await getSingleWheelForStudentWithPersonalGrades(
          match.params.wheelId,
          match.params.studentId
        ).then(({ data: personal }) => {
          setMirrorType(personal.levelType ?? "sequential");
          setPersonalWheelData(personal.wheelData);
          return;
        });
      })
      .catch(({ message }) => setError(message))
      .finally(() => setLoading(false));
  };

  const handleRefreshData = () => {
    const { wheelId } = match.params as any;
    const studentId = isToken
      ? match.params.studentId
      : user?.roles?.student?.id?._id;
    const params = new URLSearchParams(window.location.search);
    getSingleWheelDetailsForStudentId(
      wheelId,
      studentId,
      undefined,
      undefined,
      params.get("area") as string,
      params.get("level") as string
    ).then(({ data }) => {
      setEvidence(data.data.evidence);
      getSingleWheelForStudentWithPersonalGrades(wheelId, studentId).then(
        ({ data: personal }) => {
          setMirrorType(personal.levelType ?? "sequential");
          setPersonalWheelData(personal.wheelData);
        }
      );
    });
  };

  React.useEffect(() => {
    setWheelId((match.params as any).wheelId);
    match.params.token ? getTokenData() : getStudentData();

    if (match.params.evidenceId) location.hash = `#${match.params.evidenceId}`;
  }, []);

  return (
    <Context.Provider
      value={{
        areaId,
        error,
        evidence: filteredEvidence,
        evidenceId,
        feedback,
        handleRefreshData,
        isToken,
        loading,
        locked,
        mirrorType,
        personalWheelData,
        progressModalOpen,
        ref,
        setAreaId,
        setEvidenceId,
        setProgressModalOpen,
        setSubmitEvidenceModalOpen,
        submitEvidenceModalOpen,
        user: user as any,
        wheelData,
        wheelId,
        wheelName,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useDetailedWheel = () => React.useContext(Context);
