import React from "react";

import AnimatedModal from "components/common/AnimatedModal";

import { withAuth } from "../../../hoc/withAuth";
import { Content } from "./content";
import { FeedbackModalProps } from "./types";
import { Button } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";

export const FeedbackModal = (props: FeedbackModalProps) => {
  const history = useHistory();
  const { search } = useLocation();

  const isProfile = React.useMemo(() => {
    if (props.isFromProfile) return true;
    const params = new URLSearchParams(search);
    return params.get("profile") === "true";
  }, [search]);

  const handleClick = () => history.goBack();

  return (
    <AnimatedModal
      title={props.areaName}
      maxWidth="1800px"
      maxHeight="1300px"
      minHeight="550px"
      height="90vh"
      width="90%"
      handleClose={props.handleCloseModal}
      open={props.open}
      startingContent={
        isProfile && (
          <Button color="primary" onClick={handleClick} variant="outlined">
            Back to profile
          </Button>
        )
      }
    >
      <Content {...props} />
    </AnimatedModal>
  );
};

export default withAuth(FeedbackModal, ["admin", "teacher", "student"]);
