/* eslint-disable @typescript-eslint/indent */
import { CloseRounded, EditRounded } from "@material-ui/icons";
import React from "react";

import { useWheelsPage } from "../../context";
import { Sum } from "../styles";
import { Content } from "./content";
import { ContactProvider, useContact } from "./context";
import { Editor } from "./editor";

export const Contact = () => (
  <ContactProvider>
    <ContactInner />
  </ContactProvider>
);

export const ContactInner = () => {
  const { editing, setEditing } = useContact();
  const { isUser } = useWheelsPage();

  return (
    <Sum>
      <Sum.Title>
        <i
          className="uil uil-info-circle"
          style={{ color: "#007bff", marginRight: ".25rem" }}
        />
        Contact
      </Sum.Title>
      {isUser && (
        <Sum.EditButton onClick={() => setEditing(!editing)}>
          {editing ? <CloseRounded /> : <EditRounded />}
        </Sum.EditButton>
      )}
      {editing ? <Editor /> : <Content />}
    </Sum>
  );
};
