import { Button, PropTypes, Tooltip } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const PAGE_NUMBER_SEPARATOR = '...';

const StyledPaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
`;

const StyledArrowButton = styled(Button)`
  border-radius: 100% !important;
  border-width: 0px !important;
  height: 50px !important;
  width: 50px !important;
  min-width: 50px !important;
  padding: 0 !important;
  margin: 0 5px !important;
  font-size: 35px !important;
`;

const StyledPageNumberButton = styled(Button)`
  border-radius: 100% !important;
  height: 50px !important;
  width: 50px !important;
  min-width: 50px !important;
  padding: 0 !important;
  margin: 0 5px !important;
`;

interface IProps {
  pagesCount: number;
  currentPage: number;
  pageNumberSeparator?: string;
  color?: PropTypes.Color;
  onChange: (currentPage: number) => void;
}

const Pagination: React.FC<IProps> = ({ pagesCount, currentPage, pageNumberSeparator = PAGE_NUMBER_SEPARATOR, color = 'primary', onChange }: IProps) => {

  const handleChangePage = (page: number): void => {
    onChange(page);
  };

  const generatePagination = (): Array<string | number> => {
    const rangeOfNearbyPages = 1;
    const currentPageValue = currentPage + 1;
    const pageArr = [];
    const pageArrWithDots: Array<string | number> = [];

    for (let i = 1; i <= pagesCount; ++i) {
      const isFirstPage = i === 1;
      const isLastPage = i === pagesCount;
      const isActualPage = i === currentPageValue;
      const isNearNextPage = i <= (currentPageValue + rangeOfNearbyPages) && i >= currentPageValue;
      const isNearPreviousPage = i >= (currentPageValue - rangeOfNearbyPages) && i <= currentPageValue;

      if (isFirstPage || isActualPage || isLastPage || isNearNextPage || isNearPreviousPage) {
        pageArr.push(i);
      }
    }

    if (pageArr.length) {
      pageArr.forEach((page: number, pageIndex: number) => {
        if (pageIndex >= 1) {
          if ((page - pageArr[(pageIndex - 1)]) !== 1) {
            pageArrWithDots.push(PAGE_NUMBER_SEPARATOR);
          }
        }

        pageArrWithDots.push(page);
      });
    };

    return pageArrWithDots;
  };

  return (
    <StyledPaginationContainer>
      <Tooltip title="Previous Page">
        <span>
          <StyledArrowButton
            color={color}
            variant="outlined"
            onClick={() => handleChangePage(currentPage - 1)}
            disabled={currentPage <= 0}
          >
            <i className="uil uil-angle-left-b" />
          </StyledArrowButton>
        </span>
      </Tooltip>
      {generatePagination().map((page: number | string, pageIndex: number) => (
        <StyledPageNumberButton
          key={`$paggination-page-${pageIndex}`}
          color={color}
          variant={(currentPage + 1 === page || page === pageNumberSeparator) ? 'contained' : 'outlined'}
          disabled={pageNumberSeparator === page}
          onClick={() => handleChangePage(page as number - 1)}
        >
          {page}
        </StyledPageNumberButton>
      ))}
      <Tooltip title="Next Page">
        <span>
          <StyledArrowButton
            color={color}
            variant="outlined"
            disabled={currentPage + 1 >= pagesCount}
            onClick={() => handleChangePage(currentPage + 1)}
          >
            <i className="uil uil-angle-right-b" />
          </StyledArrowButton>
        </span>
      </Tooltip>
    </StyledPaginationContainer>
  );
};

export default Pagination;
