import { Theme } from "@material-ui/core";
import Palette from "palette";

const typography: Partial<Theme["typography"]> = {
  fontFamily: "'Nunito', sans-serif",
  fontWeightRegular: 500,
  fontWeightBold: 900,
  fontWeightLight: 300,
  fontWeightMedium: 700,
  fontSize: 16,
  h1: {
    color: Palette.font.heading,
    fontWeight: 700,
    fontSize: 64,
  },
  h2: {
    color: Palette.font.heading,
    fontWeight: 700,
    fontSize: 48,
  },
  h3: {
    color: Palette.font.heading,
    fontWeight: 700,
    fontSize: 36,
  },
  h4: {
    color: Palette.font.heading,
    fontWeight: 700,
    fontSize: 32,
  },
  h5: {
    color: Palette.font.heading,
    fontWeight: 700,
    fontSize: 26,
  },
  h6: {
    color: Palette.font.heading,
    fontWeight: 700,
    fontSize: 20,
  },
  body1: {
    color: Palette.font.body,
    fontSize: 18,
  },
  body2: {
    color: Palette.font.caption,
    fontSize: 18,
  },
  caption: {
    color: Palette.font.caption,
  },
};

export default typography;
