import { Typography } from "@material-ui/core";
import React from "react";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

import { StudentList } from "components/admin/studentList";
import TableSearch from "components/admin/TableSearch";
import PageContainer from "components/common/PageContainer/PageContainer";
import Select from "components/common/Select/Select";
import { getSchoolsWithClasses } from "lib/school";
import { getStudents } from "lib/student";

import { withAuth } from "../../../../hoc/withAuth";
import { SP } from "./styles";

const StudentsPage = () => {
  const [data, setData] = React.useState([]);
  const [group, setGroup] = React.useState("");
  const [limit, setLimit] = React.useState(10);
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [name, setName] = React.useState("");
  const [requestingState, setRequestingState] = React.useState(false);
  const [school, setSchool] = React.useState("");
  const [schools, setSchools] = React.useState<any[]>([]);
  const [total, setTotal] = React.useState(0);

  const groups = React.useMemo(() => {
    setGroup("");
    return schools.find(({ _id }: any) => _id === school)?.groups || [];
  }, [school]);

  const handleSearch = (value: string) => {
    setName(value);
    setPage(0);
  };

  React.useEffect(() => {
    const query = new URLSearchParams({
      limit: limit.toString(),
      page: (page + 1).toString(),
    });
    group.trim().length > 0 && query.append("groupId", group);
    name.trim().length > 0 && query.append("name", name);
    school.trim().length > 0 && query.append("mmSchoolId", school);
    setLoading(true);
    getStudents(`?${query.toString()}`)
      .then(({ data }) => {
        setData(data.students);
        setTotal(data.totalStudents);
      })
      .finally(() => setLoading(false));
  }, [group, limit, name, page, school]);

  React.useEffect(() => {
    getSchoolsWithClasses().then(({ data }) => setSchools(data));
  }, []);

  return (
    <PageContainer>
      <SP>
        <Typography variant="h6">Students</Typography>
        <SP.Divider />
        <TableSearch
          label="Student Name"
          onSearch={handleSearch}
          onClear={() => {
            handleSearch("");
            setGroup("");
            setSchool("");
          }}
          extraContent={
            <>
              <Select
                label="School"
                value={school}
                IconComponent={ExpandMoreRoundedIcon}
                disabled={requestingState}
                onChange={({ target: { value } }) =>
                  school !== value && setSchool(value as string)
                }
                options={schools.map(({ _id, name }: any) => ({
                  label: name,
                  value: _id,
                }))}
              />
              <Select
                label="Groups"
                value={group}
                IconComponent={ExpandMoreRoundedIcon}
                onChange={({ target: { value } }) =>
                  group !== value && setGroup(value as string)
                }
                options={groups.map(({ _id, name }: any) => ({
                  label: name,
                  value: _id,
                }))}
              />
            </>
          }
        />
        <StudentList
          data={data}
          limit={limit}
          loading={loading}
          page={page}
          setLimit={setLimit}
          setPage={setPage}
          total={total}
        />
      </SP>
    </PageContainer>
  );
};

export default withAuth(StudentsPage, ["admin"]);
