import React from "react";

import { UpdateEvidenceProvider } from "./context";
import { ButtonContainer, Container, DropdownContainer } from "./styles";
import { UpdateButton } from "./button";
import { AreaSelect } from "./area";
import { LevelSelect } from "./level";
import { useFeedback } from "../context";
import { useDetailedWheel } from "pages/student/sections/detailedWheelPage/context";

export const Update = () => {
  const { isToken } = useDetailedWheel();
  const { evidenceObject } = useFeedback();
  if (!evidenceObject || isToken) return null;

  return (
    <UpdateEvidenceProvider>
      <Container>
        <DropdownContainer>
          <AreaSelect />
          <LevelSelect />
        </DropdownContainer>
        <ButtonContainer>
          <UpdateButton />
        </ButtonContainer>
      </Container>
    </UpdateEvidenceProvider>
  );
};
