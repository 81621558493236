import { Tooltip } from "@material-ui/core";
import React from "react";

import palette from "MuiTheme/palette";

import { ItemActionButton } from "./styles";

type Children = {
  children: React.ReactElement<any, any>;
};

export const ActionButton = ({
  backgroundHoverColor,
  children,
  disabled,
  disabledText,
  hoverColor,
  icon,
  loading,
  notification,
  onClick,
}: any) => {
  const Container = ({ children }: Children) =>
    disabled && disabledText ? (
      <Tooltip title={disabledText} placement="bottom">
        {children}
      </Tooltip>
    ) : (
      children
    );

  return (
    <Container>
      <ItemActionButton.Container>
        <ItemActionButton
          backgroundHoverColor={backgroundHoverColor || palette.primaryLight}
          color="primary"
          disabled={disabled}
          hoverColor={hoverColor || palette.primary}
          onClick={onClick}
          size="small"
          textColor={`${palette.font.caption}bb`}
          variant="outlined"
        >
          {loading ? (
            <ItemActionButton.Spinner size={14} />
          ) : (
            <ItemActionButton.Icon className={`uil ${icon}`} />
          )}{" "}
          {children}
          {notification && <ItemActionButton.Indicator />}
        </ItemActionButton>
      </ItemActionButton.Container>
    </Container>
  );
};
