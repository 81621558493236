import React, { useState } from "react";
import TextInput from "components/common/TextInput/TextInput";
import AnimatedModal from "components/common/AnimatedModal";
import { Button, Typography } from "@material-ui/core";
import styled from "styled-components";
import palette from "palette";
import { IResponseBody } from "typings/global";

const StyledErrorMessage = styled(Typography)`
  color: ${palette.error} !important;
  height: 30px;
  margin: 0 0 20px !important;
`;

const StyledModalContainer = styled.div`
  display: flex;
  justify-content: flex;
  align-items: center;
  flex-direction: column;
  height: 200px;
`;

const StyledTextInput = styled(TextInput)`
  margin: 30px 0 20px !important;
`;

const StyledSuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

const StyledSuccessIcon = styled.i`
  color: ${palette.complete} !important;
  font-size: 50px;
  margin-top: -20px;
`;

interface IProps {
  handleClose(): void;
  handleAddSchool: (value: string) => Promise<IResponseBody>;
  open: boolean;
}

const AddSchoolModal: React.FC<IProps> = ({
  handleAddSchool,
  handleClose,
  open,
}: IProps) => {
  const [schoolName, setSchoolName] = useState("");
  const [requestPending, setRequestPending] = useState(false);
  const [schoolAdded, setSchoolAdded] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleCloseModal = (): void => {
    handleClose();
    setTimeout(() => {
      setSchoolName("");
      setRequestPending(false);
      setSchoolAdded(false);
      setErrorMessage("");
    }, 1000);
  };

  const addSchool = async (): Promise<void> => {
    setRequestPending(true);
    const result = await handleAddSchool(schoolName);
    setRequestPending(false);
    if (result.error) {
      setErrorMessage(result.message);
      return;
    }
    setErrorMessage("");
    setSchoolAdded(true);
  };

  return (
    <AnimatedModal
      open={open}
      handleClose={handleCloseModal}
      onClose={handleCloseModal}
      title="New School"
      width="100%"
      maxWidth="625px"
      minHeight="300px"
      height="auto"
    >
      <StyledModalContainer>
        {!schoolAdded ? (
          <>
            <Typography variant="body2">
              Please provide a name for the new school.
            </Typography>
            <StyledTextInput
              label="School Name"
              value={schoolName}
              fullWidth
              onChange={(e) => setSchoolName(e.target.value)}
            />
            <StyledErrorMessage variant="body2">
              {errorMessage}
            </StyledErrorMessage>
          </>
        ) : (
          <StyledSuccessContainer>
            <StyledSuccessIcon className="uil uil-check-circle" />
            <Typography variant="body2">
              New school added successfully.
            </Typography>
          </StyledSuccessContainer>
        )}

        <div className="row w-100 pl-5 pr-5">
          <div className="col-6">
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={handleCloseModal}
            >
              go back
            </Button>
          </div>
          <div className="col-6">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={schoolAdded ? handleCloseModal : addSchool}
              disabled={requestPending || (!schoolName.length && !schoolAdded)}
            >
              {schoolAdded ? "done" : "add school"}
            </Button>
          </div>
        </div>
      </StyledModalContainer>
    </AnimatedModal>
  );
};

export default AddSchoolModal;
