import React from "react";
import { Button, Typography } from "@material-ui/core";

import AnimatedModal from "components/common/AnimatedModal";
import { deleteEvidence as DeleteEvidence } from "lib/evidence";

import { useEvidenceList } from "../context";
import { Delete } from "./styles";

export const DeleteModal = () => {
  const { deleteEvidence, refresh, setDeleteEvidence } = useEvidenceList();

  const handleClose = () => setDeleteEvidence(undefined);

  const handleDelete = async () => {
    await DeleteEvidence(deleteEvidence!.id);
    refresh();
    handleClose();
  };

  return (
    <AnimatedModal
      handleClose={handleClose}
      open={!!deleteEvidence}
      maxHeight="90vh"
      maxWidth="800px"
      minWidth="320px"
      title="Are you sure you want to delete this evidence?"
      width="90%"
    >
      <Delete>
        <Typography variant="body1">
          If you continue {deleteEvidence?.name} and the grade you submitted
          alongside it will be deleted.
        </Typography>
        <Typography variant="h6">This cannot be undone.</Typography>
        <Typography variant="body1">
          Are you sure you want to continue?
        </Typography>
        <Delete.ButtonContainer>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Delete.Button variant="contained" onClick={handleDelete}>
            Delete
          </Delete.Button>
        </Delete.ButtonContainer>
      </Delete>
    </AnimatedModal>
  );
};
