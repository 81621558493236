import React, { Component } from "react";
import { IconButton, Paper, Tooltip, Typography } from "@material-ui/core";
import PageContainer from "components/common/PageContainer/PageContainer";
import UploadStudents from "components/teacher/UploadStudents";
import styled from "styled-components";
import palette from "palette";
import StudentListFilters from "components/teacher/StudentListFilters";
import StudentList from "components/teacher/StudentList";
import { RouteComponentProps } from "react-router";
import { IStudentListData } from "typings/student";
import { IGroupData } from "typings/group";
import { IWithAuth, withAuth } from "../../../hoc/withAuth";
import { QueryStringService } from "helpers/queryStringService";
import { getGroupListBasedOnSchool } from "lib/group";
import { getStudentList } from "lib/student";
import { UpdateYearGroupModel } from "components/teacher/UpdateYearGroupModel";

const StyledPaper = styled(Paper)`
  width: 100%;
  min-height: 100%;
  padding: 25px;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledIcon = styled.i`
  font-size: 30px;
  line-height: 30px;
  margin: 1px 0 0;
`;

const StyledDivider = styled.div`
  width: 100%;
  height: 3px;
  border-radius: 15px;
  background-color: ${palette.disabled};
  margin: 15px 0 0;
`;

interface ISelectedFilters {
  groups: string[];
  yearGroups: string[];
  other: string[];
  sex: string[];
  lastLogin: number;
  showLocked: boolean;
}

interface IState {
  isUploadStudentsModalOpen: boolean;
  isUpdateYearGroupModalOpen: boolean;
  isLoading: boolean;
  isFirstLoad: boolean;
  students: IStudentListData[];
  groups: IGroupData[];
  other: string[];
  selectedFilters: ISelectedFilters;
  currentPage: number;
  pageSize: number;
  selectedStudents: string[];
}

interface IProps extends RouteComponentProps, IWithAuth {}

class StudentsPage extends Component<IProps, IState> {
  state = {
    isUploadStudentsModalOpen: false,
    isUpdateYearGroupModalOpen: false,
    isLoading: false,
    isFirstLoad: true,
    students: [],
    groups: [],
    other: [],
    selectedFilters: {
      groups: [],
      yearGroups: [],
      other: [],
      sex: [],
      lastLogin: 0,
      showLocked: false,
    },
    pageSize: 10,
    currentPage: 0,
    selectedStudents: [],
  };

  public componentDidMount() {
    this.getStudentList();
    this.getGroupList();
  }

  protected getGroupList = async (): Promise<void> => {
    this.setState({
      isLoading: true,
    });

    try {
      const schoolId = this.props.user.roles?.teacher.id?.mm_school_id;
      const result = await getGroupListBasedOnSchool(schoolId);
      this.setState({
        isLoading: false,
        groups: result?.data || [],
      });
    } catch {}
  };

  protected getStudentList = async (): Promise<void> => {
    this.setState({
      isLoading: true,
    });

    const schoolId = this.props.user.roles?.teacher.id?.mm_school_id;

    const queryParams = QueryStringService.getQueryStringFromObject({
      ...this.state.selectedFilters,
      schoolId,
    });

    try {
      const result = await getStudentList(queryParams);
      if (this.state.isFirstLoad) {
        this.setOtherFilters(result?.data || []);
      }
      this.setState({
        isLoading: false,
        students: result?.data || [],
        currentPage: 0,
        pageSize: 10,
        isFirstLoad: false,
        selectedStudents: [],
      });
    } catch {}
  };

  protected setOtherFilters = (students: IStudentListData[]): void => {
    const otherArr: string[] = [];

    if (!!students.length) {
      students.forEach((student) => {
        if (student.other.length >= 1) {
          student.other.forEach((other: string) => {
            otherArr.push(other);
          });
        }
      });
    }

    this.setState({ other: [...new Set(otherArr)] });
  };

  protected handleChangeUploadStudentsState = (isOpen: boolean): void => {
    this.setState({
      isUploadStudentsModalOpen: isOpen,
    });
  };

  protected handleFilterSelect = (
    selectedFilter: Partial<ISelectedFilters>
  ): void => {
    const { selectedFilters } = this.state;

    this.setState(
      {
        selectedFilters: {
          ...selectedFilters,
          ...selectedFilter,
        },
      },
      () => {
        this.getStudentList();
      }
    );
  };

  protected handleChangeRowsPerPage = (pageSize: number): void => {
    this.setState({
      currentPage: 0,
      pageSize,
    });
  };

  protected handleChangeCurrentPage = (currentPage: number): void => {
    this.setState({
      currentPage,
    });
  };

  protected handleUpdateYearGroupModalState = (isOpen: boolean): void => {
    this.setState({
      isUpdateYearGroupModalOpen: isOpen,
    });
  };

  protected handleStudentSelect = (
    id: string | string[],
    state: boolean
  ): void => {
    let { selectedStudents } = this.state;

    if (state) {
      if (Array.isArray(id)) {
        selectedStudents = [...selectedStudents, ...id];
      } else {
        selectedStudents.push(Array.isArray(id) ? [...id] : id);
      }
    }

    if (!state) {
      if (Array.isArray(id)) {
        selectedStudents = selectedStudents.filter(
          (studentId) => !id.includes(studentId)
        );
      } else {
        selectedStudents = selectedStudents.filter(
          (studentId) => studentId !== id
        );
      }
    }

    this.setState({
      selectedStudents,
    });
  };

  render() {
    const {
      isUploadStudentsModalOpen,
      isUpdateYearGroupModalOpen,
      isLoading,
      students,
      groups,
      other,
      selectedFilters,
      currentPage,
      pageSize,
      selectedStudents,
    } = this.state;

    return (
      <PageContainer>
        <StyledPaper>
          <StyledHeader>
            <Typography variant="h6">Manage Students</Typography>
            <Tooltip title="Upload new students">
              <IconButton
                onClick={() => this.handleChangeUploadStudentsState(true)}
                color="primary"
              >
                <StyledIcon className="uil uil-arrow-circle-up" />
              </IconButton>
            </Tooltip>
          </StyledHeader>
          <StyledDivider />
          <StudentListFilters
            groups={groups}
            other={other}
            selectedGroup={selectedFilters.groups}
            selectedYearGroup={selectedFilters.yearGroups}
            selectedOther={selectedFilters.other}
            selectedSex={selectedFilters.sex}
            selectedLastLogin={selectedFilters.lastLogin}
            onFilterSelect={this.handleFilterSelect}
            disabled={isLoading}
            addToGroupDisabled={!selectedStudents.length}
            onUpdateYearGroup={() => this.handleUpdateYearGroupModalState(true)}
            showLocked={selectedFilters.showLocked}
          />
          <StudentList
            isLoading={isLoading}
            students={students}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            onChangePage={this.handleChangeCurrentPage}
            currentPage={currentPage}
            pageSize={pageSize}
            onStudentSelect={this.handleStudentSelect}
            selectedStudents={selectedStudents}
            selectedGroup={selectedFilters.groups}
            showLocked={selectedFilters.showLocked}
          />
        </StyledPaper>
        <UploadStudents
          isOpen={isUploadStudentsModalOpen}
          onClose={() => this.handleChangeUploadStudentsState(false)}
          refreshStudents={this.getStudentList}
        />
        <UpdateYearGroupModel
          isOpen={isUpdateYearGroupModalOpen}
          handleCloseModal={() => this.handleUpdateYearGroupModalState(false)}
          selectedStudents={selectedStudents}
          refreshStudents={this.getStudentList}
        />
      </PageContainer>
    );
  }
}

export default withAuth(StudentsPage, ["teacher", "admin"]);
