import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "./editor";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

interface IProps {
  config?: any;
  placeholder?: string;
  valueIn?: string;
  resetValue: boolean;
  setLevelDescription(description: string): void;
}

const RichTextEditor: React.FC<IProps> = ({
  config,
  placeholder,
  resetValue,
  setLevelDescription,
  valueIn,
}: IProps) => {
  const [value, setValue] = useState(valueIn || "");
  const [localReset, setLocalReset] = useState(false);

  useEffect(() => {
    if (resetValue !== localReset) {
      setValue("");
      setLocalReset(resetValue);
    }
  }, [resetValue]);

  return (
    <CKEditor
      editor={ClassicEditor}
      data={value}
      onChange={(event, editor) => {
        const data = editor.getData();
        setValue(data);
        setLevelDescription(data);
      }}
      config={{
        placeholder,
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "numberedList",
          "bulletedList",
          "blockquote",
          "|",
          "link",
          "|",
          "undo",
          "redo",
        ],
        removePlugins: ["mediaEmbed", "image", "indent", "outdent"],
        fontFamily: "Nunito",
        fontSize: {},
        ...config,
      }}
    />
  );
};

export default RichTextEditor;
