import React from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import SexFilters from "./SexFilters";
import LastLoginFilters from "./LastLoginFilters";
import { IGroupData } from "typings/group";
import Select from "components/common/Select/Select";
import LockedGroupFilter from "components/teacher/LockedGroupCheckbox";

export const AVAILABLE_YEAR_GROUP_FILTER = [
  2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
];

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  flex-wrap: wrap;
`;

const StyledButton = styled(Button)`
  height: 43px !important;
  margin: 0 25px 0 0 !important;
  flex-shrink: 0;
`;

const StyledSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 25px 0 0;
  flex: 1;
  max-width: 100%;
`;

const StyledSelectDiv = styled.div`
  min-width: 125px;
  width: 100%;
  max-width: 33%;
`;

const StyledCheckboxesContainer = styled.div`
  display: flex;
  flex-shrink: 0;
`;

interface ISelectedFilter {
  [key: string]: string | string[] | number;
}

interface IProps {
  selectedGroup: string[];
  selectedYearGroup: string[];
  selectedOther: string[];
  selectedSex: string[];
  selectedLastLogin: number;
  groups: IGroupData[];
  other: string[];
  disabled: boolean;
  addToGroupDisabled: boolean;
  onFilterSelect: (value: ISelectedFilter) => void;
  onUpdateYearGroup: () => void;
  showLocked: boolean;
}

const StudentListFilters: React.FC<IProps> = ({
  selectedGroup = [],
  selectedYearGroup = [],
  selectedOther = [],
  selectedSex = [],
  selectedLastLogin = 0,
  groups,
  other,
  onFilterSelect,
  disabled,
  onUpdateYearGroup,
  addToGroupDisabled,
  showLocked,
}: IProps) => {
  const clearSelect = (items: string[]): string[] => {
    return items.filter((item) => item !== "");
  };

  return (
    <StyledContainer>
      <StyledButton
        variant="contained"
        color="primary"
        disabled={addToGroupDisabled}
        onClick={() => onUpdateYearGroup()}
      >
        update year group
      </StyledButton>
      <StyledSelectContainer>
        <StyledSelectDiv className="mr-3">
          <Select
            value={selectedGroup.length ? selectedGroup : [""]}
            fullWidth
            multiple
            disabled={disabled}
            onChange={(event) =>
              onFilterSelect({
                groups: clearSelect(event.target.value as string[]),
              })
            }
            options={groups.map((group) => ({
              label: group.name,
              value: group._id,
            }))}
            label="Students Group"
          />
        </StyledSelectDiv>
        <StyledSelectDiv className="mr-3">
          <Select
            value={selectedYearGroup.length ? selectedYearGroup : [""]}
            fullWidth
            multiple
            disabled={disabled}
            onChange={(event) =>
              onFilterSelect({
                yearGroups: clearSelect(event.target.value as string[]),
              })
            }
            options={AVAILABLE_YEAR_GROUP_FILTER.map((yearGroup) => ({
              label: `Year ${yearGroup}`,
              value: `Year ${yearGroup}`,
            }))}
            label="Year Group"
          />
        </StyledSelectDiv>
        <StyledSelectDiv>
          <Select
            value={selectedOther.length ? selectedOther : [""]}
            fullWidth
            multiple
            disabled={disabled}
            onChange={(event) =>
              onFilterSelect({
                other: clearSelect(event.target.value as string[]),
              })
            }
            options={other.map((otherItem) => ({
              label: `${otherItem}`,
              value: otherItem,
            }))}
            label="Other"
          />
        </StyledSelectDiv>
      </StyledSelectContainer>
      <StyledCheckboxesContainer>
        <SexFilters
          selectedFilters={selectedSex}
          onUpdate={(values: string[]) => onFilterSelect({ sex: values })}
          disabled={disabled}
        />
      </StyledCheckboxesContainer>
      <StyledCheckboxesContainer>
        <LastLoginFilters
          selectedFilter={selectedLastLogin}
          onUpdate={(value: number) => onFilterSelect({ lastLogin: value })}
          disabled={disabled}
        />
      </StyledCheckboxesContainer>
      <LockedGroupFilter
        disabled={disabled}
        onUpdate={(value: boolean) => onFilterSelect({ showLocked: value })}
        selected={showLocked}
      />
    </StyledContainer>
  );
};

export default StudentListFilters;
