import React from "react";

import { useFeedback } from "./context";
import { Actions } from "./styles";

export const Error = () => {
  const { error } = useFeedback();

  return !!error ? <Actions.ErrorMessage>{error}</Actions.ErrorMessage> : <></>;
};
