/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/indent */
import React from "react";
import { AreaOption, UpdateEvidenceContext } from "./types";
import { getAreas } from "lib/evidence";
import { useFeedback } from "../context";
import { useDetailedWheel } from "pages/student/sections/detailedWheelPage/context";
import { getLevels } from "../helper";

const Context = React.createContext<UpdateEvidenceContext>({
  areas: [],
  levels: [],
  setArea: () => {},
  setCurrentArea: () => {},
  setCurrentLevel: () => {},
  setLevel: () => {},
  setLoading: () => {},
});

export const UpdateEvidenceProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const { wheelData } = useDetailedWheel();
  const {
    evidence,
    evidenceObject,
    selectedLevel,
    wheelData: wheelData2,
    wheelLevelType,
  } = useFeedback();

  const [area, setArea] = React.useState<string>(evidence.evidence.areaId);
  const [baseAreas, setBaseAreas] = React.useState<AreaOption[]>([]);
  const [currentArea, setCurrentArea] = React.useState<string>(area);
  const [level, setLevel] = React.useState<string>(selectedLevel);
  const [currentLevel, setCurrentLevel] = React.useState<string>(level);
  const [loading, setLoading] = React.useState(false);

  const data = React.useMemo(
    () => (wheelData?.length ? wheelData : wheelData2) ?? [],
    [wheelData, wheelData2]
  );
  const areas: AreaOption[] = React.useMemo(
    () =>
      data?.length
        ? data.map(
            ({ _id, areaName, levels }): AreaOption => ({
              label: areaName,
              value: _id!,
              levels,
            })
          )
        : baseAreas,
    [baseAreas, data]
  );

  const levels = React.useMemo(
    () =>
      getLevels({
        evidence,
        levels: areas.find(({ value }) => value === area)?.levels ?? [],
        type: wheelLevelType,
      }),
    [area, areas, evidence, wheelLevelType]
  );

  React.useEffect(() => {
    setCurrentLevel(
      (levels?.find(
        ({ label }) =>
          label === `Level ${(evidence as any)?.studentGrade?.level[0]}`
      )?.value as string) ?? ""
    );
  }, [levels]);

  React.useEffect(() => {
    const index = ((evidence as any)?.studentGrade?.level?.[0] ?? 0) - 1;
    const level = levels?.[index];
    if (level) setLevel(level.value as string);
  }, [data, evidence, evidenceObject, levels]);

  React.useEffect(() => {
    getAreas({ mirrorId: evidence.evidence.wheelId }).then(({ data }) =>
      setBaseAreas(
        data.map(
          ({ _id, name, levels }: any): AreaOption => ({
            label: name,
            value: _id,
            levels: levels.map(({ value, ...rest }: any) => ({
              ...rest,
              level: value,
            })),
          })
        )
      )
    );
  }, []);

  return (
    <Context.Provider
      value={{
        area,
        areas,
        currentArea,
        setArea,
        setCurrentArea,
        level,
        levels,
        currentLevel,
        setLevel,
        setCurrentLevel,
        loading,
        setLoading,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useUpdateEvidence = () => React.useContext(Context);
