import { Button, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import { mediaRules } from "MuiTheme/Breakpoints";
import palette from "MuiTheme/palette";
import { GradeColors } from "components/common/feedbackModal/types";
import { ILevel } from "typings/meta-mirror";

import { StyledButtonProps, StyledTextProps } from "./types";

export const GB = Object.assign(
  styled(({ grade, selected, ...rest }: StyledButtonProps) => (
    <Button {...rest} />
  ))<StyledButtonProps>(({ disabled, grade, selected }) => ({
    backgroundColor: `${
      selected
        ? grade === null
          ? `${palette.font.caption}dd`
          : (palette as any)[GradeColors[grade!]]
        : "transparent"
    } !important`,
    border: `${
      grade === null
        ? `dashed 2px ${palette.font.body}`
        : `solid 2px ${(palette as any)[GradeColors[grade!]]}`
    } !important`,
    "&::hover":
      selected || disabled
        ? {}
        : {
            background: `${
              grade === null
                ? `${palette.font.body}33`
                : `${(palette as any)[GradeColors[grade!]]}22`
            } !important`,
          },
    transition: "0.7s",
    height: "55px",
    borderRadius: "55px !important",
    display: "flex",
    alignItems: "center",
    position: "relative",
    marginBottom: "7.5px",
    width: "100%",
    opacity: disabled ? 0.5 : 1,
    cursor: disabled ? "default" : "pointer",
    paddingLeft: "0px !important",
  })),
  {
    Icon: styled.i<{ grade: ILevel["grade"] }>(({ grade }) => ({
      border:
        grade === null
          ? `dashed 4px ${palette.font.body}`
          : "solid 4px transparent",
      minWidth: 40,
      minHeight: 40,
      maxHeight: 40,
      maxWidth: 40,
      fontSize: "30px",
      lineHeight: "normal",
      textAlign: "center",
      borderRadius: "30px",
      color: palette.font.contrast,
      backgroundColor:
        grade === null ? "transparent" : (palette as any)[GradeColors[grade!]],
      position: "relative",
      "&::before": {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
    })),

    Text: styled(({ grade, selected, ...rest }: StyledTextProps) => (
      <Typography {...rest} />
    ))<StyledTextProps>(({ grade, selected }) => ({
      color: `${
        selected
          ? palette.font.contrast
          : grade === null
          ? palette.font.body
          : (palette as any)[GradeColors[grade!]]
      } !important`,
      fontWeight: "700 !important",
      margin: "auto !important",
      paddingLeft: "20px !important",
      "@media (max-width: 1500px)": {
        paddingLeft: "30px !important",
      },
      "@media (max-width: 1300px)": {
        paddingLeft: "40px !important",
      },
      [`@media ${mediaRules.xl}`]: {
        paddingLeft: "0px !important",
      },
    })),
  }
);
