import { axiosInstance } from "helpers/axios-instance";
import axios from "axios";

export const getFileById = async (fileId: string) => {
  return axiosInstance.get(`/file/${fileId}`);
};

export const getFileContentFromUrl = async (url: string) => {
  const { data } = await axios.get(url, {
    headers: {
      responseType: "arraybuffer",
    },
  });
  return Buffer.from(data, "binary").toString("base64");
};

export const getBlobFromUrl = async (url: string) => {
  const { data } = await axios.get(url, { responseType: "blob" });
  return data;
};

export const b64toBlobUrl = (value: string): string => {
  const sliceSize = 512;
  const contentType = "application/pdf";
  const byteCharacters = atob(value.split(",")[1]);
  const byteArrays = [];

  for (let i = 0; i < byteCharacters.length; i += sliceSize) {
    const slice = byteCharacters.slice(i, i + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let j = 0; j < slice.length; ++j) {
      byteNumbers[j] = slice.charCodeAt(j);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  const blobUrl = `${URL.createObjectURL(blob)}#toolbar=0&navpanes=0`;
  return blobUrl;
};
