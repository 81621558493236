export const GRADES = {
  null: 0,
  "0": 1,
  "0.5": 2,
  "1": 3,
};

export const MIRROR_LEVEL_TYPES = ["sequential", "non-sequential"];

export const LEVEL_PARTS = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
];

export const SESSION_COOKIE = "Session";

export const WHITELABEL_COOKIE = "Whitelabel";
