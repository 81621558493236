import { Button, Grid, Tooltip } from "@material-ui/core";
import React from "react";

import { useDetailedWheel } from "./context";
import { DWP } from "./styles";

export const Header = () => {
  const { isToken, setProgressModalOpen, wheelName } = useDetailedWheel();

  const token = window.location.pathname.split("/")[2];
  const url = isToken ? `/token/${token}` : "/student";

  const handleBackClick = () => {
    window.location.href = url;
  };

  const handleProgressClick = () => setProgressModalOpen(true);

  return (
    <Grid
      alignItems="center"
      container
      direction="row"
      justifyContent="space-between"
    >
      <Grid item xs={4}>
        <>
          <DWP.Link className="d-none d-md-flex" to={url}>
            <Button
              color="primary"
              onClick={handleBackClick}
              variant="outlined"
            >
              Back to profile
            </Button>
          </DWP.Link>
          <DWP.Link className="d-flex d-md-none" to={url}>
            <DWP.BackButton color="primary">
              <i className="uil uil-angle-left-b" />
            </DWP.BackButton>
          </DWP.Link>
        </>
      </Grid>
      <Grid item xs={4}>
        <DWP.Name variant="h6">{wheelName}</DWP.Name>
      </Grid>
      <Grid item xs={4}>
        <Tooltip title="View Progress">
          <DWP.IconButton color="primary" onClick={handleProgressClick}>
            <DWP.Icon className="uil uil-chart-line" />
          </DWP.IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};
