import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import styled from "styled-components";
import palette from "palette";

interface IProps {
  selectedFilters: string[];
  onUpdate: (state: string[]) => void;
  disabled: boolean;
}

const StyledOptionsContainer = styled(FormGroup)`
  display: flex;
  align-items: center;
  flex-direction: row !important;
`;

const StyledFormLabel = styled.div`
  font-size: 14px;
  color: ${palette.font.body};
  margin: 0 0 -8px;
`;

const SexFilters: React.FC<IProps> = ({
  selectedFilters,
  onUpdate,
  disabled,
}: IProps) => {
  const handleUpdateSex = (type: "m" | "f", state: boolean): void => {
    let updatedState = selectedFilters;

    if (state) {
      updatedState.push(type);
      onUpdate(updatedState);
      return;
    }

    updatedState = selectedFilters.filter((sex) => sex !== type);
    onUpdate(updatedState);
  };

  return (
    <FormControl>
      <FormGroup>
        <StyledFormLabel>Sex</StyledFormLabel>
        <StyledOptionsContainer>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(event) => handleUpdateSex("m", event.target.checked)}
                checked={selectedFilters.includes("m")}
                color="primary"
                disabled={disabled}
              />
            }
            label="Boys"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={(event) => handleUpdateSex("f", event.target.checked)}
                checked={selectedFilters.includes("f")}
                color="primary"
                disabled={disabled}
              />
            }
            label="Girls"
          />
        </StyledOptionsContainer>
      </FormGroup>
    </FormControl>
  );
};

export default SexFilters;
