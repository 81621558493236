import React from "react";
import { Loading } from "./loading";
import { getProfileFromToken } from "lib/token";
import { useRouteMatch } from "react-router-dom";
import { StudentPage } from "pages/student/sections/wheelsPage";
import { Box } from "@material-ui/core";

export const Token = () => {
  const [error, setError] = React.useState<string>();
  const [loading, setLoading] = React.useState(true);
  const [studentId, setStudentId] = React.useState<string>();
  const { params } = useRouteMatch<{ token: string }>();

  React.useEffect(() => {
    if (!params.token) return;
    getProfileFromToken(params.token)
      .then(({ data }) => setStudentId(data.studentId))
      .catch((error) => setError(error.message))
      .finally(() => setLoading(false));
  }, [params]);

  return loading ? (
    <Loading />
  ) : (
    <Box
      style={{
        maxHeight: "100vh",
        overflowX: "hidden",
        overflowY: "auto",
        padding: "1rem",
        paddingBottom: "2rem",
      }}
    >
      <StudentPage isToken userId={studentId!} />
    </Box>
  );
};
