import { Theme } from "@material-ui/core";
import palette from "palette";

type ThemeInterface = Exclude<Theme["overrides"], undefined>;

const MuiTablePagination: ThemeInterface["MuiTablePagination"] = {
  actions: {
    color: palette.font.heading,
  },
  caption: {
    color: palette.font.caption,
  },
  root: {
    color: palette.font.body,
  },
  select: {
    paddingRight: "36px !important",
    paddingTop: 12,
  },
};

export default MuiTablePagination;
