import axios from "axios";
import { GetAuthToken } from "lib/auth";

const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = GetAuthToken();
    if (token && config?.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const axiosError = (err: any, handleMessage: (msg: string) => void) => {
  if (axios.isAxiosError(err) && err.response) {
    handleMessage(err.response.data.error);
  }
};

export { axiosInstance };
