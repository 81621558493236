import React from "react";
import { CircularProgress, Grid, Typography } from "@material-ui/core";

import Pagination from "components/common/Pagination/Pagination";
import WheelCard from "components/student/WheelCard";

import { useWheelsPage } from "./context";
import { ErrorMessage, PaginationContainer } from "./styles";

export const Wheels = () => {
  const { error, handlePageChange, loading, page, pageSize, profile, wheels } =
    useWheelsPage();

  const justify = React.useMemo(
    () => (!!wheels.length ? "flex-start" : "center"),
    [wheels]
  );

  return (
    <>
      <Grid
        alignItems="center"
        className={!wheels.length ? "h-100" : ""}
        container
        direction="row"
        justifyContent={justify}
        spacing={4}
      >
        {loading ? (
          <Grid item>
            <CircularProgress />
          </Grid>
        ) : !!error.length ? (
          <Grid item>
            <ErrorMessage variant="body2">{error}</ErrorMessage>
          </Grid>
        ) : wheels.length > 0 ? (
          wheels
            .sort((a, b) => {
              const locked = profile.mm_locked_group_ids;
              const aLocked = locked?.some((i: any) => a.groupIds.includes(i));
              const bLocked = locked?.some((i: any) => b.groupIds.includes(i));
              return !aLocked && bLocked ? -1 : aLocked && bLocked ? 1 : 0;
            })
            .slice(page * pageSize, page * pageSize + pageSize)
            .map(({ _id, groupIds, hasFeedback, name, wheelData }) => (
              <Grid item key={_id} xl={6} xs={12}>
                <WheelCard
                  wheelData={wheelData}
                  wheelName={name}
                  wheelId={_id}
                  newFeedback={hasFeedback}
                  isLocked={profile.mm_locked_group_ids?.some((i: any) =>
                    groupIds.includes(i)
                  )}
                />
              </Grid>
            ))
        ) : (
          <Grid item>
            <Typography variant="body2">
              You are currently not assigned to any wheels.
            </Typography>
          </Grid>
        )}
      </Grid>
      <PaginationContainer>
        <Pagination
          pagesCount={Math.ceil(wheels.length / pageSize)}
          currentPage={page}
          onChange={handlePageChange}
        />
      </PaginationContainer>
    </>
  );
};
