import { Grid } from "@material-ui/core";
import React from "react";

import palette from "MuiTheme/palette";
import Wheel from "components/common/Wheel/Wheel";

import { useDetailedWheel } from "./context";

export const Overview = () => {
  const { mirrorType, personalWheelData, ref, wheelData } = useDetailedWheel();
  const [size, setSize] = React.useState({ height: 200, width: 250 });

  const getWheelSize = () => {
    if (!ref?.current) return;
    const height = ref.current.offsetHeight / 2 || 200;
    const width = ref.current.offsetWidth || 275;
    setSize({ height, width });
  };

  React.useEffect(() => {
    window.addEventListener("resize", getWheelSize);
    getWheelSize();
    return () => window.removeEventListener("resize", getWheelSize);
  }, [ref]);

  return (
    <Grid
      className="d-flex flex-column align-content-center align-items-center pr-xl-4 pr-0"
      item
      ref={ref}
      xl={6}
      xs={12}
    >
      <Wheel
        backgroundColor={palette.surface}
        data={wheelData ?? []}
        descriptions="bottom"
        enableStudentData
        height={size.height}
        interactive
        labels
        mirrorLevelType={mirrorType}
        studentData={personalWheelData}
        toggleStudentByDefault
        width={size.width}
      />
    </Grid>
  );
};
