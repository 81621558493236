import React, { useEffect, useMemo, useState } from "react";
import { Snackbar, Typography } from "@material-ui/core";
import { S } from "./styles";
import TextInput from "components/common/TextInput/TextInput";
import Select from "components/common/Select/Select";
import { updateTeacherGroup } from "lib/group";
import { IGroup, IStudent, ITeacher } from "@rose-arch-holdings-ltd/common";
import { GroupStudentList } from "components/teacher/GroupStudentList";
import { Alert } from "reactstrap";

const AVAILABLE_YEAR_GROUP_FILTER = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

interface IProps {
  group: IGroup;
  groups: IGroup[];
  allGroups: IGroup[];
  refreshGroups: () => void;
  groupTeachers: ITeacher[];
  schoolTeachers: ITeacher[];
  isLoadingData: boolean;
  schoolStudents: IStudent[];
  studentSearch: string;
  setStudentSearch: (val: string) => void;
  studentGroupSearch: string;
  setStudentGroupSearch: (val: string) => void;
  otherSearch: string;
  setOtherSearch: (val: string) => void;
  studentYearSearch: string;
  setStudentYearSearch: (val: string) => void;
  selectedStudents: string[];
  setSelectedStudents: (val: string[]) => void;
  openAddUserModel: () => void;
  openRemoveUserModel: () => void;
  openLockGroupModel: () => void;
}

export const EditGroupPage: React.FC<IProps> = ({
  group,
  groups,
  allGroups,
  refreshGroups,
  groupTeachers,
  schoolTeachers,
  isLoadingData,
  schoolStudents,
  studentSearch,
  setStudentSearch,
  studentGroupSearch,
  otherSearch,
  studentYearSearch,
  setStudentGroupSearch,
  setOtherSearch,
  setStudentYearSearch,
  selectedStudents,
  setSelectedStudents,
  openAddUserModel,
  openRemoveUserModel,
  openLockGroupModel,
}) => {
  const [newName, setNewName] = useState(group.name);
  const [assignedTeachers, setAssignedTeachers] = useState<string[]>(
    groupTeachers.map((teacher) => teacher._id.toString())
  );
  const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout>();
  const [savedModal, setSavedModal] = useState<boolean>(false);
  const [errorModal, setErrorModal] = useState<boolean>(false);

  const updateGroup = async (name: string, teachersInGroup: string[]) => {
    try {
      currentTimeout && clearTimeout(currentTimeout);
      setNewName(name);
      const timeOutId = setTimeout(async () => {
        await updateTeacherGroup(group._id, name, teachersInGroup);
        await refreshGroups();
        setSavedModal(true);
      }, 650);
      setCurrentTimeout(timeOutId);
    } catch {}
  };

  useEffect(() => {
    setNewName(group.name);
    setAssignedTeachers(groupTeachers.map((teacher) => teacher._id.toString()));
  }, [group, groupTeachers]);

  const othersTags = useMemo(() => {
    const b = schoolStudents.map((s) => s.other[0]).filter((i) => i !== "");
    const a = new Set(b);
    const res = Array.from(a).map((s) => ({
      label: s || "",
      value: s || "",
    }));
    return [{ label: `None`, value: `` }, ...res];
  }, [isLoadingData]);

  return (
    <S.Paper>
      <S.Header>
        <Typography variant="h6">{group.name}</Typography>
      </S.Header>

      <S.Divider />

      <TextInput
        value={newName}
        label="Group Name"
        onChange={(event) => updateGroup(event.target.value, assignedTeachers)}
        inputProps={{
          maxLength: 50,
        }}
        fullWidth
        style={{ marginTop: "20px", marginBottom: "20px" }}
        disabled={isLoadingData}
      />

      <Select
        value={assignedTeachers}
        label="Assigned Teachers"
        disabled={isLoadingData}
        fullWidth
        multiple
        style={{ marginBottom: "20px" }}
        options={schoolTeachers?.map((teacher) => ({
          label: teacher.name,
          value: teacher._id.toString(),
        }))}
        onChange={async (event) => {
          const teachersInGroup = assignedTeachers.filter(
            (val) => val === event.target.value
          );
          const newTeachersInGroup = event.target.value as string[];
          const values = [...teachersInGroup, ...newTeachersInGroup];
          if (values.length > 0) {
            setAssignedTeachers(values);
            await updateGroup(group.name, values);
          } else {
            setErrorModal(true);
          }
        }}
      />

      <Typography variant="h6" style={{ marginBottom: "10px" }}>
        Students
      </Typography>

      <S.SelectContainer>
        <Select
          value={studentGroupSearch}
          label="Students Group"
          disabled={isLoadingData}
          options={[
            { label: `None`, value: `` },
            ...groups.map((group) => ({
              label: group.name,
              value: group.name,
            })),
          ]}
          onChange={(event: any) => {
            setStudentGroupSearch(event.target.value);
          }}
        />

        <Select
          value={studentYearSearch}
          label="Year Group"
          disabled={isLoadingData}
          options={[
            { label: `None`, value: `` },
            ...AVAILABLE_YEAR_GROUP_FILTER.map((yearGroup) => ({
              label: `Year ${yearGroup}`,
              value: `Year ${yearGroup}`,
            })),
          ]}
          onChange={(event: any) => {
            setStudentYearSearch(event.target.value);
          }}
        />

        <Select
          value={otherSearch}
          label="Other"
          disabled={isLoadingData && othersTags.length <= 0}
          options={othersTags}
          onChange={(event: any) => {
            setOtherSearch(event.target.value);
          }}
        />

        <S.OptionsContainer>
          <S.OptionIcon onClick={openAddUserModel} type="primary">
            <i className="uil uil-user-plus" />
          </S.OptionIcon>
          <S.OptionIcon onClick={openLockGroupModel} type="primary">
            <i className="uil uil-padlock" />
          </S.OptionIcon>
          <S.OptionIcon onClick={openRemoveUserModel} type="secondary">
            <i className="uil uil-user-times" />
          </S.OptionIcon>
        </S.OptionsContainer>
      </S.SelectContainer>

      <TextInput
        value={studentSearch}
        label="Student Search"
        onChange={(event) => setStudentSearch(event.target.value)}
        inputProps={{
          maxLength: 50,
        }}
        fullWidth
        style={{ marginTop: "20px", marginBottom: "20px" }}
        disabled={isLoadingData}
      />

      <GroupStudentList
        students={schoolStudents}
        isLoading={isLoadingData}
        groups={groups}
        allGroups={allGroups}
        group={group}
        selectedStudents={selectedStudents}
        setSelectedStudents={setSelectedStudents}
      />

      <Snackbar
        open={savedModal}
        autoHideDuration={6000}
        onClose={() => setSavedModal(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSavedModal(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Group Saved
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorModal}
        autoHideDuration={6000}
        onClose={() => setErrorModal(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setErrorModal(false)}
          severity="success"
          style={{
            backgroundColor: "#c23616",
            borderColor: "#c23616",
            color: "white",
          }}
          sx={{ width: "100%" }}
        >
          Cannot remove all teachers from group
        </Alert>
      </Snackbar>
    </S.Paper>
  );
};
