import { PictureAsPdf } from "@material-ui/icons";
import React from "react";

import { useEvidence } from "./context";
import { ItemHeader } from "./styles";

export enum EVIDENCE_ICONS {
  ".jpeg" = "uil uil-image-v",
  ".jpg" = "uil uil-image-v",
  ".png" = "uil uil-image-v",
  ".docx" = "uil uil-file-alt",
  ".doc" = "uil uil-file-alt",
  ".ppt" = "uil uil-presentation-play",
  ".pptx" = "uil uil-presentation-play",
  "link" = "uil uil-link",
  "comment" = "uil uil-comment",
}

export const Header = () => {
  const { date, name, type } = useEvidence();

  return (
    <ItemHeader>
      <ItemHeader.Icon>
        {type === ".pdf" ? (
          <PictureAsPdf style={{ fontSize: 35 }} />
        ) : (
          <ItemHeader.EvidenceIcon className={(EVIDENCE_ICONS as any)[type]} />
        )}
      </ItemHeader.Icon>
      <ItemHeader.Info>
        <ItemHeader.Title>{name}</ItemHeader.Title>
        <ItemHeader.Date>{new Date(date).toLocaleDateString()}</ItemHeader.Date>
      </ItemHeader.Info>
    </ItemHeader>
  );
};
