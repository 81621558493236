import { Typography, Button, CircularProgress } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import DatePicker from "components/common/DatePicker/DatePicker";
import Select, { TOption } from "components/common/Select/Select";
import {
  IGenerateReportSelectedOptions,
  TReportType,
} from "./GenerateReportModal";

const TYPE_OPTIONS = [
  {
    label: "Historical",
    value: "historical",
  },
  {
    label: "Group",
    value: "group",
  },
];

const StyledModalContainer = styled.div`
  margin: 50px 0 0;
`;

const StyledSelectSection = styled.div`
  margin: 0 0 20px;
`;

const StyledSelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledSelectDescription = styled(Typography)`
  font-size: 14px !important;
  font-style: italic;
  margin: 8px 0 0 15px !important;
`;

const StyledActionSection = styled.div`
  margin: 50px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled(Button)`
  width: 150px;
  margin: 0 10px !important;
  transition: 0.3;
`;

const StyledCircularProgress = styled(CircularProgress)`
  width: 20px !important;
  height: 20px !important;
  color: #fff !important;
  margin: 0 0 0 10px;
`;

interface IProps {
  selectedOptions: IGenerateReportSelectedOptions;
  wheelGroups: TOption[];
  wheelCreateDate: string;
  isBulk?: boolean;
  isLoading?: boolean;
  handleClose: () => void;
  handleGenerate: () => void;
  handleSelectOption: (
    selectedOption: Partial<IGenerateReportSelectedOptions>
  ) => void;
}

const GenerateReportFirstStep: React.FC<IProps> = ({
  selectedOptions,
  wheelGroups = [],
  wheelCreateDate,
  isBulk,
  isLoading,
  handleClose,
  handleGenerate,
  handleSelectOption,
}: IProps) => {
  const { reportType, studentGroups, startDate, comparisonGroup } =
    selectedOptions;

  const renderStudentGroupSelect = (
    isMultiple: boolean,
    description?: string
  ): JSX.Element => (
    <StyledSelectSection>
      {wheelGroups.length <= 1 ? (
        <Select
          label="Student Group"
          options={wheelGroups}
          value={studentGroups}
          disabled
        />
      ) : (
        <Select
          label="Student Group(s)"
          options={wheelGroups}
          value={studentGroups}
          onChange={(event) =>
            handleSelectOption({
              studentGroups: event.target.value as string[],
            })
          }
          multiple={isMultiple}
        />
      )}
      {!!description && (
        <StyledSelectDescription variant="body2">
          {description}
        </StyledSelectDescription>
      )}
    </StyledSelectSection>
  );

  const isGenerateReportActive = (): boolean => {
    if (isBulk) {
      if (reportType === "historical") {
        return !!studentGroups.length && !!startDate;
      }

      return !!studentGroups.length && !!comparisonGroup;
    }

    if (reportType === "historical") {
      return !!startDate;
    }

    return !!studentGroups.length;
  };

  return (
    <StyledModalContainer>
      {isBulk && (
        <>
          {renderStudentGroupSelect(
            true,
            "Reports will be generated for all students in this group."
          )}
        </>
      )}
      <StyledSelectSection>
        <StyledSelectContainer>
          <Select
            label="Type"
            options={TYPE_OPTIONS}
            value={reportType}
            onChange={(event) => {
              const { value } = event.target;
              if (value !== "historical" && startDate !== null) {
                handleSelectOption({ startDate: null });
              }

              handleSelectOption({ reportType: value as TReportType });
            }}
          />
        </StyledSelectContainer>
        <StyledSelectDescription variant="body2">
          <b>Historical</b> - View each student’s progression from a selected
          date. <br />
          <b>Group</b> - View each student’s current results, alongside the
          average for a selected group.
        </StyledSelectDescription>
      </StyledSelectSection>
      {reportType === "group" ? (
        <>
          {isBulk ? (
            <>
              {wheelGroups.length <= 1 ? (
                <Select
                  label="Comparison Group"
                  options={wheelGroups}
                  value={studentGroups}
                  disabled
                />
              ) : (
                <Select
                  label="Comparison Group"
                  options={wheelGroups}
                  value={comparisonGroup}
                  onChange={(event) =>
                    handleSelectOption({
                      comparisonGroup: event.target.value as string,
                    })
                  }
                />
              )}
            </>
          ) : (
            <>{renderStudentGroupSelect(false)}</>
          )}
        </>
      ) : (
        <StyledSelectSection>
          <DatePicker
            label="Start Date"
            minDate={new Date(wheelCreateDate)}
            maxDate={new Date()}
            handleSetValue={(selectedDate) =>
              handleSelectOption({ startDate: selectedDate })
            }
          />
        </StyledSelectSection>
      )}
      <StyledActionSection>
        <StyledButton
          variant="outlined"
          color="primary"
          onClick={() => handleClose()}
        >
          back
        </StyledButton>
        <StyledButton
          variant="contained"
          color="primary"
          disabled={isLoading || !isGenerateReportActive()}
          onClick={() => handleGenerate()}
        >
          generate
          {isLoading && <StyledCircularProgress />}
        </StyledButton>
      </StyledActionSection>
    </StyledModalContainer>
  );
};

export default GenerateReportFirstStep;
