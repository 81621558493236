import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";

import { ImageUpload } from "components/common/imageUpload";

import { useWheelsPage } from "../context";
import { TokenButton } from "../tokenButton";
import { useProfile } from "./context";
import { MockProfile } from "./mock";
import { Container } from "./styles";
import { Summary } from "./summary";
import { Achievements } from "./achievements";
import { OtherAcheivements } from "./other";
import { Contact } from "./contact";

export const Profile = () => {
  const { isUser, mockUser, profile } = useWheelsPage();
  const { upload, image } = useProfile();

  if (mockUser) return <MockProfile />;

  return (
    <Container>
      <div style={{ flex: 1 }}>
        <TokenButton />
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}></Grid>
          <Grid
            item
            xs={12}
            lg={6}
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {isUser ? (
              <ImageUpload
                image={image}
                name="profile-image"
                onChange={upload}
                placeholder="Click here to upload your profile picture."
              />
            ) : image ? (
              <Box
                sx={{
                  alignItems: "center",
                  //  @ts-ignore
                  background: "rgba(0, 0, 0, .5) !important",
                  border: "0 !important",
                  borderRadius: "50% !important",
                  display: "flex",
                  flexDirection: "column",
                  height: "250px !important",
                  justifyContent: "center",
                  mt: 2,
                  overflow: "hidden",
                  position: "relative",
                  width: "250px !important",
                  ...(!image && {
                    "&:before": {
                      color: "white",
                      content: '"No image selected."',
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      transition: "500ms cubic-bezier(0.4, 0, 0.2, 1)",
                      textAlign: "center",
                    },
                  }),
                }}
              >
                <img
                  src={image as string}
                  alt="profile"
                  style={{
                    borderRadius: "50%",
                    height: "100%",
                    left: 0,
                    objectFit: "cover",
                    position: "absolute",
                    top: 0,
                    width: "100%",
                  }}
                />
              </Box>
            ) : (
              <React.Fragment />
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                marginLeft: "1rem",
              }}
            >
              <Typography variant="h4">{profile?.name}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} lg={3}></Grid>
          <Grid item xs={12} md={6}>
            <Summary />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ display: "flex", flexDirection: "column", gap: ".5rem" }}
          >
            <Achievements />
            <OtherAcheivements />
            <Contact />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};
