import { Button } from "@material-ui/core";
import React from "react";

import AnimatedModal from "components/common/AnimatedModal";

import { useEvidenceList } from "../context";
import { View } from "./styles";

export const ViewModal = () => {
  const { comment, setComment } = useEvidenceList();

  const handleClose = () => setComment(undefined);

  return (
    <AnimatedModal
      handleClose={handleClose}
      open={!!comment}
      maxHeight="90vh"
      maxWidth="800px"
      minWidth="320px"
      title={comment?.name}
      width="90%"
    >
      <>
        {/* <View.Area>{comment?.areaAndLevel}</View.Area> */}
        <View.Comment>
          &quot;
          {comment?.url}
          &quot;
        </View.Comment>
        <View.Container>
          <View.Info>
            <View.Text variant="caption">{comment?.date}</View.Text>
          </View.Info>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            close
          </Button>
        </View.Container>
      </>
    </AnimatedModal>
  );
};
