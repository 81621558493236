import React, { useEffect, useState } from "react";
import Select, { TOption } from "components/common/Select/Select";
import TextInput from "components/common/TextInput/TextInput";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import palette from "palette";
import { mediaRules } from "MuiTheme/Breakpoints";
import { ILevel, TWheelData } from "typings/meta-mirror";
import { getStudentCurrentGradeByArea } from "lib/student";
import { IWithAuth, withAuth } from "../../../hoc/withAuth";
import { LEVEL_PARTS } from "constants/index";

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  width: 95%;
  padding: 20px 0 15px 0;
  text-align: center !important;
  @media ${mediaRules.sm} {
    width: 100%;
  }
`;

const StyledErrorMessage = styled(Typography)`
  color: ${palette.error} !important;
  min-height: 30px;
  @media (max-width: 959px) {
    min-height: 45px;
  }
  @media ${mediaRules.sm} {
    min-height: 60px;
  }
`;

const StyledInputContainer = styled.div`
  padding: 0 10%;
  margin: 5px 0;
  @media ${mediaRules.sm} {
    padding: 0;
    margin: 0;
  }
`;

const StyledSelectContainer = styled(StyledInputContainer)`
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

interface IProps extends IWithAuth {
  error: string;
  requesting: boolean;
  selectedAreaId: string;
  selectedLevelId: string;
  wheelAreas: { _id: string; name: string; disabled: boolean }[];
  wheelData: TWheelData;
  comment: string;
  mirrorLevelType: string;
  handleSetEvidenceData(comment: string, evidenceName: string): void;
  handleSelectArea(selectedAreaId: string): void;
  handleSelectLevel(selectedAreaId: string): void;
}

const SubmitComment: React.FC<IProps> = ({
  error,
  requesting,
  selectedAreaId,
  selectedLevelId,
  wheelAreas,
  wheelData,
  comment,
  handleSetEvidenceData,
  handleSelectArea,
  handleSelectLevel,
  user,
  mirrorLevelType,
}: IProps) => {
  const [levelsLoading, setLevelsLoading] = useState(true);
  const [levelOptions, setLevelOptions] = useState<TOption[]>([]);

  const getLabel = (level: ILevel) =>
    level.name
      ? level.name
      : mirrorLevelType === "non-sequential"
      ? `Part ${LEVEL_PARTS[level.level - 1]}`
      : `Level ${level.level}`;

  const getLevels = (
    studentLevelId: string,
    teacherLevelId: string
  ): TOption[] => {
    const wheelAreaLevels = wheelData.find(
      ({ _id }) => _id === selectedAreaId
    ).levels;
    const levels = wheelAreaLevels.sort((a, b) => a.level - b.level);
    const currentLevelStudent = levels.find(
      (level) => level._id === studentLevelId
    );
    const currentLevelTeacher = levels.find(
      (level) => level._id === teacherLevelId
    );
    let levelDisabled = !!studentLevelId && !!teacherLevelId;
    const levelOptionsArr: TOption[] = [];
    const studentTeacherLevelMatch =
      currentLevelStudent?.level === currentLevelTeacher?.level;
    const gradeComplete = currentLevelTeacher?.grade === 1;
    levels.forEach((level) => {
      levelDisabled =
        level.level < currentLevelStudent?.level ||
        (level.level > currentLevelStudent?.level &&
          !studentTeacherLevelMatch) ||
        (level.level > currentLevelStudent?.level && !gradeComplete);
      levelOptionsArr.push({
        label: getLabel(level),
        value: level._id,
        disabled: levelDisabled && mirrorLevelType === "sequential",
      });
    });

    const enabledOptions = levelOptionsArr.filter(
      (option) => option.disabled === false
    );
    if (
      enabledOptions.length >= 1 &&
      (selectedLevelId === "" ||
        !enabledOptions.find((option) => option.value === selectedLevelId))
    ) {
      handleSelectLevel(enabledOptions[0].value as string);
    }
    return levelOptionsArr;
  };

  useEffect(() => {
    if (selectedAreaId) {
      (async () => {
        setLevelsLoading(true);
        const { data } = await getStudentCurrentGradeByArea(
          selectedAreaId,
          user?.roles?.student?.id._id
        );
        const wheelAreaLevels = wheelData.find(
          ({ _id }) => _id === selectedAreaId
        ).levels;
        let teacherLevelId = -1;
        let studentLevelId = 0;
        if (data.data.teacherLevelId) {
          teacherLevelId = data.data.teacherLevelId;
        }
        if (data.data.studentLevelId) {
          studentLevelId = data.data.studentLevelId;
        }
        handleSelectLevel(studentLevelId);
        setLevelOptions(getLevels(studentLevelId, teacherLevelId));
        setLevelsLoading(false);
      })();
    }
  }, [selectedAreaId]);

  return (
    <StyledContainer>
      <Typography variant="body1">
        Choose a wheel area,{" "}
        {mirrorLevelType === "non-sequential" ? "part" : "level"}, and add a
        comment. Then press submit to add your evidence.
      </Typography>
      <StyledSelectContainer>
        <Select
          options={wheelAreas.map((area) => ({
            label: area.name,
            value: area._id,
            disabled: area.disabled,
          }))}
          onChange={(e) => handleSelectArea(e.target.value as string)}
          className="text-left"
          disableMargin
          fullWidth
          value={selectedAreaId}
          label="Area"
          disabled={requesting}
        />
        <Select
          options={levelOptions}
          value={selectedLevelId}
          onChange={(e) => handleSelectLevel(e.target.value as string)}
          className="text-left"
          fullWidth
          label={mirrorLevelType === "non-sequential" ? "Part" : "Level"}
          disabled={requesting || levelsLoading || !selectedLevelId}
        />
      </StyledSelectContainer>
      <StyledInputContainer>
        <TextInput
          label="Comment"
          value={comment}
          onChange={(e) => handleSetEvidenceData(e.target.value, "Comment")}
          disabled={requesting}
          fullWidth
          multiline
          rowsMax={5}
          rows={5}
          inputProps={{ maxLength: 500 }}
          helperText={`${500 - comment.length}/500 characters remaining`}
        />
      </StyledInputContainer>

      <StyledErrorMessage>{error}</StyledErrorMessage>
    </StyledContainer>
  );
};

export default withAuth(SubmitComment, []);
