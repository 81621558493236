import React from "react";

import { useOther } from "./context";
import { ModalContainer, P, StyledModal } from "./styles";
import { getFileById } from "lib/file";
import { Tooltip } from "@material-ui/core";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { Office } from "components/common/feedbackModal/preview/office";

export const Modal = () => {
  const { item, setItem } = useOther();
  const [image, setImage] = React.useState<string>();

  const extension = React.useMemo(
    () => image?.split("?")?.[0]?.split(".").pop() ?? "",
    [image]
  );

  const handleClose = () => setItem(undefined);

  React.useEffect(() => {
    if (!!item) {
      getFileById(item).then(({ data }) => setImage(data));
    } else {
      setItem(undefined);
    }
  }, [item]);

  return (
    <StyledModal
      open={!!item}
      handleClose={handleClose}
      onClose={handleClose}
      width="100%"
      maxWidth="625px"
      height="auto"
    >
      <ModalContainer>
        {image && extension === "pdf" ? (
          <Office fileType="pdf" src={image} />
        ) : (
          <P>
            <Tooltip
              title="Scroll or Pinch to zoom in and out"
              enterDelay={1000}
            >
              <P.Section>
                <P.Container>
                  <TransformWrapper
                    initialScale={1}
                    minScale={0.5}
                    maxScale={5}
                  >
                    <TransformComponent
                      wrapperStyle={{ width: "100%", height: "100%" }}
                    >
                      <P.Image src={image} alt="Evidence Preview image" />
                    </TransformComponent>
                  </TransformWrapper>
                </P.Container>
              </P.Section>
            </Tooltip>
          </P>
        )}
      </ModalContainer>
    </StyledModal>
  );
};
