import React from "react";
import {
  BrowserRouter,
  Route,
  RouteComponentProps,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import Navbar from "components/common/Navbar/Navbar";
import SchoolsPage from "./sections/SchoolsPage";
import StudentsPage from "./sections/studentsPage";
import TeachersPage from "./sections/teachersPage";
import DetailedTeacherPage from "./sections/DetailedTeacherPage";
import DetailedSchoolPage from "./sections/DetailedSchoolPage";
import DetailedStudentPage from "./sections/DetailedStudentPage";

const routes = [
  {
    path: "/admin/students",
    Component: StudentsPage,
  },
  {
    path: "/admin/students/:studentId",
    Component: DetailedStudentPage,
  },
  {
    path: "/admin/teachers",
    Component: TeachersPage,
  },
  {
    path: "/admin/teachers/:teacherId",
    Component: DetailedTeacherPage,
  },
  {
    path: "/admin/schools/:schoolId",
    Component: DetailedSchoolPage,
  },
  {
    path: "/admin/schools",
    Component: SchoolsPage,
  },
];

const AdminSwitch = withRouter(({ location }) => (
  // <TransitionGroup>
  //   <CSSTransition
  //     key={location.key}
  //     timeout={1100}
  //     classNames="transition"
  //     unmountOnExit
  //   >
  <Switch location={location}>
    <Redirect from="/admin" to="/admin/students" exact />
    {routes.map(({ path, Component }) => (
      <Route exact path={path} key={`admin-route-${path}`}>
        {/* @ts-ignore */}
        {(routeProps: RouteComponentProps) => <Component {...routeProps} />}
      </Route>
    ))}
  </Switch>
  //   </CSSTransition>
  // </TransitionGroup>
));

const AdminPage: React.FC = () => {
  return (
    <>
      <BrowserRouter>
        <Navbar type="admin" />
        <AdminSwitch />
      </BrowserRouter>
    </>
  );
};

export default AdminPage;
