import { Button, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { TWheelData } from "typings/meta-mirror";
import Wheel from "components/common/Wheel/Wheel";
import { mediaRules } from "MuiTheme/Breakpoints";
import palette from "palette";
import { MIRROR_LEVEL_TYPES } from "constants/index";
import Select from "components/common/Select/Select";
import { updateMirror } from "lib/mirror";

const StyledPaper = styled(Paper)`
  height: auto;
  margin-bottom: 30px;
  padding: 20px 30px;
  width: calc(50% - 15px);
  :nth-child(even) {
    margin-left: 30px;
  }
  @media ${mediaRules.xl} {
    :nth-child(even) {
      margin-left: 0px;
    }
    width: 100%;
  }
`;

const StyledWheelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-height: 350px;
`;

const StyledHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
`;

const StyledFooterRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
`;

const StyledSaveButton = styled(Button)`
  background-color: ${palette.primary} !important;
  color: ${palette.font.contrast} !important;
`;

const StyledWheelTypeWrapper = styled.div`
  width: 40%;
`;

const StyledWheelTypeName = styled.h4`
  color: gray;
  font-size: 20px;
`;

const StyledDeleteButton = styled(Button)`
  background-color: ${palette.error} !important;
  color: ${palette.font.contrast} !important;
`;

const WHEEL_INITIAL_SIZE = 350;

interface IProps {
  _id: string;
  name: string;
  levelType?: string;
  disabled?: boolean;
  wheelData: TWheelData;
  handleDelete(): void;
}

const SchoolWheelItem: React.FC<IProps> = ({
  name,
  wheelData,
  handleDelete,
  levelType,
  _id,
  disabled,
}: IProps) => {
  const wheelBoxRef = useRef<HTMLDivElement>(null);
  const [wheelWidth, setWheelWidth] = useState(WHEEL_INITIAL_SIZE);
  const [wheelHeight, setWheelHeight] = useState(WHEEL_INITIAL_SIZE);
  const [mirrorLevelsType, setMirrorLevelsType] = useState("");
  const [editOpen, setEditOpen] = useState(false);

  useEffect(() => {
    setMirrorLevelsType(levelType ?? "sequential");
    const handleResize = () => {
      setWheelWidth(wheelBoxRef?.current.offsetWidth || WHEEL_INITIAL_SIZE);
      setWheelHeight(wheelBoxRef?.current.offsetHeight || WHEEL_INITIAL_SIZE);
    };

    if (wheelWidth === WHEEL_INITIAL_SIZE) {
      handleResize();
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const SaveWheel = async () => {
    try {
      await updateMirror({
        id: _id,
        disabled: disabled ?? false,
        name,
        levelType: mirrorLevelsType,
      });
      setEditOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <StyledPaper>
      <StyledHeaderRow>
        <Typography variant="h6">{name}</Typography>

        <StyledDeleteButton variant="contained" onClick={handleDelete}>
          delete
        </StyledDeleteButton>
      </StyledHeaderRow>
      <StyledWheelContainer ref={wheelBoxRef}>
        <Wheel
          backgroundColor={palette.surface}
          height={wheelHeight}
          width={wheelWidth}
          data={wheelData}
          labels
          preview
        />
      </StyledWheelContainer>
      <StyledFooterRow>
        {editOpen && (
          <>
            <StyledWheelTypeWrapper>
              <Select
                onChange={(e) => setMirrorLevelsType(e.target.value)}
                value={mirrorLevelsType}
                options={MIRROR_LEVEL_TYPES.map((v) => ({
                  label: v,
                  value: v,
                }))}
              ></Select>
            </StyledWheelTypeWrapper>
            <StyledSaveButton variant="contained" onClick={() => SaveWheel()}>
              save
            </StyledSaveButton>
          </>
        )}
        {!editOpen && (
          <>
            <StyledWheelTypeName>{mirrorLevelsType}</StyledWheelTypeName>
            <StyledSaveButton
              variant="contained"
              onClick={() => setEditOpen(true)}
            >
              edit
            </StyledSaveButton>
          </>
        )}
      </StyledFooterRow>
    </StyledPaper>
  );
};

export default SchoolWheelItem;
