import React, { useEffect, useState } from "react";
import Select, { TOption } from "components/common/Select/Select";
// import TextInput from "components/common/TextInput/TextInput";
// import { VALID_DOMAINS } from "helpers/metaMirrorEvidenceValidation";
import { Button, Typography } from "@material-ui/core";
import styled from "styled-components";
import palette from "palette";
import { mediaRules } from "MuiTheme/Breakpoints";
import { ILevel, TWheelData } from "typings/meta-mirror";
import { getStudentCurrentGradeByArea } from "lib/student";
import { IWithAuth, withAuth } from "../../../hoc/withAuth";
import { LEVEL_PARTS } from "constants/index";
import { Dashboard } from "@uppy/react";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import Uppy from "@uppy/core";
import GoogleDrive from "@uppy/google-drive";
import OneDrive from "@uppy/onedrive";
import Url from "@uppy/url";
import axios from "axios";
// import Dropbox from "@uppy/dropbox";

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 95%;
  padding: 20px 0 15px 0;
  text-align: center !important;
  @media ${mediaRules.sm} {
    width: 100%;
  }
`;

const StyledErrorMessage = styled(Typography)`
  color: ${palette.error} !important;
  min-height: 30px;
  @media (max-width: 959px) {
    min-height: 45px;
  }
  @media ${mediaRules.sm} {
    min-height: 60px;
  }
`;

const StyledInputContainer = styled.div`
  padding: 0 10%;
  margin: 5px 0;
  @media ${mediaRules.sm} {
    padding: 0;
    margin: 0;
  }
`;

const StyledSelectContainer = styled(StyledInputContainer)`
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const RetryButtonWrapper = styled.div`
  margin-top: 20px;
`;

const FileUploadWrapper = styled.div`
  margin-left: 50px;
  width: 100%;
`;

const StyledIcon = styled.i`
  font-size: 22px;
  margin: 0 5px 0 0;
`;

const LinkContainer = styled.div`
  overflow: auto;
  width: 100%;
  max-width: 500px;
  text-align: left;
  border: 3px solid gray;
  padding: 10px;
  border-radius: 10px;
`;

const EvidenceLinkWrapper = styled.div`
  margin-top: 20px;
  p {
    margin: 5px;
    text-align: left;
    color: ${palette.primary};
  }
`;

interface IProps extends IWithAuth {
  error: string;
  requesting: boolean;
  selectedAreaId: string;
  selectedLevelId: string;
  wheelAreas: { _id: string; name: string; disabled: boolean }[];
  wheelData: TWheelData;
  link: string;
  mirrorLevelType: string;
  evidenceName: string;
  handleSetEvidenceData(link: string, evidenceName: string): void;
  handleSelectArea(selectedAreaId: string): void;
  handleSelectLevel(selectedAreaId: string): void;
}

const SubmitLink: React.FC<IProps> = ({
  error,
  requesting,
  selectedAreaId,
  selectedLevelId,
  wheelAreas,
  wheelData,
  link,
  handleSetEvidenceData,
  handleSelectArea,
  handleSelectLevel,
  user,
  mirrorLevelType,
  evidenceName,
}: IProps) => {
  const [levelsLoading, setLevelsLoading] = useState(true);
  const [levelOptions, setLevelOptions] = useState<TOption[]>([]);
  const [filePickerOpen, setFilePickerOpen] = useState(true);

  const getLabel = (level: ILevel) =>
    level.name
      ? level.name
      : mirrorLevelType === "non-sequential"
      ? `Part ${LEVEL_PARTS[level.level - 1]}`
      : `Level ${level.level}`;

  const uppy = React.useMemo(() => {
    const companionUrl = `${process.env.NEXT_PUBLIC_API_URL}companion`;
    return new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
      },
    })
      .use(GoogleDrive, {
        companionUrl: companionUrl,
        // companionCookiesRule: 'include',
      })
      .use(OneDrive, {
        companionUrl: companionUrl,
        title: "OneDrive / Sharepoint",
        // companionCookiesRule: 'include',
      })
      .use(Url, {
        companionUrl: companionUrl,
        locale: {
          strings: {
            import: "Add Link",
            enterUrlToImport: "Enter/Paste URL here",
            failedToFetch:
              "Failed to load your link. Please make sure it's correct",
          },
        },
      })
      .on("file-added", (data) => {
        // console.log(data);

        setFilePickerOpen(false);

        if (data.source === "GoogleDrive") {
          const link = `https://drive.google.com/file/d/${data.data.id}`;
          handleSetEvidenceData(link, link);
        }

        if (data.source === "OneDrive") {
          const thumbnailUrl = data.data?.thumbnail;
          const url = new URL(thumbnailUrl);
          const docid = url.searchParams.get("docid");
          let downloadUrl = "";
          if (docid) {
            axios
              .get(docid)
              .then((res) => {
                downloadUrl = res.data["@content.downloadUrl"];
              })
              .then((res) => {
                const link = downloadUrl;
                const linkStart = link.split("_layouts")[0];
                const completeLink = `${linkStart}documents/${data.name}`;
                handleSetEvidenceData(completeLink, data.name);
              });
          }
        }
        if (data.source === "Url") {
          const link = (data.remote?.body?.url as string) ?? "";
          handleSetEvidenceData(link, link);
        }
      })
      .on("upload", (data) => {
        console.log(data);
      });
  }, []);

  React.useEffect(() => {
    return () => uppy.close({ reason: "unmount" });
  }, [uppy]);

  const getLevels = (
    studentLevelId: string,
    teacherLevelId: string
  ): TOption[] => {
    const wheelAreaLevels = wheelData.find(
      ({ _id }) => _id === selectedAreaId
    ).levels;
    const levels = wheelAreaLevels.sort((a, b) => a.level - b.level);
    const currentLevelStudent = levels.find(
      (level) => level._id === studentLevelId
    );
    const currentLevelTeacher = levels.find(
      (level) => level._id === teacherLevelId
    );
    let levelDisabled = !!studentLevelId && !!teacherLevelId;
    const levelOptionsArr: TOption[] = [];
    const studentTeacherLevelMatch =
      currentLevelStudent?.level === currentLevelTeacher?.level;
    const gradeComplete = currentLevelTeacher?.grade === 1;
    levels.forEach((level) => {
      levelDisabled =
        level.level < currentLevelStudent?.level ||
        (level.level > currentLevelStudent?.level &&
          !studentTeacherLevelMatch) ||
        (level.level > currentLevelStudent?.level && !gradeComplete);
      levelOptionsArr.push({
        label: getLabel(level),
        value: level._id,
        disabled: levelDisabled && mirrorLevelType === "sequential",
      });
    });

    const enabledOptions = levelOptionsArr.filter(
      (option) => option.disabled === false
    );
    if (
      enabledOptions.length >= 1 &&
      (selectedLevelId === "" ||
        !enabledOptions.find((option) => option.value === selectedLevelId))
    ) {
      handleSelectLevel(enabledOptions[0].value as string);
    }
    return levelOptionsArr;
  };

  useEffect(() => {
    if (selectedAreaId) {
      (async () => {
        setLevelsLoading(true);
        const { data } = await getStudentCurrentGradeByArea(
          selectedAreaId,
          user?.roles?.student?.id._id
        );
        const wheelAreaLevels = wheelData.find(
          ({ _id }) => _id === selectedAreaId
        ).levels;
        let teacherLevelId = -1;
        let studentLevelId = 0;
        if (data.data.teacherLevelId) {
          teacherLevelId = data.data.teacherLevelId;
        }
        if (data.data.studentLevelId) {
          studentLevelId = data.data.studentLevelId;
        }
        handleSelectLevel(studentLevelId);
        setLevelOptions(getLevels(studentLevelId, teacherLevelId));
        setLevelsLoading(false);
      })();
    }
  }, [selectedAreaId]);

  return (
    <StyledContainer>
      <Typography variant="body1">
        Choose a wheel area,{" "}
        {mirrorLevelType === "non-sequential" ? "part" : "level"}, and a link.
        Then press submit to add your evidence.
      </Typography>
      <StyledSelectContainer>
        <Select
          options={wheelAreas.map((area) => ({
            label: area.name,
            value: area._id,
            disabled: area.disabled,
          }))}
          onChange={(e) => handleSelectArea(e.target.value as string)}
          className="text-left"
          disableMargin
          fullWidth
          value={selectedAreaId}
          label="Area"
          disabled={requesting}
        />
        <Select
          style={{ minWidth: "200px" }}
          options={levelOptions}
          value={selectedLevelId}
          onChange={(e) => handleSelectLevel(e.target.value as string)}
          className="text-left"
          fullWidth
          label={mirrorLevelType === "non-sequential" ? "Part" : "Level"}
          disabled={requesting || levelsLoading || !selectedLevelId}
        />
      </StyledSelectContainer>

      {filePickerOpen && (
        <FileUploadWrapper>
          <Dashboard
            uppy={uppy}
            plugins={["Url", "GoogleDrive", "OneDrive"]}
            proudlyDisplayPoweredByUppy={false}
            disableLocalFiles={true}
            height={300}
            locale={{
              strings: {
                importFiles: "Manually add your link or choose a source below:",
                importFrom: "Add from %{name}",
              },
            }}
          />
        </FileUploadWrapper>
      )}

      {!filePickerOpen && (
        <RetryButtonWrapper>
          <Button
            variant="outlined"
            color="secondary"
            fullWidth={false}
            onClick={() => {
              handleSetEvidenceData("", "");
              uppy.cancelAll();
              setFilePickerOpen(true);
            }}
          >
            <StyledIcon className="uil uil-link-add" />
            Retry Import?
          </Button>
        </RetryButtonWrapper>
      )}

      {link && !filePickerOpen && (
        <EvidenceLinkWrapper>
          <Typography variant="body1">
            <p>Your evidence link:</p>
            <LinkContainer>
              <a href={link} target="_blank" rel="noreferrer">
                {evidenceName}
              </a>
            </LinkContainer>
          </Typography>
        </EvidenceLinkWrapper>
      )}

      <StyledErrorMessage>{error}</StyledErrorMessage>
    </StyledContainer>
  );
};

export default withAuth(SubmitLink, []);
