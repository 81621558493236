import { axiosInstance } from "helpers/axios-instance";

export const giveUserAccess = async (userId: string) => {
  const { data: user } = await axiosInstance.get(`/users?id=${userId}`);
  const applicationsAccess = [...user.applicationsAccess, "meta-mirror"];

  return axiosInstance.put(`/users`, {
    id: userId,
    applicationsAccess,
  });
};
