import { Button, Snackbar, SnackbarContent } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import React from "react";

import { getReleases, putRelease } from "lib/release";

import { withAuth } from "../../hoc/withAuth";
import { FeatureBannerProps, Release } from "./types";

export const FeatureBanner = withAuth(({ user }: FeatureBannerProps) => {
  const [releases, setReleases] = React.useState<Release[]>([]);
  const userType = React.useMemo(
    () =>
      user?.roles?.teacher
        ? "teacher"
        : user?.roles?.admin
        ? "admin"
        : "student",
    [user]
  );

  const handleClick = (id: string) =>
    putRelease(user._id as any, id).then(
      ({ status }) =>
        status === 200 && setReleases(releases.filter((r) => r._id !== id))
    );

  React.useEffect(() => {
    if (user._id) {
      getReleases(user._id as any).then(({ data }) => setReleases(data));
    }
  }, [user._id]);

  if (!releases.length || userType === "admin") return <></>;

  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        top: 10,
        left: 0,
        width: "100%",
        height: "auto",
        zIndex: 999,
        gap: ".5rem",
      }}
    >
      {releases.map((release) => (
        <div key={release._id} style={{ maxWidth: 1000 }}>
          <SnackbarContent
            message={`New Feature: ${(release as any)[userType] ?? ""}`}
            action={
              <Button
                color="secondary"
                onClick={() => handleClick(release._id)}
                startIcon={<CloseRounded />}
                variant="contained"
              >
                Close
              </Button>
            }
          />
        </div>
      ))}
    </div>
  );
}, []);
