import { CircularProgress, Grid } from "@material-ui/core";
import React from "react";

import { getEvidence } from "lib/evidence";
import { IEvidenceForReview } from "typings/evidence";

import { Feedback } from "./feedback";
import { Preview } from "./preview";
import { RE } from "./styles";
import { ContentProps } from "./types";

export const Content = ({
  evidenceIds,
  handleCloseModal,
  open,
  ...rest
}: ContentProps) => {
  const [error, setError] = React.useState<string>("");
  const [evidence, setEvidence] = React.useState<IEvidenceForReview>();
  const [evidenceIdArray, setEvidenceIdArray] = React.useState(evidenceIds);
  const [isOpen, setIsOpen] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [viewingEvidence, setViewingEvidence] = React.useState(0);

  const getData = async () => {
    setError("");
    setLoading(true);
    getEvidence(evidenceIdArray[viewingEvidence])
      .then(({ data }) => setEvidence(data))
      .catch(({ message }) => setError(message))
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    if (open !== isOpen) {
      setIsOpen(open);
      open && setEvidenceIdArray(evidenceIds);
      setError("");
    }
  }, [evidenceIds, open, isOpen]);

  React.useEffect(() => {
    if (viewingEvidence > evidenceIdArray.length - 1 && viewingEvidence !== 0) {
      setViewingEvidence(evidenceIdArray.length - 1);
      return;
    }
    evidenceIdArray.length && open && getData();
  }, [evidenceIdArray, evidence?.evidence?._id, open, viewingEvidence]);

  return (
    <>
      {loading || !evidence ? (
        <RE.Loader>
          <CircularProgress />
        </RE.Loader>
      ) : error.length > 0 ? (
        <RE.Info>
          <RE.Info.Message isError>{error}</RE.Info.Message>
        </RE.Info>
      ) : !!evidenceIdArray?.length ? (
        <RE.Scroller>
          <Grid
            container
            direction="row"
            alignItems="stretch"
            spacing={1}
            className="h-100"
          >
            <Grid item xs={12} lg={4} className="h-100">
              <Feedback
                evidence={evidence}
                evidenceIds={evidenceIds}
                viewingEvidence={viewingEvidence}
                {...rest}
              />
            </Grid>
            <RE.Preview item xs={12} lg={8}>
              <Preview evidence={evidence} />
            </RE.Preview>
          </Grid>
        </RE.Scroller>
      ) : (
        <RE.Info>
          <RE.CompleteIcon className="uil uil-file-check-alt" />
          <RE.Info.Message isError={false}>Evidence Reviewed</RE.Info.Message>
          <RE.CompleteButton
            variant="contained"
            color="primary"
            onClick={handleCloseModal}
          >
            Done
          </RE.CompleteButton>
        </RE.Info>
      )}
      {evidenceIds.length > 1 && !error?.length && !!evidenceIdArray.length && (
        <RE.CycleButtonGrid item style={{ flexBasis: "unset" }} xs={12}>
          {[
            { disabled: viewingEvidence === 0, value: -1, text: "Previous" },
            {
              disabled: viewingEvidence === evidenceIdArray.length - 1,
              value: 1,
              text: "Next",
            },
          ].map(({ disabled, text, value }) => (
            <RE.EvidenceButton
              key={text}
              variant="outlined"
              color="primary"
              disabled={disabled || loading}
              onClick={() => setViewingEvidence(viewingEvidence + value)}
            >
              {text}
            </RE.EvidenceButton>
          ))}
        </RE.CycleButtonGrid>
      )}
    </>
  );
};
