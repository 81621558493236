import styled from "styled-components";
import palette from "palette";

export const S = {
  Container: styled.div`
    border-radius: 10px !important;
    border: 3px solid ${palette.font.caption};
    overflow: auto;
  `,

  InfoContainer: styled.div`
    width: 100%;
    padding: 100px 0;
    text-align: center;
  `,

  NoDataContainer: styled.div`
    width: 100%;
    padding: 50px 0;
    text-align: center;
  `,

  Icon: styled.i`
    font-size: 30px;
  `,
};
