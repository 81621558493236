import React from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";
import Navbar from "components/common/Navbar/Navbar";
import DetailedWheelPage from "./sections/detailedWheelPage";
import { WheelsPage } from "./sections/wheelsPage";

const routes = [
  { path: "/student", Component: WheelsPage },
  { path: "/student/:wheelId", Component: DetailedWheelPage },
];

const StudentSwitch = withRouter(({ location }) => (
  <Switch location={location}>
    {routes.map(({ path, Component }) => (
      <Route exact path={path} key={`student-route-${path}`}>
        {/* @ts-ignore */}
        {(routeProps: RouteComponentProps<{ wheelId: string }>) => (
          <Component {...routeProps} />
        )}
      </Route>
    ))}
  </Switch>
));

const StudentPage: React.FC = () => {
  return (
    <>
      <BrowserRouter>
        <Navbar type="student" />
        <StudentSwitch />
      </BrowserRouter>
    </>
  );
};

export default StudentPage;
