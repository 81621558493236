/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";

import { useWheelsPage } from "../../context";
import { useProfile } from "../context";
import { OtherContext, OtherProviderProps } from "./types";

const Context = React.createContext<OtherContext>({
  editing: false,
  error: "",
  handleEditClick: () => {},
  handleFileUpload: () => {},
  items: [],
  loading: false,
  setError: () => {},
  setItem: () => {},
});

export const OtherProvider = ({ children }: OtherProviderProps) => {
  const { addOther } = useWheelsPage();
  const { other } = useProfile();
  const [editing, setEditing] = React.useState(false);
  const [error, setError] = React.useState("");
  const [item, setItem] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);

  const handleEditClick = () => setEditing((prev) => !prev);

  const handleFileUpload = async (
    achievement: string,
    image: string,
    name: string
  ) => {
    setLoading(true);
    return addOther(achievement, image, name)
      .catch((error) => setError(error.message))
      .finally(() => setLoading(false));
  };

  return (
    <Context.Provider
      value={{
        editing,
        error,
        handleEditClick,
        handleFileUpload,
        item,
        items: other,
        loading,
        setError,
        setItem,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useOther = () => React.useContext(Context);
