import { IconButton, Menu, MenuProps, Tooltip } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { ILevel } from "typings/meta-mirror";
import palette from "palette";

const StyledQuickEditIconButton = styled(IconButton)<{ disabled?: boolean }>`
  height: 35px !important;
  width: 35px !important;
  min-width: 35px !important;
  min-height: 35px !important;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)} !important;
`;

const StyledGradeIcon = styled.i<{
  backgroundColor?: string;
  padding?: string;
  fontSize?: number;
  border?: boolean;
}>`
  width: 35px;
  height: 35px;
  border-radius: 100%;
  border: ${(props) =>
    props.border ? `dashed 2px ${palette.font.caption}` : "none"};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => props.fontSize || 32}px;
  line-height: 32px;
  background-color: ${(props) => props.backgroundColor || "inherit"};
  margin: 0 auto;
  padding: ${(props) => props.padding || 0};
`;

interface IProps extends MenuProps {
  handleQuickEditSelect(grade: ILevel["grade"]): void;
  isMenuButtonDisabled(grade: ILevel["grade"]): boolean;
}

const QuickEditMenu: React.FC<IProps> = ({
  handleQuickEditSelect,
  isMenuButtonDisabled,
  ...menuProps
}: IProps) => {
  const getMarkIcon = (value: ILevel["grade"]): JSX.Element => {
    if (value === 1) {
      return (
        <StyledGradeIcon
          className="uil uil-check"
          backgroundColor={palette.complete}
          padding="2px 0 0"
        />
      );
    }

    if (value === 0.5) {
      return (
        <StyledGradeIcon
          className="uil uil-percentage"
          backgroundColor={palette.partial}
          padding="2px 0 0"
        />
      );
    }

    if (value === 0) {
      return (
        <StyledGradeIcon
          className="uil uil-flask-potion"
          backgroundColor={palette.attempting}
          fontSize={30}
          padding="2px 0 0"
        />
      );
    }
    return <StyledGradeIcon border />;
  };

  return (
    <Menu
      {...menuProps}
      keepMounted
      MenuListProps={{
        style: {
          border: "none",
          display: "flex",
          flexDirection: "column",
          padding: 10,
          height: 175,
          justifyContent: "space-between",
        },
      }}
      PaperProps={{
        style: {
          borderRadius: 45,
        },
      }}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Tooltip title="Complete" placement="right">
        <StyledQuickEditIconButton onClick={() => handleQuickEditSelect(1)}>
          {getMarkIcon(1)}
        </StyledQuickEditIconButton>
      </Tooltip>
      <Tooltip title="Partial" placement="right">
        <StyledQuickEditIconButton
          onClick={() => handleQuickEditSelect(0.5)}
          disabled={isMenuButtonDisabled(0.5)}
        >
          {getMarkIcon(0.5)}
        </StyledQuickEditIconButton>
      </Tooltip>
      <Tooltip title="Attempting" placement="right">
        <StyledQuickEditIconButton
          onClick={() => handleQuickEditSelect(0)}
          disabled={isMenuButtonDisabled(0)}
        >
          {getMarkIcon(0)}
        </StyledQuickEditIconButton>
      </Tooltip>
      <Tooltip title="None" placement="right">
        <StyledQuickEditIconButton
          onClick={() => handleQuickEditSelect(null)}
          disabled={isMenuButtonDisabled(null)}
        >
          {getMarkIcon(null)}
        </StyledQuickEditIconButton>
      </Tooltip>
    </Menu>
  );
};

export default QuickEditMenu;
