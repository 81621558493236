import { Typography } from "@material-ui/core";
import React from "react";

import { StudentList } from "components/admin/studentList";
import TableSearch from "components/admin/TableSearch";
import PageContainer from "components/common/PageContainer/PageContainer";
import { getTeachersPage } from "lib/teacher";

import { withAuth } from "../../../../hoc/withAuth";
import { TP } from "./styles";

const TeachersPage = () => {
  const [data, setData] = React.useState([]);
  const [limit, setLimit] = React.useState(10);
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [name, setName] = React.useState("");
  const [total, setTotal] = React.useState(0);

  const handleSearch = (value: string) => {
    setName(value);
    setPage(0);
  };

  React.useEffect(() => {
    const query = new URLSearchParams({
      limit: limit.toString(),
      page: (page + 1).toString(),
    });
    name.trim().length > 0 && query.append("name", name);
    setLoading(true);
    getTeachersPage(`?${query.toString()}`)
      .then(({ data }) => {
        setData(data.teachers);
        setTotal(data.totalTeachers);
      })
      .finally(() => setLoading(false));
  }, [limit, name, page]);

  return (
    <PageContainer>
      <TP>
        <Typography variant="h6">Teachers</Typography>
        <TP.Divider />
        <TableSearch
          label="Teacher Name"
          onSearch={handleSearch}
          onClear={() => handleSearch("")}
        />
        <StudentList
          data={data}
          limit={limit}
          loading={loading}
          page={page}
          setLimit={setLimit}
          setPage={setPage}
          total={total}
          type="teachers"
        />
      </TP>
    </PageContainer>
  );
};

export default withAuth(TeachersPage, ["admin"]);
