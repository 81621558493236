import React from "react";
import Wheel from "components/common/Wheel/Wheel";
import palette from "palette";
import { TWheelData } from "typings/meta-mirror";
import styled from "styled-components";
import { Typography } from "@material-ui/core";

const StyledContainer = styled.div`
  width: 50%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 0;
`;

const StyledGroupText = styled(Typography)`
  font-weight: 700 !important;
`;

interface IProps {
  wheelData: TWheelData;
  studentGroup: string;
  date: string;
  active: boolean;
}

const PrintComparisonWheel: React.FC<IProps> = ({
  wheelData,
  studentGroup,
  date,
  active,
}: IProps) => {
  return (
    <StyledContainer>
      {active && (
        <>
          <StyledGroupText variant="body1">{studentGroup}</StyledGroupText>
          <Typography variant="body2">{date}</Typography>
          <Wheel
            data={wheelData}
            backgroundColor={palette.surface}
            width={550}
            height={350}
            verticalPadding={15}
            labels
            interactive={false}
          />
        </>
      )}
    </StyledContainer>
  );
};

export default PrintComparisonWheel;
