import React, { useState } from "react";
import styled from "styled-components";
import palette from "palette";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { TWheelValidEvidenceFileType } from "typings/meta-mirror";
import { CircularProgress } from "@material-ui/core";
import { getEvidence } from "lib/evidence";

const StyledContainer = styled.div<{ color: string }>`
  width: 100%;
  border: 2px solid ${(props) => props.color};
  border-radius: 10px;
  color: ${(props) => props.color};
  padding: 10px 12px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  &:not(:first-child) {
    margin: 10px 0 0;
  }
`;

const StyledFileBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 15px 0 0;
`;

const StyledFileName = styled.p`
  font-size: 16px !important;
  font-weight: 700;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StyledLinkIcon = styled.i`
  font-size: 18px;
  margin-left: 4px;
`;

const StyledDateText = styled.p`
  font-size: 12px !important;
  margin: 0;
`;

const StyledEvidenceIcon = styled.p`
  font-size: 35px;
  margin: 0;
  line-height: 35px;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledFullWidthDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

const StyledDownloadLink = styled.a<{ color: string; hoverColor: string }>`
  transition: 0.5s;
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;
  color: ${(props) => props.color} !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-height: 30px !important;
  max-height: 30px !important;
  padding: 2px 7px;
  border-radius: 4px;
  &:hover {
    color: ${(props) => props.hoverColor} !important;
    background-color: ${palette.disabled}aa;
    text-decoration: none;
    cursor: pointer;
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: ${palette.font.body} !important;
  margin: 0 0 0 8px !important;
`;

enum EvidenceIcons {
  ".jpeg" = "uil uil-image-v",
  ".jpg" = "uil uil-image-v",
  ".png" = "uil uil-image-v",
  ".docx" = "uil uil-file-alt",
  ".doc" = "uil uil-file-alt",
  ".ppt" = "uil uil-presentation-play",
  ".pptx" = "uil uil-presentation-play",
  "link" = "uil uil-link",
}

interface IProps {
  evidenceName: string;
  date: string;
  evidenceId: string;
  evidenceType: TWheelValidEvidenceFileType | "link";
  unseen: boolean;
  variant: "admin" | "student" | "teacher";
  handleReviewEvidence(): void;
}

const EvidenceFile: React.FC<IProps> = ({
  evidenceId,
  date,
  evidenceName,
  evidenceType,
  unseen,
  variant,
  handleReviewEvidence,
}: IProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadEvidenceFile = async (): Promise<void> => {
    setIsLoading(true);

    const result = await getEvidence(evidenceId);

    const link = document.createElement("a");

    let evidenceString = result.data.evidence;

    if (evidenceType !== "link") {
      Object.assign(link, {
        download: evidenceName,
        target: "_blank",
        rel: "noopener noreferrer",
      });
    } else {
      if (
        !(
          evidenceString.includes("http://") ||
          evidenceString.includes("https://")
        )
      ) {
        evidenceString = `https://${evidenceString}`;
      }

      link.href = result.data.evidence;

      Object.assign(link, {
        target: "_blank",
        rel: "noopener noreferrer",
      });
    }

    link.href = evidenceString;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setIsLoading(false);
  };

  return (
    <StyledContainer
      color={
        !!unseen && variant === "teacher"
          ? palette.secondary
          : palette.font.body
      }
    >
      <StyledFileBox>
        <StyledIconContainer>
          {evidenceType === ".pdf" ? (
            <PictureAsPdfIcon style={{ fontSize: 35 }} />
          ) : (
            <StyledEvidenceIcon
              className={(EvidenceIcons as any)[evidenceType]}
            />
          )}
        </StyledIconContainer>
        <StyledFullWidthDiv>
          <StyledFileName>{evidenceName}</StyledFileName>
          <StyledRow>
            <StyledDateText>{date}</StyledDateText>
            {unseen && variant === "teacher" ? (
              <StyledDownloadLink
                color={palette.secondary}
                hoverColor={palette.secondaryDark}
                onClick={handleReviewEvidence}
              >
                review <StyledLinkIcon className="uil uil-comment-alt-check" />
              </StyledDownloadLink>
            ) : (
              <StyledDownloadLink
                color={palette.font.body}
                hoverColor={palette.font.heading}
                onClick={isLoading ? undefined : handleDownloadEvidenceFile}
              >
                {evidenceType !== "link" ? (
                  <>
                    download
                    {isLoading ? (
                      <StyledCircularProgress size={14} />
                    ) : (
                      <StyledLinkIcon className="uil uil-import" />
                    )}
                  </>
                ) : (
                  <>
                    visit
                    <StyledLinkIcon className="uil uil-external-link-alt" />
                  </>
                )}
              </StyledDownloadLink>
            )}
          </StyledRow>
        </StyledFullWidthDiv>
      </StyledFileBox>
    </StyledContainer>
  );
};

export default EvidenceFile;
