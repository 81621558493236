import React, { useState } from "react";
import TextInput from "components/common/TextInput/TextInput";
import AnimatedModal from "components/common/AnimatedModal";
import {
  Button,
  ButtonProps,
  Typography,
  TypographyProps,
} from "@material-ui/core";
import styled from "styled-components";
import palette from "palette";
import { IResponseBody } from "typings/global";

interface IStyledInfoMessageProps extends TypographyProps {
  textColor?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledInfoMessage = styled(
  ({ textColor, ...props }: IStyledInfoMessageProps) => (
    <Typography {...props} />
  )
)<{ textColor: string }>`
  color: ${(props) => props.textColor || palette.font.caption} !important;
  height: 30px;
  margin: 0 0 20px !important;
`;

const StyledModalContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

const StyledTypography = styled(Typography)`
  text-align: center !important;
`;

const StyledConfirmationTypography = styled(StyledTypography)`
  color: ${palette.error} !important;
  font-weight: 700 !important;
  font-size: 22px !important;
  margin: 15px 0 25px 0 !important;
`;

const StyledDeletedTypography = styled(StyledTypography)`
  color: ${palette.complete} !important;
  font-weight: 700 !important;
  margin: 100px 0 !important;
`;

const StyledTextInput = styled(TextInput)`
  margin-top: 10px !important;
  text-align: center !important;
`;

interface IStyledDeleteButtonProps extends ButtonProps {
  disabled: boolean;
}

const StyledDeleteButton = styled(
  ({ disabled, ...props }: IStyledDeleteButtonProps) => (
    <Button disabled={disabled} {...props} />
  )
)<{ disabled: boolean }>`
  background-color: ${(props) =>
    props.disabled ? palette.disabled : palette.error} !important;
  color: ${palette.font.contrast} !important;
  text-transform: uppercase !important;
`;

interface IProps {
  handleClose(): void;
  handleDeleteWheel: (wheelId: string) => Promise<IResponseBody>;
  open: boolean;
  wheelId: string;
  wheelName: string;
  schoolName: string;
}

const DeleteWheelModal: React.FC<IProps> = ({
  handleDeleteWheel,
  handleClose,
  open,
  wheelId,
  wheelName,
  schoolName,
}: IProps) => {
  const [confirmWheelName, setConfirmWheelname] = useState("");
  const [requestPending, setRequestPending] = useState(false);
  const [wheelDeleted, setWheelDeleted] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");

  const handleCloseModal = (): void => {
    handleClose();
    setTimeout(() => {
      setConfirmWheelname("");
      setRequestPending(false);
      setInfoMessage("");
      setWheelDeleted(false);
    }, 1000);
  };

  const deleteWheel = async (): Promise<void> => {
    setRequestPending(true);
    const result = await handleDeleteWheel(wheelId);
    setRequestPending(false);
    if (result.error) {
      setInfoMessage(result.message);
      return;
    }
    setInfoMessage("");
    setWheelDeleted(true);
  };

  return (
    <AnimatedModal
      open={open}
      handleClose={handleCloseModal}
      onClose={handleCloseModal}
      disableClose={requestPending}
      disableBackdropClick={requestPending}
      title="Delete Wheel"
      width="100%"
      maxWidth="625px"
      height="auto"
    >
      <StyledModalContainer>
        {wheelDeleted ? (
          <>
            <StyledDeletedTypography variant="body1">
              {wheelName} has been successfully disabled.
            </StyledDeletedTypography>
          </>
        ) : (
          <>
            <StyledConfirmationTypography variant="body1">
              Are you sure?
            </StyledConfirmationTypography>
            <StyledTypography variant="body2">
              You are about to disable
              <br />
              <b>{wheelName}</b> belonging to <b>{schoolName}</b>.
              <br />
              <br />
              This will prevent the wheel appearing for any teachers and
              students that could previously view these wheels.
              <br />
              A developer will be required to undo this action.
              <br />
              <br />
              Type out the full wheel name to confirm deletion.
            </StyledTypography>
            <StyledTextInput
              value={confirmWheelName}
              fullWidth
              onChange={(e) => setConfirmWheelname(e.target.value)}
              disabled={requestPending}
              inputProps={{
                style: {
                  textAlign: "center",
                },
              }}
            />
            <StyledTypography variant="caption">
              (case sensitive)
            </StyledTypography>
            <StyledInfoMessage
              textColor={wheelDeleted ? palette.complete : palette.error}
              variant="body2"
            >
              {infoMessage}
            </StyledInfoMessage>
          </>
        )}
        <div className="row w-100 pl-5 pr-5">
          {!wheelDeleted && (
            <div className="col">
              <StyledDeleteButton
                variant="contained"
                color="primary"
                fullWidth
                onClick={wheelDeleted ? handleCloseModal : deleteWheel}
                disabled={requestPending || !(confirmWheelName === wheelName)}
              >
                D E L E T E
              </StyledDeleteButton>
            </div>
          )}
          <div className="col">
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={handleCloseModal}
              disabled={requestPending}
            >
              go back
            </Button>
          </div>
        </div>
      </StyledModalContainer>
    </AnimatedModal>
  );
};

export default DeleteWheelModal;
