import { Button, Divider, IconButton, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import { mediaRules } from "MuiTheme/Breakpoints";
import palette from "MuiTheme/palette";
import theme from "MuiTheme/index";

export const Actions = Object.assign(
  styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    max-height: 100%;
    height: 100%;
    overflow-y: auto;
    padding-right: 5px;
    margin-right: 5px;
    ::-webkit-scrollbar-track {
      background-color: transparent;
    }
    ::-webkit-scrollbar {
      width: 7px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 7px;
      background-color: ${palette.font.caption};
    }
    @media ${mediaRules.lg} {
      max-height: unset;
    }
  `,
  {
    Divider: styled(Divider)({
      borderRadius: "3px !important",
      height: "3px !important",
      marginBottom: "15px !important",
    }),

    ErrorMessage: styled(Typography)({
      minHeight: "40px !important",
      color: `${palette.error} !important`,
    }),

    GradeButtonContainer: styled.div({
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      marginBotom: "10px",
      gap: ".5rem",
    }),

    Header: styled.div({
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
    }),

    InfoText: styled(Typography)({
      display: "flex",
      fontSize: "14px",
      fontStyle: "italic",
      fontWeight: 700,
      marginRight: "10px",
      width: "100%",
      maxWidth: "50%",
    }),

    Row: styled.div({
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      // justifyContent: 'flex-start',
      width: "100%",
    }),

    StatusLabel: styled(Typography)({ marginBottom: "5px important" }),

    SubmitButton: styled(Button)`
      margin-top: 10px !important;
      @media ${mediaRules.lg} {
        margin-bottom: 10px !important;
      }
    `,
  }
);

export const Msg = Object.assign(
  styled(({ isSender, ...rest }: any) => <div {...rest} />)(({ isSender }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: isSender ? "flex-end" : "flex-start",
    margin: ".5rem",
  })),
  {
    Container: styled(({ isSender, ...rest }: any) => <div {...rest} />)(
      ({ isSender }) => ({
        width: "auto",
        maxWidth: "75%",
        borderRadius: "10px",
        color: "white",
        background: isSender
          ? theme.palette.primary.main
          : theme.palette.grey[500],
        padding: ".5rem 1rem",
      })
    ),

    Content: styled(Typography)({
      lineHeight: "normal !important",
      letterSpacing: "-.05rem !important",
      fontSize: "1.25rem !important",
      fontWeight: "500 !important",
      color: "white !important",
    }),

    Edit: styled(IconButton)({
      top: 0,
      left: 0,
      color: "white !important",
      minHeight: "unset !important",
      minWidth: "unset !important",
      width: 30,
      height: 30,
    }),

    Name: styled(({ isSender, ...rest }: any) => <Typography {...rest} />)(
      ({ isSender }) => ({
        color: "white !important",
        fontSize: "12px !important",
        textAlign: isSender ? "right" : "left",
      })
    ),

    //  @ts-ignore
    Timestamp: styled(Typography)({
      color: "white !important",
      textAlign: "right !important",
      fontSize: "12px !important",
      marginTop: ".5rem !important",
    }),
  }
);
