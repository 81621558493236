/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from "lodash";
import React from "react";

import { TOption } from "components/common/Select/Select";
import { editMessage, getConversation, sendMessage } from "lib/conversation";
import { ILevel } from "typings/meta-mirror";

import { getLevels } from "./helper";
import { FeedbackContext, FeedbackProviderProps, Message } from "./types";
import { useDetailedWheel } from "pages/student/sections/detailedWheelPage/context";

const empty = (...props: any) => {};

const Context = React.createContext<FeedbackContext>({
  areaLevels: [],
  error: "",
  feedback: "",
  evidence: {} as any,
  evidenceIdArray: [],
  evidenceObject: {} as any,
  gradeError: "",
  handleEdit: empty,
  levels: [],
  loading: false,
  messages: [],
  selectedLevel: "",
  selectedGrade: undefined,
  setError: empty,
  setEvidenceIdArray: empty,
  setFeedback: empty,
  setLoading: empty,
  setSelectedGrade: empty,
  setSelectedLevel: empty,
  submitDisabled: true,
  submitMessage: () => Promise.resolve(),
  user: {} as any,
  viewingEvidence: 0,
  wheelData: {} as any,
  wheelLevelType: "",
  updateMessage: () => Promise.resolve(),
});

export const FeedbackProvider = ({
  areaLevels,
  children,
  evidence,
  evidenceIds,
  evidenceObject: eo,
  user: propsUser,
  viewingEvidence,
  wheelData,
  wheelLevelType,
}: FeedbackProviderProps) => {
  const { user: contextUser } = useDetailedWheel();
  const [editing, setEditing] = React.useState<Message>();
  const [error, setError] = React.useState<string>("");
  const [evidenceIdArray, setEvidenceIdArray] = React.useState(evidenceIds);
  const [feedback, setFeedback] = React.useState<string>("");
  const [levels, setLevels] = React.useState<TOption[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [messages, setMessages] = React.useState<Message[]>([]);

  const evidenceObject = React.useMemo(
    //  @ts-ignore
    () => eo ?? evidence?.[viewingEvidence] ?? evidence,
    [eo, evidence, viewingEvidence]
  );

  const [selectedGrade, setSelectedGrade] = React.useState<
    ILevel["grade"] | undefined
  >(evidenceObject?.teacherGrade?.result);
  const [selectedLevel, setSelectedLevel] = React.useState<string>("");

  const user = propsUser ?? contextUser;

  const isTeacher = React.useMemo(
    () => !!user?.roles?.teacher?.id?._id,
    [user?.roles]
  );

  const submitDisabled = React.useMemo(() => {
    const includes = ![null, 0, 0.5, 1].includes(selectedGrade!);
    const teacher = isTeacher && (includes || !selectedLevel);
    return (
      !feedback.length || loading || feedback === editing?.message || teacher
    );
  }, [feedback, loading, selectedGrade, selectedLevel]);

  const gradeError = React.useMemo(
    () =>
      isTeacher && feedback.length && selectedGrade === undefined
        ? "You must select a grade to submit feedback."
        : "",
    [feedback, isTeacher, selectedGrade]
  );

  const handleEdit = (id?: string) =>
    setEditing(
      editing?._id === id ? undefined : messages.find(({ _id }) => _id === id)
    );

  const markAsRead = async (msgs: Message[]) => {
    await Promise.all(
      msgs
        .filter(({ viewed, teacher }) => !viewed && isTeacher === !teacher)
        .map(
          async ({ _id, isLegacyMessage }) =>
            await editMessage({ _id, isLegacyMessage, viewed: true })
        )
    ).then(() =>
      setMessages(
        msgs.map(({ _id, viewed, ...message }) => ({
          _id,
          ...message,
          viewed: true,
        }))
      )
    );
  };

  const reset = ({ data }: any) => {
    const newEvidenceIdArray = _.cloneDeep(evidenceIdArray);
    newEvidenceIdArray.splice(
      evidenceIdArray.indexOf(evidenceIdArray[viewingEvidence]),
      1
    );
    setEditing(undefined);
    setEvidenceIdArray(newEvidenceIdArray);
    setFeedback("");
    setMessages(data);
    // setSelectedLevel("");
    // setSelectedGrade(undefined);
  };

  const submitMessage = async (): Promise<void> => {
    setError("");
    setLoading(true);
    const { wheelId, areaId, _id, studentId } = evidence!.evidence;
    const params: any = {
      evidenceId: _id,
      message: feedback,
      studentId,
      wheelId,
    };
    if (user.roles?.teacher?.id._id) {
      params.teacherId = user.roles?.teacher.id._id;
      params.grade = selectedGrade;
    }
    sendMessage(params)
      .then(reset)
      .then(() =>
        getConversation({ evidenceId: evidence.evidence._id }).then(
          ({ data }) => setMessages(data)
        )
      )
      .catch(({ message }) => setError(message))
      .finally(() => setLoading(false));
  };

  const updateMessage = async (): Promise<void> => {
    setError("");
    setLoading(true);
    editMessage({
      _id: editing!._id,
      message: feedback,
      isLegacyMessage: editing!.isLegacyMessage,
      viewed: false,
    })
      .then(reset)
      .catch(({ message }) => setError(message))
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    areaLevels &&
      setLevels(
        getLevels({
          evidence,
          levels: areaLevels,
          type: wheelLevelType,
        })
      );
  }, [areaLevels, evidence, wheelLevelType]);

  React.useEffect(() => {
    setSelectedLevel(
      (levels?.find(
        ({ level }: any) =>
          level === (evidenceObject as any)?.studentGrade?.level[0]
      )?.value as string) ?? ""
    );
  }, [evidenceObject, levels]);

  React.useEffect(() => {
    getConversation({ evidenceId: evidence.evidence._id }).then(({ data }) =>
      markAsRead(data)
    );
  }, [evidence]);

  React.useEffect(() => {
    setFeedback(editing?.message ?? "");
  }, [editing]);

  return (
    <Context.Provider
      value={{
        areaLevels,
        editing,
        error,
        evidence,
        evidenceIdArray,
        evidenceObject,
        feedback,
        gradeError,
        handleEdit,
        isTeacher,
        levels,
        loading,
        messages,
        selectedGrade,
        selectedLevel,
        setError,
        setEvidenceIdArray,
        setFeedback,
        setLoading,
        setSelectedGrade,
        setSelectedLevel,
        submitDisabled,
        submitMessage,
        user,
        wheelData,
        wheelLevelType,
        viewingEvidence,
        updateMessage,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useFeedback = () => React.useContext(Context);
