import styled from "styled-components";
import { Paper } from "@material-ui/core";
import palette from "palette";

export const S = {
  Paper: styled(Paper)`
    width: 100%;
    min-height: 850px;
    margin: 0 0 30px;
    padding: 20px;
  `,

  Header: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `,

  Divider: styled.div`
    width: 100%;
    height: 3px;
    border-radius: 15px;
    background-color: ${palette.disabled};
    margin-top: 15px;
  `,

  SelectContainer: styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    gap: 10px;
  `,

  OptionIcon: styled.div<{ type: "primary" | "secondary" | "disabled" }>`
    border-radius: 50%;
    background-color: ${(p) =>
      p.type === "primary"
        ? palette.primary
        : p.type === "secondary"
        ? palette.secondary
        : palette.disabled};
    width: 50px;
    height: 50px;
    color: white;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    :hover {
      background-color: ${(p) =>
        p.type === "primary"
          ? palette.primaryDark
          : p.type === "secondary"
          ? palette.secondaryDark
          : palette.disabled};
    }
  `,

  OptionsContainer: styled.div`
    display: flex;
    gap: 5px;
  `,
};
