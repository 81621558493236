import { IWithAuth } from "hoc/withAuth";
import { ILevel } from "typings/meta-mirror";
import { TEvidencePartialData } from "typings/student";

export type ContentProps = Pick<
  FeedbackModalProps,
  | "areaLevels"
  | "evidenceIds"
  | "evidenceObject"
  | "handleCloseModal"
  | "open"
  | "user"
  | "wheelLevelType"
>;

export enum GradeColors {
  // @ts-ignore
  "disabled" = null,
  "attempting" = 0,
  "partial" = 0.5,
  "complete" = 1,
}

export enum GradeIcons {
  // @ts-ignore
  "" = null,
  "uil uil-flask-potion" = 0,
  "uil uil-percentage" = 0.5,
  "uil uil-check" = 1,
}

export type FeedbackModalProps = IWithAuth & {
  areaName: string;
  areaLevels: ILevel[];
  evidenceIds: string[];
  evidenceObject: TEvidencePartialData[number];
  handleCloseModal(): void;
  isFromProfile?: boolean;
  open: boolean;
  wheelLevelType: string;
};
