import React from "react";
import { ILevel } from "typings/meta-mirror";
import { GRADE_ICONS } from "./constants";
import {
  StyledCurrentGrade,
  StyledCurrentGradeIcon,
  StyledCurrentGradeText,
} from "./styles";

interface IProps {
  grade: ILevel["grade"];
  id?: string;
  small?: boolean;
  text: string;
}

const GradeIcon: React.FC<IProps> = ({ grade, id, small, text }: IProps) => {
  return (
    <StyledCurrentGrade grade={grade} small={small}>
      <StyledCurrentGradeIcon
        // @ts-ignore
        className={GRADE_ICONS[grade]}
        grade={grade}
        small={small}
      />
      <StyledCurrentGradeText
        grade={grade}
        id={id}
        small={small}
        variant="body1"
      >
        {text}
      </StyledCurrentGradeText>
    </StyledCurrentGrade>
  );
};

export default GradeIcon;
