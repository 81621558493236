import { Button, CircularProgress } from "@material-ui/core";
import { UpdateRounded } from "@material-ui/icons";
import React from "react";

import { useDetailedWheel } from "pages/student/sections/detailedWheelPage/context";
import { updateEvidence } from "lib/evidence";

import { useFeedback } from "../context";
import { useUpdateEvidence } from "./context";

export const UpdateButton = () => {
  const { handleRefreshData, user } = useDetailedWheel();
  const { evidence } = useFeedback();
  const {
    area,
    areas,
    currentArea,
    currentLevel,
    level,
    loading,
    setCurrentArea,
    setCurrentLevel,
    setLoading,
  } = useUpdateEvidence();

  const handleClick = () => {
    setLoading(true);
    updateEvidence({
      _id: evidence.evidence._id,
      isStudent: !!user.roles?.student?.id,
      areaId: area,
      levelId: level,
    })
      .then(() => {
        handleRefreshData();
        area && setCurrentArea(area);
        level && setCurrentLevel(level);
        const title = document.querySelector("#modal-title") as HTMLElement;
        title.innerText =
          areas.find(({ value }) => value === area)?.label || "";
      })

      .finally(() => setLoading(false));
  };

  return (
    <Button
      color="primary"
      disabled={
        loading ||
        (area === currentArea && level === currentLevel) ||
        level === ""
      }
      onClick={handleClick}
      startIcon={loading ? <CircularProgress size={20} /> : <UpdateRounded />}
      style={{ height: 80 }}
      variant="contained"
    >
      Update
    </Button>
  );
};
