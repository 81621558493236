import { Button, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { IGroupData } from "typings/group";
import GroupsList from "./GroupsList/GroupsList";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex: 0.8;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-content: flex-start;
  align-items: flex-start;
`;

const StyledEditContainer = styled.div`
  display: flex;
  flex: 0.2;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-content: flex-end;
  align-items: flex-end;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 55px;
  min-width: 400px;
  align-items: center;
  align-content: center;
  margin-bottom: 10px;
`;

const StyledButton = styled(Button)`
  width: 130px;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 0 0 20px !important;
`;

const StyledTypography = styled(Typography)`
  font-size: 18px !important;
  font-weight: 700 !important;
  min-width: 120px;
  margin-right: 30px !important;
`;

interface IProps {
  data: IGroupData[];
  isLoading: boolean;
  handleNewGroup(): void;
}

const SchoolGroupsList: React.FC<IProps> = ({
  handleNewGroup,
  data,
  isLoading,
}: IProps) => {
  return (
    <StyledContainer>
      <StyledRow>
        <StyledTitleContainer>
          <StyledTypography variant="h6">Groups</StyledTypography>
        </StyledTitleContainer>
        <StyledEditContainer>
          <StyledButton
            variant="contained"
            color="primary"
            onClick={handleNewGroup}
          >
            new group
          </StyledButton>
        </StyledEditContainer>
      </StyledRow>
      <StyledRow>
        <GroupsList data={data} isLoading={isLoading} />
      </StyledRow>
    </StyledContainer>
  );
};

export default SchoolGroupsList;
