import _ from "lodash";
import { IArea, ILevel, TWheelData } from "typings/meta-mirror";
import { ISeriesData } from "components/common/ProgressChart/ProgressChart";
import { IWheelGroupProgressData } from "../typings/wheel";
import MmWheelAverageService from "./wheelAverage";

const MmProgressChartService = {
  getProgressChartData(
    dataIn: IWheelGroupProgressData[],
    wheelLevelType: string
  ): ISeriesData[] {
    const progressChartData: ISeriesData[] = [];
    const wheelAverages: { date: string; wheelData: TWheelData }[] = [];
    dataIn.forEach(({ date, data }) => {
      wheelAverages.push({
        date,
        wheelData:
          MmWheelAverageService.getAverageWheelScoreBasedOnStudentsResults(
            data.studentsResults,
            wheelLevelType
          ),
      });
    });
    const areas = wheelAverages[0].wheelData;

    areas.forEach(({ _id, areaName }) => {
      const dataPoints: ISeriesData["data"] = [];
      _.reverse(wheelAverages);
      wheelAverages.forEach(({ date, wheelData }) => {
        const areaAvg = wheelData.find((area) => area._id === _id);
        const percentCompletion =
          MmProgressChartService.getProgressChartAverageCompletionFromArea(
            areaAvg,
            wheelLevelType
          );
        dataPoints.push({
          x: new Date(date),
          y: percentCompletion,
        });
      });
      progressChartData.push({
        areaId: _id,
        areaName,
        data: dataPoints,
      });
    });
    // console.log(progressChartData);
    return progressChartData;
  },

  getProgressChartAverageCompletionFromArea(
    area: IArea,
    wheelLevelType: string
  ): number {
    if (wheelLevelType !== "non-sequential") {
      let currentLevel = MmWheelAverageService.getStudentAreaCurrentLevel(area);
      if (currentLevel.grade === null && currentLevel.level !== 1) {
        currentLevel =
          area.levels[
            area.levels.findIndex((level) => level._id === currentLevel._id) - 1
          ];
      }
      const average = MmProgressChartService.calculatePercentageFromArea(
        currentLevel,
        area
      );
      return average;
    }

    // Non-Sequential
    const amountFilled = area.levels.reduce((p, c) => {

      if (c.grade === 1) {
        return p + 1;
      }
      if (c.grade === 0.5) {
        return p + 0.66;
      }
      if (c.grade === 0) {
        return p + 0.33;
      }
      return p;
    }, 0);


    const fraction = (amountFilled) / area.levels.length;
    const perc = fraction * 100;

    return +perc.toFixed(2);
    
  },

  calculatePercentageFromArea({ grade, level }: ILevel, area: IArea): number {
    const gradeToPercent = {
      null: 0,
      "0": 0.33333,
      "0.5": 0.66666,
      "1": 1,
    };
    const currentGrade = String(grade);
    const currentScore = level + gradeToPercent[currentGrade];
    return ((currentScore - 1) / area.levels.length) * 100;
  },
};

export default MmProgressChartService;
