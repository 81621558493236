/* eslint-disable @typescript-eslint/indent */
import { TEvidencePartialData } from "typings/student";
import { IFeedbackWithDetails } from "typings/feedback";
import { axiosInstance } from "helpers/axios-instance";
import { ILevel, TWheelValidEvidenceFileType } from "../typings/meta-mirror";
import { IEvidence } from "typings/evidence";

type GetAreasParams = {
  mirrorId: string;
};

export const getAreas = ({ mirrorId }: GetAreasParams) => {
  return axiosInstance.get(`/mirrors/${mirrorId}/areas`);
};

export const getSortedEvidenceForList = (
  evidence: TEvidencePartialData
): TEvidencePartialData => {
  const unseenEvidence = evidence.filter(
    (singleEvidence) => !singleEvidence.viewed
  );
  const viewedEvidence = evidence.filter(
    (singleEvidence) => !!singleEvidence.viewed
  );
  unseenEvidence.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );
  viewedEvidence.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );
  return [...unseenEvidence, ...viewedEvidence];
};

export const getSortedFeedbackForList = (
  feedback: IFeedbackWithDetails[]
): IFeedbackWithDetails[] => {
  const unseenFeedback = feedback.filter(
    (singleEvidence) => !singleEvidence.viewed
  );
  const viewedFeedback = feedback.filter(
    (singleEvidence) => !!singleEvidence.viewed
  );
  feedback.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );
  feedback.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );
  return [...unseenFeedback, ...viewedFeedback];
};

export const getEvidence = (id: string) =>
  axiosInstance.get(`/meta-mirror/evidence/${id}`);

export const getStudentEvidenceList = (studentId: string) =>
  axiosInstance.get(`evidence/${studentId}`);

export const submitEvidence = async (
  studentId: string,
  wheelId: string,
  areaId: string,
  evidence: string,
  evidenceType: TWheelValidEvidenceFileType | "link",
  evidenceName: string,
  levelId: string,
  grade: ILevel["grade"]
) => {
  // if (evidenceType === "link") {
  //   if (!EvidenceValidator.validateEvidenceLink(evidence)) {
  //     throw new Error(
  //       "That link is not valid. Please submit a link from one of the domains listed above."
  //     );
  //   }
  // }
  return axiosInstance.post(`/meta-mirror/evidence/submit`, {
    studentId,
    wheelId,
    areaId,
    evidence,
    evidenceType,
    evidenceName,
    levelId,
    grade,
  });
};

export const deleteEvidence = async (id: string) => {
  return axiosInstance.get(`/meta-mirror/evidence/delete/${id}`);
};

export type ReviewEvidenceParams = {
  mirrorId: string;
  areaId: string;
  teacherId: string;
  studentId: string;
  levelId: string;
  grade: ILevel["grade"];
  evidenceId: string;
  comment: string;
};

export const reviewEvidence = (params: ReviewEvidenceParams) =>
  axiosInstance.put("/meta-mirror/evidence/review", params);

type UpdateParams = Partial<IEvidence> &
  Pick<IEvidence, "_id"> & { isStudent?: boolean };

export const updateEvidence = ({ _id, ...rest }: UpdateParams) =>
  axiosInstance.patch(`/meta-mirror/evidence/${_id}`, rest);
