import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import { mediaRules } from "MuiTheme/Breakpoints";
import palette from "MuiTheme/palette";

export const RE = {
  CompleteButton: styled(Button)({
    backgroundColor: `${palette.complete} !important`,
    margin: "10px 0 !important",
    width: "200px !important",
  }),

  CompleteIcon: styled.i({
    color: palette.complete,
    fontSize: "10vh",
    lineHeight: "10vh",
  }),

  CycleButtonGrid: styled(Grid)({
    alignItems: "flex-end",
    display: "flex",
    flexDirection: "row",
    height: "50px",
    justifyContent: "flex-end",
    justifyItems: "flex-end",
    marginBottom: "-10px",
  }),

  EvidenceButton: styled(Button)({
    margin: "0 5px 0 5px !important",
    width: "150px !important",
  }),

  Info: Object.assign(
    styled.div({
      alignContent: "center",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "center",
      justifyItems: "center",
    }),
    {
      Message: styled(({ isError, ...rest }: any) => <Typography {...rest} />)<{
        isError: boolean;
      }>(({ isError }) => ({
        color: `${isError ? palette.error : palette.font.body} !important`,
        margin: "10px 0 !important",
        textAlign: "center",
      })),
    }
  ),

  Loader: styled.div({
    alignItems: "center",
    display: "flex",
    height: "calc(100% - 25px)",
    justifyContent: "center",
  }),

  Preview: styled(Grid)`
    height: 100% !important;
    @media ${mediaRules.lg} {
      height: unset !important;
    }
  `,

  Scroller: styled.div`
    height: 100%;
    overflow: hidden;
    @media ${mediaRules.lg} {
      overflow-y: auto;
      width: 100%;
    }
    ::-webkit-scrollbar-track {
      background-color: transparent;
    }
    ::-webkit-scrollbar {
      width: 7px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 7px;
      background-color: ${palette.font.caption};
    }
  `,
};
