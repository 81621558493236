import { Button, CircularProgress, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import palette from "MuiTheme/palette";

type ItemProps = { selected: boolean } & React.HTMLAttributes<HTMLDivElement>;
export const Item = styled(({ selected, ...rest }: ItemProps) => (
  <div {...rest} />
))(({ selected }) => ({
  alignItems: "stretch",
  border: `2px solid ${selected ? "red" : palette.font.body}`,
  borderRadius: 10,
  color: palette.font.body,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  minHeight: 150,
  padding: 15,
  width: "100%",
  "&:not(:first-child)": {
    margin: "10px 0 0",
  },
}));

export const ItemActionButton = Object.assign(
  styled(({ textColor, backgroundHoverColor, hoverColor, ...rest }) => (
    <Button {...rest} />
  ))(({ textColor, backgroundHoverColor, hoverColor, disabled }) => ({
    height: "32px !important",
    width: "110px !important",
    padding: "0 10px 0 5px !important",
    opacity: `${disabled ? 0.5 : 1} !important`,
    color: `${textColor} !important`,
    position: "relative",
    borderWidth: "2px !important",
    borderColor: `${textColor} !important`,
    "& .MuiButton-label": {
      alignItems: "center !important",
      textAlign: "center",
      justifyContent: "flex-start !important",
    },
    "&:hover": {
      color: `${hoverColor} !important`,
      backgroundColor: `${backgroundHoverColor} !important`,
      borderColor: `${hoverColor} !important`,
    },
    "@media (max-width: 475px)": {
      minWidth: "40px !important",
      maxWidth: "40px !important",
      minHeight: "40px !important",
      maxHeight: "40px !important",
      textIndent: "100%",
      whiteSpace: "nowrap",
      "& span": {
        maxWidth: "40px",
        overflow: "hidden",
      },
      "& i": {
        textIndent: "0",
        marginLeft: "4px",
      },
    },
  })),
  {
    Container: styled.div({
      marginRight: 10,
      "&:last-of-type": {
        marginRight: "0px !important",
      },
    }),

    Icon: styled.i({
      fontSize: 18,
      marginRight: 5,
      marginTop: "0px !important",
    }),

    Indicator: styled.div({
      backgroundColor: palette.secondary,
      borderRadius: "100%",
      height: 10,
      position: "absolute",
      right: -4,
      top: -4,
      width: 10,
    }),

    Spinner: styled(CircularProgress)({
      color: `${palette.font.body} !important`,
      margin: "0 0 0 8px !important",
    }),
  }
);

export const ItemActions = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
  marginTop: 15,
});

export const ItemHeader = Object.assign(
  styled.div({
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "100%",
  }),
  {
    Date: styled(Typography)({ alignSelf: "flex-start" }),

    EvidenceIcon: styled.p({
      fontSize: "40px",
      lineHeight: "40px",
      margin: 0,
    }),

    Icon: styled.div({
      alignItems: "center",
      display: "flex",
      margin: "0 15px 0 0",
    }),

    Info: styled.div({
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      maxWidth: "calc(100% - 125px)",
      width: "100%",
      "@media (max-width: 475px)": {
        maxWidth: "calc(100% - 55px)",
      },
    }),

    Title: styled.h6({
      fontSize: "18px !important",
      fontWeight: "700",
      margin: "0",
      marginRight: 5,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "100%",
      "@media (max-width: 475px)": {
        fontSize: "16px !important",
      },
    }),
  }
);

export const ItemInfo = Object.assign(
  styled.div({
    alignItems: "flex-start",
    backgroundColor: "#f0f0f0",
    borderRadius: 15,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginTop: 15,
    padding: "10px 15px",
  }),
  {
    Row: styled.div({
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      height: "30px",
      "@media (max-width: 475px)": {
        flexDirection: "column",
        height: "60px",
        justifyContent: "spacAround",
        alignItems: "flex-start",
        marginBottom: "10px",
      },
    }),

    Text: styled(Typography)({
      fontSize: "14px !important",
      fontStyle: "italic !important",
      fontWeight: "700 !important",
      marginRight: "10px !important",
      width: "100px !important",
      "@media (max-width: 475px)": {
        margin: "0 !important",
      },
    }),
  }
);
