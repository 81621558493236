import React from "react";

import { Link } from "react-router-dom";
import PageContainer from "components/common/PageContainer/PageContainer";
import {
  Capsule,
  CurriculumAchievements,
  OtherAchievements,
  OtherDetails,
  PlayIcon,
  SectionOne,
  SectionTwo,
  StyledProfileContainer,
  StyledProfileImage,
  Summary,
  TalkingHead,
  TalkingHeadContainer,
  TopRow,
} from "./styles";
import { useWheelsPage } from "../../context";

const profileImg = new URL(
  "../../../../../../assets/images/profile-mock/profile-image.webp",
  import.meta.url
);

export const MockProfile = () => {
  const { profile } = useWheelsPage();

  const linkWheelId =
    profile?._id === "65dc82fdf8d02d4410f242a0"
      ? "666aca5ef09abbbfe6401b4b"
      : "62039d9fe06849007804a5ef";
  const evidenceId =
    profile?._id === "65dc82fdf8d02d4410f242a0"
      ? "666acd0ff09abbbfe6401c22"
      : "63ea0fd794447fe25a683f4f";
  const exampleLink = (
    <Link
      to={`/student/${linkWheelId}?seId=${evidenceId}`}
      className="uil uil-external-link-alt"
    />
  );

  return (
    <PageContainer style={{ position: "relative" }}>
      <StyledProfileContainer>
        <SectionOne>
          <TopRow>
            {/* @ts-ignore */}
            <StyledProfileImage src={profileImg} alt="Profile Picture" />
            <TalkingHeadContainer>
              <TalkingHead></TalkingHead>
              <PlayIcon className="uil uil-play" />
            </TalkingHeadContainer>
          </TopRow>
          <Summary>
            <h4>My Summary</h4>
            <div>
              <p>
                I am a hugely positive person with ambition to forge a
                successful career in marketing. My recently achieved grade 9 in
                GCSE English highlights my passion for language and my grade 8s
                in computer science and maths that I am numerate and a confident
                user of technology. I believe I possess a number of the
                attributes that make me highly employable. These can be
                evidenced via the hyperlinks that follow:
              </p>
              <p>
                I am a flexible thinker {exampleLink} capable of seeking novel
                approaches to problems. I consider the viewpoints of others and
                enjoy looking at ideas in alternative ways. I am persistent{" "}
                {exampleLink} and have shown tenacity to complete tasks and to
                do so whilst being part of and leading teams {exampleLink} on
                numerous occasions.
              </p>
              <p>
                I am a confident communicator {exampleLink} and believe I would
                be a valuable asset to any organisation. I am committed to
                working hard and combined with my natural curiosity{" "}
                {exampleLink} and understanding of how to progress myself in any
                situation {exampleLink} would put everything into an opportunity
                to be part of a marketing firm.
              </p>
            </div>
          </Summary>
        </SectionOne>

        <SectionTwo>
          <CurriculumAchievements>
            <h4>
              {/* @ts-ignore */}
              <Link className="uil uil-newspaper" /> Curriculum Achievements
            </h4>
            <div className="inner">
              <p>GCSEs:</p>
              <div className="grades">
                <Capsule color="#e35174">English 9</Capsule>
                <Capsule color="#23d692">Maths 8</Capsule>
                <Capsule color="#e35174">Computer Science 8</Capsule>
                <Capsule color="#fac545">Geography 6</Capsule>
                <Capsule color="#e35174">History 5</Capsule>
                <Capsule color="#23d692">French 4</Capsule>
                <Capsule color="#6cd9ff">Biology 6</Capsule>
                <Capsule color="#23d692">Chemistry 5</Capsule>
              </div>
            </div>
          </CurriculumAchievements>
          <OtherAchievements>
            <h4>
              {/* @ts-ignore */}
              <Link className="uil uil-medal" /> Other Achievements
            </h4>
            <div className="inner">
              <ul>
                <li>
                  DofE certificate{" "}
                  <a href="https://google.com" target="_blank" rel="noreferrer">
                    {/* @ts-ignore */}
                    Link <Link className="uil uil-external-link-alt" />
                  </a>
                </li>
                <li>
                  LAMDA certificate{" "}
                  <a href="https://google.com" target="_blank" rel="noreferrer">
                    {/* @ts-ignore */}
                    Link <Link className="uil uil-external-link-alt" />
                  </a>
                </li>
              </ul>
            </div>
          </OtherAchievements>
          <OtherDetails>
            <h4>
              {/* @ts-ignore */}
              <Link className="uil uil-info-circle" /> Contact details
            </h4>
            <div className="inner">
              <div>
                <strong>School:</strong> Saint Test School{" "}
                <a href="https://google.com" target="_blank" rel="noreferrer">
                  {/* @ts-ignore */}
                  <Link className="uil uil-external-link-alt" />
                </a>
              </div>
              <div>
                <strong>Website:</strong> clare-smith-abc.com{" "}
                <a href="https://google.com" target="_blank" rel="noreferrer">
                  {/* @ts-ignore */}
                  <Link className="uil uil-external-link-alt" />
                </a>
              </div>
              <div>
                <strong>Social:</strong> {/* @ts-ignore */}
                <Link className="uil uil-instagram" /> Instagram{" "}
                <a href="https://google.com" target="_blank" rel="noreferrer">
                  {/* @ts-ignore */}
                  <Link className="uil uil-external-link-alt" />
                </a>
              </div>
            </div>
          </OtherDetails>
        </SectionTwo>
      </StyledProfileContainer>
    </PageContainer>
  );
};
