/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";

import { useWheelsPage } from "../../context";
import { useProfile } from "../context";
import { ContactContext, ContactProviderProps } from "./types";

const Context = React.createContext<ContactContext>({
  disabled: true,
  editing: false,
  handleAdd: () => Promise.resolve(),
  school: "",
  setSchool: () => {},
  setEditing: () => {},
  setWebsite: () => {},
  website: "",
});

export const ContactProvider = ({ children }: ContactProviderProps) => {
  const { contact } = useProfile();
  const [school, setSchool] = React.useState("");
  const [website, setWebsite] = React.useState("");
  const [editing, setEditing] = React.useState(false);
  const { addContact } = useWheelsPage();

  const disabled = React.useMemo(
    () => contact.school === school && contact.website === website,
    [contact, school, website]
  );

  const handleAdd = () => addContact({ school, website });

  React.useEffect(() => {
    contact.school && setSchool(contact.school);
    contact.website && setWebsite(contact.website);
  }, [contact]);

  return (
    <Context.Provider
      value={{
        disabled,
        editing,
        handleAdd,
        school,
        setEditing,
        setSchool,
        setWebsite,
        website,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useContact = () => React.useContext(Context);
