import React from "react";

import { P } from "./styles";
import { PreviewComponentProps } from "./types";

export const Comment = ({ evidence }: PreviewComponentProps) => {
  const source = React.useMemo(
    () =>
      (evidence?.evidence.evidence ?? "")
        .split(" ")
        .map((a) =>
          a.includes("http") ? `<a href={a} target="_blank">{a}</a>` : a
        )
        .join(" "),
    [evidence]
  );

  return (
    <P border>
      <P.LinkIcon className="uil uil-comment" />
      <P.Description variant="caption">
        {evidence?.name} submitted a comment as their evidence:
      </P.Description>
      <P.Comment variant="body2" dangerouslySetInnerHTML={{ __html: source }} />
    </P>
  );
};
