import styled from "styled-components";
import { Button } from "@material-ui/core";
import TextInput from "components/common/TextInput/TextInput";

export const S = {
  ActionContainer: styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  `,

  Button: styled(Button)`
    width: 150px;
    margin: 0 12px !important;
  `,

  TextInput: styled(TextInput)`
    margin: 30px 0 20px !important;
  `,
};
