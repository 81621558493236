/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
import Compress from "browser-image-compression";
import React from "react";

import { Achievement, ProfileContext, ProfileProviderProps } from "./types";
import { patchProfileSummary, uploadProfilePicture } from "lib/profile";
import { useWheelsPage } from "../context";
import { getStudentEvidenceList } from "lib/evidence";

const Context = React.createContext<ProfileContext>({
  achievements: [],
  contact: {},
  editing: false,
  evidence: [],
  feedLoading: false,
  handleSummaryChange: () => Promise.resolve(),
  loading: false,
  other: [],
  setEditing: () => {},
  setSummary: () => {},
  summary: "",
  upload: () => Promise.resolve(),
});

const IMAGE_TYPES = [".jpg", ".png", ".jpeg", ".webp", ".svg"];
const MAX_FILE_SIZE = 10 * (1024 * 1024);
const ERR_BIG = "That file is too large. Please upload a file < 10MB in size.";
const ERR_INVALID = "Invalid file type";

export const ProfileProvider = ({ children }: ProfileProviderProps) => {
  const { isUser, profile } = useWheelsPage();
  const [achievements, setAchievements] = React.useState<Achievement[]>([]);
  const [editing, setEditing] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const [evidence, setEvidence] = React.useState<any[]>([]);
  const [feedLoading, setFeedLoading] = React.useState(false);
  const [image, setImage] = React.useState<string | ArrayBuffer | null>();
  const [loading, setLoading] = React.useState(false);
  const [other, setOther] = React.useState([]);
  const [summary, setSummary] = React.useState<string>("");

  const handleError = (err?: string) => {
    setLoading(false);
    setError(err);
  };

  const handleSummaryChange = async (summary: string) => {
    setLoading(false);
    const { status } = await patchProfileSummary({
      summary,
      id: profile.user.id,
    });
    if (status !== 200) return handleError("Error updating summary");
    setSummary(summary);
    handleError(undefined);
  };

  const upload = async (file: File) => {
    setLoading(true);
    const ext = (file.name.split(/(?=\.)/).pop() || "").toLowerCase();
    if (!IMAGE_TYPES.includes(ext)) return handleError(ERR_INVALID);
    if (file.size > MAX_FILE_SIZE) return handleError(ERR_BIG);
    const options = { initialQuality: 0.5, useWebWorker: true };
    const compressed = await Compress(file, options);
    const reader = new FileReader();
    reader.readAsDataURL(compressed);
    reader.onload = async () => {
      const { status } = await uploadProfilePicture({
        file: reader.result!,
        name: file.name,
        userId: profile.user.id,
      });
      if (status !== 200) return handleError("Error uploading file");
      setImage(reader.result);
      handleError(undefined);
    };
    reader.onerror = () => {
      handleError("Error reading file");
    };
  };

  React.useEffect(() => {
    setAchievements(profile?.achievements || []);
    setImage(profile?.image);
    setSummary(profile?.summary);
    setFeedLoading(true);
    setOther(profile?.otherAchievements || []);
    if (isUser && profile?._id) {
      getStudentEvidenceList(profile._id)
        .then(({ data }) => setEvidence(data))
        .finally(() => setFeedLoading(false));
    }
  }, [profile]);

  return (
    <Context.Provider
      value={{
        achievements,
        contact: profile?.contact || {},
        editing,
        error,
        evidence,
        feedLoading,
        handleSummaryChange,
        image,
        loading,
        other,
        setEditing,
        setSummary,
        summary,
        upload,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useProfile = () => React.useContext(Context);
