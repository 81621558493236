import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import Select from "components/common/Select/Select";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { ISchoolWithGroupListData } from "typings/school";
import { IGroupData } from "typings/group";
import { IResponseBody } from "typings/global";
import { Link } from "react-router-dom";
import palette from "palette";

const StyledContainer = styled(Paper)`
  width: 100%;
  min-height: 120px;
  padding: 10px 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
`;

const StyledButton = styled(Button)`
  min-width: 80px;
  width: 100%;
  max-width: 120px;
  flex-grow: 0;
  flex-shrink: 0;
`;

const StyledTypography = styled(Typography)`
  font-size: 16px !important;
  font-weight: 700 !important;
  width: 80px;
`;

const StyledDataTypography = styled(Typography)`
  min-height: 35px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${palette.primary};
  :hover {
    color: ${palette.primary};
    text-decoration: none;
  }
`;

interface IProps {
  schoolId: string;
  groupsIds: string[];
  lockedGroupIds: string[];
  schoolList: ISchoolWithGroupListData[];
  handleUpdateDetails(
    schoolId: string,
    groupIds: string[],
    mmLockedGroupIds: string[]
  ): Promise<IResponseBody>;
}

const UserAssign: React.FC<IProps> = ({
  schoolId,
  groupsIds,
  schoolList,
  handleUpdateDetails,
  lockedGroupIds,
}: IProps) => {
  const [selectedSchool, setSelectedSchool] = useState("");
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedLockedGroups, setSelectedLockedGroups] = useState([]);
  const [requestingState, setRequestingState] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    setSelectedSchool(schoolId);
    setSelectedGroups(groupsIds || []);
    setSelectedLockedGroups(lockedGroupIds || []);
  }, [schoolId, groupsIds]);

  const getSelectedSchoolName = (): string | null => {
    const lookingSchool = schoolList.filter(
      (school) => school._id === selectedSchool
    )[0];

    if (!!lookingSchool) {
      return lookingSchool.name;
    }

    return null;
  };

  const getSelectedGroupNames = (): string[] => {
    const selectedGroupNames = [];
    schoolList.forEach((school) => {
      school.groups.forEach((groupData) => {
        if (selectedGroups.includes(groupData._id)) {
          selectedGroupNames.push(groupData.name);
        }
      });
    });
    return selectedGroupNames;
  };

  const getSelectedLockedGroupNames = (): string[] => {
    const selectedGroupNames = [];
    schoolList.forEach((school) => {
      school.groups.forEach((groupData) => {
        if (selectedLockedGroups.includes(groupData._id)) {
          selectedGroupNames.push(groupData.name);
        }
      });
    });
    return selectedGroupNames;
  };

  const getSelectedSchoolGroups = (): IGroupData[] => {
    const lookingSchool = schoolList.filter(
      (school) => school._id === selectedSchool
    )[0];

    if (!!lookingSchool) {
      return lookingSchool.groups;
    }

    return [];
  };

  const handleSaveAssign = async (): Promise<void> => {
    setRequestingState(true);
    const result = await handleUpdateDetails(
      selectedSchool,
      selectedGroups,
      selectedLockedGroups
    );
    setRequestingState(false);
    if (!result.error) {
      setEditMode(false);
    }
  };

  return (
    <StyledContainer>
      <Grid container direction="row">
        <Grid item xs={12} sm={9} className="mt-2 mt-sm-0">
          <Grid
            container
            direction="row"
            className="h-100"
            alignContent="space-around"
          >
            <Grid item xs={12} sm={3} md={2} lg={3}>
              <StyledTypography variant="body1">School</StyledTypography>
            </Grid>
            <Grid item xs={12} sm={9} md={10} lg={9}>
              {editMode ? (
                <Select
                  fullWidth
                  value={selectedSchool}
                  IconComponent={ExpandMoreRoundedIcon}
                  disabled={requestingState}
                  onChange={(event) => {
                    if (selectedSchool === event.target.value) {
                      return;
                    }
                    setSelectedSchool(event.target.value as string);
                    setSelectedGroups([]);
                  }}
                  options={schoolList.map((school) => ({
                    label: school.name,
                    value: school._id,
                  }))}
                />
              ) : (
                <StyledDataTypography variant="body2">
                  {getSelectedSchoolName()}
                </StyledDataTypography>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              md={2}
              lg={3}
              style={{ marginTop: "10px" }}
            >
              <StyledTypography variant="body1">Groups</StyledTypography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={9}
              md={10}
              lg={9}
              style={{ marginTop: "10px" }}
            >
              {editMode ? (
                getSelectedSchoolGroups().length ? (
                  <Select
                    fullWidth
                    value={selectedGroups}
                    IconComponent={ExpandMoreRoundedIcon}
                    multiple
                    disabled={requestingState}
                    onChange={(event) => {
                      setSelectedGroups(event.target.value as Array<unknown>);
                    }}
                    options={getSelectedSchoolGroups().map((groupData) => ({
                      label: groupData.name,
                      value: groupData._id,
                    }))}
                  />
                ) : (
                  <Typography variant="body2">
                    <i>
                      This school has no groups.{" "}
                      <StyledLink to={`/admin/schools/${selectedSchool}`}>
                        Create one here.
                      </StyledLink>
                    </i>
                  </Typography>
                )
              ) : (
                <StyledDataTypography variant="body2">
                  {getSelectedGroupNames().join(", ")}
                </StyledDataTypography>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              md={2}
              lg={3}
              style={{ marginTop: "10px" }}
            >
              <StyledTypography variant="body1">Locked Groups</StyledTypography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={9}
              md={10}
              lg={9}
              style={{ marginTop: "10px" }}
            >
              {editMode ? (
                getSelectedSchoolGroups().length ? (
                  <Select
                    fullWidth
                    value={selectedLockedGroups}
                    IconComponent={ExpandMoreRoundedIcon}
                    multiple
                    disabled={requestingState}
                    onChange={(event) => {
                      setSelectedLockedGroups(
                        event.target.value as Array<unknown>
                      );
                    }}
                    options={getSelectedSchoolGroups().map((groupData) => ({
                      label: groupData.name,
                      value: groupData._id,
                    }))}
                  />
                ) : (
                  <Typography variant="body2">
                    <i>This school has no groups.</i>
                  </Typography>
                )
              ) : (
                <StyledDataTypography variant="body2">
                  {getSelectedLockedGroupNames().join(", ")}
                </StyledDataTypography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={3} className="mt-3 mt-sm-0">
          <Grid
            container
            direction="row"
            className="h-100"
            alignContent="space-around"
            justifyContent="flex-end"
          >
            {editMode ? (
              <>
                <Grid
                  item
                  xs={6}
                  sm={12}
                  className="d-flex flex-row justify-content-sm-end justify-content-center"
                >
                  <StyledButton
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setEditMode(false);
                      setSelectedGroups(groupsIds);
                      setSelectedSchool(schoolId);
                    }}
                    disabled={requestingState}
                  >
                    cancel
                  </StyledButton>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={12}
                  className="d-flex flex-row justify-content-sm-end justify-content-center"
                >
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={() => handleSaveAssign()}
                    disabled={requestingState}
                  >
                    save
                  </StyledButton>
                </Grid>
              </>
            ) : (
              <Grid item>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={() => setEditMode(true)}
                >
                  edit
                </StyledButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default UserAssign;
