import { IconButton, Paper, Typography } from "@material-ui/core";
import palette from "MuiTheme/palette";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const DWP = Object.assign(
  styled(Paper)({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyItems: "stretch",
    padding: 25,
    width: "100%",
    height: "100%",
    maxHeight: "100%",
    overflowX: "hidden",
    overflowY: "auto",
  }),
  {
    BackButton: styled(IconButton)({
      background: palette.surface,
      border: `3px solid ${palette.primary}`,
      color: palette.primary,
      height: 40,
      width: 40,
    }),

    Divider: styled.div({
      width: "100%",
      height: 3,
      borderRadius: 15,
      backgroundColor: palette.disabled,
      margin: "15px 0 0",
    }),

    Error: styled(Typography)({ color: palette.error }),

    Icon: styled.div({ fontSize: "20pt" }),

    IconButton: styled(IconButton)({ float: "right" }),

    Link: styled(Link)({ "&:hover": { textDecoration: "none" } }),

    Name: styled(Typography)({ textAlign: "center" }),
  }
);
