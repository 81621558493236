import React from "react";

import { DeleteModal } from "./delete";
import { ViewModal } from "./view";

export const Modals = () => (
  <>
    <DeleteModal />
    <ViewModal />
  </>
);
