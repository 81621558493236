import styled from "styled-components";
import PageContainer from "components/common/PageContainer/PageContainer";
import { mediaRules } from "MuiTheme/Breakpoints";
import { Typography } from "@material-ui/core";

export const S = {
  PageContainer: styled(PageContainer)`
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: 992px) {
      flex-direction: column;
    }
  `,
  GroupListContainer: styled.div`
    width: 300px;
    flex-shrink: 0;
    margin: 0 30px 0 0;
    position: fixed;
    height: calc(100% - 60px);
    @media only screen and (max-width: 992px) {
      position: relative;
      height: auto;
      width: 100%;
      margin: 0 0 30px 0;
    }
  `,
  EditContainer: styled.div`
    width: 100%;
    height: 100%;
    padding-left: 330px;
    @media ${mediaRules.lg} {
      padding-left: 0;
    }
  `,
  NoSelectedGroupContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
  `,
  Caption: styled(Typography)`
    font-weight: bold !important;
    margin: 15px 0 !important;
  `,
};
