import { Button, TextField } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const StyledForm = styled.form`
  display: flex;
  align-items: center;
  margin: 20px 0;
  gap: 1rem;
`;

const StyledButton = styled(Button)`
  width: 120px;
  height: 42px !important;
`;

const StyledActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-basis: 255px;
  // margin: 0 15px 0 0;
  flex-grow: 0;
  flex-shrink: 0;
`;

const StyledTextField = styled(TextField)`
  margin: 0 !important;
`;

interface IProps {
  onClear: () => void;
  onSearch: (value: string) => void;
  label: string;
  extraContent?: React.ReactNode;
}

const TableSearch: React.FC<IProps> = ({
  extraContent,
  label,
  onClear,
  onSearch,
}: IProps) => {
  const [searchValue, setSearchValue] = React.useState("");

  const handleClearButton = (): void => {
    setSearchValue("");
    onClear();
  };

  return (
    <StyledForm
      onSubmit={(event) => {
        event.preventDefault();
        onSearch(searchValue);
      }}
    >
      <StyledActionContainer>
        <StyledButton
          color="secondary"
          variant="contained"
          onClick={handleClearButton}
        >
          Clear
        </StyledButton>
        <StyledButton color="primary" variant="contained" type="submit">
          Search
        </StyledButton>
      </StyledActionContainer>
      <StyledTextField
        variant="outlined"
        margin="dense"
        fullWidth
        label={label}
        value={searchValue}
        onChange={(event) => setSearchValue(event.target.value)}
      />
      {extraContent}
    </StyledForm>
  );
};

export default TableSearch;
