export interface IQueryStringObject {
  [key: string]: string | string[] | number | number[] | boolean;
}

export const QueryStringService = {
  getObjectFromQueryString(queryString: string): IQueryStringObject {
    const readyObject: {
      [key: string]: string | string[] | number | number[] | boolean;
    } = {};
    const searchParams = new URLSearchParams(queryString);
    const getQueryStringValue = (queryStringValue: string): string | number => {
      return String(parseInt(queryStringValue)) === queryStringValue
        ? parseInt(queryStringValue)
        : queryStringValue;
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const entries = searchParams.entries();

    for (const entry of entries) {
      const [key, value] = entry;

      if (key.includes("[]")) {
        const preparedValue = key.replace("[]", "");

        if (readyObject.hasOwnProperty(preparedValue)) {
          const currentValue = readyObject[preparedValue] as
            | string[]
            | number[];

          currentValue.push(getQueryStringValue(value) as never);
          readyObject[preparedValue] = currentValue;
        } else {
          readyObject[preparedValue] = [getQueryStringValue(value) as never];
        }
      } else {
        readyObject[key] = getQueryStringValue(value);
      }
    }
    return readyObject;
  },

  getQueryStringFromObject(
    object: IQueryStringObject,
    removeEmptyKeys = true
  ): string {
    if (removeEmptyKeys) {
      Object.keys(object).forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        if (
          object[key] === "" ||
          (Array.isArray(object[key]) && object[key].length === 0)
        ) {
          delete object[key];
        }
      });
    }

    return `?${Object.keys(object)
      .map((key) => {
        if (Array.isArray(object[key])) {
          const array: string[] = object[key] as string[];
          return `${array
            .map((item) => `${key}[]=${item?.toString()}`)
            .join("&")}`;
        }
        return `${key}=${object[key as string].toString()}`;
      })
      .join("&")}`;
  },
};
