/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";

import { QueryStringService as QSS } from "helpers/queryStringService";

import { ViewCommentProps } from "./evidence/types";
import {
  Delete,
  EvidenceListContext,
  EvidenceListProviderProps,
} from "./types";

const Context = React.createContext<EvidenceListContext>({
  evidence: [],
  handleSubmit: () => {},
  hasFilters: false,
  mirrorLevelType: "",
  openDeleteModal: () => {},
  refresh: () => {},
  selectEvidenceId: "",
  setComment: () => {},
  setDeleteEvidence: () => {},
  studentName: "",
  variant: "student",
  wheelData: [],
});

export const EvidenceListProvider = ({
  children,
  evidence: initialEvidence,
  handleRefreshData,
  handleReviewEvidence,
  handleSubmitEvidence,
  mirrorLevelType,
  selectEvidenceId,
  studentName,
  wheelData,
  wheelLocked,
  variant,
}: EvidenceListProviderProps) => {
  const [comment, setComment] = React.useState<ViewCommentProps>();
  const [deleteEvidence, setDeleteEvidence] = React.useState<Delete>();

  const getOrder = (item: IEvidenceWithFeedback) => {
    if (variant === "teacher" && !item.viewed) return 0;
    if (variant === "student" && item.feedback?.viewed === false) return 1;
    if (item.messages?.some(({ viewed }) => !viewed)) return 2;
    return 3;
  };

  const evidence = React.useMemo(
    () =>
      initialEvidence
        .map((item) => ({
          ...item,
          order: getOrder(item),
        }))
        .sort(
          (a, b) =>
            a.order - b.order ||
            (new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
              ? -1
              : 1)
        )
        .map(({ order, ...rest }) => rest),
    [initialEvidence]
  );

  const hasFilters = React.useMemo(
    () => !!QSS.getObjectFromQueryString(window.location.search).area,
    [window.location.search]
  );

  const handleSubmit = () => handleSubmitEvidence?.();

  const openDeleteModal = (name: string, id: string) =>
    setDeleteEvidence({ id, name });

  const refresh = () => handleRefreshData?.();

  return (
    <Context.Provider
      value={{
        comment,
        deleteEvidence,
        evidence,
        handleReviewEvidence,
        handleSubmit,
        hasFilters,
        isTeacher: variant === "teacher",
        mirrorLevelType,
        openDeleteModal,
        refresh,
        selectEvidenceId,
        setComment,
        setDeleteEvidence,
        studentName,
        variant,
        wheelData,
        wheelLocked,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useEvidenceList = () => React.useContext(Context);
