import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ILevel } from "typings/meta-mirror";
import palette from "palette";
import { IFeedbackWithDetails } from "typings/feedback";
import { WheelsPageContext } from "../../states/WheelsPageContext";
import FeedbackListModal from "./FeedbackListModal";
import QuickEditMenu from "./QuickEditMenu";
import { LEVEL_PARTS } from "constants/index";

const StyledContainer = styled.div`
  border-radius: 10px !important;
  border: 3px solid ${palette.font.caption};
  overflow: auto;
`;

const StyledNoDataContainer = styled.div`
  width: 100%;
  padding: 50px 0;
  text-align: center;
`;

const StyledGradeIcon = styled.i<{
  backgroundColor?: string;
  padding?: string;
  fontSize?: number;
  border?: boolean;
}>`
  width: 35px;
  height: 35px;
  border-radius: 100%;
  border: ${(props) =>
    props.border ? `dashed 2px ${palette.font.caption}` : "none"};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => props.fontSize || 32}px;
  line-height: 32px;
  background-color: ${(props) => props.backgroundColor || "inherit"};
  margin: 0 auto;
  padding: ${(props) => props.padding || 0};
`;

const StyledCommentIcon = styled.i<{ color?: string }>`
  font-size: 24px;
  color: ${(props) => props.color || palette.disabled};
  width: 40px;
  height: 40px;
`;

const StyledQuickEditIconButton = styled(IconButton)<{ disabled?: boolean }>`
  height: 35px !important;
  width: 35px !important;
  min-width: 35px !important;
  min-height: 35px !important;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)} !important;
`;

const StyledLink = styled(Link)`
  color: inherit;

  &:hover {
    color: inherit;
  }
`;

const StyledIcon = styled.i<{ color?: string }>`
  font-size: 24px;
  color: ${(props) => props.color || palette.disabled};
`;

const StyledIconLink = styled(StyledLink)`
  border-radius: 50%;
  width: 35px;
  display: block;
  height: 35px;
  margin: 0 auto;
  transition: 0.3s;

  &:hover {
    background-color: ${palette.disabled}66;
  }
`;

interface IStyledTableCellProps extends TableCellProps {
  customAlign: string;
  customWidth: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledTableCell = styled(
  ({ customAlign, customWidth, ...props }: IStyledTableCellProps) => (
    <TableCell {...props} />
  )
)`
  width: ${(props) => (props.customWidth ? props.customWidth : "auto")};
  min-width: ${(props) => (props.customWidth ? props.customWidth : "auto")};
  text-align: ${(props) =>
    props.customAlign ? props.customAlign : "left"} !important;
`;

export interface ISelectedAreaStudentData {
  _id: string;
  name: string;
  levels: ILevel[];
  feedbacks: IFeedbackWithDetails[];
  uncheckedEvidence: boolean;
}

interface IProps {
  data: ISelectedAreaStudentData[];
  quickEditEnabled: boolean;
  addQuickEditUpdate(
    studentId: string,
    levelId: string,
    grade: ILevel["grade"]
  ): void;
  removeQuickEditUpdate(studentId: string): void;
}

const SelectedAreaStudentList: React.FC<IProps> = ({
  data,
  quickEditEnabled,
  addQuickEditUpdate,
  removeQuickEditUpdate,
}: IProps) => {
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [levelLabels, setLevelLabels] = useState<
    { level: number; _id: string }[]
  >([]);
  const [isFeedbackListModalOpen, setIsFeedbackListModalOpen] = useState(false);
  const [reviewingFeedbacks, setReviewingFeedbacks] = useState([]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [quickEditLevel, setQuickEditLevel] = useState("");
  const [quickEditStudent, setQuickEditStudent] = useState("");
  const [initialData, setInitialData] = useState<ISelectedAreaStudentData[]>();
  const [localData, setLocalData] = useState<ISelectedAreaStudentData[]>();
  const { wheelResults } = useContext(WheelsPageContext);

  const getLevelsLabels = (): void => {
    const levelsArr: { level: number; _id: string }[] = [];

    data.forEach((student) => {
      student.levels.forEach(({ level, _id }) => {
        const newLevel = { level, _id };
        if (!levelsArr.find((arrLevel) => arrLevel._id === newLevel._id)) {
          levelsArr.push(newLevel);
        }
      });
    });

    setLevelLabels(levelsArr.sort((a, b) => a.level - b.level));
  };

  const getLevelGrade = (
    lookingLevel: number,
    levels: ILevel[]
  ): ILevel["grade"] => {
    const searchingLevel = levels.filter(
      ({ level }) => level === lookingLevel
    )[0];

    return searchingLevel.grade;
  };

  const getGradeLabel = (grade: ILevel["grade"]) => {
    enum gradeLabels {
      "Not Attempted" = null,
      "Attempting" = 0,
      "Partial" = 0.5,
      "Complete" = 1,
    }
    return gradeLabels[grade];
  };

  const getMarkIcon = (value: ILevel["grade"]): JSX.Element => {
    if (value === 1) {
      return (
        <StyledGradeIcon
          className="uil uil-check"
          backgroundColor={palette.complete}
          padding="2px 0 0"
        />
      );
    }

    if (value === 0.5) {
      return (
        <StyledGradeIcon
          className="uil uil-percentage"
          backgroundColor={palette.partial}
          padding="2px 0 0"
        />
      );
    }

    if (value === 0) {
      return (
        <StyledGradeIcon
          className="uil uil-flask-potion"
          backgroundColor={palette.attempting}
          fontSize={30}
          padding="2px 0 0"
        />
      );
    }

    if (quickEditEnabled) {
      return <StyledGradeIcon border />;
    }

    return (
      <StyledGradeIcon
        className=""
        backgroundColor="transparent"
        padding="2px 0 0"
      />
    );
  };

  const getTableCellWidth = (): string => {
    return `${70 / (levelLabels.length + 1)}%`;
  };

  const handleOpenFeedbackListModal = (
    feedbacks: IFeedbackWithDetails[]
  ): void => {
    setReviewingFeedbacks(feedbacks);
    setIsFeedbackListModalOpen(true);
  };

  const getOriginalStudentLevel = (
    studentId: string,
    levelId: string
  ): ILevel => {
    return data
      .find((studentData) => studentData._id === studentId)
      .levels.find((studentLevel) => studentLevel._id === levelId);
  };

  const isQuickEditDisabled = (studentId: string, levelId: string): boolean => {
    const { grade } = getOriginalStudentLevel(studentId, levelId);
    return grade === 1;
  };

  const isMenuButtonDisabled = (gradeIn: ILevel["grade"]): boolean => {
    if (!quickEditStudent || !quickEditEnabled) {
      return true;
    }
    const { grade } = getOriginalStudentLevel(quickEditStudent, quickEditLevel);
    if (grade === null) {
      return false;
    }
    if (gradeIn === null) {
      return true;
    }
    return gradeIn < grade;
  };

  const handleQuickEditSelect = (grade: ILevel["grade"]): void => {
    const originalLevel = getOriginalStudentLevel(
      quickEditStudent,
      quickEditLevel
    );
    if (grade !== null && grade === originalLevel.grade) {
      removeQuickEditUpdate(quickEditStudent);
    } else {
      addQuickEditUpdate(quickEditStudent, quickEditLevel, grade);
    }
    const newLocalData: ISelectedAreaStudentData[] = _.cloneDeep(localData);
    const dataIndex = newLocalData.findIndex(
      (studentData) => studentData._id === quickEditStudent
    );
    const levelIndex = newLocalData[dataIndex].levels.findIndex(
      (level) => level._id === quickEditLevel
    );
    const levelValue = newLocalData[dataIndex].levels[levelIndex].level;

    newLocalData[dataIndex].levels.forEach((level, index) => {
      if (wheelResults?.levelType !== "non-sequential") {
        if (level.level < levelValue) {
          newLocalData[dataIndex].levels[index].grade = 1;
        }
        if (level.level > levelValue) {
          newLocalData[dataIndex].levels[index].grade = null;
        }
      }
      if (level.level === levelValue) {
        newLocalData[dataIndex].levels[index].grade = grade;
      }
    });
    setLocalData(newLocalData);
    setQuickEditLevel("");
    setQuickEditStudent("");
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!_.isEqual(data, initialData) || !quickEditEnabled) {
      const newData = _.cloneDeep(data);
      getLevelsLabels();
      setLocalData(newData);
      setInitialData(newData);
    }
  }, [data, quickEditEnabled]);

  return (
    <StyledContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" width="30%">
              Name
            </TableCell>
            <StyledTableCell customAlign="center" customWidth="150px">
              Updated Evidence
            </StyledTableCell>
            {levelLabels.map((level, levelIndex) => (
              <TableCell
                key={`level-${levelIndex}`}
                align="center"
                width={getTableCellWidth()}
              >
                {wheelResults.levelType !== "non-sequential"
                  ? `Level ${level.level}`
                  : `Part ${LEVEL_PARTS[level.level - 1]}`}
              </TableCell>
            ))}
            <TableCell align="center" width={getTableCellWidth()}>
              Comments
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!!localData && localData.length ? (
            <>
              {localData
                .slice(
                  currentPage * pageSize,
                  currentPage * pageSize + pageSize
                )
                .map((student, studentIndex) => {
                  // if(student.)
                  return (
                    <TableRow key={`student-${studentIndex}`}>
                      <TableCell align="center">
                        <StyledLink
                          to={`/teacher/my-wheels/${wheelResults._id}/${student._id}`}
                        >
                          {student.name}
                        </StyledLink>
                      </TableCell>
                      <TableCell align="center">
                        {student.uncheckedEvidence ? (
                          <Tooltip title="View details">
                            <StyledIconLink
                              to={`/teacher/my-wheels/${wheelResults._id}/${student._id}`}
                            >
                              <StyledIcon
                                className="uil uil-document-info"
                                color={palette.secondary}
                              />
                            </StyledIconLink>
                          </Tooltip>
                        ) : (
                          <StyledIcon className="uil uil-file-times" />
                        )}
                      </TableCell>
                      {levelLabels.map((level, levelIndex) => (
                        <TableCell
                          key={`student-level-${levelIndex}`}
                          align="center"
                        >
                          {quickEditEnabled ? (
                            <StyledQuickEditIconButton
                              onClick={(event) => {
                                setQuickEditStudent(student._id);
                                setQuickEditLevel(level._id);
                                setAnchorEl(event.currentTarget);
                              }}
                              disabled={isQuickEditDisabled(
                                student._id,
                                level._id
                              )}
                            >
                              {getMarkIcon(
                                getLevelGrade(level.level, student.levels)
                              )}
                            </StyledQuickEditIconButton>
                          ) : getLevelGrade(level.level, student.levels) ===
                            null ? (
                            getMarkIcon(
                              getLevelGrade(level.level, student.levels)
                            )
                          ) : (
                            <Tooltip
                              title={getGradeLabel(
                                getLevelGrade(level.level, student.levels)
                              )}
                            >
                              {getMarkIcon(
                                getLevelGrade(level.level, student.levels)
                              )}
                            </Tooltip>
                          )}
                        </TableCell>
                      ))}
                      <TableCell align="center">
                        {student.feedbacks.length ? (
                          <Tooltip title="View comments">
                            <IconButton
                              onClick={() =>
                                handleOpenFeedbackListModal(student.feedbacks)
                              }
                            >
                              <StyledCommentIcon
                                className="uil uil-comment-alt-message"
                                color={palette.secondary}
                              />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <StyledCommentIcon className="uil uil-comment-alt-message" />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </>
          ) : (
            <TableRow>
              <TableCell colSpan={5}>
                <StyledNoDataContainer>No data found</StyledNoDataContainer>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={data.length}
              rowsPerPage={pageSize}
              page={currentPage}
              onChangePage={(event, page) => setCurrentPage(page)}
              onChangeRowsPerPage={(event) => {
                setPageSize(parseInt(event.target.value, 10));
                setCurrentPage(0);
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
      <FeedbackListModal
        isOpen={isFeedbackListModalOpen}
        handleClose={() => setIsFeedbackListModalOpen(false)}
        feedbacks={reviewingFeedbacks}
      />
      {quickEditEnabled && anchorEl && (
        <QuickEditMenu
          handleQuickEditSelect={handleQuickEditSelect}
          isMenuButtonDisabled={isMenuButtonDisabled}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        />
      )}
    </StyledContainer>
  );
};

export default SelectedAreaStudentList;
