import { Button, CircularProgress } from "@material-ui/core";
import { DeleteRounded } from "@material-ui/icons";
import React from "react";

import { useOther } from "./context";
import { ButtonLink } from "./styles";
import { useWheelsPage } from "../../context";

type ItemProps = {
  achievement: string;
  image: string;
  name: string;
};

export const Item = ({ image, achievement }: ItemProps) => {
  const { editing, setItem } = useOther();
  const [loading, setLoading] = React.useState(false);
  const { removeOtherAchievement } = useWheelsPage();

  const handleLinkClick = () => setItem(image);

  const handleRemoveClick = () => {
    setLoading(true);
    removeOtherAchievement(image);
  };

  return (
    <li>
      {achievement}
      <ButtonLink
        disabled={loading}
        endIcon={<i className="uil uil-external-link-alt" />}
        onClick={handleLinkClick}
        variant="text"
      >
        Link
      </ButtonLink>
      {editing && (
        <Button
          color="secondary"
          disabled={loading}
          onClick={handleRemoveClick}
          startIcon={
            loading ? <CircularProgress size={20} /> : <DeleteRounded />
          }
          variant="text"
        >
          Delete
        </Button>
      )}
    </li>
  );
};
