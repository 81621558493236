import React from "react";
import styled from "styled-components";
import AnimatedModal from "components/common/AnimatedModal";
import FeedbackList from "components/common/OLD_FeedbackList/FeedbackList";
import { IFeedbackWithDetails } from "typings/feedback";

const StyledFeedbackListContainer = styled.div`
  margin: 30px 0 0;
`;

interface IProps {
  isOpen: boolean;
  feedbacks: IFeedbackWithDetails[];
  handleClose: () => void;
}

const FeedbackListModal: React.FC<IProps> = ({
  isOpen,
  handleClose,
  feedbacks,
}: IProps) => {
  return (
    <AnimatedModal
      open={isOpen}
      title="Comments"
      handleClose={handleClose}
      width="100%"
      maxWidth="800px"
      minHeight="0"
      height="auto"
    >
      <StyledFeedbackListContainer>
        <FeedbackList
          feedback={feedbacks}
          variant="teacher"
          disableTitle={true}
        />
      </StyledFeedbackListContainer>
    </AnimatedModal>
  );
};

export default FeedbackListModal;
