import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { mediaRules } from "MuiTheme/Breakpoints";
import palette from "palette";
import { getSchoolConfig, logoutUser } from "lib/auth";

const mmLogo = new URL("../../../assets/images/mm-logo.jpg", import.meta.url);

const StyledPaper = styled(Paper)`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 80px;
  border-radius: 0 !important;
  box-shadow: 6px 0 12px -6px rgba(24, 39, 75, 0.12),
    8px 0 24px -4px rgba(24, 39, 75, 0.08) !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-items: flex-start;
  z-index: 100;
  @media ${mediaRules.sm} {
    height: 80px;
    width: 100vw;
    flex-direction: row;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
      0px 8px 24px -4px rgba(24, 39, 75, 0.08) !important;
  }
`;

const StyledLogo = styled.img`
  width: 55px;
  height: 55px;
  margin: 15px auto 0 auto;
  @media ${mediaRules.sm} {
    margin: auto 0 auto 15px;
  }
`;

const StyledDivider = styled.div`
  width: 55px;
  height: 3px;
  border-radius: 15px;
  background-color: ${palette.disabled};
  margin: 15px auto 0 auto;
  @media ${mediaRules.sm} {
    display: none;
  }
`;

const StyledNavButton = styled(NavLink)`
  transition: 350ms;
  height: 55px;
  margin: 15px auto 5px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 8pt;
  line-height: 12px;
  color: ${palette.font.caption};
  :hover {
    color: ${palette.font.body};
    text-decoration: none;
  }
  @media ${mediaRules.sm} {
    display: none;
  }
`;

const StyledGlobalLink = styled.button`
  transition: 350ms;
  outline-width: 0;
  height: 55px;
  margin: 15px auto 5px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 8pt;
  line-height: 20px;
  color: ${palette.font.caption};
  padding: inherit;
  font-family: inherit;
  background-color: transparent;
  border: none;
  :hover {
    color: ${palette.font.body};
    text-decoration: none;
  }
  @media ${mediaRules.sm} {
    display: none;
  }
`;

const StyledMobileNavlink = styled(NavLink)`
  transition: 350ms;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  font-weight: 600;
  font-size: 10pt;
  color: ${palette.font.caption};
  :hover {
    color: ${palette.font.body};
    text-decoration: none;
  }
`;

const StyledMenuButton = styled(StyledGlobalLink)`
  margin: auto 15px auto auto;
  font-size: 32px;
  @media (min-width: 576px) {
    display: none;
  }
  @media ${mediaRules.sm} {
    display: flex;
  }
`;

const StyledGlobalActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-content: center;
  margin-top: auto;
  margin-bottom: 15px;
  @media ${mediaRules.sm} {
    display: none;
  }
`;

const StyledMobileDivider = styled(Divider)`
  margin: 15px !important;
`;

const StyledIcon = styled.i`
  font-size: 37px;
  margin-bottom: 5px;
`;

type TNavLinkData = {
  link: string;
  label: string;
  icon: JSX.Element;
};

interface INavLinks {
  [key: string]: TNavLinkData[];
}

const navLinks: INavLinks = {
  admin: [
    {
      link: "/admin/students",
      label: "students",
      icon: <StyledIcon className="uil uil-graduation-cap" />,
    },
    {
      link: "/admin/teachers",
      label: "teachers",
      icon: <StyledIcon className="uil uil-book-reader" />,
    },
    {
      link: "/admin/schools",
      label: "schools",
      icon: <StyledIcon className="uil uil-building" />,
    },
  ],
  teacher: [
    {
      link: "/teacher/my-wheels",
      label: "wheels",
      icon: <StyledIcon className="uil uil-chart-pie-alt" />,
    },
    {
      link: "/teacher/manage-wheels",
      label: "manage wheels",
      icon: <StyledIcon className="uil uil-edit" />,
    },
    {
      link: "/teacher/manage-groups",
      label: "manage groups",
      icon: <StyledIcon className="uil uil-users-alt" />,
    },
    {
      link: "/teacher/manage-students",
      label: "manage students",
      icon: <StyledIcon className="uil uil-book-reader" />,
    },
  ],
  student: [],
};

interface IProps {
  type: keyof typeof navLinks;
}

const Navbar: React.FC<IProps> = ({ type }: IProps) => {
  const [mobileMenuOpen, toggleMobileMenuOpen] = useState(false);
  const [topLeftIconUrl, setTopLeftIconUrl] = useState("");

  useEffect(() => {
    getSchoolConfig().then(({ schoolConfig }) => {
      schoolConfig?.metaMirror &&
        setTopLeftIconUrl(schoolConfig.metaMirror.topLeftIconUrl);
    });
  }, []);

  const handleLogout = async () => {
    await logoutUser();
    window.location.href = `${process.env.NEXT_PUBLIC_DOMAIN_APP}/logout`;
  };

  const handleDashboard = async () => {
    window.location.href = process.env.NEXT_PUBLIC_DOMAIN_APP ?? "";
  };

  return (
    <>
      <StyledPaper>
        <StyledLogo alt="Meta Mirror logo" src={topLeftIconUrl || mmLogo} />
        <StyledDivider />
        {navLinks[type].map(({ link, label, icon }) => {
          return (
            <StyledNavButton
              to={link}
              key={link}
              activeStyle={{ color: palette.primary, fontWeight: 900 }}
            >
              {icon}
              <p>{label}</p>
            </StyledNavButton>
          );
        })}

        <StyledGlobalActionsContainer>
          <StyledGlobalLink onClick={handleDashboard}>
            <div>
              <StyledIcon className="uil uil-apps" />
            </div>
            Dashboard
          </StyledGlobalLink>
          <StyledGlobalLink onClick={handleLogout}>
            <div>
              <StyledIcon className="uil uil-signout" />
            </div>
            Logout
          </StyledGlobalLink>
        </StyledGlobalActionsContainer>

        {/* MOBILE */}
        <StyledMenuButton onClick={() => toggleMobileMenuOpen(!mobileMenuOpen)}>
          <StyledIcon className="uil uil-bars" />
        </StyledMenuButton>
      </StyledPaper>

      {/* MOBILE */}
      <Drawer
        anchor="top"
        open={mobileMenuOpen}
        onBackdropClick={() => toggleMobileMenuOpen(false)}
        onClose={() => toggleMobileMenuOpen(false)}
        ModalProps={{
          style: {
            top: "80px !important",
            zIndex: 99,
          },
        }}
        PaperProps={{
          style: {
            top: 80,
          },
        }}
      >
        <List>
          {navLinks[type].map(({ icon, label, link }) => (
            <StyledMobileNavlink
              to={link}
              key={label}
              activeStyle={{ color: palette.primary, fontWeight: 900 }}
            >
              <ListItem>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText>{label}</ListItemText>
              </ListItem>
            </StyledMobileNavlink>
          ))}
          {!!navLinks[type].length && <StyledMobileDivider />}
          <ListItem>
            <ListItemIcon>
              <StyledIcon className="uil uil-apps" />
            </ListItemIcon>
            <ListItemText>dashboard</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <StyledIcon className="uil uil-signout" />
            </ListItemIcon>
            <ListItemText>logout</ListItemText>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export default Navbar;
