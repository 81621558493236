import React from "react";
import { useParams } from "react-router-dom";

import PageContainer from "components/common/PageContainer/PageContainer";

import { StudentPage as WheelPage } from "../../student/sections/wheelsPage";

export const StudentPage = () => {
  const { id } = useParams<any>();

  return (
    <PageContainer>
      <WheelPage isUser={false} userId={id} />
    </PageContainer>
  );
};
