import { Box } from "@material-ui/core";
import styled from "styled-components";

export const ButtonContainer = styled(Box)({
  alignItems: "center",
  display: "flex",
});

export const Container = styled(Box)({
  display: "flex",
  gap: ".5rem",
  width: "100%",
});

export const DropdownContainer = styled(Box)({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  gap: ".5rem",
});