import { Theme } from '@material-ui/core';
import MuiButton from './MuiButton';
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiSelect from './MuiSelect';
import MuiSlider from './MuiSlider';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiInputLabel from './MuiInputLabel';
import MuiMenu from './MuiMenu';
import MuiMenuItem from './MuiMenuItem';
import MuiTableCell from './MuiTableCell';
import MuiTablePagination from './MuiTablePagination';
import MuiInputBase from './MuiInputBase';
import MuiBackdrop from './MuiBackdrop';
import MuiCheckbox from './MuiCheckbox';
import MuiRadio from './MuiRadio';
import MuiFormControlLabel from './MuiFormControlLabel';
import MuiFormHelperText from './MuiFormHelperText';

const Overrides: Theme['overrides'] = {
  MuiButton,
  MuiIconButton,
  MuiPaper,
  MuiSlider,
  MuiSelect,
  MuiOutlinedInput,
  MuiInputLabel,
  MuiMenu,
  MuiMenuItem,
  MuiTableCell,
  MuiTablePagination,
  MuiInputBase,
  MuiBackdrop,
  MuiCheckbox,
  MuiRadio,
  MuiFormControlLabel,
  MuiFormHelperText,
};

export default Overrides;
