import { Typography } from "@material-ui/core";
import palette from "MuiTheme/palette";
import React from "react";
import styled from "styled-components";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Container = styled(({ visible, ...rest }) => <div {...rest} />)<{
  visible: boolean;
}>(({ visible }) => ({
  height: "100%",
  paddingRight: 10,
  position: "absolute",
  visibility: visible ? "visible" : "hidden",
  width: "100%",
}));

export const LevelHeading = styled(Typography)({
  color: palette.font.body,
  fontSize: "16px !important",
  fontWeight: "700 !important",
  marginBottom: "5px !important",
});
