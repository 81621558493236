import React, { useEffect, useState } from "react";
import AnimatedModal from "components/common/AnimatedModal";
import { CircularProgress } from "@material-ui/core";
import ProgressChart, {
  ISeriesData,
} from "components/common/ProgressChart/ProgressChart";
import styled from "styled-components";
import MmProgressChartService from "lib/progressChart";
import { getWheelStudentProgressData } from "lib/mirror";
import { IWithAuth, withAuth } from "../../hoc/withAuth";

const StyledLoader = styled(CircularProgress)`
  position: absolute !important;
  left: calc(50% - 20px) !important;
  top: calc(50% - 20px) !important;
`;

interface IProps extends IWithAuth {
  open: boolean;
  wheelId: string;
  wheelName: string;
  handleClose(): void;
  mirrorLevelType?: string;
}

const ProgressChartModal: React.FC<IProps> = ({
  open,
  wheelName,
  wheelId,
  handleClose,
  user,
  mirrorLevelType = "sequential",
}: IProps) => {
  const [chartData, setChartData] = useState<ISeriesData[]>();

  const getData = async (): Promise<void> => {
    try {
      const studentId = user?.roles?.student?.id?._id;
      const { data } = await getWheelStudentProgressData(wheelId, studentId);
      setChartData(
        MmProgressChartService.getProgressChartData(data, mirrorLevelType)
      );
    } catch {}
  };

  const handleCloseModal = (): void => {
    setChartData([]);
    handleClose();
  };

  useEffect(() => {
    if (open) {
      getData();
    }
  }, [open]);

  return (
    <AnimatedModal
      open={open}
      handleClose={handleCloseModal}
      onClose={handleCloseModal}
      title={`${wheelName} Progress`}
      width="80%"
      minWidth="850px"
      height="80%"
      minHeight="500px"
    >
      {!chartData || !chartData.length ? (
        <StyledLoader />
      ) : (
        <ProgressChart chartData={chartData} />
      )}
    </AnimatedModal>
  );
};

export default withAuth(ProgressChartModal, []);
