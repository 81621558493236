import { Button, Fade, Menu } from "@material-ui/core";
import React from "react";

import ALOH from "helpers/areaLevelOptionsHelper";
import { QueryStringService as QSS } from "helpers/queryStringService";

import Select from "../Select/Select";
import { useEvidenceList } from "./context";
import { FilterHeader, FilterSelect } from "./styles";
import { FilterMenuProps } from "./types";

export const FilterMenu = (props: FilterMenuProps) => {
  const { mirrorLevelType, refresh, wheelData } = useEvidenceList();
  const [area, setArea] = React.useState("");
  const [level, setLevel] = React.useState("");

  const type = React.useMemo(
    () => (mirrorLevelType === "non-sequential" ? "Part" : "Level"),
    [mirrorLevelType]
  );

  const updateFilters = (newArea = "", newLevel = "") => {
    setArea(newArea);
    setLevel(newLevel);

    const { origin, pathname } = window.location;
    const query = QSS.getQueryStringFromObject({
      area: newArea,
      level: newLevel,
    });
    const url = `${origin}${pathname}${query}`;
    window.history.replaceState({}, window.document.title, url);
    refresh();
  };

  return (
    <Menu
      MenuListProps={{ style: { border: "0px" } }}
      PaperProps={{ style: { overflow: "hidden" } }}
      TransitionComponent={Fade}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      getContentAnchorEl={null}
      id="fade-menu"
      keepMounted
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      {...props}
    >
      <FilterHeader>
        <FilterHeader.Title variant="caption">Filters</FilterHeader.Title>
        <Button
          color="primary"
          onClick={() => updateFilters()}
          size="small"
          variant="text"
        >
          Clear filters
        </Button>
      </FilterHeader>

      <FilterSelect>
        <Select
          label="Choose Area"
          onChange={({ target: { value } }) => updateFilters(value as string)}
          options={ALOH.getAreaOptionsFromWheelData(wheelData) as any}
          value={area}
        />

        <Select
          disabled={!area}
          label={
            area ? `Choose ${type}` : `Pick an area to choose a ${type}....`
          }
          onChange={({ target: { value } }) =>
            updateFilters(area, value as string)
          }
          options={
            ALOH.getLevelsOptionsFromWheelDataByArea(
              wheelData,
              area,
              mirrorLevelType
            ) as any
          }
          value={level}
        />
      </FilterSelect>
    </Menu>
  );
};
