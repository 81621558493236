import React from "react";

import ProgressChartModal from "components/student/ProgressChartModal";

import { useDetailedWheel } from "./context";

export const ProgressChart = () => {
  const {
    loading,
    mirrorType,
    progressModalOpen,
    setProgressModalOpen,
    wheelId,
    wheelName,
  } = useDetailedWheel();
  return loading ? (
    <React.Fragment />
  ) : (
    <ProgressChartModal
      open={progressModalOpen}
      wheelId={wheelId}
      wheelName={wheelName}
      handleClose={() => setProgressModalOpen(false)}
      mirrorLevelType={mirrorType}
    />
  );
};
