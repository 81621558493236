import React from "react";

import { FeedbackInput } from "./input";
import { Conversation } from "./conversation";
import { useRouteMatch } from "react-router-dom";

export const Feedback = () => {
  const { params } = useRouteMatch<any>();
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        position: "relative",
        height: "calc(100% - 213px)",
      }}
    >
      <Conversation />
      {!params.token && <FeedbackInput />}
    </div>
  );
};
