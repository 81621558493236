/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { LinkRounded } from "@material-ui/icons";
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  Snackbar,
} from "@material-ui/core";
import React from "react";
import { Alert } from "reactstrap";

import { createToken } from "lib/token";

import { useWheelsPage } from "./context";
import { Button, TextArea } from "./styles";

export const TokenButton = () => {
  const { isUser, profile } = useWheelsPage();
  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState<any>();
  const [token, setToken] = React.useState<string>();

  const handleClick = () => {
    setLoading(true);
    createToken(profile._id!)
      .then(({ data }) => setToken(`${window.origin}/token/${data.token}`))
      .catch(({ message }) => setAlert({ message, severity: "error" }))
      .finally(() => setLoading(false));
  };

  if (!isUser) return null;

  React.useEffect(() => {
    if (!!token) {
      navigator.clipboard.writeText(token);
      if (!navigator.userAgent.match(/ipad|iphone/i)) {
        setAlert({ message: "Token copied to clipboard", severity: "success" });
      }
    }
  }, [token]);

  return (
    <>
      {token ? (
        <FormControl
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            width: "100%",
            maxWidth: 350,
          }}
        >
          <FormControlLabel
            control={<TextArea disabled value={token} />}
            label="Copy this link"
          />
        </FormControl>
      ) : (
        <Button
          color="primary"
          disabled={loading}
          onClick={handleClick}
          startIcon={loading ? <CircularProgress size={20} /> : <LinkRounded />}
          variant="contained"
        >
          Generate Link
        </Button>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={() => setAlert(undefined)}
        open={alert}
      >
        {alert && (
          <Alert
            color={alert.severity}
            isOpen={!!alert}
            closeClassName="uil uil-times"
            toggle={() => setAlert(undefined)}
          >
            {alert.message}
          </Alert>
        )}
      </Snackbar>
    </>
  );
};
