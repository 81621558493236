import styled from "styled-components";
import { Paper } from "@material-ui/core";
import palette from "palette";
import { Link, NavLink } from "react-router-dom";

export const S = {
  Paper: styled(Paper)`
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
  `,

  HeaderListContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,

  Icon: styled.i`
    font-size: 30px;
    line-height: 30px;
    margin: 1px 0 0;
  `,

  Divider: styled.div`
    width: 100%;
    height: 3px;
    border-radius: 15px;
    background-color: ${palette.disabled};
    margin: 15px 0 25px;
  `,

  LoaderContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    margin: 50px 0;
  `,

  GroupListContainer: styled.div`
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: auto;

    ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    ::-webkit-scrollbar {
      width: 7px;
      cursor: pointer;
      right: 5px;
    }
    ::-webkit-scrollbar-thumb {
      cursor: pointer !important;
      border-radius: 7px;
      background-color: ${palette.primary};
    }
  `,

  GroupLink: styled(NavLink)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f6fa;
    font-size: 16px;
    color: ${palette.font.heading};
    padding: 7px 10px;
    border-radius: 10px;
    margin: 0 5px 15px 0;
    transition: 0.3s;

    &:hover {
      color: ${palette.font.heading};
      background-color: ${palette.primaryLight};
      text-decoration: none;
    }
  `,

  ManageGroupLink: styled.button<{
    textColor: string;
    boldFont: boolean;
  }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f6fa;
    font-size: 16px;
    font-weight: ${(props) => (props.boldFont ? "bold" : "inherit")};
    color: ${(props) => props.textColor};
    text-align: left;
    padding: 7px 10px;
    border: 0px;
    border-radius: 10px;
    margin: 0 5px 15px 0;
    transition: 0.3s;

    &:hover {
      color: ${palette.font.heading};
      background-color: ${palette.primaryLight};
      text-decoration: none;
    }
  `,

  NoAvailableGroupsContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0;
  `,

  AddGroupLink: styled(Link)`
    background-color: ${palette.primary};
    color: #fff;
    padding: 3px 5px;
    border-radius: 10px;
    transition: 0.3s;

    &:hover {
      text-decoration: none;
      color: #fff;
      background-color: ${palette.primaryDark};
    }
  `,

  GroupName: styled.span`
    word-wrap: break-word;
  `,
};
