import { TypographyProps, Typography } from "@material-ui/core";
import React from "react";
import styled, { css } from "styled-components";
import { ILevel } from "typings/meta-mirror";
import { mediaRules } from "MuiTheme/Breakpoints";
import palette from "palette";

enum GradeColors {
  "disabled" = null,
  "attempting" = 0,
  "partial" = 0.5,
  "complete" = 1,
}

enum GradeIcons {
  "" = null,
  "uil uil-flask-potion" = 0,
  "uil uil-percentage" = 0.5,
  "uil uil-check" = 1,
}

const StyledNewGradeButton = styled.button<{
  grade: ILevel["grade"];
  selected: boolean;
  disabled: boolean;
}>`
  ${(props) => {
    const { grade, selected, disabled } = props;
    if (selected) {
      return css`
        background-color: ${grade === null
          ? `${palette.font.caption}dd`
          : palette[GradeColors[grade]]};
        color: ${palette.font.contrast} !important;
      `;
    }
    if (disabled) {
      return css`
        background-color: transparent;
        color: ${grade === null
          ? palette.font.body
          : palette[GradeColors[grade]]};
      `;
    }
    return css`
      background-color: transparent;
      color: ${grade === null
        ? palette.font.body
        : palette[GradeColors[grade]]};
      :hover {
        background-color: ${grade === null
          ? `${palette.font.body}33`
          : `${palette[GradeColors[grade]]}22`};
      }
    `;
  }}
  border: ${(props) =>
    props.grade === null
      ? `dashed 4px ${palette.font.body}`
      : `solid 4px ${palette[GradeColors[props.grade]]}`};
  transition: 0.7s;
  height: 55px;
  border-radius: 55px;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 7.5px;
  width: 100%;
  /* width: 49%;
  :nth-child(even) {
    margin-left: 2%;
  }
  @media ${mediaRules.xl} {
    width: 100%;
    :nth-child(even) {
      margin-left: 0;
    }
  } */
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
`;

interface IStyledNewGradeTextProps extends TypographyProps {
  grade: ILevel["grade"];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledNewGradeText = styled(
  ({ grade, ...props }: IStyledNewGradeTextProps) => <Typography {...props} />
)<{ grade: ILevel["grade"]; selected: boolean }>`
  ${(props) => {
    const { grade, selected } = props;
    if (selected) {
      return css`
        color: ${palette.font.contrast} !important;
      `;
    }
    return css`
      color: ${grade === null
        ? palette.font.body
        : palette[GradeColors[grade]]} !important;
    `;
  }}
  font-weight: 700 !important;
  margin: auto !important;
  padding-left: 20px !important;
  @media (max-width: 1500px) {
    padding-left: 30px !important;
  }
  @media (max-width: 1300px) {
    padding-left: 40px !important;
  }
  @media ${mediaRules.xl} {
    padding-left: 0px !important;
  }
`;

const StyledNewGradeIcon = styled.i<{ grade: ILevel["grade"] }>`
  border: ${(props) =>
    props.grade === null
      ? `dashed 4px ${palette.font.body}`
      : "solid 4px transparent"};
  position: absolute;
  left: -4px;
  top: -4px;
  min-width: 55px;
  min-height: 55px;
  max-height: 55px;
  max-width: 60px;
  font-size: 40px;
  line-height: 51px;
  text-align: center;
  border-radius: 60px;
  background-color: ${(props) =>
    props.grade === null ? "transparent" : palette[GradeColors[props.grade]]};
  color: ${palette.font.contrast};
`;

interface IProps {
  grade: ILevel["grade"];
  selected: boolean;
  onClick(): void;
  text: string;
  disabled: boolean;
}

const GradeButton: React.FC<IProps> = ({
  grade,
  selected,
  onClick,
  text,
  disabled,
}: IProps) => (
  <StyledNewGradeButton
    grade={grade}
    selected={selected}
    onClick={onClick}
    disabled={disabled}
  >
    <StyledNewGradeIcon grade={grade} className={GradeIcons[grade]} />
    <StyledNewGradeText variant="body1" grade={grade} selected={selected}>
      {text}
    </StyledNewGradeText>
  </StyledNewGradeButton>
);

export default GradeButton;
