import { axiosInstance } from "helpers/axios-instance";

export const updateFeedback = async (params: UpdateFeedbackParams) =>
  axiosInstance.put("/meta-mirror/feedback", params);

export type UpdateFeedbackParams = {
  feedbackId: string;
  comment?: string;
  viewed: boolean;
};
