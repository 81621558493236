import {
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import { IStudent } from "@rose-arch-holdings-ltd/common";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { S } from "components/teacher/GroupStudentList/styles";
import { getStudentGroupStatus } from "lib/group";
import palette from "palette";
import { IStudentListData } from "typings/student";

const StyledContainer = styled.div`
  border-radius: 10px !important;
  border: 3px solid ${palette.font.caption};
  overflow: auto;
`;

const StyledInfoContainer = styled.div`
  width: 100%;
  padding: 100px 0;
  text-align: center;
`;

const StyledNoDataContainer = styled.div`
  width: 100%;
  padding: 50px 0;
  text-align: center;
`;

interface IProps {
  isLoading: boolean;
  students: IStudentListData[];
  currentPage: number;
  pageSize: number;
  selectedStudents: string[];
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (pageSize: number) => void;
  onStudentSelect: (id: string | string[], state: boolean) => void;
  selectedGroup: string[];
  showLocked: boolean;
}

const StudentList: React.FC<IProps> = ({
  isLoading,
  students,
  currentPage,
  pageSize,
  selectedStudents,
  onChangePage,
  onChangeRowsPerPage,
  onStudentSelect,
  selectedGroup,
  showLocked,
}: IProps) => {
  const getPageData = (): IStudentListData[] => {
    return students.slice(
      currentPage * pageSize,
      currentPage * pageSize + pageSize
    );
  };

  const getSelectAllCheckboxState = (): "isAllSelected" | "isSomeSelected" => {
    const pageStudentIds: string[] =
      getPageData().map((student) => student._id) || [];

    if (!pageStudentIds.length) {
      return;
    }

    const isAllStudentsSelected = pageStudentIds.every((id) =>
      selectedStudents.includes(id)
    );
    const isSomeStudentsSelected = selectedStudents.some((id) =>
      pageStudentIds.includes(id)
    );

    if (isAllStudentsSelected) {
      return "isAllSelected";
    }

    if (isSomeStudentsSelected) {
      return "isSomeSelected";
    }
  };

  const handleAllStudentSelect = (): void => {
    const selectionState = getSelectAllCheckboxState();
    const pageStudentIds: string[] =
      getPageData().map((student) => student._id) || [];

    if (selectionState === "isAllSelected") {
      onStudentSelect(pageStudentIds, false);
      return;
    }

    if (selectionState === "isSomeSelected") {
      const preparedStudentIds = pageStudentIds.filter(
        (id) => !selectedStudents.includes(id)
      );

      onStudentSelect(preparedStudentIds, true);
      return;
    }

    onStudentSelect(pageStudentIds, true);
  };

  return (
    <StyledContainer>
      {isLoading ? (
        <StyledInfoContainer>
          <CircularProgress />
          <Typography variant="body2" className="mt-3">
            Loading...
          </Typography>
        </StyledInfoContainer>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Checkbox
                  color="default"
                  onChange={() => handleAllStudentSelect()}
                  checked={getSelectAllCheckboxState() === "isAllSelected"}
                  indeterminate={
                    getSelectAllCheckboxState() === "isSomeSelected"
                  }
                  disabled={!students.length}
                />
              </TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Student Groups</TableCell>
              <TableCell align="center">Year Group</TableCell>
              <TableCell align="center">Other</TableCell>
              {selectedGroup[0] && (
                <TableCell align="center">Group Status</TableCell>
              )}
              <TableCell align="center">Last Login</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!students && students.length ? (
              <>
                {getPageData().map((student, studentIndex) => {
                  const groupStatus = getStudentGroupStatus(
                    student as unknown as IStudent,
                    selectedGroup[0]
                  );

                  if (groupStatus === "locked" && !showLocked) return false;

                  return (
                    <TableRow key={`student-${studentIndex}`}>
                      <TableCell align="center">
                        <Checkbox
                          color="default"
                          checked={selectedStudents.includes(student._id)}
                          onChange={(event) =>
                            onStudentSelect(student._id, event.target.checked)
                          }
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Link to={`/teacher/student/${student._id}`}>
                          {student.name}
                        </Link>
                      </TableCell>
                      <TableCell align="center">
                        {student.groups.join(", ")}
                      </TableCell>
                      <TableCell align="center">{student.yearGroup}</TableCell>
                      <TableCell align="center">{student.other}</TableCell>
                      {selectedGroup[0] && (
                        <TableCell align="center">
                          {groupStatus === "added" ? (
                            <S.Icon
                              className="uil uil-user-check"
                              style={{ color: "green" }}
                            />
                          ) : groupStatus === "locked" ? (
                            <S.Icon className="uil uil-padlock" />
                          ) : (
                            ""
                          )}
                        </TableCell>
                      )}
                      <TableCell align="center">
                        {student.lastLogin
                          ? moment(student.lastLogin).format("DD/MM/YYYY")
                          : ""}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={5}>
                  <StyledNoDataContainer>No data found</StyledNoDataContainer>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={students.length}
                rowsPerPage={pageSize}
                page={currentPage}
                onChangePage={(event, page) => onChangePage(page)}
                onChangeRowsPerPage={(event) =>
                  onChangeRowsPerPage(parseInt(event.target.value, 10))
                }
              />
            </TableRow>
          </TableFooter>
        </Table>
      )}
    </StyledContainer>
  );
};

export default StudentList;
