import { Typography } from "@material-ui/core";
import React from "react";

import { useEvidenceList } from "./context";
import { Evidence } from "./evidence";
import { List } from "./styles";
import { IEvidenceWithFeedback } from "typings/student";
import { EmptyFilterMessages } from "./constants";

export const Scroller = () => {
  const { evidence, variant } = useEvidenceList();

  const getOrder = (item: IEvidenceWithFeedback) => {
    if (variant === "teacher" && !item.viewed) return 0;
    if (variant === "student" && item.feedback?.viewed === false) return 1;
    if (item.messages?.some(({ viewed }) => !viewed)) return 2;
    return 3;
  };
  return (
    <List.Scroller>
      {evidence.length > 0 ? (
        evidence
          .map((item) => ({
            ...item,
            order: getOrder(item),
          }))
          .sort((a, b) => {
            if (a.order < b.order) return -1;
            if (a.order > b.order) return 1;
            return new Date(a.createdAt).getTime() >
              new Date(b.createdAt).getTime()
              ? -1
              : 1;
          })
          .map(({ order, ...rest }) => (
            <Evidence key={rest._id} evidence={rest} />
          ))
      ) : (
        <Typography variant="caption">
          {EmptyFilterMessages[variant]}
        </Typography>
      )}
    </List.Scroller>
  );
};
