/* eslint-disable @typescript-eslint/indent */
import { CircularProgress, Typography } from "@material-ui/core";
import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import {
  ILevel,
  TWheelData,
  TWheelValidEvidenceFileType,
} from "typings/meta-mirror";
import Select, { TOption } from "components/common/Select/Select";
import { mediaRules } from "MuiTheme/Breakpoints";
import palette from "palette";
import Compress from "browser-image-compression";
import {
  VALID_FILETYPES,
  MAX_FILE_SIZE,
} from "helpers/metaMirrorEvidenceValidation";
import { getStudentCurrentGradeByArea } from "lib/student";
import { IWithAuth, withAuth } from "../../../hoc/withAuth";
import { LEVEL_PARTS } from "constants/index";

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  width: 95%;
  padding: 20px 0 15px 0;
  text-align: center !important;
  @media ${mediaRules.sm} {
    width: 100%;
  }
`;

const StyledInputContainer = styled.div`
  padding: 0 10%;
  margin: 5px 0;
  @media ${mediaRules.sm} {
    padding: 0;
    margin: 15px 0;
  }
`;

const StyledSelectContainer = styled(StyledInputContainer)`
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledErrorMessage = styled(Typography)`
  color: ${palette.error} !important;
  min-height: 30px;
  @media (max-width: 959px) {
    min-height: 45px;
  }
  @media ${mediaRules.sm} {
    min-height: 60px;
  }
`;

const StyledInput = styled.input`
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const StyledLabel = styled.label<{ fileUploaded: boolean }>`
  transition: 0.5s;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  max-width: 100%;
  height: 50px;
  color: ${(props) =>
    !!props.fileUploaded ? palette.font.contrast : palette.secondary};
  background-color: ${(props) =>
    !!props.fileUploaded ? palette.secondary : palette.font.contrast};
  border: 1px solid ${palette.secondary};
  border-width: 3px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background-color: ${(props) =>
      !!props.fileUploaded ? palette.secondaryDark : palette.secondaryLight};
  }
`;

const StyledIcon = styled.i`
  font-size: 22px;
  margin: 0 5px 0 0;
`;

const StyledCircularProgress = styled(CircularProgress)`
  height: 25px !important;
  width: 25px !important;
  margin: 0 10px 0 0 !important;
`;

interface IProps extends IWithAuth {
  error: string;
  requesting: boolean;
  selectedAreaId: string;
  selectedLevelId: string;
  wheelAreas: { _id: string; name: string; disabled: boolean }[];
  wheelData: TWheelData;
  file: string;
  handleSetEvidenceData(
    file: string,
    fileName: string,
    fileType?: string
  ): void;
  handleSelectArea(selectedAreaId: string): void;
  handleSelectLevel(selectedAreaId: string): void;
  handleSetError(error: string): void;
  mirrorLevelType: string;
}

const SubmitFile: React.FC<IProps> = ({
  error,
  requesting,
  selectedAreaId,
  selectedLevelId,
  wheelAreas,
  wheelData,
  file,
  handleSetEvidenceData,
  handleSelectArea,
  handleSelectLevel,
  handleSetError,
  user,
  mirrorLevelType,
}: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [levelsLoading, setLevelsLoading] = useState(true);
  const [fileName, setFileName] = useState("");
  const [levelOptions, setLevelOptions] = useState<TOption[]>([]);

  const getLabel = (level: ILevel) =>
    level.name
      ? level.name
      : mirrorLevelType === "non-sequential"
      ? `Part ${LEVEL_PARTS[level.level - 1]}`
      : `Level ${level.level}`;

  const getLevels = (
    studentLevelId: string,
    teacherLevelId: string
  ): TOption[] => {
    const wheelAreaLevels =
      wheelData?.find(({ _id }) => _id === selectedAreaId)?.levels || [];
    const levels = wheelAreaLevels.sort((a, b) => a.level - b.level);
    const currentLevelStudent = levels.find(
      (level) => level._id === studentLevelId
    );
    const currentLevelTeacher = levels.find(
      (level) => level._id === teacherLevelId
    );
    let levelDisabled = !!studentLevelId && !!teacherLevelId;
    const levelOptionsArr: TOption[] = [];
    const studentTeacherLevelMatch =
      currentLevelStudent?.level === currentLevelTeacher?.level;
    const gradeComplete = currentLevelTeacher?.grade === 1;
    levels.forEach((level) => {
      levelDisabled =
        level.level < currentLevelStudent?.level ||
        (level.level > currentLevelStudent?.level &&
          !studentTeacherLevelMatch) ||
        (level.level > currentLevelStudent?.level && !gradeComplete);
      levelOptionsArr.push({
        label: getLabel(level),
        value: level._id,
        disabled: levelDisabled && mirrorLevelType === "sequential",
      });
    });

    const enabledOptions = levelOptionsArr.filter(
      (option) => option.disabled === false
    );
    if (
      enabledOptions.length >= 1 &&
      (selectedLevelId === "" ||
        !enabledOptions.find((option) => option.value === selectedLevelId))
    ) {
      handleSelectLevel(enabledOptions[0].value as string);
    }
    return levelOptionsArr;
  };

  useEffect(() => {
    if (selectedAreaId) {
      (async () => {
        setLevelsLoading(true);

        const { data } = await getStudentCurrentGradeByArea(
          selectedAreaId,
          user?.roles?.student?.id._id
        );

        const wheelAreaLevels = wheelData.find(
          ({ _id }) => _id === selectedAreaId
        ).levels;
        let teacherLevelId = -1;
        let studentLevelId = 0;
        if (data.data.teacherLevelId) {
          teacherLevelId = data.data.teacherLevelId;
        }
        if (data.data.studentLevelId) {
          studentLevelId = data.data.studentLevelId;
        }
        handleSelectLevel(studentLevelId);
        setLevelOptions(getLevels(studentLevelId, teacherLevelId));
        setLevelsLoading(false);
      })();
    }
  }, [selectedAreaId]);

  const handleUploadFile = async (
    event: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    setIsLoading(true);
    const { files } = event.target;

    if (files && files[0]) {
      const reader = new FileReader();
      const uploadFile = files[0];

      const { name, size } = uploadFile;

      let uploadFileType = name.split(/(?=\.)/).pop() || "";
      uploadFileType = uploadFileType.toLowerCase();

      const isUploadFileTypeValid =
        VALID_FILETYPES.indexOf(
          uploadFileType as TWheelValidEvidenceFileType
        ) >= 0;

      if (!isUploadFileTypeValid) {
        handleSetError(
          "Incorrect file format. Please select a file with one of the above types."
        );
        return;
      }

      if (size > MAX_FILE_SIZE) {
        handleSetError(
          "That file is too large. Please upload a file < 10MB in size."
        );
        return;
      }

      let preparedFile: File | Blob = uploadFile;

      if ([".jpg", ".png", ".jpeg"].includes(uploadFileType)) {
        const options = {
          useWebWorker: true,
          initialQuality: 0.5,
        };

        const compressedFile = await Compress(uploadFile, options);

        preparedFile = compressedFile;
      }

      reader.readAsDataURL(preparedFile);

      reader.onload = () => {
        setIsLoading(false);
        handleSetEvidenceData(reader.result as string, name, uploadFileType);
        setFileName(name.toLowerCase());
        handleSetError("");
        return;
      };

      reader.onerror = () => {
        setIsLoading(false);
        handleSetEvidenceData("", "", "file");
        handleSetError(
          "Something went wrong when uploading your file.\nPlease try again."
        );
        setFileName("");
      };
    }
  };

  return (
    <StyledContainer>
      <Typography variant="body1">
        Choose a wheel area, level, and select a file.
        <br />
        Then press submit to add your evidence.
      </Typography>
      <StyledSelectContainer>
        <Select
          options={wheelAreas.map((area) => ({
            label: area.name,
            value: area._id,
            disabled: area.disabled,
          }))}
          onChange={(e) => handleSelectArea(e.target.value as string)}
          className="text-left"
          fullWidth
          value={selectedAreaId}
          label="Area"
          disabled={requesting}
        />
        <Select
          options={levelOptions}
          value={selectedLevelId}
          onChange={(e) => handleSelectLevel(e.target.value as string)}
          className="text-left"
          fullWidth
          label={mirrorLevelType === "non-sequential" ? "Part" : "Level"}
          disabled={requesting || levelsLoading || !selectedLevelId}
        />
      </StyledSelectContainer>
      <Typography variant="body1">
        You can submit a link from any of the following formats:
        <br />
        <b>{VALID_FILETYPES.join(", ")}</b>
      </Typography>
      <StyledInputContainer>
        <StyledInput
          id="file-input"
          type="file"
          accept={VALID_FILETYPES.join(", ")}
          disabled={isLoading}
          onChange={(event) => handleUploadFile(event)}
        />
        <StyledLabel htmlFor="file-input" fileUploaded={!!file}>
          {file ? (
            <>
              <StyledIcon className="uil uil-file-check" />
              {fileName}
            </>
          ) : (
            <>
              {isLoading ? (
                <>
                  <StyledCircularProgress color="secondary" />
                  loading...
                </>
              ) : (
                <>
                  <StyledIcon className="uil uil-file-upload" />
                  choose file
                </>
              )}
            </>
          )}
        </StyledLabel>
      </StyledInputContainer>
      <StyledErrorMessage>{error}</StyledErrorMessage>
    </StyledContainer>
  );
};

export default withAuth(SubmitFile, []);
