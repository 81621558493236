import AnimatedModal from "components/common/AnimatedModal";
import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import { S } from "./styles";
import palette from "palette";
import { IStudent } from "@rose-arch-holdings-ltd/common";

interface IProps {
  isOpen: boolean;
  handleCloseModal: () => void;
  groupName: string;
  studentNames: string[];
  addToGroup: () => void;
  submitted: boolean;
  error: boolean;
  schoolStudents: IStudent[];
}

export const AddStudentToGroupModel: React.FC<IProps> = ({
  isOpen,
  handleCloseModal,
  error,
  addToGroup,
  submitted,
  studentNames,
  groupName,
  schoolStudents,
}) => {
  return (
    <AnimatedModal
      open={isOpen}
      title="Add to Group"
      handleClose={() => handleCloseModal()}
      maxWidth="625px"
      minHeight="200px"
      width="100%"
    >
      <>
        <Typography align={"center"} variant="body2">
          The following students will be added to <strong>{groupName}</strong>:
          <br />
          {studentNames
            .map((s) => {
              const student = schoolStudents.find(
                (v) => v._id.toString() === s
              );
              return student?.name;
            })
            .join(", ")}
          <br />
          <br />
          These students will gain access to any wheels that{" "}
          <strong>{groupName}</strong> has access to.
        </Typography>
        <Typography
          style={{
            color: !error ? palette.complete : palette.error,
            marginBottom: "20px",
            textAlign: "center",
          }}
          variant="body2"
        >
          {error && "Error adding group"}
          {submitted && !error && "New group added"}
        </Typography>
        <S.ActionContainer>
          <S.Button
            variant="outlined"
            color="primary"
            onClick={() => handleCloseModal()}
          >
            cancel
          </S.Button>
          <S.Button
            variant="contained"
            color="primary"
            disabled={submitted}
            onClick={() => addToGroup()}
          >
            add to group
          </S.Button>
        </S.ActionContainer>
      </>
    </AnimatedModal>
  );
};
