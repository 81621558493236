import { Theme } from '@material-ui/core';

type ThemeInterface = Exclude<Theme['overrides'], undefined>;

const MuiInputLabel: ThemeInterface['MuiInputLabel'] = {
  formControl: {
    transform: 'translate(12px, 12px) scale(1)',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(15px, -7px) scale(0.75)',
    },
  },
};

export default MuiInputLabel;
