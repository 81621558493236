import { Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import palette from "palette";
import { TEvidencePartialData } from "typings/student";
import EvidenceFile from "./EvidenceFile";

const StyledItemBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledBoxTitle = styled(Typography)`
  font-weight: 700 !important;
  margin: 0 0 10px !important;
`;

const StyledItemsContainer = styled.div`
  padding-right: 5px;
  overflow: auto;
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 7px;
    background-color: ${palette.font.caption};
  }
`;

enum EmptyMessages {
  "admin" = "No evidence has been submitted by this student on this wheel.",
  "student" = "You haven't submitted any evidence for this wheel yet.",
  "teacher" = "No evidence has been submitted yet.",
}

interface IProps {
  evidence: TEvidencePartialData;
  variant: "admin" | "student" | "teacher";
  handleReviewEvidence?(evidenceId: string, evidenceAreaId: string): void;
}

const EvidenceList: React.FC<IProps> = ({
  evidence,
  variant,
  handleReviewEvidence,
}: IProps) => (
  <StyledItemBox>
    <StyledBoxTitle variant="body1">Evidence</StyledBoxTitle>
    <StyledItemsContainer>
      {!!evidence.length ? (
        evidence.map((singleEvidence) => (
          <EvidenceFile
            key={singleEvidence._id}
            date={new Date(singleEvidence.createdAt).toLocaleDateString()}
            evidenceId={singleEvidence._id}
            evidenceName={singleEvidence.evidenceName}
            evidenceType={singleEvidence.fileType}
            unseen={!singleEvidence.viewed}
            variant={variant}
            handleReviewEvidence={() =>
              handleReviewEvidence(singleEvidence._id, singleEvidence.areaId)
            }
          />
        ))
      ) : (
        <Typography variant="caption">{EmptyMessages[variant]}</Typography>
      )}
    </StyledItemsContainer>
  </StyledItemBox>
);

export default EvidenceList;
