import React, { Component } from "react";
import styled from "styled-components";
import {
  IconButton,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import palette from "palette";
import { TabPaneProps } from "reactstrap";
import WheelView from "./WheelView";
import TableView from "./TableView";
import {
  IWheelsPageContextProps,
  WheelsPageContext,
} from "../../states/WheelsPageContext";
import { RouteComponentProps } from "react-router";
import ProgressChartModal from "./ProgressChartModal";
import GenerateReportModal from "./GenerateReport/GenerateReportModal";

const StyledPaper = styled(Paper)`
  width: 100%;
  min-height: 100%;
  margin: 0 0 30px;
  padding: 20px;
`;

const StyledDivider = styled.div`
  width: 100%;
  height: 3px;
  border-radius: 15px;
  background-color: ${palette.disabled};
  margin-top: 15px;
`;

const StyledIcon = styled.i`
  font-size: 30px;
  line-height: 30px;
  margin: 1px 0 0;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 205px;
`;

const StyledTabContainer = styled(Tabs)`
  border-bottom: 1px solid #e1e8f0;
`;

const StyledTab = styled(Tab)`
  font-size: 14px !important;
  text-transform: capitalize !important;
`;

const StyledTooltipText = styled.div`
  text-align: center;
  margin: 0;
`;

interface IProps {
  wheelId: string;
}

interface IState {
  selectedTab: number;
  isGenerateReportModalOpen: boolean;
  isProgressModalOpen: boolean;
}

class SelectedWheelWrapper extends Component<
  RouteComponentProps<IProps>,
  IState
> {
  public context: IWheelsPageContextProps;

  public state = {
    selectedTab: 0,
    isGenerateReportModalOpen: false,
    isProgressModalOpen: false,
  };

  public componentDidMount() {
    this.getWheelData();
  }

  public componentDidUpdate(prevProps: RouteComponentProps<IProps>) {
    const { wheelId } = this.props.match.params;
    const { wheelId: prevWheelId } = prevProps.match.params;

    if (wheelId !== prevWheelId) {
      this.getWheelData();
    }
  }

  protected getWheelData = (): void => {
    const { wheelId } = this.props.match.params;

    this.context.handleGetSelectedWheelData(wheelId);
    this.context.handleSelectGroup("all-students");
  };

  protected getTabAttributes = (number: number): { [key: string]: string } => {
    return {
      id: `tab-${number}`,
      "aria-controls": `tab-panel-${number}`,
    };
  };

  protected setActiveTab = (selectedTab: number): void => {
    this.setState({
      selectedTab,
    });
  };

  protected toggleProgressModalState = (isOpen: boolean): void => {
    this.setState({
      isProgressModalOpen: isOpen,
    });
  };

  protected toggleGenerateReportModalState = (isOpen: boolean): void => {
    this.setState({
      isGenerateReportModalOpen: isOpen,
    });
  };

  protected TabPanel = (props: TabPaneProps): JSX.Element => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tab-${index}`}
        aria-labelledby={`tab-panel-${index}`}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  };

  render() {
    const { selectedTab, isGenerateReportModalOpen, isProgressModalOpen } =
      this.state;

    const { wheelId } = this.props.match.params;

    return (
      <WheelsPageContext.Consumer>
        {({
          wheelResults,
          handleGenerateCSVFile,
          handleTableViewFilterSelect,
          groups,
        }) => (
          <>
            {wheelResults &&
              handleGenerateCSVFile &&
              handleTableViewFilterSelect &&
              groups && (
                <StyledPaper>
                  <StyledHeader>
                    <Typography variant="h6">
                      {!!wheelResults && wheelResults.name}
                    </Typography>
                    <StyledActionsContainer>
                      <Tooltip title="Download CSV">
                        <IconButton
                          color="primary"
                          onClick={() =>
                            handleGenerateCSVFile(
                              selectedTab ? "table-view" : "wheel-view"
                            )
                          }
                        >
                          <StyledIcon className="uil uil-arrow-circle-down" />
                        </IconButton>
                      </Tooltip>
                      {/*{wheelResults.levelType !== "non-sequential" && (*/}
                      <>
                        <Tooltip
                          title={
                            !!wheelResults &&
                            !wheelResults.studentsResults.length ? (
                              <StyledTooltipText>
                                Generate Report
                                <br />
                                <em>Unavailable: No students in groups</em>
                              </StyledTooltipText>
                            ) : (
                              "Generate Report"
                            )
                          }
                        >
                          <span>
                            <IconButton
                              color="primary"
                              onClick={() =>
                                this.toggleGenerateReportModalState(true)
                              }
                              disabled={
                                !!wheelResults &&
                                !wheelResults.studentsResults.length
                              }
                            >
                              <StyledIcon className="uil uil-file-graph" />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip
                          title={
                            !!wheelResults &&
                            !wheelResults.studentsResults.length ? (
                              <StyledTooltipText>
                                Compare Wheels
                                <br />
                                <em>Unavailable: No students in groups</em>
                              </StyledTooltipText>
                            ) : (
                              "Compare Wheels"
                            )
                          }
                        >
                          <span>
                            <IconButton
                              color="primary"
                              onClick={() =>
                                this.props.history.push(
                                  `/teacher/my-wheels/${wheelId}/compare`
                                )
                              }
                              disabled={
                                !!wheelResults &&
                                !wheelResults.studentsResults.length
                              }
                            >
                              <StyledIcon className="uil uil-compress-lines" />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip
                          title={
                            !!wheelResults &&
                            !wheelResults.studentsResults.length ? (
                              <StyledTooltipText>
                                Progress Chart
                                <br />
                                <em>Unavailable: No students in groups</em>
                              </StyledTooltipText>
                            ) : (
                              "Progress Chart"
                            )
                          }
                        >
                          <span>
                            <IconButton
                              color="primary"
                              onClick={() =>
                                this.toggleProgressModalState(true)
                              }
                              disabled={
                                !!wheelResults &&
                                !wheelResults.studentsResults.length
                              }
                            >
                              <StyledIcon className="uil uil-chart-line" />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </>
                      {/*)}*/}
                    </StyledActionsContainer>
                  </StyledHeader>
                  <StyledDivider />
                  <>
                    <StyledTabContainer
                      value={selectedTab}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                      onChange={(event, newValue) =>
                        this.setActiveTab(newValue)
                      }
                    >
                      <StyledTab
                        label="Wheel View"
                        {...this.getTabAttributes(0)}
                      />
                      <StyledTab
                        label="Table View"
                        {...this.getTabAttributes(1)}
                        onClick={() => {
                          if (selectedTab !== 1) {
                            handleTableViewFilterSelect({
                              area: "",
                              yearGroups: [],
                              studentGroups: [],
                              sex: [],
                              lastLogin: 0,
                            });
                          }
                        }}
                      />
                    </StyledTabContainer>
                    <this.TabPanel value={selectedTab} index={0}>
                      <WheelView
                        wheelId={wheelId}
                        levelType={wheelResults.levelType}
                      />
                    </this.TabPanel>
                    <this.TabPanel value={selectedTab} index={1}>
                      <TableView />
                    </this.TabPanel>
                  </>
                  <ProgressChartModal
                    wheelLevelType={wheelResults.levelType}
                    open={isProgressModalOpen}
                    handleClose={() => this.toggleProgressModalState(false)}
                    wheelId={wheelId}
                    wheelName={!!wheelResults ? wheelResults.name : ""}
                  />
                  <GenerateReportModal
                    wheelLevelType={wheelResults.levelType}
                    isOpen={isGenerateReportModalOpen}
                    wheelId={wheelId}
                    wheelCreateDate={wheelResults?.createdAt || ""}
                    wheelGroups={groups
                      .filter((group) =>
                        wheelResults?.groupIds.includes(group._id)
                      )
                      .map(({ _id, name }) => ({ label: name, value: _id }))}
                    onClose={() => this.toggleGenerateReportModalState(false)}
                    isBulk={true}
                  />
                </StyledPaper>
              )}
          </>
        )}
      </WheelsPageContext.Consumer>
    );
  }
}

SelectedWheelWrapper.contextType = WheelsPageContext;

export default SelectedWheelWrapper;
