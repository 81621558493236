import React from "react";

import { P } from "./styles";
import { PreviewComponentProps } from "./types";

export const Link = ({ evidence }: PreviewComponentProps) => {
  const source = React.useMemo(() => evidence?.evidence.evidence, [evidence]);

  const link = React.useMemo(
    () => (source?.startsWith("http") ? source : `https://${source}`),
    [source]
  );

  return (
    <P border>
      <P.LinkIcon className="uil uil-link" />
      <P.Description variant="body2">
        {evidence?.name} submitted a link as their evidence.
      </P.Description>
      <P.LinkButton
        variant="outlined"
        color="secondary"
        onClick={() => window.open(link, "_blank")}
      >
        visit link
      </P.LinkButton>
      <P.Description variant="caption">
        The submitted link can be found below:
        <br />
        <P.Link>{source}</P.Link>
      </P.Description>
    </P>
  );
};
