import { axiosInstance } from "helpers/axios-instance";
import { IStudent } from "typings/models";

// export const getSchool = async () => axiosInstance.get("/schools");

export const getSchoolsWithClasses = async () =>
  axiosInstance.get(`/meta-mirror/schools-with-groups`);

export const getSchoolsList = async (searchName: string) =>
  axiosInstance.get(`/meta-mirror/schools?name=${searchName}`);

export const addSchool = async (name: string) =>
  axiosInstance.post(`/schools`, {
    name,
    collectionType: "mm",
  });

export const getDetailedSchool = async (schoolId: string) => {
  return axiosInstance.get(`/meta-mirror/school/${schoolId}`);
};

export const updateSchool = async (
  schoolId: string,
  schoolName: string,
  wheelsLimit: number,
  mirrorLevelsType = "sequential"
) => {
  return axiosInstance.put(`/schools`, {
    id: schoolId,
    name: schoolName,
    wheelsLimit,
    collectionType: "mm",
    mirrorLevelsType,
  });
};
