import React, { PropsWithChildren, useContext, useRef, useState } from "react";
import styled from "styled-components";
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import palette from "palette";
import { RouteComponentProps } from "react-router";
import ReactToPrint from "react-to-print";
import ComparisonWheel from "./ComparisonWheel";
import { WheelsPageContext } from "../../states/WheelsPageContext";
import { TWheelData } from "typings/meta-mirror";
import PrintComparison, {
  IWheelPrintData,
} from "./PrintComparison/PrintComparison";

const StyledPaper = styled(Paper)`
  width: 100%;
  min-height: 100%;
  padding: 20px;
  margin: 0 0 30px;
`;

const StyledDivider = styled.div`
  width: 100%;
  height: 3px;
  border-radius: 15px;
  background-color: ${palette.disabled};
  margin-top: 15px;
`;

const StyledIcon = styled.i`
  font-size: 20pt;
`;

const StyledCardTitle = styled(Typography)`
  text-align: center !important;
`;

const SelectedWheelCompareWrapper: React.FC<
  RouteComponentProps<{ wheelId: string }>
> = ({
  match,
  history,
}: PropsWithChildren<RouteComponentProps<{ wheelId: string }>>) => {
  const printRef = useRef<HTMLDivElement>(null);

  const { wheelResults } = useContext(WheelsPageContext);

  const [wheel3Active, setWheel3Active] = useState(false);
  const [wheel4Active, setWheel4Active] = useState(false);

  const [wheel1PrintData, setWheel1PrintData] = useState<IWheelPrintData>();
  const [wheel2PrintData, setWheel2PrintData] = useState<IWheelPrintData>();
  const [wheel3PrintData, setWheel3PrintData] = useState<IWheelPrintData>();
  const [wheel4PrintData, setWheel4PrintData] = useState<IWheelPrintData>();

  return (
    <StyledPaper>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={4}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() =>
              history.push(`/teacher/my-wheels/${match.params.wheelId}`)
            }
          >
            back
          </Button>
        </Grid>
        <Grid item xs={4}>
          <StyledCardTitle variant="h6">
            Compare: {!!wheelResults && wheelResults.name}
          </StyledCardTitle>
        </Grid>
        <Grid item xs={4} className="d-flex flex-row justify-content-end">
          <ReactToPrint
            trigger={() => (
              <Tooltip title="Print Comparison">
                <IconButton color="primary" className="ml-3">
                  <StyledIcon className="uil uil-print" />
                </IconButton>
              </Tooltip>
            )}
            content={() => printRef.current}
          />
        </Grid>
      </Grid>
      <StyledDivider />
      <Grid
        container
        direction="row"
        alignItems="stretch"
        wrap="wrap"
        className="flex-grow-1 h-100"
      >
        <Grid item xs={12} xl={6} className="h-50">
          <ComparisonWheel
            active
            handleSetPrintData={(
              wheelData: TWheelData,
              selectedGroup: string,
              selectedDate: string
            ) => setWheel1PrintData({ wheelData, selectedDate, selectedGroup })}
            wheelId={match.params.wheelId}
            levelType={wheelResults?.levelType}
          />
        </Grid>
        <Grid item xs={12} xl={6} className="h-50">
          <ComparisonWheel
            active
            handleSetPrintData={(
              wheelData: TWheelData,
              selectedGroup: string,
              selectedDate: string
            ) => setWheel2PrintData({ wheelData, selectedDate, selectedGroup })}
            wheelId={match.params.wheelId}
            levelType={wheelResults?.levelType}
          />
        </Grid>
        <Grid item xs={12} xl={6} className="h-50">
          <ComparisonWheel
            active={wheel3Active}
            handleActivate={() => setWheel3Active(true)}
            handleSetPrintData={(
              wheelData: TWheelData,
              selectedGroup: string,
              selectedDate: string
            ) => setWheel3PrintData({ wheelData, selectedDate, selectedGroup })}
            wheelId={match.params.wheelId}
            levelType={wheelResults?.levelType}
          />
        </Grid>
        <Grid item xs={12} xl={6} className="h-50">
          <ComparisonWheel
            active={wheel4Active}
            disabled={!wheel3Active}
            handleActivate={() => setWheel4Active(true)}
            handleSetPrintData={(
              wheelData: TWheelData,
              selectedGroup: string,
              selectedDate: string
            ) => setWheel4PrintData({ wheelData, selectedDate, selectedGroup })}
            wheelId={match.params.wheelId}
            levelType={wheelResults?.levelType}
          />
        </Grid>
      </Grid>
      <PrintComparison
        printRef={printRef}
        wheelName={wheelResults?.name || ""}
        wheelsActive={[true, true, wheel3Active, wheel4Active]}
        wheelsPrintData={[
          wheel1PrintData,
          wheel2PrintData,
          wheel3PrintData,
          wheel4PrintData,
        ]}
      />
    </StyledPaper>
  );
};

export default SelectedWheelCompareWrapper;
