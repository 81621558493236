import { axiosInstance } from "helpers/axios-instance";
import { IStudent } from "typings/models";

export const getStudents = async (query = "") =>
  axiosInstance.get(`/students/all${query}`);

export const getStudentList = async (queryString?: string) =>
  axiosInstance.get(`/meta-mirror/students${!!queryString ? queryString : ""}`);

export const getDetailedStudent = async (studentId: string) => {
  return axiosInstance.get(`/meta-mirror/student/${studentId}`);
};

export const getStudent = async (studentId: string) => {
  return axiosInstance.get(`/students/?id=${studentId}`);
};

export const updateStudent = async (
  id: string,
  mmSchoolId: string,
  mmGroupIds: string[],
  mmLockedGroupIds?: string[]
) => {
  return axiosInstance.put(`/students`, {
    id,
    mmSchoolId,
    mmGroupIds,
    mmLockedGroupIds,
  });
};

export const hasAccess = (student: IStudent, application: string) => {
  return student?.user?.id?.applicationsAccess.includes(application);
};

export const getStudentCurrentGradeByArea = async (
  areaId: string,
  studentId: string,
  levelId?: string
) => {
  const levelParam = levelId ? `&levelId=${levelId}` : "";

  return axiosInstance.get(
    `/meta-mirror/student/current-grades?studentId=${studentId}&areaId=${areaId}${levelParam}`
  );
};

export const uploadStudents = async (
  file: string,
  teacherId: string,
  schoolId: string
) => {
  return axiosInstance.post(`/csv/student/upload-students`, {
    file,
    teacherId,
    schoolId,
  });
};

export const updateManyStudents = async (
  studentIds: string[],
  yearGroup: string
) => {
  return axiosInstance.post(`/students/update/many`, {
    studentIds,
    yearGroup,
  });
};
