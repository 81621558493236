import { Typography } from "@material-ui/core";
import { upperFirst } from "lodash";
import React from "react";

import GradeIcon from "components/common/GradeIcon";
import { LEVEL_PARTS } from "constants/index";

import { useFeedback } from "./context";
import { Grades } from "./grades";
import { Actions } from "./styles";
import { Update } from "./update";

export const Status = () => {
  const { evidenceObject, isTeacher, wheelLevelType } = useFeedback();

  const Grade = ({ grade, ...rest }: any) => (
    <GradeIcon
      text={
        wheelLevelType === "sequential"
          ? `Level ${grade.level}`
          : `Part ${LEVEL_PARTS[Number(grade.level) - 1]}`
      }
      grade={grade.result}
      small
      {...rest}
    />
  );

  return (
    <>
      <Actions.Row>
        {["student", "teacher"].map((type) => (
          <Actions.InfoText key={type} variant="caption">
            {upperFirst(type)} Grade:
            <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
              {(evidenceObject as any)?.[`${type}Grade`] ? (
                <Grade
                  grade={(evidenceObject as any)[`${type}Grade`]}
                  id={`${type}-grade`}
                />
              ) : (
                <Typography variant="caption">No grade available.</Typography>
              )}
            </div>
          </Actions.InfoText>
        ))}
      </Actions.Row>
      {isTeacher && (
        <>
          <Actions.StatusLabel variant="caption">
            New Status
          </Actions.StatusLabel>
          <Grades />
        </>
      )}
      <Actions.Row style={{ alignItems: "flex-end", marginTop: 10 }}>
        <Update />
      </Actions.Row>
    </>
  );
};
