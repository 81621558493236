import { Button } from "@material-ui/core";
import { CancelRounded, SaveRounded } from "@material-ui/icons";
import React from "react";

import RichTextEditor from "components/teacher/RichTextEditor";

import { useProfile } from "../context";
import { getFeeds } from "../helper";
import { Edit } from "../styles";

export const Editor = () => {
  const { evidence, feedLoading, handleSummaryChange, summary, setEditing } =
    useProfile();
  const [description, setDescription] = React.useState(summary);

  const disabled = React.useMemo(
    () => (description?.length ?? 0) < 1 || description === summary,
    [description, summary]
  );

  const handleCancel = () => setEditing(false);

  const handleSave = () => {
    setEditing(false);
    handleSummaryChange(description);
  };

  return !feedLoading ? (
    <Edit>
      <RichTextEditor
        config={{ mention: { dropdownLimit: 100, feeds: getFeeds(evidence) } }}
        resetValue={false}
        setLevelDescription={setDescription}
        valueIn={description}
      />
      <Edit.Actions>
        <Button
          color="secondary"
          onClick={handleCancel}
          size="small"
          startIcon={<CancelRounded />}
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={disabled}
          onClick={handleSave}
          size="small"
          startIcon={<SaveRounded />}
          variant="contained"
        >
          Save
        </Button>
      </Edit.Actions>
    </Edit>
  ) : (
    <React.Fragment />
  );
};
