import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import palette from "palette";
import { IGroupData } from "typings/group";
import GroupsListRow from "./GroupsListRow";

const StyledContainer = styled.div`
  width: 100%;
  border-radius: 10px !important;
  border: 3px solid ${palette.font.caption};
  overflow: auto;
`;

const StyledInfoContainer = styled.div`
  width: 100%;
  padding: 100px 0;
  text-align: center;
`;

const StyledTableCell = styled(TableCell)`
  width: 33.333%;
`;

interface IProps {
  data: IGroupData[];
  isLoading: boolean;
}

const GroupsList: React.FC<IProps> = ({ data, isLoading }: IProps) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [groupData, setGroupData] = useState<IGroupData[]>([]);

  useEffect(() => {
    setCurrentPage(0);
    setGroupData(data);
  }, [data]);

  return (
    <StyledContainer>
      {isLoading ? (
        <StyledInfoContainer>
          <CircularProgress />
          <Typography variant="body2" className="mt-3">
            Loading...
          </Typography>
        </StyledInfoContainer>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">ID</StyledTableCell>
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">Created At</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!groupData?.length ? (
              <TableRow>
                <TableCell colSpan={6}>
                  <StyledInfoContainer>
                    This school currently has no groups assigned.
                  </StyledInfoContainer>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {groupData
                  .slice(currentPage * 5, currentPage * 5 + 5)
                  .map((group, index) => (
                    <GroupsListRow
                      key={`${group._id}-${index}`}
                      groupData={group}
                    />
                  ))}
              </>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={groupData?.length}
                rowsPerPage={5}
                page={currentPage}
                rowsPerPageOptions={[]}
                onChangePage={(event, page) => setCurrentPage(page)}
              />
            </TableRow>
          </TableFooter>
        </Table>
      )}
    </StyledContainer>
  );
};

export default GroupsList;
