import { Theme } from "@material-ui/core";
import palette from "palette";

type ThemeInterface = Exclude<Theme["overrides"], undefined>;

const MuiSelect: ThemeInterface["MuiSelect"] = {
  root: {
    outlineWidth: "0px !important",
    border: "0px",
    borderRadius: "10px !important",
    color: palette.font.caption,
    fontSize: 18,
    "&:hover": {
      borderColor: palette.primary,
    },
    ".MuiInput-underline:before": {
      display: "none",
    },
    "&.Mui-disabled": {
      "&:hover": {
        borderColor: palette.font.caption,
      },
    },
  },
};

export default MuiSelect;
