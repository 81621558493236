import { Link } from "react-router-dom";
import styled from "styled-components";

import palette from "MuiTheme/palette";
import { StyledIcon } from "components/common/AnimatedModal/styles";

export const SL = Object.assign(
  styled.div({
    border: `3px solid ${palette.font.caption}`,
    borderRadius: "10px !important",
    overflow: "auto",
  }),
  {
    Checked: styled(StyledIcon)({ color: palette.complete }),

    Icon: styled.i({ fontSize: 24, lineHeight: "24px" }),

    Info: styled.div({
      padding: "100px 0",
      textAlign: "center",
      width: "100%",
    }),

    Link: styled(Link)({ color: "inherit", "&:hover": { color: "inherit" } }),

    Times: styled(StyledIcon)({ color: palette.error }),
  }
);
