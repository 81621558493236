import React from "react";
import styled from "styled-components";
import {
  CircularProgress,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import palette from "palette";
import { KeyboardArrowRightRounded } from "@material-ui/icons";
import { IWheelsListItem } from "typings/wheel";
import { Link, NavLink } from "react-router-dom";

const StyledPaper = styled(Paper)`
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const StyledHeaderListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledIcon = styled.i`
  font-size: 30px;
  line-height: 30px;
  margin: 1px 0 0;
`;

const StyledDivider = styled.div`
  width: 100%;
  height: 3px;
  border-radius: 15px;
  background-color: ${palette.disabled};
  margin: 15px 0 25px;
`;

const StyledLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  margin: 50px 0;
`;

const StyledWheelListContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: auto;

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 7px;
    cursor: pointer;
    right: 5px;
  }
  ::-webkit-scrollbar-thumb {
    cursor: pointer !important;
    border-radius: 7px;
    background-color: ${palette.primary};
  }
`;

const StyledWheelLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f6fa;
  font-size: 16px;
  color: ${palette.font.heading};
  padding: 7px 10px;
  border-radius: 10px;
  margin: 0 5px 15px 0;
  transition: 0.3s;

  &:hover {
    color: ${palette.font.heading};
    background-color: ${palette.primaryLight};
    text-decoration: none;
  }
`;

const StyledManageWheelLink = styled.button<{
  textColor: string;
  boldFont: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f6fa;
  font-size: 16px;
  font-weight: ${(props) => (props.boldFont ? "bold" : "inherit")};
  color: ${(props) => props.textColor};
  text-align: left;
  padding: 7px 10px;
  border: 0px;
  border-radius: 10px;
  margin: 0 5px 15px 0;
  transition: 0.3s;

  &:hover {
    color: ${palette.font.heading};
    background-color: ${palette.primaryLight};
    text-decoration: none;
  }
`;

const StyledNoAvailableWheelsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0;
`;

const StyledAddWheelLink = styled(Link)`
  background-color: ${palette.primary};
  color: #fff;
  padding: 3px 5px;
  border-radius: 10px;
  transition: 0.3s;

  &:hover {
    text-decoration: none;
    color: #fff;
    background-color: ${palette.primaryDark};
  }
`;

const StyledWheelName = styled.span`
  word-wrap: break-word;
`;

interface IProps {
  wheels: IWheelsListItem[];
  isLoading: boolean;
  page: "manage" | "wheels";
  setSelectedWheel?(wheelId: string): void;
  selectedWheel?: string;
}

const WheelsNavigation: React.FC<IProps> = ({
  wheels = [],
  isLoading = true,
  page,
  setSelectedWheel,
  selectedWheel,
}: IProps) => {
  const renderLoading = (): JSX.Element => (
    <StyledLoaderContainer>
      <CircularProgress />
      <Typography variant="body2" className="mt-3">
        Loading...
      </Typography>
    </StyledLoaderContainer>
  );

  const renderNoWheelsAvailable = (): JSX.Element => (
    <StyledNoAvailableWheelsContainer>
      <Typography variant="body2" align="center">
        You don&apos;t have access to any wheels. <br />
        <br />
        Visit{" "}
        <StyledAddWheelLink to="/teacher/manage-wheels/add">
          Add Wheel
        </StyledAddWheelLink>{" "}
        page and try to create one.
      </Typography>
    </StyledNoAvailableWheelsContainer>
  );

  const renderWheelsListForWheelsPage = (): JSX.Element[] =>
    wheels.map((wheel, wheelIndex) => (
      <StyledWheelLink
        to={`/teacher/my-wheels/${wheel._id}`}
        key={`wheel-menu-item-${wheelIndex}`}
        activeStyle={{
          color: palette.primary,
          fontWeight: 700,
        }}
      >
        <StyledWheelName>{wheel.name}</StyledWheelName>
        <KeyboardArrowRightRounded />
      </StyledWheelLink>
    ));

  const renderWheelsListForManagePage = (): JSX.Element[] =>
    wheels.map((wheel, wheelIndex) => (
      <StyledManageWheelLink
        textColor={
          wheel._id === selectedWheel ? palette.primary : palette.font.heading
        }
        boldFont={wheel._id === selectedWheel}
        onClick={() => setSelectedWheel(wheel._id)}
        key={`${wheelIndex}-manage-link`}
      >
        <StyledWheelName>{wheel.name}</StyledWheelName>
        <KeyboardArrowRightRounded />
      </StyledManageWheelLink>
    ));

  const renderContent = (): JSX.Element[] | JSX.Element | null => {
    if (isLoading) {
      return renderLoading();
    }
    if (!wheels?.length) {
      return renderNoWheelsAvailable();
    }
    if (page === "wheels") {
      return renderWheelsListForWheelsPage();
    }
    if (page === "manage" && !!setSelectedWheel) {
      return renderWheelsListForManagePage();
    }
    return null;
  };

  return (
    <StyledPaper>
      <StyledHeaderListContainer>
        <Typography variant="h6">
          {page === "manage" ? "Manage Wheels" : "My Wheels"}
        </Typography>
        <Tooltip title="Add new wheel">
          <Link to="/teacher/manage-wheels/add">
            <IconButton color="primary">
              <StyledIcon className="uil uil-plus-circle" />
            </IconButton>
          </Link>
        </Tooltip>
      </StyledHeaderListContainer>
      <StyledDivider />
      <StyledWheelListContainer>{renderContent()}</StyledWheelListContainer>
    </StyledPaper>
  );
};

export default WheelsNavigation;
