import React from "react";
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";

import { Token } from "./token";
import { Wheel } from "./wheel";

const routes = [
  { path: "/token/:token", Component: Token },
  { path: "/token/:token/:studentId/:wheelId", Component: Wheel },
  { path: "/token/:token/:studentId/:wheelId/:areaId", Component: Wheel },
  {
    path: "/token/:token/:studentId/:wheelId/:areaId/:evidenceId",
    Component: Wheel,
  },
];

export const TokenPage = withRouter(({ location }) => (
  <BrowserRouter>
    <Switch location={location}>
      {routes.map(({ Component, path }) => (
        <Route exact path={path} key={`token-route-${path}`}>
          {/* @ts-ignore */}
          {(routeProps: RouteComponentProps<{ wheelId: string }>) => (
            <Component {...routeProps} />
          )}
        </Route>
      ))}
    </Switch>
  </BrowserRouter>
));
