import AnimatedModal from "components/common/AnimatedModal";
import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import { S } from "./styles";
import palette from "palette";

interface IProps {
  isOpen: boolean;
  handleCloseModal: () => void;
  newGroupName: string;
  setNewGroupName: (groupName: string) => void;
  newGroupAdded: boolean;
  addNewGroup: () => void;
  newGroupErrorMsg: string;
}

export const CreateGroupModel: React.FC<IProps> = ({
  isOpen,
  handleCloseModal,
  newGroupName,
  setNewGroupName,
  newGroupAdded,
  addNewGroup,
  newGroupErrorMsg,
}) => {
  return (
    <AnimatedModal
      open={isOpen}
      title="Create New Group"
      handleClose={() => handleCloseModal()}
      maxWidth="625px"
      minHeight="200px"
      width="100%"
    >
      <>
        <Typography align={"center"} variant="body2">
          Choose a name for your new group and press Create.
          <br />
          You will be able to assign students and teachers from this group next.
        </Typography>
        <S.TextInput
          label="Group Name"
          value={newGroupName}
          fullWidth
          onChange={(e) => setNewGroupName(e.target.value)}
          disabled={newGroupAdded}
        />
        <Typography
          style={{
            color: !newGroupErrorMsg ? palette.complete : palette.error,
            marginBottom: "20px",
            textAlign: "center",
          }}
          variant="body2"
        >
          {newGroupErrorMsg}
          {newGroupAdded && !newGroupErrorMsg && "New group added"}
        </Typography>
        <S.ActionContainer>
          <S.Button
            variant="outlined"
            color="primary"
            onClick={() => handleCloseModal()}
          >
            cancel
          </S.Button>
          <S.Button
            variant="contained"
            color="primary"
            disabled={newGroupName.length < 2 || newGroupAdded}
            onClick={() => addNewGroup()}
          >
            add group
          </S.Button>
        </S.ActionContainer>
      </>
    </AnimatedModal>
  );
};
