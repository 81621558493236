import { Typography } from "@material-ui/core";
import React from "react";

import { useFeedback } from "./context";
import { Actions } from "./styles";

export const Header = () => {
  const { evidence } = useFeedback();

  return (
    <Actions.Header>
      <Typography variant="h6">{evidence.name}</Typography>
    </Actions.Header>
  );
};
