import { IStudentWheelResultsData } from "../typings/student";
import { IArea, ILevel, TWheelData } from "typings/meta-mirror";

const gradeCalcWeightings = {
  "0": null,
  "0.333": 0,
  "0.666": 0.5,
  "1": 1,
};

const decimalToGrade = {
  "00": 1,
  "33": 0,
  "67": 0.5,
};

const MmWheelAverageService = {
  getAverageWheelScoreBasedOnStudentsResults(
    wheelResults: IStudentWheelResultsData[],
    wheelLevelType: string
  ): TWheelData {
    const { areas } = wheelResults[0];
    const preparedWheelData: TWheelData = [];

    if (wheelLevelType === "non-sequential") {
      return this.getSequentialAverage(wheelResults);
    }

    areas.forEach(({ _id, levels, areaName }) => {
      let areaAverageGrade = 0;

      wheelResults.forEach((studentResult) => {
        const studentResultArea = studentResult.areas.find(
          (findArea) => findArea._id === _id
        );

        const currentStudentLevel =
          MmWheelAverageService.getStudentAreaCurrentLevel(
            studentResultArea,
            wheelLevelType
          );

        areaAverageGrade =
          areaAverageGrade +
          MmWheelAverageService.getAverageForStudentLevel(currentStudentLevel);
      });
      areaAverageGrade = MmWheelAverageService.roundToNearestThird(
        areaAverageGrade / wheelResults.length
      );

      const avgLevel =
        MmWheelAverageService.getLevelFromAverageGrade(areaAverageGrade);

      const avgGrade =
        MmWheelAverageService.getGradeFromAverageGrade(areaAverageGrade);

      preparedWheelData.push({
        areaName,
        _id,
        levels: MmWheelAverageService.getNewLevelsForArea(
          levels,
          avgLevel,
          avgGrade
        ),
      });
    });
    return preparedWheelData;
  },

  getSequentialAverage(wheelResults: IStudentWheelResultsData[]): TWheelData {
    const { areas } = wheelResults[0];
    const preparedWheelData: TWheelData = [];

    areas.forEach(({ _id, levels, areaName }) => {
      // Go through each area
      const area: IArea = { _id: _id, areaName: areaName, levels: [] };

      levels.sort((a, b) => a.level - b.level);

      levels.forEach((level) => {
        const levelGrades: (number | null)[] = [];

        wheelResults.forEach((result) => {
          const currentStudentArea = result.areas.find(
            (findArea) => findArea._id === _id
          );
          const currentStudentLevel = currentStudentArea?.levels?.find((l) => {
            return l._id === level._id;
          });
          levelGrades.push(currentStudentLevel?.grade ?? null);
        });

        const levelGradeSum = levelGrades.reduce((p, c) => {
          if (!p) return c;
          if (c) return p + c;
          return p;
        }, 0);

        let levelGradeAverage: number | null = null;
        if (levelGradeSum !== null) {
          levelGradeAverage = levelGradeSum / levelGrades.length;

          const gradeLimits = [0, 0.5, 1];

          let diff = 10;
          let newGrade = 0;
          gradeLimits.forEach((lim) => {
            const curDiff = Math.abs(lim - levelGradeAverage);
            if (curDiff < diff) {
              diff = curDiff;
              newGrade = lim;
            }
          });

          levelGradeAverage = newGrade;
        }
        area.levels.push({ ...level, grade: levelGradeAverage });
      });
      preparedWheelData.push(area);
    });

    return preparedWheelData;
  },

  getStudentAreaCurrentLevel(area: IArea): ILevel {
    const { levels } = area;

    levels.sort((a, b) => a.level - b.level);
    const index = levels.findIndex((level) => level.grade === null);
    if (index === -1) {
      return levels[levels.length - 1];
    }
    if (index === 0) {
      return levels[index];
    }
    return levels[index - 1];
  },

  getAverageForStudentLevel(levelIn: ILevel): number {
    const { level, grade } = levelIn;
    const gradeNum = MmWheelAverageService.getGradeCalcValuefromGrade(grade);
    const levelNum = level - 1;
    return levelNum + gradeNum;
  },

  getGradeCalcValuefromGrade(levelGrade: null | 0 | 0.5 | 1): number {
    return parseFloat(
      Object.keys(gradeCalcWeightings).find(
        (key) => gradeCalcWeightings[key] === levelGrade
      ) as string
    );
  },

  getLevelFromAverageGrade(averageGrade: number): number {
    const levelString = averageGrade.toFixed(2).split(".")[0];
    return Math.ceil(parseInt(levelString));
  },

  getGradeFromAverageGrade(averageGrade: number): ILevel["grade"] {
    const gradeString = averageGrade.toFixed(2).split(".")[1];
    return decimalToGrade[gradeString];
  },

  roundToNearestThird(averageGrade: number): number {
    return (1 / 3) * Math.round(averageGrade / (1 / 3));
  },

  getNewLevelsForArea(
    levelsIn: ILevel[],
    avgLevel: number,
    avgGrade: ILevel["grade"]
  ): ILevel[] {
    let adjustedLevel = avgLevel;
    if (avgGrade !== 1) {
      adjustedLevel = avgLevel + 1;
    }
    const newLevels: ILevel[] = levelsIn.map(
      ({ _id, level, description, name }) => {
        let grade = null;
        if (level === adjustedLevel) {
          grade = avgGrade;
        }
        if (level < adjustedLevel) {
          grade = 1;
        }
        const newLevel = {
          _id,
          description,
          grade,
          name,
          level,
        };

        return newLevel;
      }
    );
    return newLevels;
  },
};

export default MmWheelAverageService;
