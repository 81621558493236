import {
  Button,
  CircularProgress,
  TextField,
  Typography,
  Tooltip,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IResponseBody } from "typings/global";
import Select from "components/common/Select/Select";
import palette from "palette";
import { MIRROR_LEVEL_TYPES } from "constants/index";

const StyledDetailsContainer = styled.div`
  display: flex;
  flex: 0.8;
  flex-direction: column;
  justify-content: space-around;
  align-content: flex-start;
  align-items: flex-start;
`;

const StyledLoader = styled(CircularProgress)`
  height: 20px !important;
  width: 20px !important;
  color: ${palette.font.caption} !important;
  margin-right: 10px;
`;

const StyledEditContainer = styled.div`
  display: flex;
  flex: 0.2;
  flex-direction: column;
  justify-content: space-around;
  justify-items: space-around;
  align-content: flex-end;
  align-items: flex-end;
  padding-bottom: 10px;
`;

const StyledWheelLimitRow = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 55px;
  align-items: center;
  align-content: center;
`;

const StyledSchoolNameRow = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 55px;
  min-width: 400px;
  align-items: center;
  align-content: center;
`;

const StyledButton = styled(Button)`
  width: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 0 0 20px !important;
`;

const StyledTypography = styled(Typography)`
  font-size: 18px !important;
  font-weight: 700 !important;
  min-width: 120px;
  margin-right: 30px !important;
`;

const StyledErrorMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: ${palette.error} !important;
  min-width: 280px;
  max-width: 280px;
  margin-left: 10px;
`;

const StyledTextField = styled(TextField)`
  min-width: 225px !important;
  max-width: 225px;
`;

interface IProps {
  name: string;
  wheelsLimit: number;
  handleUpdateDetails(
    schoolName: string,
    wheelsLimit: number,
    mirrorLevelsType: string
  ): Promise<IResponseBody>;
  mirrorLevelsType: string;
  setMirrorLevelsType: (type: string) => void;
}

const SchoolAssign: React.FC<IProps> = (props: IProps) => {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [name, setName] = useState("");
  const [wheelsLimit, setWheelsLimit] = useState(0);

  useEffect(() => {
    setName(props.name);
    setWheelsLimit(props.wheelsLimit);
  }, [props]);

  const handleEdit = (): void => {
    setEditing(true);
  };

  const handleSave = async (): Promise<void> => {
    setLoading(true);
    const response = await props.handleUpdateDetails(
      name,
      wheelsLimit,
      props.mirrorLevelsType
    );
    if (response.error) {
      setError(response.message);
      setLoading(false);
      return;
    }
    setError("");
    setEditing(false);
    setLoading(false);
  };

  const handleCancel = (): void => {
    setEditing(false);
    setError("");
    setName(props.name);
    setWheelsLimit(props.wheelsLimit);
  };

  return (
    <>
      <StyledDetailsContainer>
        <StyledSchoolNameRow>
          <StyledTypography variant="h6">School Name</StyledTypography>
          {editing ? (
            <StyledTextField
              variant="outlined"
              margin="dense"
              value={name}
              onChange={(event): void => setName(event.target.value)}
              fullWidth
            />
          ) : (
            <Typography variant="body2">{name}</Typography>
          )}
          <StyledErrorMessageContainer>
            <Typography color="inherit" variant="caption">
              {error}
            </Typography>
          </StyledErrorMessageContainer>
        </StyledSchoolNameRow>
        <StyledWheelLimitRow>
          <StyledTypography variant="h6">Wheel Limit</StyledTypography>
          {editing ? (
            <StyledTextField
              variant="outlined"
              margin="dense"
              type="number"
              InputProps={{
                inputProps: {
                  max: 30,
                  min: 0,
                },
              }}
              value={wheelsLimit}
              onChange={(event): void =>
                setWheelsLimit(
                  parseInt(event.target.value) > 30
                    ? 30
                    : parseInt(event.target.value)
                )
              }
              fullWidth
            />
          ) : (
            <Typography variant="body2">{wheelsLimit}</Typography>
          )}
        </StyledWheelLimitRow>
        <StyledWheelLimitRow>
          <Tooltip
            title={
              <>
                When the school is creating a new wheel, this option is
                automatically selected for them.
                <br />
                <br />
                <strong>Sequential</strong> - A completed level will complete
                all previous levels. Eg. completing level 3 will also complete
                level 2 and 1.
                <br />
                <br />
                <strong>Non sequential</strong> - Levels can be completed
                individually.
              </>
            }
            placement="bottom-start"
          >
            <StyledTypography variant="h6">Mirror Levels Type</StyledTypography>
          </Tooltip>
          {editing ? (
            <>
              <Select
                onChange={(e) => props.setMirrorLevelsType(e.target.value)}
                value={props.mirrorLevelsType}
                options={MIRROR_LEVEL_TYPES.map((v) => ({
                  label: v,
                  value: v,
                }))}
              ></Select>
            </>
          ) : (
            <Typography variant="body2">{props.mirrorLevelsType}</Typography>
          )}
        </StyledWheelLimitRow>
      </StyledDetailsContainer>
      <StyledEditContainer>
        {editing && (
          <StyledButton
            variant="outlined"
            color="primary"
            onClick={handleCancel}
            disabled={loading}
          >
            cancel
          </StyledButton>
        )}
        <StyledButton
          variant="contained"
          color="primary"
          onClick={editing ? handleSave : handleEdit}
          disabled={loading}
        >
          {loading && <StyledLoader />}
          {editing ? "save" : "edit"}
        </StyledButton>
      </StyledEditContainer>
    </>
  );
};

export default SchoolAssign;
