import { Box, Button, CircularProgress } from "@material-ui/core";
import { AddRounded } from "@material-ui/icons";
import Compress from "browser-image-compression";
import React, { ChangeEvent } from "react";

import TextInput from "components/common/TextInput/TextInput";
import { MAX_FILE_SIZE } from "helpers/metaMirrorEvidenceValidation";

import {
  ERROR_INVALID,
  ERROR_READER,
  ERROR_SIZE,
  VALID_FILE_TYPES,
} from "./constants";
import { useOther } from "./context";
import { List } from "./list";
import {
  StyledCircularProgress,
  StyledIcon,
  StyledInput,
  StyledInputContainer,
  StyledLabel,
} from "./styles";

export const Editor = () => {
  const { handleFileUpload, items, loading, setError } = useOther();
  const [file, setFile] = React.useState<string>();
  const [image, setImage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [item, setItem] = React.useState("");

  const name = React.useMemo(() => image.split("/").pop(), [image]);

  const handleAddClick = () => {
    if (!file) return;
    handleFileUpload(item, file, image);
  };

  const handleUploadFile = async ({
    target: { files },
  }: ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    const file = files?.[0];
    if (!file) return;
    const { name, size } = file;
    const type = file.type;

    if (!VALID_FILE_TYPES.includes(type)) return setError(ERROR_INVALID);

    if (size > MAX_FILE_SIZE) return setError(ERROR_SIZE);

    const reader = new FileReader();
    let prepared: File | Blob = file;
    if (["image/jpeg", "image/png"].includes(type)) {
      const compressed = await Compress(file, {
        useWebWorker: true,
        initialQuality: 0.5,
      });
      prepared = compressed;
    }
    reader.readAsDataURL(prepared);

    reader.onload = () => {
      //  handle reader.result
      setFile(reader.result as string);
      setError;
      setIsLoading(false);
      setImage(name);
    };

    reader.onerror = () => {
      setIsLoading(false);
      setError(ERROR_READER);
      setImage("");
    };
  };

  React.useEffect(() => {
    setFile("");
    setImage("");
    setItem("");
    setIsLoading(false);
  }, [items]);

  return (
    <Box>
      <Box
        style={{
          alignItems: "center",
          display: "flex",
          gap: ".5rem",
        }}
      >
        <TextInput
          value={item}
          label="Achievement"
          onChange={({ target }) => setItem(target.value)}
          InputProps={{
            startAdornment: <i className="uil uil-medal" />,
          }}
        />
        <StyledInputContainer>
          <StyledInput
            id="file-input"
            type="file"
            accept="image/*,application/pdf"
            disabled={isLoading || loading}
            onChange={handleUploadFile}
          />
          <StyledLabel htmlFor="file-input" fileUploaded={!!image}>
            {image ? (
              <>
                <StyledIcon className="uil uil-file-check" />
                {name}
              </>
            ) : (
              <>
                {isLoading ? (
                  <>
                    <StyledCircularProgress color="secondary" />
                    loading...
                  </>
                ) : (
                  <>
                    <StyledIcon className="uil uil-file-upload" />
                    choose file
                  </>
                )}
              </>
            )}
          </StyledLabel>
        </StyledInputContainer>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "flex-end",
          }}
        >
          <Button
            color="primary"
            disabled={!item || !image || loading}
            onClick={handleAddClick}
            startIcon={
              loading ? <CircularProgress size={20} /> : <AddRounded />
            }
            variant="contained"
          >
            Add
          </Button>
        </Box>
      </Box>
      {items?.length ? <List /> : <React.Fragment />}
    </Box>
  );
};
