import { IconButton, Paper } from "@material-ui/core";
import styled from "styled-components";

export const Capsule = styled.div(({ color }) => ({
  borderRadius: "10px",
  backgroundColor: color,
  color: "white",
  padding: "4px 10px",
  zIndex: "10",
  fontSize: "16px",
  margin: "5px 10px",
  textAlign: "center",
  display: "inline-block",
}));

export const Container = styled.div({
  columnGap: "30px",
  display: "flex",
  flexWrap: "wrap",
  padding: "10px 0 40px 0",
  rowGap: "20px",
  "& .btn-close": {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: 15,
    background: "none",
    border: "none",
    outline: "none",
  },
});

export const Edit = Object.assign(
  styled.div({
    display: "flex",
    flexDirection: "column",
    "& .mention": {
      color: "#007bff",
      background: "transparent",
    },
  }),
  {
    Actions: styled.div({
      display: "flex",
      justifyContent: "flex-end",
      marginTop: ".5rem",
      gap: ".5rem",
    }),
  }
);

export const Image = styled.img({
  borderRadius: "50%",
  objectFit: "cover",
  height: "250px",
  width: "250px",
  objectPosition: "top",
  margin: "10px 0",
  transition: "0.5s",
  cursor: "pointer",
  ":hover": {
    opacity: "0.8",
    borderRadius: "40%",
  },
});

export const Row = styled.div({
  columnGap: 30,
  display: "flex",
  justifyContent: "center",
  padding: "0 0 20px 0",
});

export const Sum = Object.assign(
  styled(Paper)({
    padding: "30px 40px",
    width: "100%",
    position: "relative",
  }),
  {
    Content: styled.div({
      background: "#F0F0F0",
      borderRadius: 10,
      maxHeight: 250,
      overflowX: "hidden",
      overflowY: "auto",
      padding: "10px 20px",
      width: "100%",
    }),

    EditButton: styled(IconButton)({
      //  @ts-ignore
      position: "absolute !important",
      right: 20,
      top: 20,
    }),

    Title: styled.h4({
      marginBottom: 20,
    }),
  }
);
