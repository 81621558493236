import { Typography, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";

import { mediaRules } from "MuiTheme/Breakpoints";
import PageContainer from "components/common/PageContainer/PageContainer";
import EditWheel from "components/teacher/EditWheel";
import WheelsNavigation from "components/teacher/WheelsNavigation";
import { axiosInstance } from "helpers/axios-instance";
import { getMirrorList } from "lib/mirror";
import { IGroup } from "typings/meta-mirror";

import { IWithAuth, withAuth } from "../../../hoc/withAuth";

const StyledPageContainer = styled(PageContainer)`
  display: flex;
  flex-direction: row;
  gap: "30px";

  @media only screen and (max-width: 992px) {
    flex-direction: column;
  }
`;

const StyledCaption = styled(Typography)`
  font-weight: bold !important;
  margin: 15px 0 !important;
`;

const StyledWheelListContainer = styled.div`
  width: 300px;
  flex-shrink: 0;
  margin: 0 30px 0 0;
  height: 100%;

  @media only screen and (max-width: 992px) {
    position: relative;
    height: auto;
    width: 100%;
    margin: 0 0 30px 0;
  }
`;

const StyledEditContainer = styled.div`
  width: 100%;
  height: 100%;
  @media ${mediaRules.lg} {
    padding-left: 0;
  }
`;

const StyledNoSelectedWheelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
`;

interface IProps extends RouteComponentProps, IWithAuth {}

const ManageWheelsPage: React.FC<IProps> = ({ history, user }) => {
  const [groups, setGroups] = React.useState<IGroup[]>([]);
  const [wheels, setWheels] = useState([]);
  const [wheelsLoading, setWheelsLoading] = useState(true);
  const [selectedWheel, setSelectedWheel] = useState("");

  const getWheelList = async (): Promise<void> => {
    try {
      const result = await getMirrorList(
        user.roles?.teacher.id?.mm_group_ids ?? []
      );

      setWheels(result?.data || []);
      setWheelsLoading(false);
    } catch {}
  };

  useEffect(() => {
    (async () => {
      await getWheelList();
      axiosInstance.get("/groups").then(({ data }) => setGroups(data));
    })();
  }, []);

  const updateWheelList = async (): Promise<void> => {
    setWheelsLoading(true);
    await getWheelList();
  };

  const renderNoWheelSelected = () => (
    <StyledNoSelectedWheelContainer>
      <Typography variant="body2">
        Select a wheel from the list <br />
        on the left to edit it
      </Typography>
      <StyledCaption variant="caption">or</StyledCaption>
      <Button
        variant="contained"
        color="primary"
        onClick={() => history.push("/teacher/manage-wheels/add")}
      >
        add a new wheel
      </Button>
    </StyledNoSelectedWheelContainer>
  );

  const getPageContent = () => {
    if (selectedWheel) {
      return (
        <EditWheel
          groups={groups}
          wheelId={selectedWheel}
          refreshWheels={updateWheelList}
          selectWheel={setSelectedWheel}
        />
      );
    }
    return renderNoWheelSelected();
  };

  return (
    <StyledPageContainer>
      <StyledWheelListContainer>
        <WheelsNavigation
          wheels={wheels}
          isLoading={wheelsLoading}
          page="manage"
          selectedWheel={selectedWheel}
          setSelectedWheel={setSelectedWheel}
        />
      </StyledWheelListContainer>
      <StyledEditContainer>{getPageContent()}</StyledEditContainer>
    </StyledPageContainer>
  );
};

export default withAuth(ManageWheelsPage, ["teacher", "admin"]);
