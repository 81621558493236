import React, { useState } from "react";
import TextInput from "components/common/TextInput/TextInput";
import AnimatedModal from "components/common/AnimatedModal";
import { Button, Typography, TypographyProps } from "@material-ui/core";
import styled from "styled-components";
import palette from "palette";
import { IGroupDataResponseBody } from "typings/group";

interface IStyledInfoMessageProps extends TypographyProps {
  textColor?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledInfoMessage = styled(
  ({ textColor, ...props }: IStyledInfoMessageProps) => (
    <Typography {...props} />
  )
)<{ textColor: string }>`
  color: ${(props) => props.textColor || palette.font.caption} !important;
  height: 30px;
  margin: 0 0 20px !important;
`;

const StyledModalContainer = styled.div`
  display: flex;
  justify-content: flex;
  align-items: center;
  flex-direction: column;
`;

const StyledTextInput = styled(TextInput)`
  margin: 30px 0 20px !important;
`;

interface IProps {
  handleClose(): void;
  handleAddGroup: (groupName: string) => Promise<IGroupDataResponseBody>;
  open: boolean;
}

const AddGroupModal: React.FC<IProps> = ({
  handleAddGroup,
  handleClose,
  open,
}: IProps) => {
  const [groupName, setSchoolName] = useState("");
  const [requestPending, setRequestPending] = useState(false);
  const [groupAdded, setGroupAdded] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");

  const handleCloseModal = (): void => {
    handleClose();
    setTimeout(() => {
      setSchoolName("");
      setRequestPending(false);
      setInfoMessage("");
      setGroupAdded(false);
    }, 1000);
  };

  const addSchool = async (): Promise<void> => {
    setRequestPending(true);
    const result = await handleAddGroup(groupName);
    setRequestPending(false);
    if (result.error) {
      setInfoMessage(result.message);
      return;
    }
    setInfoMessage("New group added successfully.");
    setGroupAdded(true);
  };

  return (
    <AnimatedModal
      open={open}
      handleClose={handleCloseModal}
      onClose={handleCloseModal}
      disableClose={requestPending}
      disableBackdropClick={requestPending}
      title="New Group"
      width="100%"
      maxWidth="625px"
      height="auto"
    >
      <StyledModalContainer>
        <Typography variant="body2">
          Please provide a name for the new group.
        </Typography>
        <StyledTextInput
          label="Group Name"
          value={groupName}
          fullWidth
          onChange={(e) => setSchoolName(e.target.value)}
          disabled={groupAdded || requestPending}
        />
        <StyledInfoMessage
          textColor={groupAdded ? palette.complete : palette.error}
          variant="body2"
        >
          {infoMessage}
        </StyledInfoMessage>
        <div className="row w-100 pl-5 pr-5">
          <div className="col-6">
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={handleCloseModal}
              disabled={requestPending}
            >
              go back
            </Button>
          </div>
          <div className="col-6">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={groupAdded ? handleCloseModal : addSchool}
              disabled={requestPending || (!groupName.length && !groupAdded)}
            >
              {groupAdded ? "done" : "add group"}
            </Button>
          </div>
        </div>
      </StyledModalContainer>
    </AnimatedModal>
  );
};

export default AddGroupModal;
