import { Tooltip } from "@material-ui/core";
import React from "react";

import { useEvidenceList } from "./context";
import { FilterMenu } from "./filterMenu";
import { FilterIcon, Indicator } from "./styles";

export const EvidenceListFilters = () => {
  const { hasFilters } = useEvidenceList();

  const [anchor, setAnchor] = React.useState<EventTarget & HTMLButtonElement>();

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) =>
    setAnchor(anchor ? undefined : e.currentTarget);

  const handleClose = () => setAnchor(undefined);

  return (
    <>
      <Tooltip title="Filter Evidence">
        <FilterIcon
          color={hasFilters ? "secondary" : "primary"}
          onClick={handleClick}
        >
          {hasFilters && <Indicator />}
          <i className="uil uil-filter" style={{ fontSize: 20 }} />
        </FilterIcon>
      </Tooltip>

      <FilterMenu anchorEl={anchor} open={!!anchor} onClose={handleClose} />
    </>
  );
};
