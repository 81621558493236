import { Button, TextField } from "@material-ui/core";
import React from "react";
import ReactToPrint from "react-to-print";
import styled from "styled-components";
import palette from "palette";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const StyledTextField = styled(TextField)`
  margin: 0 0 30px 0 !important;
  height: 100% !important;

  & .MuiOutlinedInput-multiline {
    padding: 10.5px !important;
    height: 100%;
  }

  & textarea {
    height: 100% !important;
    overflow: auto !important;

    ::-webkit-scrollbar-track {
      background-color: transparent;
    }
    ::-webkit-scrollbar {
      width: 7px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 7px;
      background-color: ${palette.primary};
    }
  }
`;

interface IProps {
  printRef: React.MutableRefObject<HTMLDivElement>;
  comment: string;
  handleUpdateComment: (comment: string) => void;
}

const GenerateReportAction: React.FC<IProps> = ({
  printRef,
  comment,
  handleUpdateComment,
}: IProps) => {
  return (
    <StyledContainer>
      <StyledTextField
        variant="outlined"
        label="Comment"
        multiline
        fullWidth
        inputProps={{ maxLength: 450 }}
        rowsMax={15}
        value={comment}
        onChange={(event) => handleUpdateComment(event.target.value as string)}
      />
      <ReactToPrint
        trigger={() => (
          <Button color="secondary" variant="contained" fullWidth>
            print / save
          </Button>
        )}
        content={() => printRef.current}
      />
    </StyledContainer>
  );
};

export default GenerateReportAction;
