import { Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import palette from "palette";

interface IProps {
  wheelName: string;
  subtitle: string;
  comment: string;
}

const StyledHeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledCommentSection = styled.div`
  border: 1px solid ${palette.disabled};
  border-radius: 10px;
  padding: 6px;
  margin: 10px 0 0;
  width: 100%;
`;

const StyledTypography = styled(Typography)<{
  fontSize?: number;
  align?: string;
}>`
  font-size: ${(props) =>
    props.fontSize ? `${props.fontSize}px !important` : "auto"};
  text-align: ${(props) => (props.align ? props.align : "center")} !important;
  white-space: pre-wrap;
`;

const ReportHeaderSection: React.FC<IProps> = ({
  wheelName,
  subtitle,
  comment,
}: IProps) => (
  <StyledHeaderSection>
    <Typography variant="h3">{wheelName}</Typography>
    <Typography variant="body2">{subtitle}</Typography>
    {!!comment && (
      <StyledCommentSection>
        <StyledTypography variant="body1" fontSize={18} align="left">
          {comment}
        </StyledTypography>
      </StyledCommentSection>
    )}
  </StyledHeaderSection>
);

export default ReportHeaderSection;
