import React from "react";
import { IGroup } from "@rose-arch-holdings-ltd/common";
import { S } from "./styles";
import palette from "palette";
import { KeyboardArrowRightRounded } from "@material-ui/icons";
import {
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";

interface IProps {
  groups: IGroup[];
  isLoading: boolean;
  page: "manage" | "groups";
  setSelectedGroup(groupId: IGroup): void;
  selectedGroup?: IGroup;
  openModal: () => void;
}

export const GroupsNavigation: React.FC<IProps> = ({
  groups,
  isLoading = true,
  page,
  setSelectedGroup,
  selectedGroup,
  openModal,
}) => {
  const renderLoading = (): JSX.Element => (
    <S.LoaderContainer>
      <CircularProgress />
      <Typography variant="body2" className="mt-3">
        Loading...
      </Typography>
    </S.LoaderContainer>
  );

  const renderNoGroupsAvailable = (): JSX.Element => (
    <S.NoAvailableGroupsContainer>
      <Typography variant="body2" align="center">
        You don&apos;t have access to any groups. <br />
        <br />
        Visit{" "}
        <S.AddGroupLink onClick={() => openModal()} to={"#"}>
          Add Group
        </S.AddGroupLink>{" "}
        page and try to create one.
      </Typography>
    </S.NoAvailableGroupsContainer>
  );

  const renderGroupsListForGroupsPage = (): JSX.Element[] =>
    groups.map((group, groupIndex) => (
      <S.GroupLink
        to={`/teacher/my-groups/${group._id}`}
        key={`group-menu-item-${groupIndex}`}
        activeStyle={{
          color: palette.primary,
          fontWeight: 700,
        }}
      >
        <S.GroupName>{group.name}</S.GroupName>
        <KeyboardArrowRightRounded />
      </S.GroupLink>
    ));

  const renderGroupsListForManagePage = (): JSX.Element[] =>
    groups.map((group, groupIndex) => (
      <S.ManageGroupLink
        textColor={
          group._id === selectedGroup?._id
            ? palette.primary
            : palette.font.heading
        }
        boldFont={group._id === selectedGroup?._id}
        onClick={() => setSelectedGroup(group)}
        key={`${groupIndex}-manage-link`}
      >
        <S.GroupName>{group.name}</S.GroupName>
        <KeyboardArrowRightRounded />
      </S.ManageGroupLink>
    ));

  const renderContent = (): JSX.Element[] | JSX.Element | null => {
    if (isLoading) {
      return renderLoading();
    }
    if (!groups?.length) {
      return renderNoGroupsAvailable();
    }
    if (page === "groups") {
      return renderGroupsListForGroupsPage();
    }
    if (page === "manage" && !!setSelectedGroup) {
      return renderGroupsListForManagePage();
    }
    return null;
  };

  return (
    <S.Paper>
      <S.HeaderListContainer>
        <Typography variant="h6">
          {page === "manage" ? "Manage Groups" : "My Groups"}
        </Typography>
        <Tooltip title="Add new group">
          <IconButton color="primary" onClick={() => openModal()}>
            <S.Icon className="uil uil-plus-circle" />
          </IconButton>
        </Tooltip>
      </S.HeaderListContainer>
      <S.Divider />
      <S.GroupListContainer>{renderContent()}</S.GroupListContainer>
    </S.Paper>
  );
};
