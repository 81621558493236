import { Paper } from "@material-ui/core";
import styled from "styled-components";

export const Capsule = styled.div(({ color }) => ({
  borderRadius: "10px",
  backgroundColor: color,
  color: "white",
  padding: "4px 10px",
  zIndex: "10",
  fontSize: "16px",
  margin: "5px 10px",
  textAlign: "center",
  display: "block",
}));

export const CurriculumAchievements = styled(Paper)({
  padding: "30px 40px",
  flex: 1,
  "& p": {
    marginBottom: "5px",
    fontWeight: "bold",
    padding: "0 10px",
  },
  "& .inner": {
    backgroundColor: "#f0f0f0",
    padding: "10px 10px",
    borderRadius: "10px",
  },
  "& .grades": {
    display: "flex",
    flexWrap: "wrap",
  },
});

export const OtherAchievements = styled(Paper)({
  padding: "30px 40px",
  flex: 1,
  "& .inner": {
    backgroundColor: "#f0f0f0",
    padding: "10px 10px",
    borderRadius: "10px",
    "& ul": {
      margin: "0",
      padding: "0 20px",
      listStyleType: "circle",
      "&li": {
        margin: "10px",
      },
    },
  },
});

export const OtherDetails = styled(Paper)({
  padding: "30px 40px",
  flex: 1,
  "& .inner": {
    backgroundColor: "#f0f0f0",
    borderRadius: "10px",
    padding: "10px 20px",
    "& div": {
      margin: "10px 0",
    },
  },
});

export const PlayIcon = styled.i({
  fontSize: "102px",
  zIndex: 10,
  marginTop: "50px",
  transition: "0.5s",
  cursor: "pointer",
  color: "white",
  "&:hover": {
    color: "gray",
  },
});

export const SectionOne = styled.div({ flex: 1 });

export const SectionTwo = styled.div({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  rowGap: "10px",
});

export const StyledProfileContainer = styled.div({
  padding: "10px 0 40px 0",
  display: "flex",
  columnGap: "30px",
  flexWrap: "wrap",
  rowGap: "20px",
});

export const StyledProfileImage = styled.img({
  borderRadius: "50%",
  objectFit: "cover",
  height: "250px",
  width: "250px",
  objectPosition: "top",
  margin: "10px 0",
  transition: "0.5s",
  cursor: "pointer",
  "&:hover": {
    opacity: 0.8,
    borderRadius: "40%",
  },
});

export const Summary = styled(Paper)({
  padding: "30px 40px",
  "& h4": {
    marginBottom: "20px",
  },
  " & div": {
    backgroundColor: "#f0f0f0",
    padding: "10px 20px",
    borderRadius: "10px",
  },
});

export const TalkingHead = styled.div({
  width: "100%",
  height: "100%",
  backgroundImage:
    'url("https://i.ytimg.com/vi/fOhaFDSjtYo/maxresdefault.jpg")',
  backgroundSize: "100% 100%",
  filter: "blur(5px)",
  WebkitFilter: "blur(5px)",
  position: "absolute",
});

export const TalkingHeadContainer = styled.div({
  border: "3px solid #212121",
  borderRadius: "15px",
  overflow: "hidden",
  width: "400px",
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
});

export const TopRow = styled.div({
  display: "flex",
  padding: "0 0 20px 0",
  justifyContent: "center",
  columnGap: "30px",
});
