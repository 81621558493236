import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import palette from "palette";
import { ISchoolListData } from "typings/school";
import { Link } from "react-router-dom";

const StyledContainer = styled.div`
  border-radius: 10px !important;
  border: 3px solid ${palette.font.caption};
  overflow: auto;
`;

const StyledInfoContainer = styled.div`
  width: 100%;
  padding: 100px 0;
  text-align: center;
`;

const StyledLink = styled(Link)`
  color: inherit;

  &:hover {
    color: inherit;
  }
`;

interface IProps {
  data: ISchoolListData[];
  isLoading: boolean;
}

const SchoolList: React.FC<IProps> = ({ data, isLoading }: IProps) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [schoolData, setSchoolData] = useState<ISchoolListData[]>([]);

  useEffect(() => {
    setPageSize(10);
    setCurrentPage(0);
    setSchoolData(data);
  }, [data]);

  return (
    <StyledContainer>
      {isLoading ? (
        <StyledInfoContainer>
          <CircularProgress />
          <Typography variant="body2" className="mt-3">
            Loading...
          </Typography>
        </StyledInfoContainer>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">ID</TableCell>
              <TableCell align="center">Students</TableCell>
              <TableCell align="center">Teachers</TableCell>
              <TableCell align="center">
                Wheels Used / Wheels Available
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!schoolData.length ? (
              <TableRow>
                <TableCell colSpan={6}>
                  <StyledInfoContainer>No data found</StyledInfoContainer>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {schoolData
                  .slice(
                    currentPage * pageSize,
                    currentPage * pageSize + pageSize
                  )
                  .map((school, schoolIndex) => (
                    <TableRow key={`${school.name}-${schoolIndex}`}>
                      <TableCell align="center">
                        <StyledLink to={`/admin/schools/${school._id}`}>
                          {school.name}
                        </StyledLink>
                      </TableCell>
                      <TableCell align="center">{school._id}</TableCell>
                      <TableCell align="center">
                        {school.studentCount}
                      </TableCell>
                      <TableCell align="center">
                        {school.teacherCount}
                      </TableCell>
                      <TableCell align="center">
                        {school.wheelsUsed} / {school.wheelsLimit}
                      </TableCell>
                    </TableRow>
                  ))}
              </>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={schoolData.length}
                rowsPerPage={pageSize}
                page={currentPage}
                onChangePage={(event, page) => setCurrentPage(page)}
                onChangeRowsPerPage={(event) => {
                  setPageSize(parseInt(event.target.value, 10));
                  setCurrentPage(0);
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      )}
    </StyledContainer>
  );
};

export default SchoolList;
