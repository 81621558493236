import React, { Component } from "react";
import {
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import PageContainer from "components/common/PageContainer/PageContainer";
import styled from "styled-components";
import palette from "palette";
import SchoolList from "components/admin/SchoolList";
import TableSearch from "components/admin/TableSearch";
import { IDetailedSchoolData, ISchoolListData } from "typings/school";
import AddSchoolModal from "components/admin//AddSchoolModal";
import { IResponseBody } from "typings/global";
import { addSchool, getSchoolsList } from "lib/school";
import { withAuth } from "../../../hoc/withAuth";

const StyledPaper = styled(Paper)`
  width: 100%;
  min-height: 100%;
  padding: 25px;
`;

const StyledDivider = styled.div`
  width: 100%;
  height: 3px;
  border-radius: 15px;
  background-color: ${palette.disabled};
  margin: 15px 0 0;
`;

const StyledIcon = styled.i`
  font-size: 20pt;
`;

interface IState {
  data: ISchoolListData[];
  isLoading: boolean;
  searchingSchool: string;
  addSchoolModalOpen: boolean;
}

class SchoolsPage extends Component<{}, IState> {
  state = {
    data: [],
    isLoading: true,
    searchingSchool: "",
    addSchoolModalOpen: false,
  };

  componentDidMount() {
    this.getSchoolListData();
  }

  protected getSchoolListData = async (): Promise<void> => {
    this.setState({
      isLoading: true,
    });

    const result = await getSchoolsList("");

    this.setState({
      isLoading: false,
      data: result?.data?.schools || [],
    });
  };

  protected handleSearchByName = (schoolName: string): void => {
    this.setState({
      searchingSchool: schoolName,
    });
  };

  protected getFilteredSchoolData = (): ISchoolListData[] => {
    const { data, searchingSchool } = this.state;

    return data.filter((school: IDetailedSchoolData) =>
      school.name
        .toString()
        .toUpperCase()
        .includes(searchingSchool.toUpperCase())
    );
  };

  protected closeAddSchoolModal = (): void => {
    this.setState({ addSchoolModalOpen: false });
  };

  protected openAddSchoolModal = (): void => {
    this.setState({ addSchoolModalOpen: true });
  };

  protected handleAddSchool = async (
    schoolName: string
  ): Promise<IResponseBody> => {
    try {
      const { data } = await addSchool(schoolName);
      await this.getSchoolListData();
      return data;
    } catch {
      return {};
    }
  };

  render() {
    const { isLoading, addSchoolModalOpen } = this.state;

    return (
      <PageContainer>
        <AddSchoolModal
          open={addSchoolModalOpen}
          handleAddSchool={this.handleAddSchool}
          handleClose={this.closeAddSchoolModal}
        />
        <StyledPaper>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h6">Schools</Typography>
            </Grid>
            <Grid item>
              <Tooltip title="Add School">
                <IconButton color="primary" onClick={this.openAddSchoolModal}>
                  <StyledIcon className="uil uil-plus-circle" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <StyledDivider />
          <TableSearch
            label="School Name"
            onSearch={this.handleSearchByName}
            onClear={() => this.handleSearchByName("")}
          />
          <SchoolList
            isLoading={isLoading}
            data={this.getFilteredSchoolData()}
          />
        </StyledPaper>
      </PageContainer>
    );
  }
}

export default withAuth(SchoolsPage, ["admin"]);
