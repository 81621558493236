import { Grid } from "@material-ui/core";
import React from "react";

import { EvidenceList } from "components/common/evidenceList";
import { getSortedEvidenceForList } from "lib/evidence";

import { useDetailedWheel } from "./context";

export const Evidence = () => {
  const {
    evidence,
    evidenceId,
    handleRefreshData,
    locked,
    mirrorType,
    setAreaId,
    setEvidenceId,
    setSubmitEvidenceModalOpen,
    user,
    wheelData,
  } = useDetailedWheel();

  const handleSubmitClick = () => setSubmitEvidenceModalOpen(true);

  return (
    <Grid item xl={6} xs={12}>
      <EvidenceList
        evidence={getSortedEvidenceForList(evidence)}
        variant="student"
        selectEvidenceId={evidenceId ?? ""}
        handleSubmitEvidence={handleSubmitClick}
        handleReviewEvidence={(eid, aid) => {
          setAreaId(aid);
          setEvidenceId(eid);
        }}
        handleRefreshData={handleRefreshData}
        studentName={user?.roles?.student?.name}
        wheelData={wheelData ?? []}
        wheelLocked={locked}
        mirrorLevelType={mirrorType!}
      />
    </Grid>
  );
};
