import * as csv from "csv";

type TCSVToolArrayData = Array<Array<string | number>>;

const CSVTool = {
  downloadCSVFileFromArray: (
    data: TCSVToolArrayData,
    fileName?: string
  ): void => {
    csv.stringify(data, (stringifyErr, output) => {
      if (stringifyErr) {
        console.error(
          "Something goes wrong while trying to stringify dataArr.",
          stringifyErr
        );
        return;
      }

      const csvContent = `data:text/csv;charset=utf-8,${output}`;

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");

      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${fileName ? fileName : "report"}.csv`);
      document.body.appendChild(link);

      link.click();
    });
  },
};

export default CSVTool;
