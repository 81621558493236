import { axiosInstance } from "helpers/axios-instance";
import { IGroup, IStudent } from "@rose-arch-holdings-ltd/common";

export const addGroup = (schoolId: string, name: string) =>
  axiosInstance.post("/meta-mirror/group", {
    name,
    schoolId,
  });

export const updateGroup = (groupId: string, name: string) =>
  axiosInstance.put("/meta-mirror/group", {
    name,
    groupId,
  });

export const getGroups = (teacherId: string) =>
  axiosInstance.get(`/meta-mirror/groups?teacherId=${teacherId}`);

export const getStudentListBasedOnGroup = (groupIds: string[]) =>
  axiosInstance.post(`/meta-mirror/student/group/students-id-list`, {
    groupIds,
  });

export const getGroupListBasedOnSchool = (schoolId: string) =>
  axiosInstance.get(
    `/meta-mirror/groups/school-group-list?schoolId=${schoolId}`
  );

export const addStudentsToGroup = (studentIds: string[], groupIds: string[]) =>
  axiosInstance.post(`/meta-mirror/groups/add-students-to-group`, {
    studentIds,
    groupIds,
  });

export const removeStudentsFromGroup = (
  studentIds: string[],
  groupIds: string[]
) =>
  axiosInstance.post(`/meta-mirror/groups/remove-students-from-group`, {
    studentIds,
    groupIds,
  });

export const findGroups = (): Promise<{ data: IGroup[] }> => {
  return axiosInstance.get(`/groups`);
};

export const findAllGroups = (): Promise<{ data: IGroup[] }> => {
  return axiosInstance.get(`/groups/all`);
};

export const addNewGroup = (name: string) => {
  return axiosInstance.post(`/groups`, {
    name,
  });
};

export const updateTeacherGroup = (
  groupId: string,
  name?: string,
  teacherIds?: string[]
) => {
  return axiosInstance.post(`/groups/update`, {
    groupId,
    name,
    teacherIds,
  });
};

export const lockGroupForStudent = (groundId: string, studentIds: string[]) => {
  return axiosInstance.post(`/groups/student/lock`, {
    groundId,
    studentIds,
  });
};

export const getStudentGroupStatus = (
  student: IStudent | any,
  groupId: string
): "locked" | "added" | "none" => {
  const groupLocked: boolean = student?.mm_locked_group_ids?.includes(groupId);
  const groupAdded: boolean = student?.mm_group_ids?.includes(groupId);
  return groupLocked ? "locked" : groupAdded ? "added" : "none";
};
