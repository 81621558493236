/* eslint-disable @typescript-eslint/indent */
import { Box, Button, Typography } from "@material-ui/core";
import { OpenInNewRounded } from "@material-ui/icons";
import React from "react";

import { useWheelsPage } from "../../context";
import { Sum } from "../styles";
import { useContact } from "./context";
import { getUrl } from "./helper";

export const Content = () => {
  const { school, website } = useContact();
  const { isUser } = useWheelsPage();

  const hasContact = React.useMemo(
    () => `${school}${website}` !== "",
    [school, website]
  );

  return (
    <Sum.Content>
      {hasContact ? (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {school?.length > 0 && (
            <Box sx={{ alignItems: "center", display: "flex" }}>
              <Typography
                style={{ fontWeight: "bolder", color: "black" }}
                variant="body1"
              >
                School:
              </Typography>
              <Typography
                style={{ color: "black", marginLeft: ".5rem" }}
                variant="body1"
              >
                {school}
              </Typography>
            </Box>
          )}
          {website?.length > 0 && (
            <Box sx={{ alignItems: "center", display: "flex" }}>
              <Typography
                style={{ fontWeight: "bolder", color: "black" }}
                variant="body1"
              >
                Website:
              </Typography>
              <Button
                color="primary"
                endIcon={<OpenInNewRounded />}
                href={getUrl(website)}
                target="_blank"
                type="text"
              >
                {website}
              </Button>
            </Box>
          )}
        </Box>
      ) : isUser ? (
        "Add your contact details here when you’re ready"
      ) : (
        "This user has not added any contact details yet."
      )}
    </Sum.Content>
  );
};
