import React from "react";
import { Route, Redirect, Switch, RouteComponentProps } from "react-router-dom";
import Navbar from "components/common/Navbar/Navbar";
import { GroupsPage } from "pages/teacher/sections/GroupsPage";

import AddWheelPage from "./sections/AddWheelPage";
import ManageWheelsPage from "./sections/ManageWheelsPage";
import StudentsPage from "./sections/StudentsPage";
import { StudentPage } from "./sections/studentPage";
import WheelsPage from "./sections/WheelsPage/WheelsPage";

const routes = [
  {
    path: "/teacher/my-wheels",
    Component: WheelsPage,
    exact: false,
  },
  {
    path: "/teacher/manage-wheels/add",
    Component: AddWheelPage,
    exact: true,
  },
  {
    path: "/teacher/manage-wheels",
    Component: ManageWheelsPage,
    exact: true,
  },
  {
    path: "/teacher/manage-students",
    Component: StudentsPage,
    exact: true,
  },
  {
    path: "/teacher/manage-groups",
    Component: GroupsPage,
  },
  {
    path: "/teacher/student/:id",
    Component: StudentPage,
  },
];

let currentRoute = "";

const TeacherSwitch: React.FC<RouteComponentProps> = (routeProps) => {
  currentRoute = location.pathname.split("/")[3];

  return (
    // <TransitionGroup>
    //   <CSSTransition
    //     key={currentRoute}
    //     timeout={1100}
    //     classNames="transition"
    //     unmountOnExit
    //   >
    <Switch location={routeProps.location}>
      <Redirect from="/teacher" to="/teacher/my-wheels" exact />
      {routes.map(({ path, Component, exact }, index) => (
        <Route
          exact={exact}
          path={path}
          key={`teacher-route-${index}`}
          component={Component}
        />
      ))}
    </Switch>
    //   </CSSTransition>
    // </TransitionGroup>
  );
};

const TeacherPage: React.FC<RouteComponentProps> = (routeProps) => {
  return (
    <>
      <Navbar type="teacher" />
      <TeacherSwitch {...routeProps} />
    </>
  );
};

export default TeacherPage;
