import React from "react";

// import { Loading } from "../loading";
// import { WheelProvider, useWheel } from "./context";
import { DetailedWheelPage } from "pages/student/sections/detailedWheelPage";
import { RouteComponentProps } from "react-router-dom";

// const Content = () => {
//   const { error, loading, wheel } = useWheel();

//   return loading ? (
//     <Loading />
//   ) : error ? (
//     <div>{error}</div>
//   ) : (
//     <DetailedWheelPage />
//   );
// };

export const Wheel = (props: RouteComponentProps<any>) => (
  <DetailedWheelPage isAuth {...props} />
);
