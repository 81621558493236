import React from 'react';
import styled from 'styled-components';
import { Button, Grid, Paper, Typography } from '@material-ui/core';

const StyledContainer = styled(Paper)`
  width: 100%;
  min-height: 120px;
  padding: 10px 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledTextSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const StyledTypography = styled(Typography)`
  font-weight: 800 !important;
`;

const StyledButton = styled(Button)`
  width: 100px;
`;

interface IProps {
  userName: string;
  disabled: boolean;
  handleLogin: () => void;
}

const LoginAs: React.FC<IProps> = ({ userName, handleLogin, disabled }: IProps) => (

  <StyledContainer>
    <Grid container direction="row" alignContent="center">
      <Grid item xs={12} sm={8} className="d-flex flex-column justify-content-center align-content-start mt-2 mt-sm-0">
        <StyledTextSection>
          <Typography variant="body2">
            Login as
          </Typography>
          <StyledTypography variant="body1">
            {userName}
          </StyledTypography>
        </StyledTextSection>
      </Grid>
      <Grid item xs={12} sm={4} className="mt-3 mt-sm-0 d-flex flex-row justify-content-end align-items-center">
        <StyledButton
          color="secondary"
          variant="contained"
          onClick={handleLogin}
          disabled={disabled}
        >
          login
        </StyledButton>
      </Grid>
    </Grid>

  </StyledContainer>
);

export default LoginAs;
