import { Theme } from "@material-ui/core";
import Palette from "palette";

type ThemeInterface = Exclude<Theme["overrides"], undefined>;

const MuiOutlinedInput: ThemeInterface["MuiOutlinedInput"] = {
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: Palette.font.caption,
      borderWidth: 3,
      borderRadius: 10,
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: Palette.primary,
      borderWidth: 3,
      borderRadius: 10,
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: Palette.primary,
      borderWidth: 3,
      borderRadius: 10,
    },
    "& .MuiOutlinedInput-input": {
      color: Palette.font.body,
    },
    "&:hover .MuiOutlinedInput-input": {
      color: Palette.font.body,
    },
    "&.Mui-focused .MuiOutlinedInput-input": {
      color: Palette.font.body,
      backgroundColor: "transparent",
    },
    "& .MuiInputLabel-outlined": {
      color: Palette.font.caption,
    },
    "&:hover .MuiInputLabel-outlined": {
      color: Palette.primary,
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: Palette.primary,
    },
    "@global": {
      "*::-webkit-scrollbar": {
        width: "7px",
      },
      "*::-webkit-scrollbar-track": {
        backgroundColor: "transparent",
      },
      "*::-webkit-scrollbar-thumb": {
        borderRadius: "7px",
        backgroundColor: Palette.primary,
      },
    },
  },
};

export default MuiOutlinedInput;
