import React from "react";
import { useAuth } from "../hooks/useAuth";
import { IUser } from "../typings/models";

export interface IWithAuth {
  user: IUser;
  isAuth: boolean;
}

export const withAuth = (Component: any, requiredRoles: string[]) => {
  return (props: any) => {
    const { user, isAuth } = useAuth(requiredRoles);
    if (!isAuth) {
      return <p></p>;
    } else if (user?._id) {
      return <Component user={user} isAuth={isAuth} {...props} />;
    }
    return <p>Loading</p>;
  };
};
