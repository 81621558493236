import { Theme } from "@material-ui/core";
import palette from "palette";

type ThemeInterface = Exclude<Theme["overrides"], undefined>;

const MuiRadio: ThemeInterface["MuiRadio"] = {
  root: {
    color: palette.font.body,
    backgroundColor: "inherit",
    "&:hover": {
      backgroundColor: `${palette.font.body}1A`,
    },
    "&$disabled": {
      color: `${palette.disabled} !important`,
      backgroundColor: "transparent !important",
    },
  },
  colorPrimary: {
    color: palette.primary,
    "&.Mui-checked:hover, &:hover": {
      backgroundColor: `${palette.primary}1A !important`,
    },
  },
  colorSecondary: {
    color: palette.secondary,
    "&.Mui-checked:hover, &:hover": {
      backgroundColor: `${palette.secondary}1A !important`,
    },
  },
};

export default MuiRadio;
