import { Theme } from "@material-ui/core";
import palette from "palette";

type ThemeInterface = Exclude<Theme["overrides"], undefined>;

const MuiInputBase: ThemeInterface["MuiInputBase"] = {
  root: {
    "&:before": {
      display: "none",
    },
    "&:after": {
      display: "none",
    },
    "& .MuiSelect-icon": {
      color: palette.font.caption,
      top: "auto",
      right: "8px",
    },
    "&$disabled": {
      "& fieldset.MuiOutlinedInput-notchedOutline": {
        borderColor: palette.disabled,
      },
    },
  },
};

export default MuiInputBase;
