import { Box, Button, CircularProgress } from "@material-ui/core";
import { AddRounded } from "@material-ui/icons";
import React from "react";

import TextInput from "components/common/TextInput/TextInput";

import { useWheelsPage } from "../../context";
import { useAchievements } from "./context";
import { AchievementList } from "./list";

export const Editor = () => {
  const { achievements, grade, setGrade, setSubject, subject } =
    useAchievements();
  const [loading, setLoading] = React.useState(false);
  const { addAchievement } = useWheelsPage();

  const handleAddClick = () => {
    setLoading(true);
    addAchievement(subject, grade);
  };

  React.useEffect(() => {
    setGrade("");
    setLoading(false);
    setSubject("");
  }, [achievements]);

  return (
    <Box>
      <Box
        style={{
          alignItems: "center",
          display: "flex",
          gap: ".5rem",
        }}
      >
        <TextInput
          value={subject}
          label="Subject"
          onChange={({ target }) => setSubject(target.value)}
        />
        <TextInput
          value={grade}
          label="Grade"
          onChange={({ target }) => setGrade(target.value)}
        />
        <Button
          color="primary"
          disabled={!subject || !grade || loading}
          onClick={handleAddClick}
          startIcon={loading ? <CircularProgress size={20} /> : <AddRounded />}
          variant="contained"
        >
          Add
        </Button>
      </Box>
      {achievements?.length ? <AchievementList /> : <React.Fragment />}
    </Box>
  );
};
