import { Theme } from "@material-ui/core";
import Palette from "palette";

type ThemeInterface = Exclude<Theme["overrides"], undefined>;

const MuiMenu: ThemeInterface["MuiMenu"] = {
  list: {
    border: `solid 3px ${Palette.primary}`,
    borderRadius: 10,
  },
  paper: {
    boxShadow:
      "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
  },
};

export default MuiMenu;
