import { Tooltip } from "@material-ui/core";
import React from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import { b64toBlobUrl, getFileById } from "lib/file";

import { Comment } from "./comment";
import { Link } from "./link";
import { Office } from "./office";
import { P } from "./styles";
import { PreviewComponents, PreviewProps } from "./types";

const components: PreviewComponents = {
  comment: Comment,
  link: Link,
  ".pdf": Office,
  ".doc": Office,
  ".docx": Office,
  ".pptx": Office,
};

export const Preview = ({ evidence }: PreviewProps) => {
  const [cloudUrl, setCloudUrl] = React.useState<string>();
  const [src, setSrc] = React.useState<string>();

  const Component = React.useMemo(
    () => components[evidence?.evidence.fileType as keyof PreviewComponents],
    [evidence]
  );

  React.useEffect(() => {
    if (evidence?.evidence.isCloudStorage) {
      getFileById(evidence.evidence.evidence).then(({ data }) =>
        setCloudUrl(data)
      );
    }
  }, [evidence]);

  React.useEffect(() => {
    if (cloudUrl) {
      setSrc(cloudUrl);
      return;
    }
    if (
      evidence?.evidence.isCloudStorage === false &&
      !["comment", "link"].includes(evidence?.evidence.fileType)
    ) {
      setSrc(b64toBlobUrl(evidence.evidence.evidence));
    }
  }, [cloudUrl]);

  return Component ? (
    <Component
      evidence={evidence}
      fileType={evidence?.evidence.fileType?.replace(".", "")}
      src={src}
    />
  ) : (
    <P>
      <Tooltip title="Scroll or Pinch to zoom in and out" enterDelay={1000}>
        <P.Section>
          <P.Container>
            <TransformWrapper initialScale={1} minScale={0.5} maxScale={5}>
              <TransformComponent
                wrapperStyle={{ width: "100%", height: "100%" }}
              >
                <P.Image
                  src={cloudUrl ?? evidence?.evidence.evidence}
                  alt="Evidence Preview image"
                />
              </TransformComponent>
            </TransformWrapper>
          </P.Container>
        </P.Section>
      </Tooltip>
    </P>
  );
};
