import React from "react";
import { XYPlot, ArcSeries, LabelSeries, GradientDefs } from "react-vis";
import palette from "palette";
import { ILevel, TWheelData } from "typings/meta-mirror";
import styled from "styled-components";
import { Switch, Tooltip, Typography } from "@material-ui/core";
import { LEVEL_PARTS } from "constants/index";

const StyledHelpTooltipIcon = styled.i`
  transition: 1s;
  font-size: 20pt;
  color: ${palette.font.caption};
`;

const StyledTooltip = styled(Tooltip)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const StyledInteractiveContainer = styled.div<{ direction: "column" | "row" }>`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: ${(props) => props.direction};
  justify-content: flex-start;
  align-items: center;
  align-content: center;
`;

const StyledDescriptionHeading = styled(Typography)`
  width: 100% !important;
  font-weight: 700 !important;
  font-size: 20px !important;
`;

const StyledDescriptionContainer = styled.div`
  width: 100%;
  height: 100%;
  transition: 0.5s;
  min-height: 200px;
  max-height: calc((100vh - 395px) / 2);
  overflow-y: auto;
  @media (max-width: 1199px) {
    max-height: 35vh;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 7px;
    background-color: ${palette.font.caption};
  }
`;

const StyledPlotContainer = styled.div<{
  verticalPadding: number;
  horizontalPadding: number;
}>`
  padding: ${(props) =>
    `${props.verticalPadding}px ${props.horizontalPadding}px`};
`;

const StyledStudentDataToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 10px;
  top: 10px;
`;

const StyledSwitchIcon = styled.i`
  font-size: 20pt;
  color: ${palette.font.caption};
`;

type TArc = {
  id: string;
  angle0: number;
  angle: number;
  opacity: number;
  radius: number;
  radius0: number;
  fill: string;
  stroke: string;
  description: string;
  name: string;
};

type TLabel = {
  id: string;
  x: number;
  y: number;
  label: string;
  style: object;
};
enum ArcColors {
  "disabled" = null,
  "attempting" = 0,
  "partial" = 0.5,
  "complete" = 1,
}

interface IState {
  /**
   * The unformatted data being used in the chart.
   * This data is from grades set by teachers.
   */
  dataIn: TWheelData;
  /**
   * The unformatted data being used in the chart.
   * This data is from grades set by students.
   */
  studentDataIn: TWheelData;
  /**
   * The formatted data being used in the chart.
   */
  formattedData: TArc[];
  /**
   * The area labels being utilised in the LabelSeries component.
   */
  formattedDataLabels: TLabel[];
  /**
   * The id of the selected arc.
   */
  selectedArcId: string;
  /**
   * The name of the selected arc.
   */
  selectedArcName: string;
  /**
   * The description of the selected arc.
   */
  selectedArcDescription: string;
  /**
   * Whether to show student wheel results data or teacher wheel result data.
   */
  showStudentData: boolean;
  /**
   * Have the student data toggled by default
   */
  toggleStudentByDefault?: boolean;
}

interface IProps {
  /**
   * The teacher-set data to be displayed in the chart, arranged into groups by area.
   */
  readonly data: TWheelData;
  /**
   * The student-set data to be displayed in the chart, arranged into groups by area.
   */
  readonly studentData?: TWheelData;
  /**
   * The background color of the chart.
   */
  backgroundColor: string;
  /**
   * The width of the chart container.
   * If width is smaller than height, it is used as the radius of the wheel.
   */
  width: number;
  /**
   * The height of the chart container.
   * If height is smaller than width, it is used as the radius of the wheel.
   */
  height: number;
  /**
   * Vertical Padding applied to the wheel  component.
   */
  verticalPadding?: number;
  /**
   * Horizontal Padding applied to the wheel  component.
   */
  horizontalPadding?: number;
  /**
   * Whether or not the chart is interactive.
   * If enabled, this will return a clickable chart with rubrics for each level.
   * @defaultValue false
   */
  interactive?: boolean;
  /**
   * Whether or not the chart will contain data..
   * If enabled, this will return a preview of the charts shape and appearance, but will display no grades/colors for each level.
   * @defaultValue false
   */
  preview?: boolean;
  /**
   * Whether or not the chart will show data labels for each area.
   * If disabled, the labels for the wheel areas will be hidden.
   */
  labels: boolean;
  /**
   * Location of the level descriptions.
   * If supplied, this will render a description for the selected level
   * @requires interactive === true to render descriptions
   * @defaultValue null
   */
  descriptions?: "bottom" | "right";
  /**
   * If true will show student data toggle switch.
   * Will only render if studentData is also supplied.
   * @defaultValue false
   */
  enableStudentData?: boolean;

  toggleStudentByDefault?: boolean;
  mirrorLevelType?: string;
}

class Wheel extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      dataIn: props.data,
      studentDataIn: props.studentData || [],
      formattedData: [],
      formattedDataLabels: [],
      selectedArcId: "",
      selectedArcDescription: "",
      selectedArcName: "",
      showStudentData: false,
      toggleStudentByDefault: props?.toggleStudentByDefault || false,
    };
  }

  componentDidMount() {
    this.formatData(
      this.state.selectedArcId,
      this.state.dataIn,
      this.state.studentDataIn,
      this.state.showStudentData
    );
    if (this.state.toggleStudentByDefault) {
      this.toggleShowStudentData();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        dataIn: this.props.data,
        studentDataIn: this.props.studentData,
        selectedArcId: "",
      });
      this.formatData(
        "",
        this.props.data,
        this.props.studentData,
        this.state.showStudentData
      );
    }
  }

  /**
   * Formats data for the chart.
   * Creates a data set for both the LineSeries and the ArcSeries and sets them into state.
   * @param selectedArcId ID of the selected Arc
   * @param dataIn The areas and their levels for which arcs will be drawn with teacher grades.
   * @param studentDataIn The areas and their levels for which arcs will be drawn with student grades.
   * @param renderStudentData Whether to render the studentData or not.
   */
  protected formatData = (
    selectedArcId: string,
    dataIn: TWheelData,
    studentDataIn: TWheelData,
    renderStudentData: boolean
  ): void => {
    const { backgroundColor, preview, interactive, labels } = this.props;
    const formattedData: TArc[] = [];

    const totalAreas = dataIn.length;
    const areaAngle = 360 / totalAreas;
    const singleDegreeRadian = Math.PI / 180;
    const areaAngleRadians = areaAngle * singleDegreeRadian;

    const levelWidth = 5 / dataIn[0].levels.length;

    dataIn.forEach((area, areaIndex) => {
      area.levels.forEach((level, levelIndex) => {
        const id = `${area._id}-${level.level}`;

        const angle0 = areaIndex * areaAngleRadians;
        const angle = angle0 + areaAngleRadians;
        const radius0 = levelIndex * levelWidth;
        const radius = radius0 + levelWidth;
        let fill = palette[ArcColors[level.grade]];
        const stroke = backgroundColor;

        let opacity = 0.35;

        if (preview) {
          fill = palette.disabled;
        }

        // If not preview and interactive, then configure colors for when arc is selected
        if (!preview && interactive) {
          if (selectedArcId.length && id === selectedArcId) {
            opacity = 1;
            if (level.grade === null) {
              fill = "#D1D8E0";
            }
          }
        }

        if (!selectedArcId.length) {
          opacity = 1;
        }

        if (studentDataIn && renderStudentData) {
          const currentStudentLevel =
            studentDataIn[areaIndex].levels[levelIndex];
          const patternId = `${ArcColors[currentStudentLevel.grade]}`;
          fill = `url(#${patternId})`;
        }
        const formatted = {
          id,
          angle0,
          angle,
          radius0,
          radius,
          opacity,
          fill,
          stroke,
          description: level.description ?? "",
          name: `${area.areaName} ${level.name ?? `Level ${level.level}`}`,
        };
        if (this.props.mirrorLevelType === "non-sequential") {
          if (!level.name) {
            const nameArray = formatted.name.split(" ");
            nameArray.push(LEVEL_PARTS[level.level - 1]);
            formatted.name = nameArray.join(" ").replace("Level", "Part");
          }
        }
        formattedData.push(formatted);
      });
    });

    if (labels) {
      this.generateDataLabels(dataIn, selectedArcId);
    }

    this.setState({ formattedData });
  };

  /**
   * Generates the data for the label series, these are the area labels on the chart.
   * @param areas The areas which need to be labeled.
   * @param selectedArcId - The id of the currently selected arc.
   */
  protected generateDataLabels = (areas: TWheelData, selectedArcId: string) => {
    const formattedDataLabels: TLabel[] = [];
    const areaCount = areas.length;
    const areasEven = areaCount % 2 === 0;
    const middle = Math.round(areaCount / 2);

    const areaLabels = areas.map(({ areaName }) => areaName);
    const fontSize = this.getLabelFontSize(
      areaLabels.sort((a, b) => b.length - a.length)[0].length
    );

    const { height, width, verticalPadding, horizontalPadding } = this.props;
    const localHeight = height - (verticalPadding || 0) * 2;
    const localWidth = width - (horizontalPadding || 0) * 2;
    const isHeightBigger = localHeight > localWidth;
    const offset = isHeightBigger
      ? -1 + localHeight * 0.001
      : -1 + localWidth * 0.001;
    let ratio = localWidth / localHeight;
    let xOffset = offset;
    let yOffset = offset;
    if (isHeightBigger) {
      ratio = localHeight / localWidth;
      xOffset = offset;
      yOffset = offset * ratio;
    }

    areas.forEach((area, areaIndex) => {
      const labelText = area.areaName;
      const selectedArcIdAreaId = selectedArcId.substr(
        0,
        selectedArcId.indexOf("-")
      );

      const active = selectedArcIdAreaId === labelText;
      const areaAngle = 360 / areaCount;
      const areaAngleRadians = areaAngle * (Math.PI / 180);
      const labelAngle = areaAngleRadians * areaIndex + areaAngleRadians / 2;
      const labelRadius = 4.8;
      let y = labelRadius * Math.cos(labelAngle) + yOffset;
      const xCorrection = localWidth * 0.00025;
      let x = labelRadius * Math.sin(labelAngle) + xOffset - xCorrection;
      const label = labelText;
      const labelId = `${labelText}-label`;

      if (!!verticalPadding) {
        x = x * (0.9 - verticalPadding / height);
      }

      if (!!horizontalPadding) {
        y = y * (0.9 - horizontalPadding / width);
      }

      let textAnchor = "start";
      if (areaIndex + 1 > middle) {
        textAnchor = "end";
      }
      if (!areasEven && areaIndex + 1 === middle) {
        textAnchor = "middle";
      }

      const translateX = 3;

      const style = {
        transition: "1s",
        fontSize,
        fontWeight: active ? 800 : 600,
        fontFamily: '"Nunito", sans-serif',
        fill: active ? palette.font.heading : palette.font.caption,
        textAnchor,
        transform: `translate(-${translateX}, 0)`,
      };

      if (label.length < 20) {
        formattedDataLabels.push({
          id: labelId,
          label,
          x,
          y,
          style,
        });
      } else {
        const labels = label.match(/.{1,20}(\s|$)/g);

        let ySpacing =
          localHeight > localWidth ? localWidth * 0.035 : localHeight * 0.035;
        if (ySpacing < 10) {
          ySpacing = 10;
        }
        if (ySpacing > 25) {
          ySpacing = 25;
        }

        const startY = -(ySpacing * (labels.length / 2)) + 7;
        labels.forEach((labelSplitText, index) => {
          formattedDataLabels.push({
            id: labelId,
            label: labelSplitText,
            x,
            y,
            style: {
              transition: "1s",
              fontSize,
              fontWeight: active ? 800 : 600,
              fontFamily: '"Nunito", sans-serif',
              fill: active ? palette.font.heading : palette.font.caption,
              textAnchor,
              transform: `translate(-${translateX}, ${
                startY + index * ySpacing
              })`,
            },
          });
        });
      }
    });

    this.setState({ formattedDataLabels });
  };

  protected getLabelFontSize = (labelLength: number): number => {
    let stringLength = labelLength;
    if (stringLength < 20) {
      stringLength = 20;
    }

    const maxFontSize = 16;

    const minStringLength = 20;
    const maxStringLength = 40;
    const minFontModifier = 0;
    const maxFontModifier = 3;

    const charRange = maxStringLength - minStringLength;
    const fontRange = maxFontModifier - minFontModifier;

    const fontSize =
      maxFontSize -
      (((stringLength - minStringLength) * fontRange) / charRange +
        minFontModifier);

    const { width, height, horizontalPadding, verticalPadding } = this.props;
    const paddedWidth = width - horizontalPadding * 2;
    const paddedHeight = height - verticalPadding * 2;
    if (paddedWidth < 300 || paddedHeight < 300) {
      return fontSize - 5;
    }
    if (paddedWidth < 400 || paddedHeight < 400) {
      return fontSize - 3;
    }
    return fontSize;
  };

  protected getStudentGradeGradients = () => {
    const patternDefinitions = [];
    for (const studentColor in ArcColors) {
      patternDefinitions.push(
        <pattern
          id={`${ArcColors[studentColor]}`}
          width="25"
          height="25"
          patternUnits="userSpaceOnUse"
          patternTransform="rotate(55)"
        >
          <line
            x1="0"
            y="0"
            x2="0"
            y2="25"
            stroke={palette[ArcColors[studentColor]]}
            strokeWidth="20"
          />
          <rect
            width="40"
            height="40"
            fill={`${palette[ArcColors[studentColor]]}99`}
          />
          {/* <rect width="40" height="40" fill={palette[ArcColors[teacherColor]]} />
              <circle cx="20" cy="20" r="10" fill={palette[ArcColors[studentColor]]} /> */}
        </pattern>
      );
    }
    return <GradientDefs>{patternDefinitions}</GradientDefs>;
  };

  /**
   * Function fired when a level is clicked.
   * @param d The data object associated with the arc - TArc[]
   * @param e The event triggered by the svg element.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected onArcClick = (d, e): void => {
    const { preview, interactive } = this.props;

    if (!preview && interactive) {
      const selectedArcId = d.id;
      if (selectedArcId === this.state.selectedArcId) {
        this.setState({
          selectedArcId: "",
          selectedArcDescription: "",
          selectedArcName: "",
        });
        this.formatData(
          "",
          this.state.dataIn,
          this.state.studentDataIn,
          this.state.showStudentData
        );
      } else {
        this.setState({
          selectedArcId,
          selectedArcDescription: d.description,
          selectedArcName: d.name,
        });
        this.formatData(
          selectedArcId,
          this.state.dataIn,
          this.state.studentDataIn,
          this.state.showStudentData
        );
      }
    }
  };

  /**
   * Function fired on double clicking the chart anywhere.
   * At present, this unselects a selected arc.
   */
  protected onDoubleClick = (): void => {
    this.setState({ selectedArcId: "" });
    this.formatData(
      "",
      this.state.dataIn,
      this.state.studentDataIn,
      this.state.showStudentData
    );
  };

  protected toggleShowStudentData = (): void => {
    const newValue = !this.state.showStudentData;
    this.formatData(
      this.state.selectedArcId,
      this.state.dataIn,
      this.state.studentDataIn,
      newValue
    );
    this.setState({ showStudentData: newValue });
  };

  protected renderStudentDataToggleSwitch = (): void | JSX.Element => {
    const { studentData, enableStudentData } = this.props;
    if (!studentData || !enableStudentData) {
      return;
    }

    const { showStudentData } = this.state;
    return (
      <StyledStudentDataToggleContainer>
        <Tooltip title="Teacher Assigned Grades">
          <StyledSwitchIcon className="il uil-graduation-cap" />
        </Tooltip>
        <Switch
          color="primary"
          value={showStudentData}
          onChange={() => this.toggleShowStudentData()}
          defaultChecked={this.state.toggleStudentByDefault}
        />
        <Tooltip title="Student Assigned Grades">
          <StyledSwitchIcon className="il uil-book-reader" />
        </Tooltip>
      </StyledStudentDataToggleContainer>
    );
  };

  render() {
    const {
      formattedData,
      formattedDataLabels,
      selectedArcDescription,
      selectedArcName,
    } = this.state;
    const {
      height,
      width,
      interactive,
      backgroundColor,
      labels,
      descriptions,
      verticalPadding,
      horizontalPadding,
    } = this.props;

    const isHeightBigger = height > width;

    const strokeWidth = !isHeightBigger ? height / 100 : width / 100;
    const offset = isHeightBigger ? -1 + height / 1000 : -1 + width / 1000;

    let ratio = width / height;
    let xDomain = [-5 * ratio, 5 * ratio];
    let yDomain = [-5, 5];
    let centerPos = { x: offset * ratio, y: offset };
    if (isHeightBigger) {
      ratio = height / width;
      xDomain = [-5, 5];
      yDomain = [-5 * ratio, 5 * ratio];
      centerPos = { x: offset, y: offset * ratio };
    }

    let vPadding = verticalPadding;
    let hPadding = horizontalPadding;
    if (!vPadding) {
      vPadding = 0;
    }
    if (!hPadding) {
      hPadding = 0;
    }

    const baseChart = (
      <StyledPlotContainer
        verticalPadding={vPadding}
        horizontalPadding={hPadding}
      >
        {this.renderStudentDataToggleSwitch()}
        <XYPlot
          animation={{ duration: 1500 }}
          {...{ xDomain, yDomain }}
          width={width - hPadding * 2}
          height={height - vPadding * 2}
          onDoubleClick={this.onDoubleClick}
          style={{ backgroundColor }}
        >
          {this.getStudentGradeGradients()}
          <ArcSeries
            fillType="literal"
            strokeType="literal"
            radiusDomain={[0, labels ? 6.5 : 5]}
            center={centerPos}
            data={formattedData}
            onValueClick={(d, e) => this.onArcClick(d, e)}
            style={{ strokeWidth }}
          />
          <LabelSeries data={formattedDataLabels} allowOffsetToBeReversed />
        </XYPlot>
      </StyledPlotContainer>
    );

    if (!(descriptions && interactive)) {
      return baseChart;
    }

    return (
      <StyledInteractiveContainer
        direction={descriptions === "bottom" ? "column" : "row"}
      >
        {baseChart}
        <StyledTooltip
          title={
            <>
              Click on a level to see the corresponding rubric.
              <br />
              Click the level again to deselect.
            </>
          }
          placement="left"
        >
          <StyledHelpTooltipIcon className="il uil-question-circle" />
        </StyledTooltip>
        <StyledDescriptionHeading variant="body1">
          {this.props.mirrorLevelType === "sequential" ? "Level" : "Part"}{" "}
          Breakdown {!!selectedArcName && `- ${selectedArcName}`}
        </StyledDescriptionHeading>
        {!!selectedArcName ? (
          <StyledDescriptionContainer
            dangerouslySetInnerHTML={{ __html: selectedArcDescription }}
          />
        ) : (
          <StyledDescriptionContainer>
            <Typography variant="caption">
              Please select an area on the wheel above to view the corresponding
              rubric.
            </Typography>
          </StyledDescriptionContainer>
        )}
      </StyledInteractiveContainer>
    );
  }
}

export default Wheel;
