import React, { Component } from "react";
import {
  IWheelsPageContextProps,
  WheelsPageContext,
} from "../../states/WheelsPageContext";
import SelectedWheelStudentList, {
  IArea as ISelectedWheelArea,
  ISelectedWheelData,
  IStudentData,
} from "./SelectedWheelStudentList";
import styled from "styled-components";
import { IArea, ILevel, TWheelData } from "typings/meta-mirror";
import TableViewFilters, { IAreaFilter } from "./TableViewFilters";
import SelectedAreaStudentList, {
  ISelectedAreaStudentData,
} from "./SelectedAreaStudentList";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import {
  IStudentTableViewFilters,
  IStudentWheelResultsData,
  StudentWheelResultEvidence,
} from "typings/student";
import { Button, CircularProgress } from "@material-ui/core";
import FeedbackModal from "../common/feedbackModal";
import { IFeedbackWithDetails } from "typings/feedback";
import WheelDataFilteringTool from "../../pages/teacher/sections/WheelsPage/tools/wheelDataFilteringTool";
import { IGroupData } from "typings/group";
import { quickEditMirror } from "lib/mirror";
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0 0;
  overflow: hidden;
`;

const StyledFiltersContainer = styled.div`
  margin: 0 0 20px;
`;

const StyledActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const StyledLoaderContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 100px 0;
`;

export interface IQuickEditUpdate {
  studentId: string;
  levelId: string;
  grade: ILevel["grade"];
}

interface IState {
  isReviewEvidenceModalOpen: boolean;
  isQuickEditEnabled: boolean;
  quickEditUpdates: IQuickEditUpdate[];
}

class TableView extends Component<{}, IState> {
  public context: IWheelsPageContextProps;

  public componentDidMount(): void {
    this.getStudentListData();
  }

  public state = {
    isReviewEvidenceModalOpen: false,
    isQuickEditEnabled: false,
    quickEditUpdates: [],
  };

  protected areaValueBasedOnLevels = (levels: ILevel[]): string | number => {
    const levelsNumber = levels.length;

    const levelType = this.context.wheelResults?.levelType;

    if (levelType !== "non-sequential") {
      for (let i = levelsNumber; i >= 0; --i) {
        const lookingResult = levels.find(({ level }) => level === i);
        if (lookingResult && lookingResult.grade === 1) {
          return i;
        } else if (lookingResult && lookingResult.grade === 0.5) {
          return i - 1;
        }
      }
    } else {
      let gradeCount = 0;
      for (let i = levelsNumber; i >= 0; --i) {
        const lookingResult = levels.find(({ level }) => level === i);
        if (lookingResult && lookingResult.grade === 1) {
          gradeCount++;
        }
      }
      return `${gradeCount}/${levelsNumber}`;
    }
    return 0;
  };

  protected getAreasList = (): IAreaFilter[] => {
    const { wheelResults } = this.context;
    const areas: IAreaFilter[] = [];

    if (wheelResults.studentsResults[0]) {
      wheelResults.studentsResults[0].areas.forEach(({ areaName, _id }) => {
        areas.push({
          value: _id,
          label: areaName,
        });
      });
    }

    return areas;
  };

  protected getAreaData = (areas: TWheelData): ISelectedWheelArea[] => {
    //
    const preparedAreaData: ISelectedWheelArea[] = [];

    // console.log(areas);

    areas.forEach((area) => {
      preparedAreaData.push({
        name: area.areaName,
        level: this.areaValueBasedOnLevels(area.levels),
      });
    });

    return preparedAreaData;
  };

  protected getFilteredStudentListData = (): IStudentWheelResultsData[] => {
    const { wheelResults, tableViewSelectedFilters } = this.context;

    // console.log(wheelResults.studentsResults);

    return WheelDataFilteringTool.getFilteredTableViewStudentListData(
      wheelResults.studentsResults,
      tableViewSelectedFilters,
      wheelResults?.groupIds
    );
  };

  protected isUpdatedEvidence = ({
    evidences,
    messages,
  }: IStudentWheelResultsData) => {
    if (evidences?.some(({ viewed }) => !viewed)) return true;
    const filtered = messages?.filter(
      ({ teacher, teacherId }) => !teacher && !teacherId
    );
    if (filtered?.some(({ viewed }) => !viewed)) return true;
    return false;
  };

  protected getStudentListData = (): ISelectedWheelData => {
    const students: IStudentData[] = [];

    this.getFilteredStudentListData().forEach((studentResult) => {
      students.push({
        _id: studentResult._id,
        name: studentResult.name,
        areas: this.getAreaData(studentResult.areas),
        isNewEvidence: this.isUpdatedEvidence(studentResult),
        mm_locked_group_ids: studentResult.lockedGroupIds,
        mm_group_ids: studentResult.groupIds,
      });
    });

    return {
      students,
      areas: this.getAreasList(),
    };
  };

  protected handleFiltersSelect = (
    selectedFilter: Partial<IStudentTableViewFilters>
  ): void => {
    const { isQuickEditEnabled, quickEditUpdates } = this.state;
    const { tableViewSelectedFilters } = this.context;

    if (
      isQuickEditEnabled &&
      !!quickEditUpdates.length &&
      Object.keys(selectedFilter).includes("area") &&
      selectedFilter.area !== tableViewSelectedFilters.area
    ) {
      if (
        confirm(
          "Swiching or clearing the area filter will cause you to lose unsaved edits.\nAre you sure you want to continue?"
        )
      ) {
        this.setState(
          {
            isQuickEditEnabled: false,
            quickEditUpdates: [],
          },
          () => {
            this.context.handleTableViewFilterSelect(selectedFilter);
          }
        );
      }
      return;
    }
    this.setState(
      {
        isQuickEditEnabled: false,
      },
      () => {
        this.context.handleTableViewFilterSelect(selectedFilter);
      }
    );
  };

  protected handleClearFilters = (): void => {
    this.context.handleTableViewFilterSelect({
      area: "",
      other: [],
      yearGroups: [],
      studentGroups: [],
      sex: [],
      lastLogin: 0,
    });
  };

  protected getLevelsBasedOnSelectedArea = (
    selectedArea: string,
    areas: IArea[]
  ): ILevel[] => {
    const lookingArea = areas.filter((area) => area._id === selectedArea)[0];

    if (lookingArea) {
      return lookingArea.levels;
    }

    return [];
  };

  protected getFeedbackListBasedOnSelectedArea = (
    selectedArea: string,
    feedbacks: IFeedbackWithDetails[]
  ): IFeedbackWithDetails[] => {
    return feedbacks.filter((feedback) => feedback.areaId === selectedArea);
  };

  protected hasUncheckedEvidenceForArea = (
    selectedArea: string,
    evidence: StudentWheelResultEvidence[]
  ): boolean => {
    const uncheckedEvidence = evidence.find(
      (singleEvidence) =>
        singleEvidence.areaId === selectedArea && !singleEvidence.viewed
    );
    return !!uncheckedEvidence;
  };

  protected getSelectedAreaStudentListData = (): ISelectedAreaStudentData[] => {
    const { tableViewSelectedFilters } = this.context;
    const preparedData: ISelectedAreaStudentData[] = [];

    this.getFilteredStudentListData().forEach(
      ({ _id, name, areas, feedbacks, evidences }) => {
        preparedData.push({
          _id,
          name,
          feedbacks: this.getFeedbackListBasedOnSelectedArea(
            tableViewSelectedFilters.area,
            feedbacks
          ),
          levels: this.getLevelsBasedOnSelectedArea(
            tableViewSelectedFilters.area,
            areas
          ),
          uncheckedEvidence: this.hasUncheckedEvidenceForArea(
            tableViewSelectedFilters.area,
            evidences
          ),
        });
      }
    );

    return preparedData;
  };

  protected handleToggleReviewEvidenceModal = (isOpen: boolean): void => {
    if (!isOpen) {
      this.handleReloadWheelData();
    }
    this.setState({ isReviewEvidenceModalOpen: isOpen });
  };

  protected getUnseenEvidencesIds = (): string[] => {
    const evidencesArr: string[] = [];
    const { area } = this.context.tableViewSelectedFilters;

    this.getFilteredStudentListData().forEach(({ evidences }) => {
      if (evidences.length) {
        evidences.forEach((evidence) => {
          if (evidence.areaId === area) {
            evidencesArr.push(evidence._id);
          }
        });
      }
    });

    return evidencesArr;
  };

  protected getAreaLevels = (): ILevel[] => {
    const { area } = this.context.tableViewSelectedFilters;

    let allLevels = [];

    this.getFilteredStudentListData().forEach(({ areas }) => {
      areas.forEach(({ _id, levels }) => {
        if (area === _id) {
          allLevels = [
            ...allLevels,
            ...levels.map(({ _id: levelId, level, description }) => ({
              _id: levelId,
              level,
              description,
            })),
          ];
        }
      });
    });

    return [...new Set(allLevels.map((level) => JSON.stringify(level)))].map(
      (s) => JSON.parse(s)
    );
  };

  protected handleReloadWheelData = (): void => {
    const { wheelResults, handleGetSelectedWheelData } = this.context;

    handleGetSelectedWheelData(wheelResults._id);
  };

  protected handleSendQuickEditData = async (): Promise<boolean> => {
    const { quickEditUpdates } = this.state;
    const { tableViewSelectedFilters } = this.context;

    try {
      await quickEditMirror(
        this.context.wheelResults._id,
        tableViewSelectedFilters.area,
        quickEditUpdates
      );
      return true;
    } catch {
      return false;
    }
  };

  protected handleToggleQuickEdit = (isQuickEditEnabled: boolean): void => {
    this.setState({
      isQuickEditEnabled,
      quickEditUpdates: [],
    });
  };

  protected handleSaveQuickEdit = async (): Promise<void> => {
    if (await this.handleSendQuickEditData()) {
      this.handleToggleQuickEdit(false);
      this.handleReloadWheelData();
    }
  };

  protected handleRemoveQuickEditUpdate = (
    studentId: string
  ): IQuickEditUpdate[] => {
    const { quickEditUpdates } = this.state;
    const index = quickEditUpdates.findIndex(
      (update) => update.studentId === studentId
    );
    if (index >= 0) {
      quickEditUpdates.splice(index, 1);
      this.setState({ quickEditUpdates });
    }
    return quickEditUpdates;
  };

  protected handleAddQuickEditUpdate = (
    studentId: string,
    levelId: string,
    grade: ILevel["grade"]
  ): void => {
    const quickEditUpdates = this.handleRemoveQuickEditUpdate(studentId);
    quickEditUpdates.push({
      studentId,
      levelId,
      grade,
    });
    this.setState({ quickEditUpdates });
  };

  protected getFilterGroups = (): IGroupData[] => {
    if (!!this.context.wheelResults) {
      const { wheelResults, groups } = this.context;
      return groups.filter((group) =>
        wheelResults.groupIds.includes(group._id)
      );
    }
  };

  protected getOther = (): string[] => {
    const otherArr: string[] = [];

    if (!!this.context.wheelResults) {
      const { wheelResults } = this.context;

      wheelResults?.studentsResults.forEach((student) => {
        student?.other.forEach((otherItem) => {
          otherArr.push(otherItem);
        });
      });
    }

    return [...new Set(otherArr)];
  };

  render() {
    const { isReviewEvidenceModalOpen, isQuickEditEnabled, quickEditUpdates } =
      this.state;
    const { tableViewSelectedFilters, isLoading, wheelResults } = this.context;
    return (
      <WheelsPageContext.Consumer>
        {() => (
          <StyledContainer>
            <StyledFiltersContainer>
              <TableViewFilters
                selectedArea={tableViewSelectedFilters.area}
                selectedStudentGroups={tableViewSelectedFilters.studentGroups}
                selectedYearGroups={tableViewSelectedFilters.yearGroups}
                selectedOthers={tableViewSelectedFilters.other}
                selectedSex={tableViewSelectedFilters.sex}
                selectedLastLogin={tableViewSelectedFilters.lastLogin}
                lockedGroupsViewable={
                  tableViewSelectedFilters.lockedGroupsViewable
                }
                areas={this.getAreasList()}
                groups={this.getFilterGroups()}
                other={this.getOther()}
                disabled={isLoading}
                onFilterSelect={this.handleFiltersSelect}
                resetFilters={this.handleClearFilters}
              />
            </StyledFiltersContainer>
            {isLoading ? (
              <StyledLoaderContainer>
                <CircularProgress size={48} />
              </StyledLoaderContainer>
            ) : (
              <>
                <SwitchTransition>
                  {!tableViewSelectedFilters.area ? (
                    <CSSTransition
                      timeout={500}
                      classNames="switch-view"
                      unmountOnExit
                      key="selected-wheel-student-list"
                    >
                      <SelectedWheelStudentList
                        data={this.getStudentListData()}
                        currentGroup={tableViewSelectedFilters.studentGroups}
                        groups={this.getFilterGroups()}
                      />
                    </CSSTransition>
                  ) : (
                    <CSSTransition
                      timeout={500}
                      classNames="switch-view"
                      unmountOnExit
                      key={`selected-area-student-list-${tableViewSelectedFilters.area}`}
                    >
                      <div>
                        <StyledActionContainer>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() =>
                              this.handleToggleReviewEvidenceModal(true)
                            }
                            disabled={
                              isLoading || !this.getUnseenEvidencesIds().length
                            }
                          >
                            view unchecked evidence
                          </Button>
                          <div>
                            {isQuickEditEnabled && (
                              <Button
                                variant="outlined"
                                color="primary"
                                className="mr-2"
                                onClick={() =>
                                  this.handleToggleQuickEdit(false)
                                }
                              >
                                {!quickEditUpdates.length
                                  ? "cancel edit"
                                  : "discard edits"}
                              </Button>
                            )}
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={
                                isQuickEditEnabled
                                  ? this.handleSaveQuickEdit
                                  : () => this.handleToggleQuickEdit(true)
                              }
                              disabled={
                                true
                                // isQuickEditEnabled && !quickEditUpdates.length
                              }
                            >
                              {isQuickEditEnabled ? "save" : "quick edit"}
                            </Button>
                          </div>
                        </StyledActionContainer>
                        <SelectedAreaStudentList
                          data={this.getSelectedAreaStudentListData()}
                          quickEditEnabled={isQuickEditEnabled}
                          addQuickEditUpdate={this.handleAddQuickEditUpdate}
                          removeQuickEditUpdate={
                            this.handleRemoveQuickEditUpdate
                          }
                        />
                      </div>
                    </CSSTransition>
                  )}
                </SwitchTransition>
              </>
            )}

            <FeedbackModal
              wheelLevelType={this.context.wheelResults?.levelType}
              open={isReviewEvidenceModalOpen}
              areaName={this.context.wheelResults?.name || ""}
              areaLevels={this.getAreaLevels()}
              evidenceIds={this.getUnseenEvidencesIds()}
              handleCloseModal={() =>
                this.handleToggleReviewEvidenceModal(false)
              }
            />
          </StyledContainer>
        )}
      </WheelsPageContext.Consumer>
    );
  }
}

TableView.contextType = WheelsPageContext;

export default TableView;
