import React from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import styled from "styled-components";
import palette from "palette";

const StyledGroupContainer = styled(RadioGroup)`
  display: flex;
  align-items: center;
  flex-direction: row !important;
`;

const StyledFormLabel = styled.div`
  font-size: 14px;
  color: ${palette.font.body};
  margin: 0 0 -8px;
`;

interface IProps {
  selectedFilter: number;
  onUpdate: (value: number) => void;
  disabled: boolean;
}

const LastLoginFilters: React.FC<IProps> = ({
  selectedFilter,
  onUpdate,
  disabled,
}: IProps) => (
  <FormControl>
    <StyledFormLabel>Last Login</StyledFormLabel>
    <StyledGroupContainer
      value={selectedFilter}
      onChange={(event) => onUpdate(parseInt(event.target.value, 10))}
    >
      <FormControlLabel
        value={0}
        control={<Radio color="primary" />}
        label="Any"
        disabled={disabled}
      />
      <FormControlLabel
        value={1}
        control={<Radio color="primary" />}
        label="Last 7 Days"
        disabled={disabled}
      />
      <FormControlLabel
        value={2}
        control={<Radio color="primary" />}
        label="Over 7 Days Ago"
        disabled={disabled}
      />
    </StyledGroupContainer>
  </FormControl>
);

export default LastLoginFilters;
