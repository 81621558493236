import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import Select from "components/common/Select/Select";
import { IGroupData } from "typings/group";
import LastLoginFilters from "./LastLoginFilters";
import SexFilters from "./SexFilters";
import LockedGroupFilter from "components/teacher/LockedGroupCheckbox";

const AVAILABLE_YEAR_GROUP_FILTER = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

const StyledCheckboxesContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  margin-top: -8px;
`;

export interface IAreaFilter {
  value: string;
  label: string;
}

interface ISelectedFilter {
  [key: string]: string | string[] | number | boolean;
}

interface IProps {
  selectedArea: string;
  selectedYearGroups: string[];
  selectedOthers: string[];
  selectedStudentGroups: string[];
  selectedSex: string[];
  selectedLastLogin: number;
  lockedGroupsViewable: boolean;
  areas: IAreaFilter[];
  groups: IGroupData[];
  other: string[];
  disabled: boolean;
  onFilterSelect: (value: ISelectedFilter) => void;
  resetFilters(): void;
}

const TableViewFilters: React.FC<IProps> = ({
  selectedArea,
  selectedYearGroups,
  selectedOthers,
  selectedStudentGroups,
  selectedSex,
  selectedLastLogin,
  lockedGroupsViewable,
  areas,
  groups,
  other,
  disabled,
  onFilterSelect,
  resetFilters,
}: IProps) => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xl={4} xs={12}>
          <Select
            value={selectedArea}
            fullWidth
            onChange={(event) =>
              onFilterSelect({
                area:
                  event.target.value === "default"
                    ? ""
                    : (event.target.value as string),
              })
            }
            options={areas.map(({ label, value }) => ({ label, value }))}
            label="Area"
            disabled={disabled}
            displayEmpty={true}
            defaultOption="All Areas"
          />
        </Grid>
        <Grid item xl={4} xs={12}>
          {groups.length <= 1 ? (
            <Tooltip
              title="Only one group is available for this wheel."
              placement="top"
            >
              <div>
                <Select
                  value={groups[0]?._id}
                  label="Student Group"
                  options={groups.map((studentGroup) => ({
                    label: studentGroup.name,
                    value: studentGroup._id,
                  }))}
                  disabled
                />
              </div>
            </Tooltip>
          ) : (
            <Select
              value={selectedStudentGroups}
              fullWidth
              multiple
              onChange={(event) =>
                onFilterSelect({ studentGroups: event.target.value as string })
              }
              options={groups.map((studentGroup) => ({
                label: studentGroup.name,
                value: studentGroup._id,
              }))}
              label="Student Group"
              disabled={disabled}
            />
          )}
        </Grid>
        <Grid item xl={4} xs={6}>
          <Select
            value={selectedYearGroups}
            fullWidth
            multiple
            onChange={(event) =>
              onFilterSelect({ yearGroups: event.target.value as string })
            }
            options={AVAILABLE_YEAR_GROUP_FILTER.map((yearGroup) => ({
              label: `Year ${yearGroup}`,
              value: `Year ${yearGroup}`,
            }))}
            label="Year Group"
            disabled={disabled}
          />
        </Grid>
        <Grid item xl={4} xs={6}>
          <Select
            value={selectedOthers}
            fullWidth
            multiple
            onChange={(event) =>
              onFilterSelect({ other: event.target.value as string })
            }
            options={other.map((otherItem) => ({
              label: otherItem,
              value: otherItem,
            }))}
            label="Other"
            disabled={disabled}
          />
        </Grid>
        <Grid item xl={6} xs={12}>
          <StyledCheckboxesContainer>
            <SexFilters
              selectedFilters={selectedSex}
              onUpdate={(values: string[]) => onFilterSelect({ sex: values })}
              disabled={disabled}
            />
            <LastLoginFilters
              selectedFilter={selectedLastLogin}
              onUpdate={(value: number) => onFilterSelect({ lastLogin: value })}
              disabled={disabled}
            />
            <LockedGroupFilter
              disabled={disabled}
              onUpdate={(value: boolean) =>
                onFilterSelect({ lockedGroupsViewable: value })
              }
              selected={lockedGroupsViewable}
            />
          </StyledCheckboxesContainer>
        </Grid>
        <Grid item xl={2} xs={12}>
          <Button
            color="primary"
            variant="outlined"
            onClick={resetFilters}
            fullWidth
            disabled={disabled}
          >
            clear filters
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default TableViewFilters;
