import React from "react";
import { IBreadCrumb } from "typings/main";
import styled from "styled-components";
import { Grid, Paper, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { KeyboardArrowRight } from "@material-ui/icons";
import palette from "palette";

const StyledHeadingContainer = styled(Paper)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 30px;
`;

const StyledBreadCrumbsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  @media (max-width: 576px) {
    justify-content: flex-start;
  }
`;

const StyledBreadCrumb = styled.span`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: ${palette.font.body};
`;

const StyledLink = styled(Link)`
  color: ${palette.primary};

  &:hover {
    color: ${palette.primary};
  }
`;

const StyledRightArrow = styled(KeyboardArrowRight)`
  font-size: 18px !important;
  margin: 0 5px;
`;

interface IProps {
  title: string;
  breadCrumbs: IBreadCrumb[];
}

const PageHeading: React.FC<IProps> = ({ title, breadCrumbs }: IProps) => {
  const renderBreadCrumbs = (): JSX.Element[] => {
    const preparedBreadCrumbs: JSX.Element[] = [];
    const breadCrumbsLength = breadCrumbs.length;

    breadCrumbs.forEach((breadCrumb, index) => {
      preparedBreadCrumbs.push(
        <StyledBreadCrumb key={`breadcrumb-${index}`}>
          {breadCrumbsLength - 1 === index ? (
            <>{breadCrumb.name}</>
          ) : (
            <>
              <StyledLink to={`/meta-mirror${breadCrumb.url}`}>
                {breadCrumb.name}
              </StyledLink>{" "}
              <StyledRightArrow />
            </>
          )}
        </StyledBreadCrumb>
      );
    });

    return preparedBreadCrumbs;
  };

  return (
    <StyledHeadingContainer>
      <Grid container direction="row">
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">{title}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledBreadCrumbsContainer>
            {renderBreadCrumbs()}
          </StyledBreadCrumbsContainer>
        </Grid>
      </Grid>
    </StyledHeadingContainer>
  );
};

export default PageHeading;
