import { Theme } from "@material-ui/core";
import Palette from "palette";

type ThemeInterface = Exclude<Theme["overrides"], undefined>;

const MuiButton: ThemeInterface["MuiButton"] = {
  root: {
    outlineWidth: "0px !important",
    textTransform: "initial",
    transition: "500ms",
    borderRadius: 10,
    height: 40,
    paddingLeft: 20,
    paddingRight: 20,
    boxShadow: "0 0 0 0 rgba(0,0,0,0) !important",
    fontSize: 18,
  },
  outlined: {
    border: "solid",
    borderWidth: 3,
    "&$disabled": {
      borderWidth: 3,
    },
  },
  outlinedPrimary: {
    border: "solid 3px",
    "&:hover": {
      borderWidth: 3,
      borderColor: Palette.primary,
      backgroundColor: Palette.primaryLight,
    },
    "&:active": {
      borderWidth: 3,
      borderColor: Palette.primary,
      backgroundColor: Palette.primaryLight,
    },
  },
  outlinedSecondary: {
    border: "solid",
    borderWidth: 3,
    borderColor: Palette.secondary,
    "&:hover": {
      borderWidth: 3,
      borderColor: Palette.secondary,
      backgroundColor: Palette.secondaryLight,
    },
    "&:active": {
      borderWidth: 3,
      borderColor: Palette.secondary,
      backgroundColor: Palette.secondaryLight,
    },
  },
  containedPrimary: {
    color: Palette.font.contrast,
    "&:hover": {},
  },
  containedSecondary: {
    color: `${Palette.font.contrast} !important`,
    "&:hover": {},
  },
  textPrimary: {
    "&:hover": {
      color: Palette.primaryDark,
      textDecoration: "underline",
    },
  },
};

export default MuiButton;
