export enum GRADE_COLORS {
  'disabled' = null,
  'attempting' = 0,
  'partial' = 0.5,
  'complete' = 1
}

export  enum GRADE_ICONS {
  '' = null,
  'uil uil-flask-potion' = 0,
  'uil uil-percentage' = 0.5,
  'uil uil-check' = 1
}
