import { Theme } from "@material-ui/core";
import palette from "palette";

type ThemeInterface = Exclude<Theme["overrides"], undefined>;

const MuiTableCell: ThemeInterface["MuiTableCell"] = {
  body: {
    color: palette.font.body,
  },
  head: {
    color: palette.font.heading,
  },
};

export default MuiTableCell;
