import { createMuiTheme } from "@material-ui/core";
import typography from "./Typography";
import { breakpoints } from "./Breakpoints";
import overrides from "./Overrides";
import Palette from "./palette";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: Palette.primary,
      dark: Palette.primaryDark,
    },
    secondary: {
      main: Palette.secondary,
      dark: Palette.secondaryDark,
    },
  },
  shape: {
    borderRadius: 10,
  },
  breakpoints,
  typography,
  overrides,
});

export default theme;
