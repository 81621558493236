import React, { Component } from "react";
import PageContainer from "components/common/PageContainer/PageContainer";
import styled from "styled-components";
import { CircularProgress, Paper, Typography } from "@material-ui/core";
import PageHeading from "components/common/PageHeading/PageHeading";
import { RouteComponentProps } from "react-router";
import { IBreadCrumb } from "typings/main";
import SchoolGroups from "components/admin/SchoolGroups";
import SchoolAssign from "components/admin/SchoolAssign";
import { IResponseBody } from "typings/global";
import AddGroupModal from "components/admin/AddGroupModal";
import { IGroupData, IGroupDataResponseBody } from "typings/group";
import { IFormattedWheelData } from "typings/meta-mirror";
import SchoolWheelItem from "components/admin/SchoolWheelItem";
import DeleteWheelModal from "components/admin/DeleteWheelModal";
import { getDetailedSchool, updateSchool } from "lib/school";
import { addGroup } from "lib/group";
import { updateMirror } from "lib/mirror";
import { withAuth } from "../../../hoc/withAuth";

const StyledLoaderContainer = styled.div`
  width: 100%;
  padding: 100px 0;
  text-align: center;
`;

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 30px 0;
`;

const StyledPaper = styled(Paper)`
  width: 100%;
  min-height: 100px;
  padding: 10px 25px;
  display: flex;
  flex-direction: row;
`;

const StyledContentBlock = styled.div`
  width: 100%;
  flex: 1;
  &:nth-child(n + 2) {
    margin: 30px 0;
  }
`;

const StyledWheelBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

interface IState {
  isLoading: boolean;
  _id: string;
  name: string;
  wheelsLimit: number;
  groups: IGroupData[];
  wheels: IFormattedWheelData[];
  addGroupModalOpen: boolean;
  deleteWheelModalOpen: boolean;
  deleteWheelId: string;
  deleteWheelName: string;
  mirrorLevelsType: string;
}

class DetailedSchoolPage extends Component<
  RouteComponentProps<{ schoolId: string }>,
  IState
> {
  state = {
    isLoading: true,
    addGroupModalOpen: false,
    _id: "",
    name: "",
    wheelsLimit: 0,
    groups: [{}],
    wheels: [],
    deleteWheelModalOpen: false,
    deleteWheelId: "",
    deleteWheelName: "",
    mirrorLevelsType: "",
  };

  componentDidMount(): void {
    this.getDetailedSchoolData();
  }

  protected getDetailedSchoolData = async (): Promise<void> => {
    this.setState({
      isLoading: true,
    });
    const { schoolId } = this.props.match.params;

    try {
      const result = await getDetailedSchool(schoolId);
      const { _id, name, wheelsLimit, groups, wheels, mirrorLevelsType } =
        result.data[0];
      this.setState({
        _id,
        name,
        wheels,
        wheelsLimit,
        groups,
        mirrorLevelsType: mirrorLevelsType ?? "sequential",
      });
      this.setState({
        isLoading: false,
      });
    } catch {}
  };

  protected updateSchoolDetails = async (
    schoolName: string,
    wheelsLimit: number,
    mirrorLevelsType: string
  ): Promise<IResponseBody> => {
    try {
      const { data } = await updateSchool(
        this.state._id,
        schoolName,
        wheelsLimit,
        mirrorLevelsType
      );
      this.setState({
        name: schoolName,
        wheelsLimit,
        mirrorLevelsType,
      });
      return data;
    } catch {
      return {};
    }
  };

  protected openAddGroupModal = (): void => {
    this.setState({ addGroupModalOpen: true });
  };

  protected closeAddGroupModal = (): void => {
    this.setState({ addGroupModalOpen: false });
  };

  protected addGroup = async (
    groupName: string
  ): Promise<IGroupDataResponseBody> => {
    try {
      const { data } = await addGroup(this.state._id, groupName);
      const { groups } = this.state;
      groups.push(data);
      this.setState({ groups });
      return data;
    } catch {
      return {};
    }
  };

  protected openDeleteModal = (wheelId: string, wheelName: string): void => {
    this.setState(
      {
        deleteWheelId: wheelId,
        deleteWheelName: wheelName,
      },
      () => {
        this.setState({ deleteWheelModalOpen: true });
      }
    );
  };

  protected closeDeleteModal = (): void => {
    this.setState({ deleteWheelModalOpen: false });
  };

  protected deleteWheel = async (): Promise<IResponseBody> => {
    const { deleteWheelId } = this.state;

    try {
      const { data } = await updateMirror({
        id: deleteWheelId,
        disabled: true,
      });
      const { wheels } = this.state;
      wheels.splice(
        wheels.indexOf(wheels.find((wheel) => wheel._id === deleteWheelId)),
        1
      );
      this.setState({ wheels });
      return data;
    } catch {
      return {};
    }
  };

  render() {
    const {
      name,
      groups,
      wheels,
      wheelsLimit,
      isLoading,
      addGroupModalOpen,
      deleteWheelId,
      deleteWheelName,
      deleteWheelModalOpen,
      mirrorLevelsType,
    } = this.state;
    const breadCrumbs: IBreadCrumb[] = [
      {
        url: "/admin/schools",
        name: "Schools",
      },
      {
        url: "",
        name,
      },
    ];

    return (
      <PageContainer>
        <AddGroupModal
          open={addGroupModalOpen}
          handleAddGroup={this.addGroup}
          handleClose={this.closeAddGroupModal}
        />
        <PageHeading title={name} breadCrumbs={breadCrumbs} />
        {isLoading ? (
          <StyledLoaderContainer>
            <CircularProgress />
            <Typography variant="body2" className="mt-3">
              Loading...
            </Typography>
          </StyledLoaderContainer>
        ) : (
          <StyledContentContainer>
            <StyledContentBlock>
              <StyledPaper>
                <SchoolAssign
                  name={name}
                  wheelsLimit={wheelsLimit}
                  mirrorLevelsType={mirrorLevelsType}
                  handleUpdateDetails={this.updateSchoolDetails}
                  setMirrorLevelsType={(v) =>
                    this.setState({
                      mirrorLevelsType: v,
                    })
                  }
                />
              </StyledPaper>
            </StyledContentBlock>
            <StyledContentBlock>
              <StyledPaper>
                <SchoolGroups
                  data={groups}
                  isLoading={isLoading}
                  handleNewGroup={this.openAddGroupModal}
                />
              </StyledPaper>
            </StyledContentBlock>
            <StyledWheelBlock>
              {wheels?.map((wheel: IFormattedWheelData) => (
                <SchoolWheelItem
                  key={wheel._id}
                  handleDelete={() =>
                    this.openDeleteModal(wheel._id, wheel.name)
                  }
                  {...wheel}
                />
              ))}
            </StyledWheelBlock>
            <DeleteWheelModal
              open={deleteWheelModalOpen}
              handleDeleteWheel={this.deleteWheel}
              handleClose={this.closeDeleteModal}
              wheelId={deleteWheelId}
              wheelName={deleteWheelName}
              schoolName={name}
            />
          </StyledContentContainer>
        )}
      </PageContainer>
    );
  }
}

export default withAuth(DetailedSchoolPage, ["admin"]);
