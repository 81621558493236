import React, { useState } from "react";
import { S } from "./styles";
import {
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { IGroup, IStudent } from "@rose-arch-holdings-ltd/common";
import { getStudentGroupStatus } from "lib/group";

interface IProps {
  isLoading: boolean;
  students: IStudent[];
  groups: IGroup[];
  allGroups: IGroup[];
  group: IGroup;
  selectedStudents: string[];
  setSelectedStudents: (val: string[]) => void;
}

export const GroupStudentList: React.FC<IProps> = ({
  isLoading,
  students,
  groups,
  allGroups,
  group,
  selectedStudents,
  setSelectedStudents,
}) => {
  const studentRows = students
    .map((student) => {
      const groupStatus = getStudentGroupStatus(student, group._id.toString());
      return { ...student, groupStatus };
    })
    .sort((a, b) => {
      if (a.groupStatus === "added" && b.groupStatus !== "added") {
        return -1;
      }
      if (a.groupStatus === "locked" && b.groupStatus !== "added") {
        return -1;
      }
      return 0;
    });

  return (
    <S.Container>
      {isLoading ? (
        <S.InfoContainer>
          <CircularProgress />
          <Typography variant="body2" className="mt-3">
            Loading...
          </Typography>
        </S.InfoContainer>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Checkbox
                  color="default"
                  onChange={(event) => {
                    if (event.target.checked) {
                      setSelectedStudents(
                        students.map((student) => student._id.toString())
                      );
                    } else {
                      setSelectedStudents([]);
                    }
                  }}
                  disabled={!students.length}
                />
              </TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Active Student Groups</TableCell>
              <TableCell align="center">Year Group</TableCell>
              <TableCell align="center">Other</TableCell>
              <TableCell align="center">Group Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!students && studentRows.length ? (
              <>
                {studentRows.map((student, studentIndex) => {
                  console.log({ student });
                  return (
                    <TableRow key={`student-${studentIndex}`}>
                      <TableCell align="center">
                        <Checkbox
                          color="default"
                          checked={selectedStudents.includes(
                            student._id.toString()
                          )}
                          onChange={(event) => {
                            if (event.target.checked) {
                              setSelectedStudents([
                                student._id.toString(),
                                ...selectedStudents,
                              ]);
                            } else {
                              setSelectedStudents([
                                ...selectedStudents.filter(
                                  (s) => s !== student._id.toString()
                                ),
                              ]);
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">{student.name}</TableCell>
                      <TableCell align="center">
                        {student.mm_group_ids
                          .map(
                            (id) => allGroups?.find((g) => g._id === id)?.name
                          )
                          .filter((n) => n)
                          .join(", ")}
                      </TableCell>
                      <TableCell align="center">
                        {Array.isArray(student?.year_group)
                          ? student.year_group[0]
                          : student?.year_group}
                      </TableCell>
                      <TableCell align="center">
                        {student?.other?.join(", ")}
                      </TableCell>
                      <TableCell align="center">
                        {student.groupStatus === "added" ? (
                          <S.Icon
                            className="uil uil-user-check"
                            style={{ color: "green" }}
                          />
                        ) : student.groupStatus === "locked" ? (
                          <S.Icon className="uil uil-padlock" />
                        ) : (
                          <S.Icon className="uil uil-times" />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={5}>
                  <S.NoDataContainer>No data found</S.NoDataContainer>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter></TableFooter>
        </Table>
      )}
    </S.Container>
  );
};
